import React from 'react';
import * as R from 'ramda';
import { optionalAddressValidators, ifNotEmpty } from '@poly/client-utils';
import { Checkbox, Input } from '@poly/admin-book';
import { propEqLegacy, validationFuncWithAllValues } from '@poly/utils';
import { TaxClassification } from '@poly/constants';
import styled from 'styled-components';
import { func } from 'prop-types';

import { halfWidth } from '../common.js';
import {
  ExemptSupplierSalesTaxCheckbox,
  SupplierFormCheckbox,
} from '../components/Checkbox.js';
import { AddressSection } from '../../../fields/AddressSection/index.js';
import { ChooseFileField } from '../../../fields/ChooseFileField.js';
import { SocialSecurityInput } from '../components/SocialSecurityInput.js';
import { TaxIDInput } from '../components/TaxIDInput.js';
import { TaxClassSelect } from '../components/TaxClassSelect.js';
import { isAddressesEquals, isSoleProprietorship } from '../utils.js';

const taxIdRegExp = /^(?:(\d{2}-\d{7})|(\d{3}-\d{2}-\d{4})|(\d{9}))$/;

const CheckBoxS = styled(Checkbox)`
  margin-top: 34px;
  margin-left: 15px;
`;

function TaxAddress(props) {
  return (
    <AddressSection labelPrefix="Tax" required={false} isClearable {...props} />
  );
}

// skipValidationIfTaxAbsent :: ((String, FormData) -> Boolean) -> (String, FormData) -> Boolean
const skipValidationIfTaxAbsent = (validateFn) => (value, supplier) =>
  R.ifElse(
    R.either(R.path(['tax', 'absentTaxId']), R.path(['tax', 'exempt'])),
    R.T,
    () => validateFn(value, supplier),
  )(supplier);

// validateSupplierTaxId :: (String, FormData) -> Boolean
// eslint-disable-next-line import/no-unused-modules
export const validateSupplierTaxId = (taxId, supplier) =>
  R.ifElse(
    R.compose(
      R.equals(TaxClassification.SOLE_PROPRIETORSHIP),
      R.path(['tax', 'classification']),
    ),
    R.ifElse(R.path(['tax', 'socialSecurityNumber']), R.T, R.always(!!taxId)),
    R.always(!!taxId),
  )(supplier);

// validateSupplierSSN :: (String, FormData) -> Boolean
// eslint-disable-next-line import/no-unused-modules
export const validateSupplierSSN = (ssn, supplier) =>
  R.ifElse(
    R.compose(
      R.equals(TaxClassification.SOLE_PROPRIETORSHIP),
      R.path(['tax', 'classification']),
    ),
    R.ifElse(R.path(['tax', 'id']), R.T, R.always(!!ssn)),
    R.T,
  )(supplier);

function AbsentTaxIdCheckBox({ changeFieldValue, onChange, ...props }) {
  const handleChange = (value) => {
    if (value) {
      changeFieldValue('tax.id', null);
      changeFieldValue('tax.socialSecurityNumber', null);
    }
    onChange(value);
  };
  return <CheckBoxS onChange={handleChange} {...props} />;
}

AbsentTaxIdCheckBox.propTypes = {
  changeFieldValue: func.isRequired,
  onChange: func.isRequired,
};

export const supplierTaxSection = (ConvertSupplierComponent) => [
  {
    label: 'Tax Info',
    order: 6,
    layout: { column: 2 },
    fields: [
      {
        label: 'Is 1099',
        order: 1,
        layout: { row: 1, width: '50%' },
        field: {
          name: 'tax.is1099',
          Component: SupplierFormCheckbox,
        },
      },
      {
        label: 'W9',
        order: 8,
        layout: { row: 1, width: '50%' },
        field: {
          name: 'tax.w9File',
          withFormData: true,
          Component: R.ifElse(
            R.path(['formData', 'tax', 'exempt']),
            R.always('Exempt'),
            ChooseFileField,
          ),
        },
      },
      {
        label: 'Tax Class',
        order: 2,
        layout: { row: 2, width: halfWidth },
        field: {
          name: 'tax.classification',
          withChangeFieldValue: true,
          Component: TaxClassSelect,
        },
      },
      {
        label: 'Tax ID NO',
        order: 3,
        layout: { row: 2, width: '25%' },
        field: {
          name: 'tax.id',
          withFormData: true,
          Component: TaxIDInput,
        },
        validateFunction: validationFuncWithAllValues,
        validators: [
          [ifNotEmpty(R.test(taxIdRegExp)), 'Tax id is not correct'],
          [
            skipValidationIfTaxAbsent(validateSupplierTaxId),
            'Tax ID is required',
          ],
        ],
      },
      {
        order: 4,
        layout: { row: 2, width: '24%' },
        field: {
          name: 'tax.absentTaxId',
          Component: AbsentTaxIdCheckBox,
          withChangeFieldValue: true,
          additionalProps: {
            label: 'Currently Absent',
          },
        },
      },
      {
        label: 'Social Security #',
        order: 5,
        layout: { row: 3 },
        field: {
          name: 'tax.socialSecurityNumber',
          withFormData: true,
          Component: SocialSecurityInput,
        },
        validators: [
          [
            ifNotEmpty(propEqLegacy('length', 9)),
            'Social Security is not correct',
          ],
          [
            skipValidationIfTaxAbsent(validateSupplierSSN),
            'Social Security is required',
          ],
        ],
        validateFunction: validationFuncWithAllValues,
        renderIf: isSoleProprietorship,
      },
      {
        order: 6,
        layout: { row: 4 },
        label: 'Exempt a Supplier from Sales Tax',
        field: {
          name: 'tax.exemptSupplierSalesTax',
          Component: ExemptSupplierSalesTaxCheckbox,
        },
      },
      {
        order: 7,
        layout: { row: 5 },
        field: {
          name: 'tax.address',
          Component: TaxAddress,
          isEqual: isAddressesEquals,
        },
        validators: optionalAddressValidators,
      },
      {
        label: 'Tax Name',
        order: 8,
        layout: { row: 6, width: halfWidth },
        field: {
          name: 'tax.name',
          Component: Input,
        },
      },
      {
        label: 'Exempt/Utility',
        order: 9,
        layout: { row: 6, width: halfWidth },
        field: {
          name: 'tax.exempt',
          Component: SupplierFormCheckbox,
        },
      },
      {
        order: 10,
        layout: { row: 7 },
        field: {
          name: 'allowToConvert',
          Component: ConvertSupplierComponent,
        },
        renderIf: R.prop('allowToConvert'),
      },
    ],
  },
];
