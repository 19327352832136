import * as R from 'ramda';
import { ALL } from '@poly/admin-ui';
import { RecurringProjectTypes } from '@poly/constants';

import {
  FieldOperation,
  BulkEditProjectFieldValues,
  ProjectTypeSelectOptionValues,
} from '../constants.js';

// convertToArrayValue :: String|[String] -> [String]
export const convertToArrayValue = R.ifElse(
  R.is(Array),
  R.map(R.prop('value')),
  R.of(Array),
);

// {
//   "input": {
//       "query": {
//           "bool": {
//               "must": [
//                   {
//                       "terms": {
//                           "type": [
//                               "GLOBAL_ALL_IDENTIFIER"
//                           ]
//                       }
//                   }
//               ]
//           }
//       },
//       "from": 0,
//       "size": 25
//   }
// }

// isValuesIncludeGlobalALL :: [String] -> Boolean
const isValuesIncludeGlobalALL = R.includes(ALL);

export const getCommonTermsConfigs = (field) => ({
  [FieldOperation.EQ]: (value) => {
    if (isValuesIncludeGlobalALL([value])) {
      return {};
    }

    return {
      terms: { [field]: convertToArrayValue(value) },
    };
  },
  [FieldOperation.IN]: (value) => {
    const values = convertToArrayValue(value);

    if (isValuesIncludeGlobalALL(values)) {
      return {};
    }

    return { terms: { [field]: values } };
  },
  [FieldOperation.NIN]: (value) => {
    const values = convertToArrayValue(value);

    if (isValuesIncludeGlobalALL(values)) {
      // match not existing value to simplify query
      return { terms: { [field]: [ALL] } };
    }

    return {
      bool: { must_not: { terms: { [field]: convertToArrayValue(value) } } },
    };
  },
});

const getCityQueryPartByValue = (value) => ({
  match: {
    [BulkEditProjectFieldValues.CITY]: {
      fuzziness: 'AUTO',
      operator: 'and',
      query: value,
    },
  },
});

const ProjectQueryConfig = {
  [BulkEditProjectFieldValues.STATUS]: getCommonTermsConfigs(
    BulkEditProjectFieldValues.STATUS,
  ),
  [BulkEditProjectFieldValues.ACCOUNTING_STATUS]: getCommonTermsConfigs(
    BulkEditProjectFieldValues.ACCOUNTING_STATUS,
  ),
  [BulkEditProjectFieldValues.COST_TYPE]: getCommonTermsConfigs(
    BulkEditProjectFieldValues.COST_TYPE,
  ),
  [BulkEditProjectFieldValues.CLIENT_NAME]: getCommonTermsConfigs(
    BulkEditProjectFieldValues.CLIENT_NAME,
  ),
  [BulkEditProjectFieldValues.AA_MANAGER]: getCommonTermsConfigs(
    BulkEditProjectFieldValues.AA_MANAGER,
  ),
  [BulkEditProjectFieldValues.PROPERTY_NAME]: getCommonTermsConfigs(
    BulkEditProjectFieldValues.PROPERTY_NAME,
  ),
  [BulkEditProjectFieldValues.SERVICE_TYPE]: getCommonTermsConfigs(
    BulkEditProjectFieldValues.SERVICE_TYPE,
  ),
  [BulkEditProjectFieldValues.STATE]: getCommonTermsConfigs(
    BulkEditProjectFieldValues.STATE,
  ),
  [BulkEditProjectFieldValues.CITY]: {
    [FieldOperation.EQ]: getCityQueryPartByValue,
    [FieldOperation.IN]: (values) => ({
      bool: {
        should: convertToArrayValue(values).map(getCityQueryPartByValue),
        minimum_should_match: 1,
      },
    }),
    [FieldOperation.NIN]: (values) => ({
      bool: {
        must_not: convertToArrayValue(values).map(getCityQueryPartByValue),
      },
    }),
  },
  [BulkEditProjectFieldValues.PROJECT_TYPE]: {
    [FieldOperation.EQ]: {
      [ProjectTypeSelectOptionValues.PROJECT]: {
        bool: { must_not: { exists: { field: 'parentId' } } },
      },
      [ProjectTypeSelectOptionValues.CHILD_RECURRING]: {
        bool: {
          must: [
            { exists: { field: 'parentId' } },
            {
              match: { 'parent.type': RecurringProjectTypes.recurringProject },
            },
          ],
        },
      },
      [ProjectTypeSelectOptionValues.MASTER_RECURRING]: {
        match: { type: RecurringProjectTypes.recurringProject },
      },
      [ProjectTypeSelectOptionValues.CHILD_PM]: {
        bool: {
          must: [
            { exists: { field: 'parentId' } },
            {
              match: {
                'parent.type':
                  RecurringProjectTypes.preventiveMaintenanceProject,
              },
            },
          ],
        },
      },
      [ProjectTypeSelectOptionValues.PM]: {
        match: { type: RecurringProjectTypes.preventiveMaintenanceProject },
      },
    },
  },
};

// checkIsConfigExists :: (String, String) -> Object -> Boolean
export const checkIsConfigExists = (field, fieldOperation) =>
  R.compose(
    R.complement(R.isNil),
    R.path([field, fieldOperation]),
  )(ProjectQueryConfig);

export const prepareSingleDatelessRule = ({
  field,
  fieldOperation,
  fieldValue,
}) => {
  const isConfigExists = checkIsConfigExists(field, fieldOperation);

  if (isConfigExists && field === BulkEditProjectFieldValues.PROJECT_TYPE) {
    return ProjectQueryConfig[field][fieldOperation][fieldValue] || [];
  }

  if (isConfigExists) {
    return ProjectQueryConfig[field][fieldOperation](fieldValue);
  }

  return [];
};

// isDateRule :: RuleLine -> Boolean
const isDateRule = R.propSatisfies(
  R.includes(R.__, [
    BulkEditProjectFieldValues.START,
    BulkEditProjectFieldValues.END,
  ]),
  'field',
);

const prepareSingleDateRule = ({ field, fieldOperation, fieldValue }) => ({
  [field]: { [fieldOperation]: fieldValue },
});

// getQueryInputWithRulesCondition :: [RuleLine] -> CollectionSearchParams
const getQueryInputWithRulesCondition = R.curry((rulesCond, originalRules) =>
  R.compose(
    R.assoc('bool', R.__, {}),
    R.reject(R.isEmpty),
    R.mergeAll,
    R.flatten,
    R.juxt([
      R.compose(
        R.assoc(rulesCond, R.__, {}),
        R.reject(R.isEmpty),
        R.flatten,
        R.map(prepareSingleDatelessRule),
        R.reject(isDateRule),
        R.reject(R.isEmpty),
      ),
      R.compose(
        R.ifElse(
          R.isEmpty,
          R.always([]),
          R.compose(R.objOf('filter'), R.map(R.assoc('range', R.__, {}))),
        ),
        R.map(prepareSingleDateRule),
        R.filter(isDateRule),
      ),
    ]),
  )(originalRules),
);

// prepareProjectQueryInput :: [RuleLine] -> CollectionSearchParams
// RuleLine = {
//    objectType: String
//    rulesAndOrCondition: { and: Boolean, or: Boolean }
//    field: String
//    fieldOperation: String
//    fieldValue: String | [String]
// }
export const prepareProjectQueryInput = R.converge(
  getQueryInputWithRulesCondition,
  [
    R.ifElse(
      R.path(['0', 'rulesAndOrCondition', 'and']),
      R.always('must'),
      R.always('should'),
    ),
    R.tail,
  ],
);
