import * as R from 'ramda';
import { isNilOrEmpty } from '@poly/utils';
import { endOfDay, startOfDay } from 'date-fns';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import {
  MAX_ITEMS,
  usePaginationParams,
  useSetItemsCount,
} from '@poly/admin-ui';

const detailedClientSurveysReportQuery = gql`
  query detailedClientSurveysReportQuery(
    $input: DetailedClientSurveysReportInput!
  ) {
    detailedClientSurveysReport(input: $input) {
      total
      hits {
        _id
        projectId
        receivedAt
        question
        answer
        client {
          _id
          name
        }
      }
    }
  }
`;

// prepareDetailedClientSurveysReportInput :: PageFilters -> DetailedClientSurveysReportInput
const prepareDetailedClientSurveysReportInput = R.compose(
  R.reject(R.isNil),
  R.applySpec({
    clientId: R.prop('clientId'),
    projectId: R.prop('projectId'),
    receivedFrom: R.compose(
      startOfDay,
      R.constructN(1, Date),
      R.prop('dateFrom'),
    ),
    receivedTo: R.compose(endOfDay, R.constructN(1, Date), R.prop('dateTo')),
  }),
);

export const useDetailedClientSurveysReportQuery = ({
  sort,
  queryFilters,
  isUserWithPermission,
}) => {
  const paginationParams = usePaginationParams();

  const { data, loading } = useQuery(detailedClientSurveysReportQuery, {
    variables: {
      input: {
        sort,
        ...prepareDetailedClientSurveysReportInput(queryFilters),
        ...paginationParams,
      },
    },
    skip: isNilOrEmpty(queryFilters) || !isUserWithPermission,
  });

  useSetItemsCount(R.pathOr(0, ['detailedClientSurveysReport', 'total']), data);

  return { data, loading };
};

export const useDetailedClientSurveysReportLazyQuery = () => {
  const [queryHandler] = useLazyQuery(detailedClientSurveysReportQuery);

  const handler = async ({ filter, sort }) => {
    const response = await queryHandler({
      fetchPolicy: 'network-only',
      variables: {
        input: {
          ...prepareDetailedClientSurveysReportInput(filter),
          sort,
          size: MAX_ITEMS,
        },
      },
    });

    return response;
  };

  return handler;
};
