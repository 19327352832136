import * as R from 'ramda';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { string, func, shape } from 'prop-types';
import { gql, useQuery, useMutation } from '@apollo/client';
import { UserEmployeeInfoStatus, UserRelatedEntities } from '@poly/constants';
import { usePristineSubscribe } from '@poly/client-routing';
import { removePropDeep } from '@poly/utils';
import {
  useOnSubmitSetStopSubmitting,
  commonSidebarFormLayout,
  employeeStatusColors,
  adminUserQuery,
  UserSelect,
} from '@poly/admin-ui';
import {
  useNotificationContext,
  getThemeColor,
  EntityStatus,
  FormCreator,
  Loader,
} from '@poly/admin-book';

const USER_FOR_REASSIGN_QUERY = gql`
  query USER_FOR_REASSIGN_QUERY($id: ID!) {
    user(id: $id) {
      _id
      profile {
        fullName
      }
      employeeInfo {
        status
        startDate
      }
      isContractor
    }
  }
`;

const REASSIGN_USER_MUTATION = gql`
  mutation REASSIGN_USER_MUTATION($input: ReassignUserInput!) {
    reassignUser(input: $input) {
      reassigned
    }
  }
`;

const USER_RELATED_ENTITIES_QUERY = gql`
  query USER_RELATED_ENTITIES_QUERY($id: ID!) {
    userRelatedEntities(id: $id) {
      tasksCount
      mentionGroupsCount
      clientManagerCount
      supplierManagerCount
      projectRequesterCount
      projectAssignedCSRCount
      projectSiteContactCount
      propertyAssignedCSRCount
      propertyBranchManagerCount
      propertyDistrictManagerCount
    }
  }
`;

const ReassignUserFormRowS = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 14px 24px;
  border-bottom: 1px solid ${getThemeColor(['secondaryLighter5'])};
  font-size: 20px;
  color: ${getThemeColor(['darkest'])};
  line-height: 24px;
`;

const ReassignUserFormSubTitleS = styled(ReassignUserFormRowS)`
  padding-top: 0;
  font-size: 14px;
  color: ${getThemeColor(['midDark'])};
  line-height: 22px;
`;

const ReassignUserFormEntitiesS = styled(ReassignUserFormRowS)`
  font-size: 12px;
  color: ${getThemeColor(['black'])};
  line-height: 16px;
  border-bottom: unset;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: start;
  position: relative;

  > span {
    width: 100%;
    padding-bottom: 8px;
  }

  > div:nth-child(4) {
    position: absolute;
    top: 70px;
    left: calc(50% + 10px);
  }
`;

const ReassignUserCountsWrapperS = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: calc(50% - 6px);
  padding: 4px;
  border: 1px solid ${getThemeColor(['midLight'])};
  background-color: ${getThemeColor(['white'])};
  border-radius: 3px;
`;

const ReassignUserEntityItemS = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 4px;
  margin: 2px;
  background-color: ${getThemeColor(['accent2Lighter4'])};
  border-radius: 4px;
  font-size: 12px;
  color: ${getThemeColor(['dark'])};
  line-height: 12px;
`;

const EntityStatusS = styled(EntityStatus)`
  > div:first-child {
    margin-left: 16px;
  }
`;

// prepareUserData :: UserQueryResult -> FormData
const prepareUserData = R.compose(
  R.applySpec({
    userStatus: R.pathOr(UserEmployeeInfoStatus.INACTIVE, [
      'employeeInfo',
      'status',
    ]),
    userFullName: R.path(['profile', 'fullName']),
    isContractor: R.propOr(false, 'isContractor'),
  }),
  R.propOr({}, 'user'),
);

function ReassignUserFormHeader({ formData }) {
  const { currentUserId } = formData;
  const subTitle = 'You can reassign all entities to a single user.';

  const { data, loading } = useQuery(USER_FOR_REASSIGN_QUERY, {
    variables: { id: currentUserId },
    fetchPolicy: 'network-only',
  });

  const { userStatus, userFullName, isContractor } = useMemo(
    () => prepareUserData(data),
    [data],
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <ReassignUserFormSubTitleS>{subTitle}</ReassignUserFormSubTitleS>
      <ReassignUserFormRowS>
        {userFullName}
        <EntityStatusS
          title={isContractor ? 'Contractor' : 'Employee'}
          status={{
            text: R.toLower(userStatus),
            color: employeeStatusColors[userStatus],
          }}
        />
      </ReassignUserFormRowS>
    </>
  );
}

ReassignUserFormHeader.propTypes = {
  formData: shape({ currentUserId: string.isRequired }).isRequired,
};

// prepareUserRelatedEntitiesCounts :: UserRelatedEntitiesResult -> [String]
const prepareUserRelatedEntitiesCounts = R.compose(
  R.map(
    R.compose(
      R.join(' '),
      R.juxt([
        R.compose(R.toString, R.nth(1)),
        R.compose(R.prop(R.__, UserRelatedEntities), R.nth(0)),
      ]),
    ),
  ),
  R.toPairs,
  removePropDeep('__typename'),
  R.propOr({}, 'userRelatedEntities'),
);

function ReassignUserAllEntities({ formData, ...props }) {
  const { currentUserId } = formData;

  const { data, loading } = useQuery(USER_RELATED_ENTITIES_QUERY, {
    variables: { id: currentUserId },
    fetchPolicy: 'network-only',
  });

  const userRelatedEntitiesCounts = useMemo(
    () => prepareUserRelatedEntitiesCounts(data),
    [data],
  );

  const query = {
    bool: { must: adminUserQuery, must_not: { term: { _id: currentUserId } } },
  };

  return (
    <ReassignUserFormEntitiesS>
      <span>All Entities</span>
      <ReassignUserCountsWrapperS>
        {loading ? (
          <Loader />
        ) : (
          userRelatedEntitiesCounts.map((item) => (
            <ReassignUserEntityItemS key={item}>{item}</ReassignUserEntityItemS>
          ))
        )}
      </ReassignUserCountsWrapperS>
      <UserSelect
        {...props}
        withoutSkip
        query={query}
        width="calc(50% - 6px)"
        placeholder="Enter user name"
      />
    </ReassignUserFormEntitiesS>
  );
}

ReassignUserAllEntities.propTypes = {
  formData: shape({ currentUserId: string.isRequired }).isRequired,
};

const reassignUserFormSections = [
  {
    order: 1,
    layout: { margin: 0 },
    fields: [
      {
        order: 1,
        layout: { row: 1, width: '100%' },
        field: {
          name: 'userInfo',
          withFormData: true,
          Component: ReassignUserFormHeader,
        },
      },
      {
        order: 2,
        required: true,
        layout: { row: 2, width: '100%' },
        field: {
          withFormData: true,
          name: 'reassignUserId',
          Component: ReassignUserAllEntities,
        },
        validators: [[R.identity, 'User to reassign is required']],
      },
    ],
  },
];

export function ReassignUserForm({ userId, onCancel, formId }) {
  const [reassignUser] = useMutation(REASSIGN_USER_MUTATION);
  const { showSuccessNotification } = useNotificationContext();
  const pristineSubscribeProps = usePristineSubscribe({ id: formId });

  const onSubmitHandler = async (input) => {
    await reassignUser({ variables: { input } });
    showSuccessNotification('All entities were successfully reassigned');
    onCancel();
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(formId, onSubmitHandler);

  return (
    <FormCreator
      id={formId}
      onSubmit={onSubmit}
      keepDirtyOnReinitialize
      layout={commonSidebarFormLayout}
      sections={reassignUserFormSections}
      initialValues={{ currentUserId: userId }}
      {...pristineSubscribeProps}
    />
  );
}

ReassignUserForm.propTypes = {
  userId: string.isRequired,
  formId: string.isRequired,
  onCancel: func.isRequired,
};
