import * as R from 'ramda';
import React, { useState } from 'react';
import { MultiSelectDropDown } from '@poly/admin-ui';
import { arrayOf, func, oneOfType, shape, string } from 'prop-types';

// prepareEmailsValue :: [Option] -> [Option]
const prepareEmailsValue = R.compose(R.defaultTo([]));

export function MultiInput({ value, onChange, ...props }) {
  const [enteredString, setEnteredString] = useState('');

  const options = enteredString
    ? [{ label: enteredString, value: enteredString }]
    : [];

  return (
    <MultiSelectDropDown
      options={prepareEmailsValue(options)}
      onInputChange={setEnteredString}
      value={prepareEmailsValue(value)}
      handleChange={onChange}
      placeholder="Start typing emails"
      {...props}
    />
  );
}

MultiInput.propTypes = {
  value: oneOfType([arrayOf(shape({ value: string, label: string })), string]),
  onChange: func.isRequired,
};
