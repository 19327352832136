import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { string } from 'prop-types';
import { CancelBtn, SubmitBtn } from '@poly/admin-ui';

const BulkEditFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding: 0 65px;
  height: ${R.prop('height')};
`;

export function BulkEditFooter({ height }) {
  // logic for "onCancel" and "onSubmit"
  // will be added in next tasks
  const onCancel = () => null;
  const onSubmit = () => null;

  return (
    <BulkEditFooterWrapper height={height}>
      <CancelBtn disabled onClick={onCancel} />
      <SubmitBtn disabled onClick={onSubmit}>
        Edit
      </SubmitBtn>
    </BulkEditFooterWrapper>
  );
}

BulkEditFooter.propTypes = {
  height: string,
};
