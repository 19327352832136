import React from 'react';
import { string, element, shape } from 'prop-types';
import { Loader } from '@poly/admin-book';
import { SidebarSubTabs } from '@poly/admin-ui';

import { projectStatusesUI } from '../../../modules/core/constants/projects.js';
import { CommonProjectsTabTable } from '../commonSidebarComponents.js';
import { projectsSubTabsConfig } from '../../../modules/tabs/common.js';
import { useSearchSubTabsProjectsQuery } from './useSearchSubTabsProjectsQuery.js';
import { TabTitleBase } from '../../../modules/tabs/projectsTab/ProjectsTabTitle.js';

function CommonProjectsTabTitle({ status, ...restProps }) {
  const { count } = useSearchSubTabsProjectsQuery({ status, ...restProps });

  return (
    <TabTitleBase
      status={status}
      count={count}
      text={projectStatusesUI[status]}
    />
  );
}

CommonProjectsTabTitle.propTypes = { status: string.isRequired };

function CommonProjectsTab(props) {
  const { loading, tableProps } = useSearchSubTabsProjectsQuery(props);

  if (loading) return <Loader />;

  return <CommonProjectsTabTable {...tableProps} />;
}

export function CommonProjectsSubTabs({ toolBar, searchTerm, entity }) {
  return (
    <SidebarSubTabs
      tabs={projectsSubTabsConfig.map(([status, title]) => [
        <CommonProjectsTabTitle {...{ status, searchTerm, entity }} />,
        title,
        <CommonProjectsTab {...{ status, searchTerm, entity }} />,
      ])}
      defaultValue="all"
      toolBar={toolBar}
    />
  );
}

CommonProjectsSubTabs.propTypes = {
  toolBar: element,
  searchTerm: string,
  entity: shape({
    _id: string.isRequired,
    name: string.isRequired,
  }),
};
