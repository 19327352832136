import React, { useState, useMemo } from 'react';
import { Loader } from '@poly/admin-book';
import { ASC_SORT_ORDER } from '@poly/constants';
import { useTableSorting } from '@poly/admin-ui';
import { useTableInfiniteScrollQuery } from '@poly/client-utils';

import {
  PrintChecksTable,
  GET_SUPPLIER_CHECKS_QUERY,
} from './PrintChecksTable.js';
import { defaultSearchFilters } from './printChecksConstants.js';
import { PrintChecksPageHeader } from './PrintChecksPageHeader.js';
import { PageWithSearchHeader } from '../../components/PageWithSearchHeader.js';
import { printChecksTableConfig } from './PrintChecksTableConfig.js';
import { prepareSupplierChecksFilters } from './printChecksUtils.js';

export function PrintChecksPage() {
  const [filters, setFilters] = useState(defaultSearchFilters);
  const [selectedChecks, setSelectedChecks] = useState([]);

  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig: printChecksTableConfig,
    order: ASC_SORT_ORDER,
    column: 7,
  });

  const input = useMemo(
    () => prepareSupplierChecksFilters(sort)(filters),
    [filters, sort],
  );

  const { data, loading, tableProps } = useTableInfiniteScrollQuery(
    GET_SUPPLIER_CHECKS_QUERY,
    input,
    {
      pageSize: 100,
      endpointName: 'searchAdminChecks',
    },
  );

  return (
    <PageWithSearchHeader headerHeight="180px">
      <PrintChecksPageHeader
        loading={loading}
        setFilters={setFilters}
        setSelectedChecks={setSelectedChecks}
      />
      {loading ? (
        <Loader />
      ) : (
        <PrintChecksTable
          data={data}
          tableProps={{ ...tableProps, ...tableSortingProps }}
          filters={filters}
          selectedChecks={selectedChecks}
          setSelectedChecks={setSelectedChecks}
        />
      )}
    </PageWithSearchHeader>
  );
}
