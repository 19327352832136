import React from 'react';
import * as R from 'ramda';
import { string, shape, bool } from 'prop-types';
import { EntityStatus, LinkButton } from '@poly/admin-book';
import {
  getDateByPropWithTime,
  getUIMasterProjectStatusText,
  pathOrNothingUI,
} from '@poly/client-utils';
import {
  masterProjectStatusesColors,
  FlexContainer,
  SidebarRow,
} from '@poly/admin-ui';

import {
  SidebarLabel,
  BlockWithLabel,
  SectionWrapper,
  headerTextProps,
} from '../components/commonSidebarComponents.js';
import { SidebarIDs } from '../constants.js';
import { masterProjectSidebarTabsId } from './constants.js';
import { OpenFullInfoSidebarButton } from '../components/OpenFullInfoSidebarButton.js';
import { useOpenMasterRecurringProjectSidebarTabs } from './tabs/useOpenMasterRecurringProjectSidebarTabs.js';
import { useEditProjectSidebar } from '../ProjectSidebar/forms/edit/useEditProjectSidebar.js';
import { getUserFriendlyProjectTypeName } from '../ProjectSidebar/projectSidebarUtils.js';
import {
  getStartEndDateSection,
  getSectionText,
} from '../components/commonSidebarSectionFormatters.js';
import { checkProjectExpired } from '../components/commonSidebarUtils.js';

function EditButton({ project, isCard }) {
  const openEditProjectSidebar = useEditProjectSidebar(isCard);

  const onClick = () => openEditProjectSidebar({ project });

  return (
    <LinkButton disabled={!project} onClick={onClick}>
      Edit
    </LinkButton>
  );
}

EditButton.propTypes = {
  isCard: bool,
  project: shape({ _id: string.isRequired }),
};

// getMREndDate :: Project -> String
const getMREndDate = R.ifElse(
  R.propSatisfies(R.isNil, 'endDate'),
  R.always('Never'),
  getDateByPropWithTime('endDate'),
);

export function MasterRecurringProjectSidebarHeader({
  project,
  isCard = false,
}) {
  const openFullProject = useOpenMasterRecurringProjectSidebarTabs(project);

  return (
    <SectionWrapper>
      <SidebarRow justify>
        <FlexContainer>
          <SidebarLabel>{project.projectId}</SidebarLabel>
          <EntityStatus
            title={getUserFriendlyProjectTypeName(project.childType)}
            status={{
              text: getUIMasterProjectStatusText(project),
              color:
                masterProjectStatusesColors[
                  getUIMasterProjectStatusText(project)
                ],
            }}
          />
        </FlexContainer>
        <OpenFullInfoSidebarButton
          isCard={isCard}
          openSidebarHandler={openFullProject}
          sidebarId={SidebarIDs.masterProject}
          fullSidebarId={masterProjectSidebarTabsId}
        />
      </SidebarRow>
      <SidebarRow justify>
        <BlockWithLabel
          id="start-end-date"
          label="Start - End Date"
          Component={getStartEndDateSection(
            {
              startDate: getDateByPropWithTime('startDate')(project),
              endDate: getMREndDate(project),
              isProjectExpired: checkProjectExpired(project),
            },
            headerTextProps,
          )}
        />
        <EditButton project={project} isCard={isCard} />
      </SidebarRow>
      <SidebarRow>
        <BlockWithLabel
          id="description"
          label="Description"
          Component={getSectionText(
            pathOrNothingUI(['description'])(project),
            headerTextProps,
          )}
        />
      </SidebarRow>
    </SectionWrapper>
  );
}

MasterRecurringProjectSidebarHeader.propTypes = {
  isCard: bool,
  project: shape({
    _id: string.isRequired,
    childType: string,
    projectId: string.isRequired,
  }),
};
