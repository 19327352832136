import React, { useState } from 'react';
import * as R from 'ramda';
import { node } from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import {
  Button,
  DateRangePicker,
  Icon,
  LinkButton,
  Text,
} from '@poly/admin-book';
import { prepareExcelWorkbookByConfig } from '@poly/utils';
import {
  getSpendReportsXlsConfig,
  PREVIEW_CLIENT_SPEND_REPORT_BY_HIERARCHY,
} from '@poly/client-utils';
import { downloadExcelWorkbook } from '@poly/client-utils/src/excel-export/download.js';
import { CancelBtn, useModalContext } from '@poly/admin-ui';
import styled from 'styled-components';
import { endOfDay, startOfDay, subMonths } from 'date-fns';

import { rejectZeroTotalReports } from './SpendReportsExportXlsBtn.js';
import { getMarkupSpendReportXlsConfig } from './getMarkupSpendReportXlsConfig.js';
import { prepareSpendReportQueryInput } from '../helpers.js';
import { prepareSpendReports } from './prepareSpendReports.js';
import { ProjectSpendTypeSelect } from './ProjectSpendTypeSelect.js';
import { SpendCostFilters } from './SpendCostFilters.js';
import { prepareSpendReportFilters } from './prepareSpendreportFilters.js';

// groupReportByClients :: [PropertyHierarchySpendReportResult] ->  [String, [PropertySpendReportResult]]
const groupReportByClients = R.compose(
  R.toPairs,
  R.groupBy(R.path(['client', 'name'])),
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  pointer-events: ${({ loader }) => (loader ? 'none' : 'auto')};
  opacity: ${({ loader }) => (loader ? 0.7 : 1)};
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const Title = styled(Text)`
  color: #333333;
  font-size: 18px;
`;

const Subtitle = styled(Text)`
  color: #999999;
  font-size: 14px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const LabelS = styled.label`
  font-size: 12px;
  color: #999999;
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DatePikerLabelsWrapper = styled.div`
  display: flex;
  gap: 185px;
`;

const IconS = styled(Icon)`
  cursor: pointer;
`;

const exportAllClientsSpendReportModalId = 'exportAllClientsSpendReportModalId';

function SpendReportAllClients() {
  const [loading, setLoading] = useState(false);
  const { closeModal } = useModalContext();

  const handleCancel = () => {
    if (!loading) {
      closeModal(exportAllClientsSpendReportModalId);
    }
  };

  const [filters, setFilters] = useState({
    endDate: endOfDay(new Date()),
    startDate: startOfDay(subMonths(new Date(), 1)),
    spendCostConfig: {
      includeClientInvoice: false,
      includeSupplierInvoice: false,
    },
  });

  const [spendReportQuery] = useLazyQuery(
    PREVIEW_CLIENT_SPEND_REPORT_BY_HIERARCHY,
  );

  const onProjectSpendTypeChange = (value) => {
    setFilters({ ...filters, spendType: value });
  };

  const onProjectSpendCostChange = (field) => (value) => {
    setFilters({
      ...filters,
      spendCostConfig: { ...filters.spendCostConfig, [field]: value },
    });
  };

  const handleExport = async () => {
    if (!filters?.endDate || !filters?.startDate) {
      return null;
    }

    setLoading(true);

    const queryInput = prepareSpendReportFilters([])(filters);

    const { data } = await spendReportQuery({
      variables: { input: prepareSpendReportQueryInput(queryInput) },
    });

    const additionalRecordProps = {
      ...(!!queryInput && queryInput?.spendCostConfig
        ? { spendCostConfig: queryInput?.spendCostConfig }
        : {}),
    };

    const reports = prepareSpendReports(null, additionalRecordProps)(data);

    const preparedReports = rejectZeroTotalReports(reports);

    const clientsReports = groupReportByClients(preparedReports);

    const config = getSpendReportsXlsConfig(
      preparedReports,
      'All Clients',
      filters.startDate,
      filters.endDate,
      true,
      true,
      clientsReports,
    );

    const markupReportConfigs = getMarkupSpendReportXlsConfig(clientsReports);

    const workbook = prepareExcelWorkbookByConfig([
      config,
      ...markupReportConfigs,
    ]);

    setLoading(false);

    downloadExcelWorkbook('spend-report-all-clients.xlsx', workbook);

    closeModal(exportAllClientsSpendReportModalId);

    return true;
  };

  return (
    <Wrapper loader={loading}>
      <div>
        <HeaderWrapper>
          <Title>Export report for all clients</Title>
          <IconS
            name="close"
            size={12}
            color="#333741"
            onClick={handleCancel}
          />
        </HeaderWrapper>
        <Subtitle>
          Please select the configurations to run a report across all clients
          and properties. The report will be exported as an XLS file once
          generated. This process may take a few minutes, so please avoid
          leaving the page.
        </Subtitle>
      </div>
      <SelectWrapper>
        <DatePikerLabelsWrapper>
          <LabelS>Start Date</LabelS> <LabelS>End Date</LabelS>
        </DatePikerLabelsWrapper>
        <DateRangePicker
          width="200px"
          value={{ startDate: filters.startDate, endDate: filters.endDate }}
          onChange={(dates) => setFilters({ ...filters, ...dates })}
        />
      </SelectWrapper>
      <SelectWrapper>
        <LabelS>Select spend type</LabelS>
        <ProjectSpendTypeSelect
          value={filters.spendType}
          onChange={onProjectSpendTypeChange}
        />
      </SelectWrapper>
      <SpendCostFilters
        spendCostConfig={filters.spendCostConfig}
        onProjectSpendCostChange={onProjectSpendCostChange}
        size="12px"
        checkboxNamePrefix="all-clients"
      />
      <ButtonsContainer>
        <Button size="small" onClick={handleExport} loader={loading}>
          Export
        </Button>
        <CancelBtn size="small" onClick={handleCancel} disabled={loading}>
          Export
        </CancelBtn>
      </ButtonsContainer>
    </Wrapper>
  );
}

const CustomModalLayoutS = styled.div`
  width: 488px;
  height: 366px;
  background-color: #fff;
  padding: 16px;
  border-radius: 3px;
`;

function EmptyLayout({ children }) {
  return <CustomModalLayoutS>{children}</CustomModalLayoutS>;
}

EmptyLayout.propTypes = {
  children: node.isRequired,
};

export function SpendReportAllClientsExportXls() {
  const { openModal } = useModalContext();

  const onClick = () =>
    openModal({
      id: exportAllClientsSpendReportModalId,
      content: <SpendReportAllClients />,
      Layout: EmptyLayout,
      simple: true,
    });

  return <LinkButton onClick={onClick}>Export For All</LinkButton>;
}
