import React, { useRef } from 'react';
import * as R from 'ramda';
import { func, shape } from 'prop-types';

import { ChartComponent } from './ChartComponent.js';

// isNumberInRange :: (Number, Number) -> Boolean
const isNumberInRange = (n1, n2) =>
  R.and(R.gte(n1, R.subtract(n2, 10)), R.lte(n1, R.add(n2, 10)));

export function BarChart({ data, options, handleClick, ...props }) {
  const chartInstanceRef = useRef(null);

  const handleIfCoordinatesMatch = (event, callback) => {
    const chartInstance = chartInstanceRef.current.getChartInstance();

    const { offsetX, offsetY } = event.native;
    chartInstance.data.datasets.forEach((dataset, datasetIndex) => {
      const meta = chartInstance.getDatasetMeta(datasetIndex);
      meta.data.forEach((bar) => {
        if (bar.custom) {
          const { x, y, index } = bar.custom;
          if (isNumberInRange(offsetX, x) && isNumberInRange(offsetY, y)) {
            const label = R.path(['labels', index], data);
            callback(label, dataset);
          }
        }
      });
    });
  };

  const handleChartClick = (event, elements) => {
    if (!handleClick) {
      return;
    }

    if (elements.length > 0) {
      const firstElement = R.head(elements);
      const dataset = R.path(['datasets', firstElement.datasetIndex], data);
      const label = R.path(['labels', firstElement.index], data);
      handleClick(label, dataset);
    } else {
      handleIfCoordinatesMatch(event, handleClick);
    }
  };

  const handleHover = (event, elements) => {
    const targetCanvas = chartInstanceRef.current.getChartElement();
    const chartInstance = chartInstanceRef.current.getChartInstance();
    let cursorChanged = false;

    if (elements.length > 0) {
      const element = elements[0];
      const meta = chartInstance.getDatasetMeta(element.datasetIndex);

      if (meta.type) {
        targetCanvas.style.cursor = 'pointer';
        return;
      }
    } else {
      handleIfCoordinatesMatch(event, () => {
        targetCanvas.style.cursor = 'pointer';
        cursorChanged = true;
      });
    }

    if (!cursorChanged) {
      targetCanvas.style.cursor = 'default';
    }
  };

  const chartOptions = {
    ...options,
    ...(handleClick ? { onClick: handleChartClick, onHover: handleHover } : {}),
  };

  return (
    <ChartComponent
      ref={chartInstanceRef}
      data={data}
      options={chartOptions}
      type="bar"
      {...props}
    />
  );
}

BarChart.propTypes = {
  data: shape({}).isRequired,
  options: shape({}),
  handleClick: func,
};
