import * as R from 'ramda';
import { gql } from '@apollo/client';
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { useInfiniteScrollQueryWithSubscription } from '@poly/client-utils';
import { assocBy, isNilOrEmpty, unwindByProp } from '@poly/utils';
import { useTableSorting } from '@poly/admin-ui';
import { TableCardWithoutTabs } from '@poly/admin-book';
import { WorkOrderStatus } from '@poly/constants';
import { PageWithSearchHeader } from '../../components/PageWithSearchHeader.js';
import { PROJECTS_BY_SEARCH_SUB } from '../../modules/core/hooks/projects/subscriptions.js';
import {
  SupplierDocumentExceptionsPageTable,
  supplierDocumentExceptionsPageTableConfig,
} from './SupplierDocumentExceptionsPageTable.js';
import { SupplierDocumentExceptionsPageHeader } from './SupplierDocumentExceptionsPageHeader.js';

const GET_PROJECTS_QUERY = gql`
  query GET_PROJECTS_QUERY($searchInput: CollectionSearchParams!) {
    searchProjects(input: $searchInput) {
      hits {
        _id
        type
        projectId
        property {
          _id
          name
        }
        suppliers {
          _id
          type
          isVeteran
          company {
            name
          }
          tax {
            exempt
            w9File {
              url
            }
          }
          mwbe {
            isWBE
            minority
          }
          documentExceptions
        }
      }
    }
  }
`;

const getDefaultQuery = () => ({
  bool: {
    must: [
      { match: { status: WorkOrderStatus.ACTIVE } },
      {
        nested: {
          path: 'suppliers',
          query: {
            bool: {
              must: {
                exists: {
                  field: 'suppliers.documentExceptions',
                },
              },
              must_not: {
                term: {
                  'suppliers.documentExceptions': '',
                },
              },
            },
          },
        },
      },
    ],
  },
});

// getProjectSuppliers :: { searchProjects: { hits: [Project] } } -> [Project]
const getProjectSuppliers = R.compose(
  R.map(
    assocBy(
      '_id',
      R.compose(
        R.join('_'),
        R.juxt([R.prop('_id'), R.path(['suppliers', '_id'])]),
      ),
    ),
  ),
  R.reject(R.pathSatisfies(isNilOrEmpty, ['suppliers', 'documentExceptions'])),
  unwindByProp('suppliers'),
  R.pathOr([], ['searchProjects', 'hits']),
);

export function SupplierDocumentExceptionsPage() {
  const searchTerm = useSelector((state) => state.searchText);
  const [input, setInput] = useState({ searchTerm, query: getDefaultQuery() });

  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig: supplierDocumentExceptionsPageTableConfig,
    column: 1,
  });

  useEffect(() => {
    setInput({ ...input, searchTerm, sort });
  }, [searchTerm, sort]);

  const { data, loading, tableProps, allItemsFetched, debouncedRefetch } =
    useInfiniteScrollQueryWithSubscription(
      GET_PROJECTS_QUERY,
      input,
      {
        inputName: 'searchInput',
        endpointName: 'searchProjects',
      },
      PROJECTS_BY_SEARCH_SUB,
      { searchInput: {} },
    );

  const projectSuppliers = getProjectSuppliers(data);

  return (
    <PageWithSearchHeader headerHeight="110px">
      <SupplierDocumentExceptionsPageHeader {...{ projectSuppliers }} />
      <TableCardWithoutTabs>
        <SupplierDocumentExceptionsPageTable
          isPrint={false}
          loading={loading}
          tableProps={{ ...tableProps, ...tableSortingProps }}
          searchTerm={searchTerm}
          allItemsFetched={allItemsFetched}
          projectSuppliers={projectSuppliers}
          debouncedRefetch={debouncedRefetch}
        />
      </TableCardWithoutTabs>
    </PageWithSearchHeader>
  );
}
