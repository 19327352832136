import { useMutation, gql } from '@apollo/client';
import { useModalContext, useNotificationState } from '@poly/admin-ui';
import { waitForTimeoutP } from '@poly/utils/src/general.js';
import { DEBOUNCE_REFETCH_TIMEOUT } from '@poly/client-utils/src/hooks/useReactiveQuery.js';

import { useModelsProcessContext } from './ModelsProcessProvider.js';

const confirmModalProps = {
  cancelBtnCaption: 'No',
  btnCaption: 'Yes, Delete',
  id: 'deleteAssetManufacturerModel',
  content: 'Are you sure you want to remove this model?',
};

const deleteAssetManufacturerModelMutation = gql`
  mutation deleteAssetManufacturerModel($id: ID!) {
    deleteAssetManufacturerModel(id: $id)
  }
`;

export const useDeleteAssetManufactureModel = (id) => {
  const { openConfirmModal } = useModalContext();
  const { showSuccessNotification } = useNotificationState();
  const { setModelProcess } = useModelsProcessContext();

  const [deleteAssetManufactureModel] = useMutation(
    deleteAssetManufacturerModelMutation,
    {
      variables: {
        id,
      },
    },
  );

  return () =>
    openConfirmModal({
      ...confirmModalProps,
      onConfirm: (closeConfirmModal) => async () => {
        try {
          setModelProcess(id, true);
          await deleteAssetManufactureModel();
          showSuccessNotification('Model was successfully removed');
        } finally {
          closeConfirmModal();
          await waitForTimeoutP(DEBOUNCE_REFETCH_TIMEOUT);
          setModelProcess(id, false);
        }
      },
    });
};
