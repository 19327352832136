import * as R from 'ramda';
import React, { useCallback, useMemo, useState } from 'react';
import { Select } from '@poly/admin-book';
import { func, string } from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { debounce, isNilOrEmpty } from '@poly/utils';
import {
  useReactiveQuery,
  entityToOptionByLabelPath,
} from '@poly/client-utils';

const CLIENT_INVOICES_QUERY = gql`
  query CLIENT_INVOICES_QUERY($input: ClientInvoicesInput) {
    clientInvoices(input: $input) {
      hits {
        _id
        number
      }
    }
  }
`;

const CLIENT_INVOICES_SUBSCRIPTION = gql`
  subscription CLIENT_INVOICES_SUBSCRIPTION($input: CollectionSearchParams!) {
    searchClientInvoiceChanged(input: $input) {
      id
      type
    }
  }
`;

const DEFAULT_CLIENT_INVOICE_QUERY = gql`
  query DEFAULT_CLIENT_INVOICE_QUERY($id: ID!) {
    clientInvoice(id: $id) {
      _id
      number
    }
  }
`;

// getDropdownOptions :: Pair ClientInvoicesResult { clientInvoice: ClientInvoice } -> [Pair String String]
const getDropdownOptions = R.compose(
  R.map(entityToOptionByLabelPath(['number'])),
  R.reject(R.isEmpty),
  R.flatten,
  R.juxt([
    R.pathOr([], ['0', 'clientInvoices', 'hits']),
    R.pathOr({}, ['1', 'clientInvoice']),
  ]),
);

export function ClientInvoiceSelect({ value, onChange, ...otherProps }) {
  const [searchText, setSearchText] = useState('');

  const setSearchTextDebounced = useCallback(debounce(500)(setSearchText), []);

  const queryOptions = useMemo(
    () => ({
      variables: {
        input: {
          invoiceNumberString: searchText,
          size: 10,
          sort: { number: 1 },
        },
      },
      fetchPolicy: 'network-only',
      skip: isNilOrEmpty(searchText),
    }),
    [searchText],
  );

  const { data, loading } = useReactiveQuery(
    CLIENT_INVOICES_QUERY,
    CLIENT_INVOICES_SUBSCRIPTION,
    { queryOptions, subscriptionOptions: queryOptions },
  );

  const { data: singleClientInvoiceData, loading: singleClientInvoiceLoading } =
    useQuery(DEFAULT_CLIENT_INVOICE_QUERY, {
      variables: { id: value },
      fetchPolicy: 'network-only',
      skip: isNilOrEmpty(value),
    });

  const selectOptions = useMemo(
    () => getDropdownOptions([data, singleClientInvoiceData]),
    [data, singleClientInvoiceData],
  );

  const selectProps = {
    ...otherProps,
    value,
    onChange,
    searchText,
    onInputChange: setSearchTextDebounced,
    loading: loading || singleClientInvoiceLoading,
    options: selectOptions,
    isClearable: true,
    placeholder: 'Start typing client invoices',
  };

  return <Select {...selectProps} />;
}

ClientInvoiceSelect.propTypes = {
  value: string,
  onChange: func,
};
