import React from 'react';
import styled from 'styled-components';
import { RadioButton } from '@poly/admin-book';
import { bool, func, shape, string } from 'prop-types';

const RulesAndOrConditionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export function RulesAndOrCondition({ onChange, name, value }) {
  const onRulesAndOrConditionChange = () => {
    onChange({
      or: !value.or,
      and: !value.and,
    });
  };

  return (
    <RulesAndOrConditionWrapper>
      <RadioButton
        name={`${name}.and`}
        value={!value.or}
        onChange={onRulesAndOrConditionChange}
        label="And"
      />
      <RadioButton
        name={`${name}.or`}
        value={!value.and}
        onChange={onRulesAndOrConditionChange}
        label="Or"
      />
    </RulesAndOrConditionWrapper>
  );
}

RulesAndOrCondition.propTypes = {
  onChange: func,
  name: string,
  value: shape({ and: bool, or: bool }),
};
