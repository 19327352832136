import * as R from 'ramda';
import { isNilOrEmpty } from '@poly/utils';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import {
  MAX_ITEMS,
  useSetItemsCount,
  usePaginationParams,
} from '@poly/admin-ui';
import { getProjectsByQueryData } from './helpers/query-data.js';

const bulkEditingProjectQuery = gql`
  query bulkEditingProjectQuery($input: CollectionSearchParams) {
    searchProjects(input: $input) {
      total
      hits {
        _id
        type
        status
        projectId
        description
        startDate
        endDate
        property {
          _id
          name
        }
        client {
          _id
          name
        }
        manager {
          fullName
          profile {
            fullName
          }
        }
      }
    }
    searchRecurringProjects(input: $input) {
      total
      hits {
        _id
        type
        status
        projectId
        description
        startDate
        endDate
        property {
          _id
          name
        }
        client {
          _id
          name
        }
        manager {
          fullName
          profile {
            fullName
          }
        }
      }
    }
  }
`;

// getItemsCount :: { searchProjects: { total: Int }, searchRecurringProjects: { total: Int } } -> Int
const getItemsCount = R.compose(
  R.sum,
  R.juxt([
    R.pathOr(0, ['searchProjects', 'total']),
    R.pathOr(0, ['searchRecurringProjects', 'total']),
  ]),
);

export const useBulkEditProjectsQuery = ({
  sort,
  input,
  isUserWithUpdateProjectPermission,
}) => {
  const paginationParams = usePaginationParams();

  const { data, loading } = useQuery(bulkEditingProjectQuery, {
    variables: { input: { sort, ...input, ...paginationParams } },
    fetchPolicy: 'network-only',
    skip: isNilOrEmpty(input) || !isUserWithUpdateProjectPermission,
  });

  useSetItemsCount(getItemsCount, data);

  return { data, loading };
};

export const useBulkEditProjectsLazyQuery = () => {
  const [getProjectsQuery] = useLazyQuery(bulkEditingProjectQuery);

  const getProjects = async (input, sort) => {
    const { data } = await getProjectsQuery({
      variables: { input: { sort, ...input, from: 0, size: MAX_ITEMS } },
      fetchPolicy: 'network-only',
    });

    return getProjectsByQueryData(data);
  };

  return getProjects;
};
