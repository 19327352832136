import { useQuery } from '@apollo/client';

import { useMultipleSubscriptions } from './useMultipleSubscriptions.js';
import { useDebounceRefetchByOptions } from './useDebounceRefetchByOptions.js';

// eslint-disable-next-line import/no-unused-modules
export const DEBOUNCE_REFETCH_TIMEOUT = 2000;

export const useReactiveQuery = (
  query,
  subscription,
  {
    queryOptions,
    subscriptionOptions,
    refetchDebounce = DEBOUNCE_REFETCH_TIMEOUT,
  },
) => {
  const { data, loading, error, refetch, fetchMore } = useQuery(
    query,
    queryOptions,
  );

  const debouncedRefetch = useDebounceRefetchByOptions({
    refetch,
    queryOptions,
    refetchDebounce,
    subscriptionOptions,
  });

  useMultipleSubscriptions(subscription, subscriptionOptions, {
    debouncedRefetch,
    data,
  });

  return { data, loading, error, refetch, debouncedRefetch, fetchMore };
};
