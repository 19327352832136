import React from 'react';
import { Select } from '@poly/admin-book';

import { BulkEditObjectType } from '../constants.js';

const objectTypeSelectOptions = [
  { value: BulkEditObjectType.PROJECTS, label: 'Projects' },
];

export function ObjectTypeSelect(props) {
  const selectProps = {
    options: objectTypeSelectOptions,
    placeholder: 'Select object type',
    width: '160px',
    required: true,
    ...props,
  };

  return <Select {...selectProps} />;
}
