import * as R from 'ramda';
import React, { useEffect } from 'react';
import {
  useSetItemsCount,
  useMapConfigToTableProps,
  useTableSorting,
  useSetInitialPaginationOnMount,
} from '@poly/admin-ui';
import {
  useHighlightMatchesBySearch,
  keywordSortQuery,
  listLengthSortQuery,
} from '@poly/client-utils';
import { Loader } from '@poly/admin-book';
import { arrayOf, func, number, shape, string } from 'prop-types';
import { ClientsTableComp } from './ClientsTableComp.js';
import { addressColumn, clientLinkColumn } from '../columns/clients.js';
import { useClientsBySearch } from '../../core/hooks/clients/index.js';

// getPropertiesTotal :: SearchPropertiesQueryResult -> Number
const getPropertiesTotal = R.pathOr(0, ['searchProperties', 'total']);

const getClientsTableConfig = (isPrint) => [
  ...(isPrint ? [['Name', R.prop('name')]] : [clientLinkColumn]),
  ['Nick Name', R.propOr('-', 'nickName'), keywordSortQuery(['nickName'])],
  addressColumn,
  ['Branches', getPropertiesTotal, listLengthSortQuery(['propertyIds'])],
];

// getClientsTotal :: SearchClientsQueryResult -> Number
const getClientsTotal = R.pathOr(0, ['searchClients', 'total']);

// getClients :: SearchClientsQueryResult -> [Client]
const getClients = R.pathOr([], ['searchClients', 'hits']);

export function ClientsPrintTable({ clients }) {
  const tableProps = useMapConfigToTableProps(
    R.identity,
    getClientsTableConfig(true),
    clients,
  );

  return <ClientsTableComp {...tableProps} />;
}

ClientsPrintTable.propTypes = {
  clients: arrayOf(
    shape({
      _id: string.isRequired,
      name: string,
      status: string,
      address: shape({ formatted_address: string }),
      searchProperties: shape({ total: number }),
    }),
  ),
};

export function ClientsTable({ status, setSort }) {
  useSetInitialPaginationOnMount();

  const tableConfig = getClientsTableConfig(false);

  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig,
    column: 1,
  });

  const { loading, result } = useClientsBySearch({
    status,
    sort,
  });

  useSetItemsCount(getClientsTotal, result);

  const { rows, ...restTableProps } = useMapConfigToTableProps(
    getClients,
    tableConfig,
    result,
  );

  const { highlightedRows } = useHighlightMatchesBySearch(
    R.identity,
    [['address', 'formatted_address'], ['name'], ['nickName']],
    rows,
  );

  useEffect(() => {
    if (setSort) {
      setSort(sort);
    }
  }, [sort, setSort]);

  if (loading) return <Loader />;

  return (
    <ClientsTableComp
      {...restTableProps}
      {...tableSortingProps}
      rows={highlightedRows}
    />
  );
}

ClientsTable.displayName = 'ClientsTable';

ClientsTable.propTypes = {
  status: string,
  setSort: func,
};
