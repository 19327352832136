import { combineReducers } from 'redux';

import { projectsFilterReducer } from './projectsFilter.js';
import { billingProfileConfigReducer } from './billingProfileConfig.js';
import { bulkEditFiltersConfigReducer } from './bulkEditFiltersConfig.js';

export const searchFiltersReducer = combineReducers({
  projectsFilter: projectsFilterReducer,
  billingProfileConfig: billingProfileConfigReducer,
  bulkEditFiltersConfig: bulkEditFiltersConfigReducer,
});
