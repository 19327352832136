import { func, shape, string } from 'prop-types';
import styled from 'styled-components';
import React from 'react';
import { getThemeColor, Checkbox, Text } from '@poly/admin-book';

import { FlexSpaceBetween } from '../../../modules/forms/assignSupplierForm/styles.js';

const FilterContainerS = styled(FlexSpaceBetween)`
  margin-top: 10px;
  width: 100%;
`;

const SpendCostFilterContainerS = styled(FilterContainerS)`
  justify-content: flex-start;
  align-items: center;

  > div:not(:first-child) {
    margin-right: 15px;
  }
`;

const FilterTitleS = styled(Text)`
  font-size: ${({ size }) => size || '14px'};
  font-weight: 600;
  margin-right: 40px;
  color: ${getThemeColor(['primaryLighter4'])};
`;

export function SpendCostFilters({
  spendCostConfig,
  onProjectSpendCostChange,
  size,
  checkboxNamePrefix,
}) {
  return (
    <SpendCostFilterContainerS>
      <FilterTitleS size={size}>Spend Cost Config</FilterTitleS>
      <Checkbox
        name={`include-supplier-invoice${checkboxNamePrefix}`}
        label="Include Supplier Invoice"
        value={spendCostConfig.includeSupplierInvoice}
        onChange={onProjectSpendCostChange('includeSupplierInvoice')}
      />
      <Checkbox
        name={`include-client-invoice${checkboxNamePrefix}`}
        label="Include Client Invoice"
        value={spendCostConfig.includeClientInvoice}
        onChange={onProjectSpendCostChange('includeClientInvoice')}
      />
    </SpendCostFilterContainerS>
  );
}

SpendCostFilters.propTypes = {
  spendCostConfig: shape({}),
  onProjectSpendCostChange: func,
  size: string,
  checkboxNamePrefix: string,
};
