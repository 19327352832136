import * as R from 'ramda';
import { gql } from '@apollo/client';
import React, { useEffect, useMemo } from 'react';
import { func, shape, string } from 'prop-types';
import { MultiSelectDropDown } from '@poly/admin-ui/src/components/MultiSelectDropDown.js';
import { ALL, MAX_ITEMS } from '@poly/admin-ui/src/constants/general.js';
import { ASC_SORT_ORDER } from '@poly/constants';
import {
  entityToOptionByLabelPath,
  useReactiveQuery,
  keywordSortQuery,
} from '@poly/client-utils';

import {
  onMultipleSelectValueChange,
  prepareMultipleSelectValue,
} from '../../../../../pages/ClientInvoicingCollection/BillingProfileConfigurationSidebar/utils.js';
import { multiSelectorValueProperTypes } from '../../../../../pages/ClientInvoicingCollection/BillingProfileConfigurationSidebar/components/common.js';

const SEARCH_TAGS_QUERY = gql`
  query SEARCH_TAGS_QUERY($input: CollectionSearchParams!) {
    searchTags(input: $input) {
      hits {
        _id
        name
      }
    }
  }
`;

const SEARCH_TAGS_SUBSCRIPTION = gql`
  subscription SEARCH_TAGS_SUBSCRIPTION($input: CollectionSearchParams!) {
    searchTagChanged(input: $input) {
      id
      type
    }
  }
`;

// formaTagOptions :: SearchTagsQueryResult -> [Option]
const formaTagOptions = R.compose(
  R.when(R.complement(R.isEmpty), R.prepend({ value: ALL, label: 'All' })),
  R.map(entityToOptionByLabelPath(['name'])),
  R.pathOr([], ['searchTags', 'hits']),
);

// getAllTagIds :: SearchTagsQueryResult -> [String]
const getAllTagIds = R.compose(
  R.pluck('_id'),
  R.pathOr([], ['searchTags', 'hits']),
);

export function MultipleTagSelect({
  value,
  onChange,
  formData,
  changeFieldValue,
  ...props
}) {
  const clientId = formData?.client?._id;

  const queryOptions = {
    variables: {
      input: {
        size: MAX_ITEMS,
        query: { term: { clientId } },
        sort: keywordSortQuery(['name'])(ASC_SORT_ORDER),
      },
    },
    skip: !clientId,
  };

  const { data, loading } = useReactiveQuery(
    SEARCH_TAGS_QUERY,
    SEARCH_TAGS_SUBSCRIPTION,
    { queryOptions, subscriptionOptions: queryOptions },
  );

  useEffect(() => {
    if (data) {
      const allTagIds = getAllTagIds(data);
      changeFieldValue('allTagIds', allTagIds);
    }
  }, [data]);

  const options = useMemo(() => formaTagOptions(data), [data]);

  const preparedValue = useMemo(
    () => prepareMultipleSelectValue(value, options, true),
    [value, options],
  );

  const selectProps = {
    ...props,
    loading,
    options,
    value: preparedValue,
    placeholder: 'Start typing tags',
    handleChange: onMultipleSelectValueChange(onChange),
  };

  return <MultiSelectDropDown {...selectProps} />;
}

MultipleTagSelect.propTypes = {
  onChange: func.isRequired,
  value: multiSelectorValueProperTypes,
  formData: shape({ clientId: string }),
  changeFieldValue: func.isRequired,
};
