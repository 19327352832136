import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { arrayOf, number, string } from 'prop-types';
import * as R from 'ramda';
import { useNavigate } from '@poly/client-routing';
import { insertQueryParamsIntoURL } from '@poly/utils';

import { ChartComponent } from '../Charts/ChartComponent.js';
import { routesNames } from '../../../../routes/constants.js';

const ReoccurringProjectsChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  width: calc(33% - 15px);
  height: 86px;
  cursor: pointer;
`;

const SpaceBetweenContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ChartWrapper = styled.div`
  height: 53px;
`;

const ReoccurringProjectsChartTitle = styled.p`
  font-size: 16px;
  color: #94969c;
  margin: 0;
  padding: 0;
`;

const ProjectsCountValue = styled.div`
  border-radius: 70px;
  padding: 5px 9px;
  background-color: ${R.prop('secondaryColor')};
  color: ${R.prop('primaryColor')};
  font-size: 12px;
`;

const commonScaleOptions = {
  grid: {
    display: false,
  },
  ticks: {
    display: false,
  },
  border: {
    display: false,
  },
};

export function ReoccurringProjectsChart({
  data,
  title,
  value,
  primaryColor,
  secondaryColor,
  tab,
}) {
  const chartInstanceRef = useRef(null);
  const [gradient, setGradient] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (chartInstanceRef) {
      const chartInstance = chartInstanceRef.current.getChartElement();
      const ctx = chartInstance.getContext('2d');
      const gr = ctx.createLinearGradient(0, 0, 0, 400);
      gr.addColorStop(0, secondaryColor);
      gr.addColorStop(1, '#fff');

      setGradient(gr);
    }
  }, [chartInstanceRef]);

  const chartConfig = {
    type: 'line',
    data: {
      labels: data,
      datasets: [
        {
          data,
          borderColor: primaryColor,
          backgroundColor: gradient,
          tension: 0.3,
          pointRadius: 0,
          fill: true,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
          tooltip: {
            enabled: false,
          },
        },
      },
      scales: {
        x: commonScaleOptions,
        y: commonScaleOptions,
      },
    },
  };

  const handleClick = () => {
    const href = insertQueryParamsIntoURL(
      { tab },
      routesNames.MY_RECURRING_PROJECTS_DIRECTORY,
    );
    navigate(href);
  };

  return (
    <ReoccurringProjectsChartContainer onClick={handleClick}>
      <SpaceBetweenContainer>
        <ReoccurringProjectsChartTitle>{title}</ReoccurringProjectsChartTitle>
        <ProjectsCountValue
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
        >
          {value}
        </ProjectsCountValue>
      </SpaceBetweenContainer>
      <ChartWrapper>
        <ChartComponent {...chartConfig} ref={chartInstanceRef} />
      </ChartWrapper>
    </ReoccurringProjectsChartContainer>
  );
}

ReoccurringProjectsChart.propTypes = {
  data: arrayOf(number),
  title: string.isRequired,
  value: number.isRequired,
  primaryColor: string.isRequired,
  secondaryColor: string.isRequired,
  tab: string.isRequired,
};
