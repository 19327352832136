import React, { createContext, useContext, useMemo, useState } from 'react';
import * as R from 'ramda';
import { node } from 'prop-types';

const ModelsProcessContext = createContext({});

export function ModelsProcessProvider({ children }) {
  const [modelsProcesses, setModelsProcesses] = useState({});

  const isModelProcesses = (id) => R.prop(id, modelsProcesses);

  const setModelProcess = (id, loading) =>
    setModelsProcesses((prev) => ({ ...prev, [id]: loading }));

  const contextValue = useMemo(
    () => ({
      isModelProcesses,
      setModelProcess,
    }),
    [modelsProcesses, setModelsProcesses],
  );

  return (
    <ModelsProcessContext.Provider value={contextValue}>
      {children}
    </ModelsProcessContext.Provider>
  );
}

ModelsProcessProvider.propTypes = {
  children: node.isRequired,
};

export const useModelsProcessContext = () => {
  const { isModelProcesses, setModelProcess } =
    useContext(ModelsProcessContext);

  return { isModelProcesses, setModelProcess };
};
