import React from 'react';
import styled from 'styled-components';
import { Icon, Popover } from '@poly/admin-book';

export const PopoverContentWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  text-align: start;
  background-color: #12347a;
  color: #fff;
  border-radius: 5px;
  max-width: 300px;
  font-size: 12px;
  line-height: 18px;
`;

export function AppTMRulesWarning() {
  return (
    <Popover
      position="right"
      bgColor="#12347a"
      isClickable={false}
      title={<Icon name="yellowWarning" size={16} />}
      /* eslint-disable @cspell/spellchecker */
      content={
        <PopoverContentWrapperS>
          Projects can&apos;t be created because Markup Rules are missing.
          Please set them to allow project creation.
        </PopoverContentWrapperS>
      }
      /* eslint-enable @cspell/spellchecker */
    />
  );
}
