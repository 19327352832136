import { useQuery, gql } from '@apollo/client';

const ACCOUNT_INDEX_QUERY = gql`
  query ACCOUNT_INDEX_QUERY {
    adminAccountIndex {
      clients {
        countByStatuses {
          status
          count
        }
      }
      aacSuppliersAdministrative {
        countByStatuses {
          status
          count
        }
      }
      aacSuppliersSubcontracts {
        countByStatuses {
          status
          count
        }
      }
      masterSuppliersSubcontracts {
        countByStatuses {
          status
          count
        }
      }
      masterSuppliersAdministrative {
        countByStatuses {
          status
          count
        }
      }
    }
  }
`;

export const useAccountIndexQuery = () => {
  const { data, loading } = useQuery(ACCOUNT_INDEX_QUERY, {
    fetchPolicy: 'network-only',
  });

  return { data, loading };
};
