import * as R from 'ramda';

// getProjectsByQueryData :: { searchProjects: { hits: [Project] }, searchRecurringProjects: { hits: [RecurringProject] } }
// -> [Project]
export const getProjectsByQueryData = R.compose(
  R.flatten,
  R.juxt([
    R.pathOr([], ['searchRecurringProjects', 'hits']),
    R.pathOr([], ['searchProjects', 'hits']),
  ]),
);
