import * as R from 'ramda';
import {
  createExcelExportCell,
  ExcelExportCellType,
  performExcelExport,
} from '@poly/client-utils';

const TITLE_ROW = ['Project #', 'Property', 'Supplier', 'Exceptions'];

// getSupplierDocumentExceptionsExcelRows :: [Project] -> [ExcelExportDataCell]
const getSupplierDocumentExceptionsExcelRows = R.map(
  R.compose(
    R.zipWith(createExcelExportCell, R.repeat(ExcelExportCellType.default, 4)),
    R.juxt([
      R.propOr('', 'projectId'),
      R.pathOr('', ['property', 'name']),
      R.pathOr('', ['suppliers', 'company', 'name']),
      R.pathOr('', ['suppliers', 'documentExceptions']),
    ]),
  ),
);

// eslint-disable-next-line import/no-unused-modules
export const getSupplierDocumentExceptionsExcelConfig = (projectSuppliers) => ({
  exportFileName: 'supplier_document_exceptions.xlsx',
  columnWidths: [15, 30, 30, 45],
  rows: [
    [
      {
        cellType: ExcelExportCellType.title,
        value: 'Supplier Document Exceptions',
      },
    ],
    R.zipWith(
      createExcelExportCell,
      R.repeat(ExcelExportCellType.tableHeader, 4),
      TITLE_ROW,
    ),
    ...getSupplierDocumentExceptionsExcelRows(projectSuppliers),
  ],
});

// supplierDocumentExceptionsXLS :: [Project] -> Promise _ _
export const supplierDocumentExceptionsXLS = R.compose(
  performExcelExport,
  getSupplierDocumentExceptionsExcelConfig,
);
