import * as R from 'ramda';
import { addHours } from 'date-fns';
import {
  WorkOrderPriority,
  ClientProjectPriorityUnit,
  DEFAULT_CLIENT_PROJECT_PRIORITIES,
} from '@poly/constants';

import { addBusinessDays, addBusinessHours } from '../dates-business.js';
import { wholeDays, alwaysNewDate } from '../dates.js';
import { isNilOrEmpty } from '../general.js';
import { propEqLegacy } from '../ramdaLegacyFunctions.js';

// getPrioritiesByClientConfigs :: ClientConfigs -> [ProjectPriorityConfigEntry]
const getPrioritiesByClientConfigs = R.compose(
  R.map(
    R.when(
      propEqLegacy('unit', ClientProjectPriorityUnit.DAYS),
      R.over(R.lensProp('amount'), R.multiply(24)),
    ),
  ),
  R.when(isNilOrEmpty, R.always(DEFAULT_CLIENT_PROJECT_PRIORITIES)),
  R.propOr([], 'projectPriorities'),
);

// getHoursOffsetForPriority :: ProjectWithClient -> Number
//   ProjectWithClient = {
//     ...Project,
//     client: Client,
//   }
const getHoursOffsetForPriority = R.compose(
  R.prop('amount'),
  R.converge(R.find, [
    R.compose(
      propEqLegacy('id'),
      R.propOr(WorkOrderPriority.NORMAL, 'priority'),
    ),
    R.compose(getPrioritiesByClientConfigs, R.path(['client', 'configs'])),
  ]),
);

// getStartDateByProject :: ProjectWithClient -> Date
const getStartDateByProject = R.compose(
  R.when(R.isNil, alwaysNewDate),
  R.prop('startDate'),
);

// addHoursAsBusinessDaysToStartDate :: ProjectWithClient -> Date
const addHoursAsBusinessDaysToStartDate = R.converge(addHours, [
  R.converge(addBusinessDays, [
    R.compose(wholeDays, getHoursOffsetForPriority),
    getStartDateByProject,
  ]),
  R.compose(R.mathMod(R.__, 24), getHoursOffsetForPriority),
]);

// addBusinessHoursToStartDate :: ProjectWithClient -> Date
const addBusinessHoursToStartDate = R.converge(addBusinessHours, [
  getHoursOffsetForPriority,
  getStartDateByProject,
]);

// shouldCountBusinessHoursForPriority :: ProjectWithClient -> Boolean
const shouldCountBusinessHoursForPriority = R.converge(R.includes, [
  R.prop('priority'),
  R.pathOr([], ['client', 'configs', 'countWorkingHoursForPriorities']),
]);

// getEndDateByProjectWithClient :: ProjectWithClient -> Date
export const getEndDateByProjectWithClient = R.ifElse(
  R.propIs(Date, 'endDate'),
  R.prop('endDate'),
  R.ifElse(
    shouldCountBusinessHoursForPriority,
    addBusinessHoursToStartDate,
    addHoursAsBusinessDaysToStartDate,
  ),
);
