import React from 'react';
import * as R from 'ramda';
import { string, shape } from 'prop-types';
import { useMutation, gql } from '@apollo/client';
import { formatSelectOptionsByConstantsValue } from '@poly/client-utils';
import { FormCreator, Input, Select } from '@poly/admin-book';
import { usePristineSubscribe } from '@poly/client-routing';
import { ProblemCodeStatuses } from '@poly/constants';
import { isNilOrEmpty } from '@poly/utils';
import {
  useOnSubmitSetStopSubmitting,
  useCloseCurrentModal,
  useNotificationState,
} from '@poly/admin-ui';

import { commonModalLayout } from '../../modules/forms/common.js';
import { ModalFormWrapper } from '../../modules/modals/ModalFormWrapper.js';
import { addProblemCodeFormId, editProblemCodeFormId } from './constants.js';

const CREATE_PROBLEM_CODE_MUTATION = gql`
  mutation CREATE_PROBLEM_CODE_MUTATION($input: CreateUpdateProblemCodeInput!) {
    createProblemCode(input: $input) {
      _id
    }
  }
`;

const UPDATE_PROBLEM_CODE_MUTATION = gql`
  mutation UPDATE_PROBLEM_CODE_MUTATION(
    $id: ID!
    $input: CreateUpdateProblemCodeInput!
  ) {
    updateProblemCode(id: $id, input: $input) {
      _id
    }
  }
`;

function ProblemCodeStatusSelect(props) {
  const options = formatSelectOptionsByConstantsValue(ProblemCodeStatuses);

  return <Select {...props} options={options} />;
}

const problemCodeFormSections = [
  {
    label: '',
    order: 1,
    fields: [
      {
        order: 1,
        label: 'Problem Code',
        layout: { row: 1, width: 'calc(60% - 10px)' },
        field: {
          name: 'name',
          Component: Input,
          additionalProps: {
            charactersLimit: 100,
            showCharactersLeft: true,
            placeholder: 'Please Enter Problem Code',
          },
        },
        validators: [[R.identity, 'Problem Code is required']],
        required: true,
      },
      {
        order: 2,
        label: 'Status',
        layout: { row: 1, width: 'calc(40% - 10px)' },
        field: {
          name: 'status',
          Component: ProblemCodeStatusSelect,
        },
        validators: [[R.identity, 'Problem Code status is required']],
        required: true,
      },
      {
        order: 3,
        label: 'Description',
        field: {
          name: 'description',
          Component: Input,
          additionalProps: {
            charactersLimit: 500,
            showCharactersLeft: true,
          },
        },
      },
    ],
  },
];

// getInitialValuesByProblemCode :: ProblemCode -> FormValues
const getInitialValuesByProblemCode = R.pick(['name', 'status', 'description']);

// prepareDescriptionByPayload :: { isEdit: Boolean, description: String, payload: Object }
const prepareDescriptionByPayload = R.ifElse(
  R.allPass([
    R.prop('isEdit'),
    R.propSatisfies(isNilOrEmpty, 'description'),
    R.pathSatisfies(R.complement(isNilOrEmpty), ['payload', 'description']),
  ]),
  R.always(null),
  R.prop('description'),
);

function ProblemCodeForm({ formId, payload }) {
  const closeCurrentModal = useCloseCurrentModal();
  const pristineSubscribeProps = usePristineSubscribe();
  const { showSuccessNotification } = useNotificationState();

  const isEdit = !!payload && !!payload._id;

  const MUTATION = isEdit
    ? UPDATE_PROBLEM_CODE_MUTATION
    : CREATE_PROBLEM_CODE_MUTATION;

  const [mutation] = useMutation(MUTATION);

  const mutate = async ({ name, description, status }) => {
    await mutation({
      variables: {
        ...(isEdit && { id: payload._id }),
        input: {
          name,
          status,
          description: prepareDescriptionByPayload({
            isEdit,
            description,
            payload,
          }),
        },
      },
    });
  };

  const successMessage = isEdit
    ? 'Problem code was updated'
    : 'Problem code was created';

  const handleSubmit = async (input) => {
    await mutate(input);

    showSuccessNotification(successMessage);
    closeCurrentModal();
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(formId, handleSubmit);

  const initialValues = isEdit
    ? getInitialValuesByProblemCode(payload)
    : { status: ProblemCodeStatuses.ACTIVE };

  return (
    <FormCreator
      id={formId}
      onSubmit={onSubmit}
      layout={commonModalLayout}
      initialValues={initialValues}
      sections={problemCodeFormSections}
      closeCurrentModal={closeCurrentModal}
      {...pristineSubscribeProps}
    />
  );
}

ProblemCodeForm.propTypes = {
  formId: string.isRequired,
  payload: shape({ _id: string }),
};

export function AddProblemCodeModalForm(props) {
  return (
    <ModalFormWrapper
      {...props}
      buttonLabel="Add"
      Form={ProblemCodeForm}
      title="Add Problem Code"
      formId={addProblemCodeFormId}
    />
  );
}

export function EditProblemCodeModalForm(props) {
  return (
    <ModalFormWrapper
      {...props}
      buttonLabel="Save"
      Form={ProblemCodeForm}
      title="Edit Problem Code"
      formId={editProblemCodeFormId}
    />
  );
}
