import * as R from 'ramda';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import arrayMutators from 'final-form-arrays';
import { arrayOf, func, number, shape, string } from 'prop-types';
import { FLIPPED_CONTAINS_AIT_OPERATOR } from '@poly/security';
import { usePristineSubscribe } from '@poly/client-routing';
import {
  commonSidebarFormSectionLayout,
  commonSidebarFormFieldLayout,
  commonSidebarFormLayout,
} from '@poly/admin-ui';
import {
  useNotificationContext,
  FieldLayout,
  Select,
  Text,
} from '@poly/admin-book';
import { waitForTimeoutP } from '@poly/utils';

import {
  UserGroupFlexLineS,
  UserGroupFormCreatorS,
} from './components/userGroupFormComponents.js';
import { userGroupAssignUserFormId } from '../constants.js';
import { UserGroupScopeRadioButtons } from './components/UserGroupScopeRadioButtons.js';
import { UserGroupScopeEntitySelect } from './components/UserGroupScopeEntitySelect.js';
import { USER_GROUP_ASSIGN_USER_SCOPE } from './components/entitySelectPropsByScopeMap.js';
import { useAssignUserGroupMutation } from '../hooks/useAssignUserGroupMutation.js';
import { checkIsNumberTypeScope } from './components/UserGroupScopeLine.js';
import { prepareInitialValues } from './helpers.js';

const ColumnLabel = styled(Text)`
  font-size: 12px;
  color: #999;
`;

function ScopeText({ value }) {
  return <Text size="12px">{value}</Text>;
}

ScopeText.propTypes = { value: string };

function DisabledUserGroupSelect({ value }) {
  const options = [
    {
      label: value?.name,
      value: value?._id,
    },
  ];

  return (
    <Select
      disabled
      options={options}
      value={value?._id}
      onChange={() => null}
    />
  );
}

DisabledUserGroupSelect.propTypes = {
  value: shape({
    _id: string,
    name: string,
  }),
};

// getAllLinesValuesByFormData :: FormData -> [FormLine]
const getAllLinesValuesByFormData = R.compose(
  R.map(R.pick(['entities', 'scope', 'operator'])),
  R.defaultTo([]),
  R.prop('variables'),
);

function UserGroupVariableLines({ name, index, formData, fields: { value } }) {
  const isFirstLine = index === 0;

  const formLineData = useMemo(() => value[index], [value]);

  const allLinesValues = useMemo(
    () => getAllLinesValuesByFormData(formData),
    [formData],
  );

  const isNumberTypeScope = useMemo(
    () => checkIsNumberTypeScope(formLineData),
    [formLineData],
  );

  return (
    <UserGroupFlexLineS align="center">
      <FieldLayout
        layout={{ width: 'calc(15% - 10px)' }}
        field={{
          name: `${name}.userGroup`,
          Component: isFirstLine ? DisabledUserGroupSelect : ColumnLabel,
        }}
      />
      <FieldLayout
        layout={{ width: 'calc(20% - 10px)' }}
        field={{
          name: `${name}.scopeText`,
          Component: ScopeText,
        }}
      />
      <FieldLayout
        layout={{
          padding: 0,
          width: `calc(${isNumberTypeScope ? 35 : 20}% - 10px)`,
        }}
        field={{
          name: `${name}.operator`,
          additionalProps: { formLineData, skipVariableOption: true },
          Component: UserGroupScopeRadioButtons,
        }}
      />
      <FieldLayout
        required={!!formLineData.scope}
        layout={{
          padding: 0,
          width: `calc(${isNumberTypeScope ? 30 : 45}% - 10px)`,
        }}
        field={{
          name: `${name}.entities`,
          Component: UserGroupScopeEntitySelect,
          additionalProps: { formLineData, isNumberTypeScope, allLinesValues },
        }}
      />
    </UserGroupFlexLineS>
  );
}

UserGroupVariableLines.propTypes = {
  name: string,
  index: number,
  fields: shape({ value: arrayOf(shape({ id: string })) }),
  formData: shape({ variables: arrayOf(shape({ id: string })) }),
};

// variablesNotEmpty :: { variables: [UserGroupVariable] } -> Boolean
const variablesNotEmpty = R.propSatisfies(R.complement(R.isEmpty), 'variables');

const formSections = [
  {
    id: 'main',
    layout: { column: 1 },
    order: 1,
    fields: [
      {
        label: '',
        order: 1,
        layout: { row: 1, width: '100%' },
        required: true,
        field: {
          name: 'usersToAssign',
          Component: UserGroupScopeEntitySelect,
          additionalProps: {
            isNumberTypeScope: false,
            formLineData: {
              scope: USER_GROUP_ASSIGN_USER_SCOPE,
              operator: FLIPPED_CONTAINS_AIT_OPERATOR,
            },
          },
        },
      },
    ],
  },
  {
    id: 'userGroupSection',
    label: 'User Group',
    layout: { column: 1 },
    order: 1,
    fields: [
      {
        label: '',
        order: 1,
        layout: { row: 1, width: 'calc(15% - 10px)' },
        field: {
          name: 'columnLabel_1',
          Component: ColumnLabel,
          additionalProps: {
            children: 'Group',
          },
        },
      },
      {
        label: '',
        order: 2,
        layout: { row: 1, width: 'calc(20% - 10px)' },
        field: {
          name: 'columnLabel_2',
          Component: ColumnLabel,
          additionalProps: {
            children: 'Variable Scopes',
          },
        },
      },
      {
        label: '',
        order: 2,
        layout: { row: 1, width: 'calc(65% - 10px)' },
        field: {
          name: 'emptyColumnLabel',
          Component: ColumnLabel,
          additionalProps: {
            children: '',
          },
        },
      },
      {
        label: '',
        order: 3,
        layout: { row: 2, width: '100%' },
        field: {
          name: 'variables',
          isArrayField: true,
          withFormData: true,
          withChangeFieldValue: true,
          Component: UserGroupVariableLines,
        },
      },
    ],
    renderIf: variablesNotEmpty,
  },
];

export function UserGroupAssignUserFrom({
  onClose,
  userGroup,
  debouncedRefetch,
}) {
  const assignUserGroup = useAssignUserGroupMutation();
  const { showSuccessNotification } = useNotificationContext();
  const pristineSubscribeProps = usePristineSubscribe({
    id: userGroupAssignUserFormId,
  });

  const initialValues = prepareInitialValues(userGroup);

  const onSubmit = async (formData) => {
    await assignUserGroup({ userGroup, ...formData });

    showSuccessNotification('Users assigned successfully.');

    await waitForTimeoutP(2000);

    if (onClose) {
      onClose();
    }

    debouncedRefetch();
  };

  return (
    <UserGroupFormCreatorS
      onCancel={onClose}
      onSubmit={onSubmit}
      sections={formSections}
      mutators={arrayMutators}
      initialValues={initialValues}
      id={userGroupAssignUserFormId}
      layout={commonSidebarFormLayout}
      formId={userGroupAssignUserFormId}
      fieldLayout={commonSidebarFormFieldLayout}
      successMessage="Users assigned successfully"
      sectionLayout={commonSidebarFormSectionLayout}
      {...pristineSubscribeProps}
    />
  );
}

UserGroupAssignUserFrom.propTypes = {
  userGroup: shape({}),
  onClose: func.isRequired,
  debouncedRefetch: func.isRequired,
};
