import * as R from 'ramda';
import { formatDate } from '@poly/utils';
import { NOTHING_UI_STRING, ASC_SORT_ORDER } from '@poly/constants';

const getSorting = (key) => (order) => ({
  [key]: order === ASC_SORT_ORDER ? 1 : -1,
});

export const detailedClientSurveysReportTableConfig = [
  [
    'Date of Submission',
    R.compose(formatDate, R.prop('receivedAt')),
    getSorting('receivedAt'),
  ],
  [
    'Client',
    R.pathOr(NOTHING_UI_STRING, ['client', 'name']),
    getSorting('clientName'),
  ],
  [
    'Project #',
    R.propOr(NOTHING_UI_STRING, 'projectId'),
    getSorting('projectId'),
  ],
  ['Question', R.propOr(NOTHING_UI_STRING, 'question'), getSorting('question')],
  ['Answer', R.propOr(NOTHING_UI_STRING, 'answer'), getSorting('answer')],
];
