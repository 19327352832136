import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { arrayOf, oneOfType, shape, string } from 'prop-types';
import {
  ClientStatuses,
  EXPORT_XLS_CAPTION,
  PRINT_PDF_CAPTION,
} from '@poly/constants';
import { LinkButton } from '@poly/admin-book/src/LinkButton/index.js';
import { ToolBarBtnDivider } from '@poly/admin-book/src/Table/styles.js';
import { performExcelExport } from '@poly/client-utils/src/excel-export/export.js';
import { openPrintWindowWithData } from '@poly/client-utils/src/print.js';
import { CommonPrintLayout } from '@poly/admin-ui/src/components/CommonPrintLayout.js';

import { useClientsLazyQuery } from '../Clients/useClientsLazyQuery.js';
import { getXlsClientsConfig } from '../Clients/getXlsClientsConfig.js';
import { ClientsPrintTable } from '../../modules/tables/clientsTable/ClientsTable.js';

export const FlexContainer = styled.div`
  display: flex;
  gap: ${R.propOr('0px', 'gap')};
  align-items: center;
`;

export function ProspectsExportButtons({ sort }) {
  const { runClientsQuery, loading } = useClientsLazyQuery({
    sort,
    status: ClientStatuses.PROSPECT,
  });

  const handlePrintPDF = async () => {
    const clients = await runClientsQuery();

    await openPrintWindowWithData({
      fileName: 'prospects_export.pdf',
      Layout: CommonPrintLayout,
      Table: ClientsPrintTable,
      metaData: {
        title: 'Prospects',
      },
      clients,
    });
  };

  const handleExportXLS = async () => {
    const clients = await runClientsQuery();

    const exportConfig = getXlsClientsConfig({
      clients,
      exportFileName: 'prospects_export',
      withStatus: false,
    });
    return performExcelExport(exportConfig);
  };

  return (
    <FlexContainer>
      <LinkButton onClick={handlePrintPDF} disabled={loading}>
        {PRINT_PDF_CAPTION}
      </LinkButton>
      <ToolBarBtnDivider />
      <LinkButton onClick={handleExportXLS} disabled={loading}>
        {EXPORT_XLS_CAPTION}
      </LinkButton>
    </FlexContainer>
  );
}

ProspectsExportButtons.propTypes = {
  sort: arrayOf(oneOfType([string, shape({})])),
};
