import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';
import { endOfDay, startOfDay } from 'date-fns';
import React, { useState, useMemo } from 'react';
import { ensureIsDate, isNilOrEmpty, assocBy } from '@poly/utils';

import { ALL } from '../../modules/core/constants/general.js';
import { PageWithSearchHeader } from '../../components/PageWithSearchHeader.js';
import { ClientSurveysSummaryReportTable } from './ClientSurveysSummaryReportTable.js';
import { ClientSurveysSummaryReportHeader } from './ClientSurveysSummaryReportHeader.js';

const clientSurveysSummaryReportQuery = gql`
  query clientSurveysSummaryReportQuery(
    $input: ClientSurveysSummaryReportInput!
  ) {
    clientSurveysSummaryReport(input: $input) {
      client {
        _id
        name
      }
      surveysNumber
      surveysAverageScore
      surveys {
        surveyQuestion
        surveyAverageScore
      }
    }
  }
`;

// prepareQueryInput :: FilterValues -> ClientSurveysSummaryReportInput
const prepareQueryInput = R.compose(
  R.over(R.lensProp('endDate'), endOfDay),
  R.over(R.lensProp('startDate'), startOfDay),
  R.reject(R.either(isNilOrEmpty, R.equals(ALL))),
  R.converge(R.mergeLeft, [
    R.compose(R.map(ensureIsDate), R.propOr({}, 'date')),
    R.pick(['clientId', 'propertyId']),
  ]),
);

// getReportEntries :: ClientSurveysSummaryReportResult -> [ClientSurveysSummaryReportEntry]
const getReportEntries = R.compose(
  R.map(assocBy('_id', R.path(['client', '_id']))),
  R.defaultTo([]),
  R.prop('clientSurveysSummaryReport'),
);

export function ClientSurveysSummaryReportPage() {
  const [filters, setFilters] = useState({});

  const { data, loading } = useQuery(clientSurveysSummaryReportQuery, {
    variables: { input: prepareQueryInput(filters) },
    skip: R.isEmpty(filters),
  });

  const reportEntries = useMemo(
    () => (R.isEmpty(filters) ? [] : getReportEntries(data)),
    [data, filters],
  );

  return (
    <PageWithSearchHeader headerHeight="145px">
      <ClientSurveysSummaryReportHeader
        {...{ setFilters, loading, reportEntries }}
      />
      <ClientSurveysSummaryReportTable {...{ loading, reportEntries }} />
    </PageWithSearchHeader>
  );
}
