import * as R from 'ramda';
import { format, differenceInDays, isAfter } from 'date-fns';
import {
  alwaysNewDate,
  applyOr,
  applyPath,
  applyProp,
  formatDate,
  formatDateTime as formatDateTimeUtils,
  ensureIsDate,
  isNilOrEmpty,
  formatDateByPattern,
} from '@poly/utils';
import { NOTHING_UI_STRING } from '@poly/constants';

/**
 * @deprecated import it from @poly/utils instead
 */
export const formatDateTime = formatDateTimeUtils;

// formatDateMongo :: Date -> String
export const formatDateMongo = (date) =>
  format(ensureIsDate(date), 'yyyy-MM-dd');

// formatDateOr :: Any -> Date -> Any
export const formatDateOr = applyOr(formatDate);

// formatDateOrNothingUI :: Any -> Date -> String
export const formatDateOrNothingUI = formatDateOr(NOTHING_UI_STRING);

// formatDatePath :: [String] -> Object -> String
export const formatDatePath = applyPath(formatDateOrNothingUI);

// formatDateProp :: String -> Object -> String
export const formatDateProp = applyProp(formatDateOrNothingUI);

// formatDateRange :: (Date, Date, String) -> String
export const formatDateRange = (rangeStart, rangeEnd, separator = '-') =>
  `${formatDateOrNothingUI(rangeStart)} ${separator} ${formatDateOrNothingUI(
    rangeEnd,
  )}`;

// getTimezoneByDate :: Date -> String
export const getTimezoneByDate = (date) =>
  /([-+]\d+)\s/.exec(new Date(date).toString())[1];

/**
 * getDifferenceInDaysForCreatedOrNothingUI :: EntityObject -> String
 * EntityObject = { createdAt: Date }
 */
export const getDifferenceInDaysForCreatedOrNothingUI = R.compose(
  R.ifElse(
    R.identity,
    R.converge(differenceInDays, [alwaysNewDate, ensureIsDate]),
    R.always(NOTHING_UI_STRING),
  ),
  R.prop('createdAt'),
);

// checkDateExpired :: Object -> Number
export const checkDateExpired = (date, fromPoint) =>
  isAfter(ensureIsDate(fromPoint) || Date.now(), ensureIsDate(date));

const HEADER_DATE_FORMAT = 'dd MMMM yyyy';

// eslint-disable-next-line import/no-unused-modules
export const HEADER_DEFAULT_SUBTITLE = 'All records';

// formatDateByPrependIfExists :: String -> Date -> String
const formatDateByPrependIfExists = (prepend) =>
  R.when(
    R.complement(R.isNil),
    R.compose(
      R.concat(prepend),
      (date) => format(date, HEADER_DATE_FORMAT),
      ensureIsDate,
    ),
  );

// formatFromToDateTitle :: (Date, Date) -> String
export const formatFromToDateTitle = (startDate, endDate) =>
  R.compose(
    R.ifElse(R.isEmpty, R.always(HEADER_DEFAULT_SUBTITLE), R.join(' ')),
    R.reject(R.isNil),
    R.juxt([
      R.compose(formatDateByPrependIfExists('From '), R.prop('startDate')),
      R.compose(formatDateByPrependIfExists('To '), R.prop('endDate')),
    ]),
  )({ startDate, endDate });

// getDateByPropWithTime :: String -> EntityObject -> String
export const getDateByPropWithTime = (prop) =>
  R.compose(
    R.ifElse(
      isNilOrEmpty,
      R.always(NOTHING_UI_STRING),
      formatDateByPattern('MM/dd/yyyy (h:mm a)'),
    ),
    R.prop(prop),
  );
