import * as R from 'ramda';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { objectOf, arrayOf, shape, string, instanceOf } from 'prop-types';
import { Loader } from '@poly/admin-book';
import { assocBy } from '@poly/utils';
import {
  useSetInitialPaginationOnMount,
  useMapConfigToTableProps,
  useSetItemsCount,
  useTableSorting,
} from '@poly/admin-ui';
import {
  useHighlightMatchesBySearch,
  keywordSortQuery,
  commonSortQuery,
  formatDateProp,
} from '@poly/client-utils';
import {
  ELASTIC_SCORE_FIELD,
  WorkOrderStatus,
  DESC_SORT_ORDER,
} from '@poly/constants';

import { RecentProjectsTableComp } from './RecentProjectsTableComp.js';
import { projectIdColumn, commonSortWithId } from '../columns/projects.js';
import { RecentProjectsNoResultsPlaceholder } from './RecentProjectsTablePlaceholder.js';
import { useProjectsBySearch } from '../../core/hooks/projects/index.js';
import { setRecentProjects } from './recentProjectsTableReducer.js';

export const completionDateColumn = [
  'Completed',
  formatDateProp('workCompletionDate'),
  commonSortWithId(commonSortQuery(['workCompletionDate'])),
];

export const startDateColumn = [
  'Started',
  formatDateProp('startDate'),
  commonSortWithId(commonSortQuery(['startDate'])),
];

export const tableConfig = [
  projectIdColumn,
  ['Description', R.prop('description'), keywordSortQuery(['description'])],
  [
    'Property',
    R.path(['property', 'name']),
    keywordSortQuery(['property', 'name']),
  ],
];

// getProjectsTotal :: SearchProjectsQueryResult -> Number
const getProjectsTotal = R.pathOr(0, ['searchProjects', 'total']);

// getProjects :: SearchProjectsQueryResult -> [Project]
const getProjects = R.pathOr([], ['searchProjects', 'hits']);

export function RecentProjectsTable({ status, query }) {
  const dispatch = useDispatch();
  const reduxState = useSelector((state) => state.recentProjects);

  useSetInitialPaginationOnMount();

  const config = R.equals(status, WorkOrderStatus.ACTIVE)
    ? R.append(startDateColumn, tableConfig)
    : R.append(completionDateColumn, tableConfig);

  const { sort, ...tableSortingProps } = useTableSorting({
    defaultSort: [{ createdAt: DESC_SORT_ORDER }, ELASTIC_SCORE_FIELD],
    tableConfig: config,
  });

  useEffect(() => {
    if (sort) {
      dispatch(setRecentProjects({ ...reduxState, sort }));
    }
  }, [sort]);

  const { loading, result } = useProjectsBySearch({ query, sort });

  useSetItemsCount(getProjectsTotal, result);

  const { rows, ...restTableProps } = useMapConfigToTableProps(
    getProjects,
    config,
    result,
  );

  const { highlightedRows } = useHighlightMatchesBySearch(
    R.map(assocBy('projectLinkId', R.prop('projectId'))),
    [['projectId'], ['description'], ['property', 'name']],
    rows,
  );

  if (loading) return <Loader />;
  if (getProjectsTotal(result) === 0)
    return <RecentProjectsNoResultsPlaceholder />;

  return (
    <RecentProjectsTableComp
      {...tableSortingProps}
      {...restTableProps}
      rows={highlightedRows}
    />
  );
}

RecentProjectsTable.displayName = 'RecentProjectsTable';

RecentProjectsTable.propTypes = {
  status: string.isRequired,
  query: shape({
    bool: shape({
      filter: shape({
        range: shape({
          workCompletionDate: objectOf(instanceOf(Date)),
        }),
      }),
      must: arrayOf(
        shape({
          match: shape({
            status: string,
          }),
        }),
      ),
    }),
  }).isRequired,
};
