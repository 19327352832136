import React, { useState } from 'react';
import { isNilOrEmpty } from '@poly/utils';
import { ASC_SORT_ORDER } from '@poly/constants';
import { useTableSorting } from '@poly/admin-ui';

import { useReactiveQuery } from '@poly/client-utils';
import { PrintChecksDetailsTable } from './PrintChecksDetailsTable.js';
import { PageWithSearchHeader } from '../../components/PageWithSearchHeader.js';
import { printChecksTableConfig } from '../PrintChecksPage/PrintChecksTableConfig.js';
import { PrintChecksDetailsPageHeader } from './PrintChecksDetailsPageHeader.js';
import {
  GET_SUPPLIER_CHECKS_QUERY,
  GET_SUPPLIERS_SUBSCRIPTION,
} from '../PrintChecksPage/PrintChecksTable.js';
import { prepareSupplierChecksFilters } from '../PrintChecksPage/printChecksUtils.js';

export function PrintChecksDetailsPage() {
  const [filters, setFilters] = useState(null);

  const { sort } = useTableSorting({
    tableConfig: printChecksTableConfig,
    order: ASC_SORT_ORDER,
    column: 7,
  });

  const queryOptions = {
    variables: {
      input: prepareSupplierChecksFilters(sort)(filters),
    },
    skip: isNilOrEmpty(filters),
    fetchPolicy: 'network-only',
  };
  const subscriptionOptions = {
    variables: { input: prepareSupplierChecksFilters(sort)(filters) },
  };

  const { data, loading } = useReactiveQuery(
    GET_SUPPLIER_CHECKS_QUERY,
    GET_SUPPLIERS_SUBSCRIPTION,
    { queryOptions, subscriptionOptions },
  );

  return (
    <PageWithSearchHeader headerHeight="190px">
      <PrintChecksDetailsPageHeader loading={loading} setFilters={setFilters} />
      <PrintChecksDetailsTable data={data} loading={loading} />
    </PageWithSearchHeader>
  );
}
