import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import arrayMutators from 'final-form-arrays';
import { func } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useOutSidebarContext } from '@poly/client-utils';
import { usePristineSubscribe } from '@poly/client-routing';
import { FormCreator, getThemeColor } from '@poly/admin-book';
import {
  SidebarWrapper,
  commonSidebarFormLayout,
  commonSidebarFormFieldLayout,
  useOnSubmitSetStopSubmitting,
  commonSidebarFormSectionLayout,
} from '@poly/admin-ui';

import { RuleLine } from './RuleLine.js';
import { SidebarCloseIcon } from '../../components/SidebarLayouts.js';
import { FilterActionButtons } from './components/FilterActionButtons.js';
import { usePrepareQueryInput } from './usePrepareQueryInput.js';
import {
  BulkEditObjectType,
  BulkEditingFilterFormId,
  BulkEditingFilterSidebarId,
} from './constants.js';
import {
  getBulkEditFiltersConfig,
  setBulkEditFiltersConfig,
} from '../../redux/searchFilters/bulkEditFiltersConfig.js';

const ProjectSidebarWrapperS = styled(SidebarWrapper)`
  overflow-x: hidden;
  height: 100%;
`;

const SidebarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 24px;
  border-bottom: 1px solid #ebebeb;
`;

const PageTitle = styled.h3`
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: ${getThemeColor(['primary'])};
  margin: 0;
`;

const bulkEditingFilterFormSections = [
  {
    order: 1,
    layout: { padding: 0 },
    fields: [
      {
        order: 1,
        layout: { row: 1, width: '100%' },
        field: {
          name: 'rules',
          isArrayField: true,
          withChangeFieldValue: true,
          Component: RuleLine,
        },
      },
      {
        order: 2,
        layout: { row: 2, width: '100%', margin: '0 0 0 24px' },
        field: {
          name: 'actionButtons',
          withChangeFieldValue: true,
          Component: FilterActionButtons,
        },
      },
    ],
  },
];

export function BulkEditingFilterSidebar({ setInput }) {
  const dispatch = useDispatch();
  const { closeOutSidebar } = useOutSidebarContext();
  const prepareQueryInput = usePrepareQueryInput();
  const pristineSubscribeProps = usePristineSubscribe({
    id: BulkEditingFilterSidebarId,
  });

  const previousFilters = useSelector(getBulkEditFiltersConfig);

  const onClose = () => closeOutSidebar(BulkEditingFilterSidebarId);

  const submitHandler = async (formData) => {
    const input = prepareQueryInput(formData);

    if (R.isNil(input)) {
      return null;
    }

    setInput(input);
    dispatch(setBulkEditFiltersConfig(formData));

    return onClose();
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    BulkEditingFilterFormId,
    submitHandler,
  );

  const initialValues = previousFilters || {
    rules: [
      {
        objectType: BulkEditObjectType.PROJECTS,
        rulesAndOrCondition: { and: true, or: false },
      },
      {},
    ],
  };

  return (
    <ProjectSidebarWrapperS>
      <SidebarHeader>
        <PageTitle>Bulk Editing Filters</PageTitle>
        <SidebarCloseIcon onClick={onClose} />
      </SidebarHeader>
      <FormCreator
        initialValues={initialValues}
        onSubmit={onSubmit}
        id={BulkEditingFilterSidebarId}
        mutators={arrayMutators}
        layout={commonSidebarFormLayout}
        sections={bulkEditingFilterFormSections}
        fieldLayout={commonSidebarFormFieldLayout}
        sectionLayout={commonSidebarFormSectionLayout}
        {...pristineSubscribeProps}
      />
    </ProjectSidebarWrapperS>
  );
}

BulkEditingFilterSidebar.propTypes = {
  setInput: func,
};
