import * as R from 'ramda';
import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import OutsideClickHandler from 'react-outside-click-handler';
import { debounce } from '@poly/utils';
import { MultiSelectDropDown } from '@poly/admin-ui';
import { DEBOUNCE_USER_INPUT_THRESHOLD } from '@poly/client-utils';
import { SelectComponents, Popover, Icon } from '@poly/admin-book';
import { string, shape, func, arrayOf, bool } from 'prop-types';

import { ALL } from '../../../modules/core/constants/general.js';
import { useMultiAssetsSelectQuery } from './useMultiAssetsSelectQuery.js';
import { MultiAssetsSelectOptionsDropdown } from './MultiAssetsSelectOptionsDropdown.js';

const AssetsSelectWrapper = styled.div`
  position: relative;
`;

// checkIfAssetForExecutedProcedure :: MultiValueRemoveProps -> Boolean
const checkIfAssetForExecutedProcedure = R.converge(R.includes, [
  R.path(['data', 'value']),
  R.propOr([], 'executedProcedureAssets'),
]);

const IconS = styled(Icon)`
  margin: 0 4px;
`;

const PopoverContentS = styled.div`
  height: auto;
  padding: 10px;
  color: #fff;
  font-size: 12px;
  max-width: 150px;
  border-radius: 5px;
  background-color: #12347a;
`;

function ExecutedProcedureAssetWarningIcon() {
  return <IconS name="confirm" size={12} color="#61646C" />;
}

function ExecutedProcedureAssetWarning() {
  const warningMessage =
    'You cannot unlink the asset because the procedure has been completed';

  return (
    <Popover
      position="left"
      withPortalAnchor
      bgColor="#12347a"
      Icon={ExecutedProcedureAssetWarningIcon}
      content={<PopoverContentS>{warningMessage}</PopoverContentS>}
    />
  );
}

// getCustomMultiValueRemove :: [ID] -> MultiValueRemoveProps -> ReactComponent
const getCustomMultiValueRemove = (executedProcedureAssets) =>
  function MultiValueRemove(props) {
    const isAssetForExecutedProcedure = checkIfAssetForExecutedProcedure({
      ...props,
      executedProcedureAssets,
    });

    if (isAssetForExecutedProcedure) {
      return <ExecutedProcedureAssetWarning />;
    }

    return <SelectComponents.MultiValueRemove {...props} />;
  };

export function MultiAssetsSelect({
  value,
  entity,
  onChange,
  formData,
  disabled,
  changeFieldValue,
  ...props
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [assetTypesValue, setAssetTypesValue] = useState([]);

  const { executedProcedureAssets } = formData;

  const { loading, options, assetTypeOptions } = useMultiAssetsSelectQuery({
    entity,
    formData,
    disabled,
    searchTerm,
    assetTypesValue,
  });

  const onSearchDebounced = useCallback(
    debounce(DEBOUNCE_USER_INPUT_THRESHOLD)(setSearchTerm),
    [],
  );

  const onInputChange = (input) => onSearchDebounced(input.trim());

  const selectProps = {
    ...props,
    value: R.reject(R.propEq(ALL, 'value'), value),
    loading,
    options,
    handleChange: onChange,
    onInputChange,
    required: true,
    maxMenuHeight: 250,
    isDisabled: disabled,
    onSearchChange: setSearchTerm,
    placeholder: 'Start typing assets',
    components: {
      MultiValueRemove: getCustomMultiValueRemove(executedProcedureAssets),
    },
    styleType: 'withCheckBox',
    menuIsOpen: false,
    onFocus: () => setMenuIsOpen(true),
  };

  const onOutsideClick = () => {
    if (menuIsOpen) {
      setMenuIsOpen(false);
      onChange(R.reject(R.propEq(ALL, 'value'), value));
    }
  };

  return (
    <OutsideClickHandler onOutsideClick={onOutsideClick}>
      <MultiSelectDropDown {...selectProps} />
      <AssetsSelectWrapper>
        {menuIsOpen && (
          <MultiAssetsSelectOptionsDropdown
            error={props?.error}
            assetsValue={value}
            assetsLoading={loading}
            assetsOptions={options}
            handleAssetSelect={onChange}
            assetTypesValue={assetTypesValue}
            assetTypesOptions={assetTypeOptions}
            setAssetTypesValue={setAssetTypesValue}
          />
        )}
      </AssetsSelectWrapper>
    </OutsideClickHandler>
  );
}

MultiAssetsSelect.propTypes = {
  error: string,
  disabled: bool,
  changeFieldValue: func,
  onChange: func.isRequired,
  entity: shape({ _id: string, name: string }),
  value: arrayOf(shape({ value: string, label: string })),
  formData: shape({
    propertyId: string,
    types: arrayOf(string),
    allAssetsIds: arrayOf(string),
    executedProcedureAssets: arrayOf(string),
    attachedAssets: arrayOf(string).isRequired,
  }).isRequired,
};
