import React from 'react';
import * as R from 'ramda';
import { bool, number, shape, string } from 'prop-types';
import { stringifyQuery } from '@poly/client-routing';
import { InvoicesStatuses } from '@poly/constants';

import { routesNames } from '../../../routes/index.js';
import { TotalItemComp } from './TotalItemComp.js';
import { TotalItemContainer, ItemLabelText } from '../styles.js';

export const getOpenSupplierInvoicesItemCompProps = (hrefVars) => ({
  margin: '0 0 6px 28px',
  href: `${routesNames.OPEN_SUPPLIER_INVOICE}?${stringifyQuery(hrefVars)}`,
});

export const openSupplierInvoicesItemsConfig = [
  {
    invoiceStatus: InvoicesStatuses.REQUESTED,
    fieldName: 'requested',
    label: 'Requested',
  },
  {
    invoiceStatus: InvoicesStatuses.RECEIVED,
    fieldName: 'received',
    label: 'Received',
  },
  {
    invoiceStatus: InvoicesStatuses.MANUAL_FOLLOW_UP,
    fieldName: 'manualFollowUp',
    label: 'Manual Follow Up',
  },
];

export function OpenSupplierInvoicesTotalComponent({
  isStyled,
  managerId,
  counts,
}) {
  const defaultHref = `${routesNames.OPEN_SUPPLIER_INVOICE}?${stringifyQuery({
    managerId,
  })}`;

  const firstItemProps = isStyled ? { padding: '2px 0 8px 0' } : {};

  return (
    <>
      <TotalItemContainer href={defaultHref} {...firstItemProps}>
        <ItemLabelText fullWidth>Open Supplier Invoices</ItemLabelText>
      </TotalItemContainer>
      {openSupplierInvoicesItemsConfig.map(
        ({ fieldName, label, invoiceStatus }) => (
          <TotalItemComp
            {...getOpenSupplierInvoicesItemCompProps({
              managerId,
              invoiceStatus,
            })}
            isStyled={isStyled}
            total={R.propOr(0, fieldName, counts)}
            label={label}
            key={label}
          />
        ),
      )}
    </>
  );
}

OpenSupplierInvoicesTotalComponent.defaultProps = {
  counts: {},
};

OpenSupplierInvoicesTotalComponent.propTypes = {
  managerId: string,
  isStyled: bool,
  counts: shape({
    requested: number,
    received: number,
    rejected: number,
    manualFollowUp: number,
  }),
};
