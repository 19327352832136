import * as R from 'ramda';
import { createAction, handleAction } from 'redux-actions';

export const getBulkEditFiltersConfig = R.path([
  'searchFilters',
  'bulkEditFiltersConfig',
]);

export const setBulkEditFiltersConfig = createAction(
  'SET_BULK_EDIT_FILTERS_CONFIG',
);

export const bulkEditFiltersConfigReducer = handleAction(
  setBulkEditFiltersConfig,
  (_, { payload }) => payload,
  null,
);
