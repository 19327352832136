import React from 'react';
import {
  arrayOf,
  instanceOf,
  number,
  oneOfType,
  shape,
  string,
} from 'prop-types';

import {
  LightText,
  SupplierItemGridRow,
  SupplierItemWrapper,
  SuppliersListWrapper,
  SuppliersListContainer,
} from '../../../../styles.js';
import { supplierPropTypes } from '../../../../propTypes.js';
import { SupplierListItem } from './SuppliersListItem/SuppliersListItem.js';

export function SuppliersListComp({
  suppliers,
  propertyCoordinates,
  entityData,
}) {
  return (
    <SuppliersListWrapper>
      <SupplierItemWrapper>
        <SupplierItemGridRow>
          <LightText padding="0 0 0 25px">Supplier</LightText>
          <LightText align="end">Hourly</LightText>
          <LightText align="end">Overtime</LightText>
          <LightText align="end">Emergency</LightText>
          <LightText align="end">Trip</LightText>
        </SupplierItemGridRow>
      </SupplierItemWrapper>
      <SuppliersListContainer>
        {suppliers.map(({ _id, ...props }) => (
          <SupplierListItem
            {...{ _id, ...props }}
            key={_id}
            propertyCoordinates={propertyCoordinates}
            entityData={entityData}
          />
        ))}
      </SuppliersListContainer>
    </SuppliersListWrapper>
  );
}

SuppliersListComp.propTypes = {
  suppliers: arrayOf(supplierPropTypes),
  propertyCoordinates: shape({
    lat: number.isRequired,
    lon: number.isRequired,
  }),

  entityData: shape({
    startDate: oneOfType([instanceOf(Date), string]),
    endDate: oneOfType([instanceOf(Date), string]),
  }),
};
