import * as R from 'ramda';

import { formatChartNumber } from '../HomePage/ChartDashboard/Charts/StatusBar.js';

export const barBackgroundClientsChartPlugin = {
  id: 'barBackgroundPlugin',
  beforeDatasetsDraw: (chart) => {
    const { x } = chart.scales;
    const { ctx, chartArea, data } = chart;

    const barWidth = (x.width / data.labels.length) * 0.9;
    const halfBarWidth = barWidth / 2;
    const borderRadius = 5;
    const patternSize = 20;

    const patternCanvas = document.createElement('canvas');

    patternCanvas.width = patternSize;
    patternCanvas.height = patternSize;

    const patternCtx = patternCanvas.getContext('2d');

    const gradient = patternCtx.createLinearGradient(
      0,
      0,
      patternSize,
      patternSize,
    );

    gradient.addColorStop(0.48, 'transparent');
    gradient.addColorStop(0.48, '#f5f5f5');
    gradient.addColorStop(0.52, '#f5f5f5');
    gradient.addColorStop(0.52, 'transparent');

    patternCtx.fillStyle = '#fbfbfb';
    patternCtx.fillRect(0, 0, patternSize, patternSize);

    patternCtx.fillStyle = gradient;
    patternCtx.fillRect(0, 0, patternSize, patternSize); // Gradient overlay

    const pattern = ctx.createPattern(patternCanvas, 'repeat');

    data.datasets.forEach((dataset) => {
      dataset.data.forEach((_, index) => {
        const xPosition = x.getPixelForTick(index);

        ctx.beginPath();

        ctx.moveTo(xPosition - halfBarWidth + borderRadius, chartArea.top);
        ctx.lineTo(xPosition + halfBarWidth - borderRadius, chartArea.top);
        ctx.quadraticCurveTo(
          xPosition + halfBarWidth,
          chartArea.top,
          xPosition + halfBarWidth,
          chartArea.top + borderRadius,
        );
        ctx.lineTo(xPosition + halfBarWidth, chartArea.bottom - borderRadius);
        ctx.quadraticCurveTo(
          xPosition + halfBarWidth,
          chartArea.bottom,
          xPosition + halfBarWidth - borderRadius,
          chartArea.bottom,
        );
        ctx.lineTo(xPosition - halfBarWidth + borderRadius, chartArea.bottom);
        ctx.quadraticCurveTo(
          xPosition - halfBarWidth,
          chartArea.bottom,
          xPosition - halfBarWidth,
          chartArea.bottom - borderRadius,
        );
        ctx.lineTo(xPosition - halfBarWidth, chartArea.top + borderRadius);
        ctx.quadraticCurveTo(
          xPosition - halfBarWidth,
          chartArea.top,
          xPosition - halfBarWidth + borderRadius,
          chartArea.top,
        );

        ctx.closePath();

        ctx.fillStyle = pattern;

        ctx.fill();
      });
    });
  },
};

// getCountFromChartByIndex :: Int -> ChartProps -> String
const getCountFromChartByIndex = (index) =>
  R.compose(formatChartNumber, R.path(['data', 'datasets', 0, 'data', index]));

export const styledLabelsClientsChartPlugin = {
  id: 'styledLabels',
  afterDraw: (chart) => {
    const {
      ctx,
      scales: { x },
      chartArea: { bottom },
    } = chart;

    const { labels } = chart.data;

    labels.forEach((label, index) => {
      const xPosition = x.getPixelForTick(index);

      const row1 = getCountFromChartByIndex(index)(chart);

      ctx.font = '14px';
      ctx.textAlign = 'center';
      ctx.fillStyle = '#313131';

      ctx.fillText(row1, xPosition, bottom + 20);

      ctx.font = '10px';
      ctx.textAlign = 'center';
      ctx.fillStyle = '#94969C';

      ctx.fillText(label, xPosition, bottom + 40);
    });
  },
};
