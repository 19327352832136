import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { string } from 'prop-types';
import { CommonPrintLayout, useNotificationState } from '@poly/admin-ui';
import { PRINT_PDF_CAPTION } from '@poly/constants';

import { openPrintWindowWithData } from '@poly/client-utils';
import { LinkButton } from '@poly/admin-book';

import { ARAgingReportTable } from './ARAgingReportTable.js';
import { commonAgingReportPrintTableStyles } from '../APAgingReport/styledComponents.js';
import { invoicesPropTypes } from './prop-types.js';

const ARAgingReportPrintTable = styled(ARAgingReportTable)`
  ${commonAgingReportPrintTableStyles};

  th,
  td {
    :nth-child(6),
    :nth-child(7),
    :nth-child(8),
    :nth-child(9),
    :nth-child(10) {
      width: 125px !important;
    }
  }

  tr {
    padding-right: 5px;
  }

  tfoot {
    td:first-child {
      width: 330px !important;
    }

    td:not(:first-child) {
      width: 125px !important;
    }
  }
`;

export function ARAgingReportPrintBtn({ report, title }) {
  const { showErrorNotification } = useNotificationState();

  const onClick = async () => {
    if (R.isEmpty(report)) {
      showErrorNotification('No report records to print');
      return;
    }

    await openPrintWindowWithData({
      fileName: 'ar_aging_report',
      Layout: CommonPrintLayout,
      Table: ARAgingReportPrintTable,
      metaData: { title },
      report,
      isPrintPDF: true,
    });
  };
  return <LinkButton onClick={onClick}>{PRINT_PDF_CAPTION}</LinkButton>;
}

ARAgingReportPrintBtn.propTypes = {
  title: string.isRequired,
  report: invoicesPropTypes.isRequired,
};
