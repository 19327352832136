import React from 'react';
import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';
import { useRouterParams } from '@poly/client-routing';
import { NotFoundPage } from '@poly/admin-ui';
import {
  PageHeaderContainer,
  DefaultBodyWrapper,
  MainHeader,
  Loader,
} from '@poly/admin-book';

import { ClientInvoicesButtons } from './ClientInvoiceButtons.js';
import { EditClientInvoiceForm } from './EditClientInvoiceForm.js';
import { FormContainerS } from '../../components/FormCardContainer.js';
import {
  clientInvoiceProjectDetailsFragment,
  jobCostFragment,
  jobCostSearchInput,
} from './useProjectDetailsQuery.js';
import { formatProjectInfo } from './helpers.js';
import { formatAccountOptionText } from '../../components/AccountsSelect.js';

const editClientInvoiceFormId = 'editClientInvoiceFormId';

const clientInvoiceQuery = gql`
  query clientInvoice($invoiceId: ID!, $searchInput: CollectionSearchParams!) {
    clientInvoice(id: $invoiceId) {
      _id
      taxPercent
      invoiceDate
      markupPercent
      taxRawAmount
      freightRawAmount
      lines {
        total
        rate
        quantity
        name
        isNonMarkup
        isProjectTime
      }
      description
      costCenter
      glAccount {
        code
        name
      }
      project {
        ...clientInvoiceProjectDetailsFields
        searchInvoices(input: $searchInput) {
          hits {
            ...jobCostFragment
          }
        }
      }
    }
  }
  ${clientInvoiceProjectDetailsFragment}
  ${jobCostFragment}
`;

// formatProjectInfoToEdit :: Project -> ClientInvoice -> ProjectInfo
const formatProjectInfoToEdit = R.curry((project, clientInvoice) =>
  R.converge(R.mergeRight, [
    formatProjectInfo,
    R.compose(
      R.applySpec({
        divisionAccountCode: R.compose(
          formatAccountOptionText,
          R.propOr({}, 'glAccount'),
        ),
        costCenter: R.prop('costCenter'),
      }),
      R.always(clientInvoice),
    ),
  ])(project),
);

export function EditClientInvoicePage() {
  const { invoiceId } = useRouterParams(['invoiceId']);
  const { data, loading } = useQuery(clientInvoiceQuery, {
    variables: {
      invoiceId,
      searchInput: jobCostSearchInput,
    },
    fetchPolicy: 'network-only',
  });

  const project = R.pathOr({}, ['clientInvoice', 'project'], data);

  const clientInvoice = R.pathOr({}, ['clientInvoice'], data);

  const projectInfo = formatProjectInfoToEdit(project, clientInvoice);

  if (loading) return <Loader />;

  if (R.isEmpty(clientInvoice)) return <NotFoundPage />;

  return (
    <>
      <PageHeaderContainer>
        <MainHeader>Edit Invoice</MainHeader>
      </PageHeaderContainer>
      <DefaultBodyWrapper height="auto">
        <FormContainerS>
          <EditClientInvoiceForm
            invoiceId={invoiceId}
            projectInfo={projectInfo}
            clientInvoice={clientInvoice}
            formId={editClientInvoiceFormId}
          />
          <ClientInvoicesButtons formId={editClientInvoiceFormId} />
        </FormContainerS>
      </DefaultBodyWrapper>
    </>
  );
}
