import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { keywordSortQuery } from '@poly/client-utils';
import { string, number, bool } from 'prop-types';
import { ProjectType, ProjectTypeToNameMap } from '@poly/constants';
import { propEqLegacy, applyProp, centsToDollarsWithFormat } from '@poly/utils';

import { ProjectLink } from '../../sidebars/ProjectSidebar/useOpenProjectSidebar.js';
import { getWoChargeAmount } from '../CreateClientInvoice/helpers.js';
import { projectTypesColors } from '../../modules/core/constants/projects.js';
import { ProjectErrorPopover } from './components/ProjectErrorPropover.js';
import { PONumberTableComponent } from './components/PONumberTableComponent.js';
import { isProjectInvoiceAmountInvalid } from '../BatchInvoicingTM/batchInvoicingUtils.js';

// hexToRgb :: { color: String } -> String
const getBackgroundColor = ({ color }) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
  const getPoint = (value) => parseInt(value, 16);

  return result
    ? `rgba(${getPoint(result[1])}, ${getPoint(result[2])}, ${getPoint(
        result[3],
      )}, 0.4)`
    : '';
};

const ProjectCostTypeCompS = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 18px;
  padding: 3px 6px;
  border-radius: 6px;
  width: fit-content;
  color: ${R.prop('color')};
  background-color: ${getBackgroundColor};
`;

function ProjectCostType({ type }) {
  const title = ProjectTypeToNameMap[type];
  const color = projectTypesColors[type];

  return <ProjectCostTypeCompS color={color}>{title}</ProjectCostTypeCompS>;
}

ProjectCostType.propTypes = { type: string.isRequired };

function ProjectErrorIcon({ isPOEnabled, ...props }) {
  const showErrorPopover = isProjectInvoiceAmountInvalid(props);

  if (!showErrorPopover) return null;

  return <ProjectErrorPopover {...props} />;
}

ProjectErrorIcon.propTypes = { rowIndex: number.isRequired, isPOEnabled: bool };

const emptyCellConfig = [['', () => null]];

export const getConsolidatedInvoiceProjectsTableConfig = (
  showWoCharge,
  isConsolidatedByPO,
) => [
  ['Project #', ProjectLink, keywordSortQuery(['projectId'])],
  ['', ProjectErrorIcon],
  ['Description', R.prop('description'), keywordSortQuery(['description'])],
  ...(isConsolidatedByPO
    ? [['PO Number', PONumberTableComponent]]
    : emptyCellConfig),
  ['Cost Type', ProjectCostType, keywordSortQuery(['type'])],
  ['Job Cost', applyProp(centsToDollarsWithFormat)('jobCost')],
  ['Markup', applyProp(centsToDollarsWithFormat)('clientInvoiceMarkupAmount')],
  ...(showWoCharge
    ? [
        [
          'Work Order Charge',
          R.compose(
            centsToDollarsWithFormat,
            R.ifElse(
              propEqLegacy('type', ProjectType.WORK_ORDER),
              R.compose(R.defaultTo(0), getWoChargeAmount),
              R.always(0),
            ),
          ),
        ],
      ]
    : emptyCellConfig),
  [
    'Inv. Amt.',
    applyProp(centsToDollarsWithFormat)('suggestedClientInvoiceAmount'),
  ],
];
