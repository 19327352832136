import React, { useState } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { EXPORT_XLS_CAPTION, PRINT_PDF_CAPTION } from '@poly/constants';
import { CommonOuterButton, TableSearchInput } from '@poly/admin-ui';
import {
  openPrintWindowWithData,
  useOutSidebarContext,
} from '@poly/client-utils';
import {
  HeaderLinksWrapper,
  getThemeColor,
  getThemeProp,
  LinkButton,
} from '@poly/admin-book';
import { bool, number } from 'prop-types';

import { ProjectsFilter } from './ProjectsFilter.js';
import { ProjectsDirectoryFilterId } from './constants.js';
import { ToolBarBtnDivider } from '../../modules/core/tableToolbar/tableToolBarStyles.js';
import { ProjectsPageCommonTable } from '../../modules/tables/projectsTable/ProjectsTable.js';
import { useProjectsToolbarLogic } from './useProjectsToolbarLogic.js';
import { usePrintLayout } from '../../hooks/usePrintLayout.js';
import {
  generateRecurringProjectsXlsReport,
  generateProjectsXlsReport,
} from '../../modules/tables/projectsTable/exportToXlsUtils.js';

const FilterBnt = styled(LinkButton)`
  font-size: 12px;
  line-height: 18px;
  color: ${getThemeColor(['midDark'])};
  text-decoration: none;
  transition: color ${getThemeProp(['defaultTransition'])};
  margin-left: 35px;
  cursor: pointer;
  width: max-content;

  :hover,
  :focus {
    color: ${getThemeColor(['accent5'])};
  }
`;

// isExportButtonDisabled :: Int -> Boolean
const isExportButtonDisabled = R.equals(0);

function FilterButton() {
  const { openOutSidebar } = useOutSidebarContext();

  const onFilterClick = () =>
    openOutSidebar({
      width: 610,
      alwaysFirst: true,
      content: <ProjectsFilter />,
      id: ProjectsDirectoryFilterId,
    });

  return <FilterBnt onClick={onFilterClick}>Filter</FilterBnt>;
}

function ProjectsPrintBtn({ totalItemsCount, ...props }) {
  const [loading, setLoading] = useState(false);
  const Layout = usePrintLayout();
  const getProjects = useProjectsToolbarLogic({ setLoading, ...props });

  const onClick = async () => {
    const { projects, fileName, metaData, isRecurringSearch } =
      await getProjects();

    return openPrintWindowWithData({
      Layout,
      projects,
      fileName,
      metaData,
      isPrint: true,
      isRecurringSearch,
      Table: ProjectsPageCommonTable,
    });
  };

  return (
    <CommonOuterButton
      onClick={onClick}
      loading={loading}
      disabled={isExportButtonDisabled(totalItemsCount)}
    >
      {PRINT_PDF_CAPTION}
    </CommonOuterButton>
  );
}

ProjectsPrintBtn.propTypes = {
  totalItemsCount: number,
};

function ProjectsExportBtn({ totalItemsCount, ...props }) {
  const [loading, setLoading] = useState(false);
  const getProjects = useProjectsToolbarLogic({ setLoading, ...props });

  const onClick = async () => {
    const { projects, fileName, isRecurringSearch } = await getProjects();

    const generateReportFunc = isRecurringSearch
      ? generateRecurringProjectsXlsReport
      : generateProjectsXlsReport;

    return generateReportFunc(fileName)(projects);
  };

  return (
    <CommonOuterButton
      onClick={onClick}
      loading={loading}
      disabled={isExportButtonDisabled(totalItemsCount)}
    >
      {EXPORT_XLS_CAPTION}
    </CommonOuterButton>
  );
}

ProjectsExportBtn.propTypes = {
  totalItemsCount: number,
};

const ActiveButtonsWrapperS = styled(HeaderLinksWrapper)`
  position: absolute;
  right: 5px;
  top: ${({ isFilter }) => (isFilter ? '-80px' : '-40px')};
  z-index: 2;
`;

export function ProjectsDirectoryToolbar({ isFilter, ...props }) {
  const totalItemsCount = useSelector(R.path(['pagination', 'allItemsCount']));

  return (
    <>
      <ActiveButtonsWrapperS isFilter={isFilter}>
        <ProjectsPrintBtn {...props} totalItemsCount={totalItemsCount} />
        <ToolBarBtnDivider />
        <ProjectsExportBtn {...props} totalItemsCount={totalItemsCount} />
      </ActiveButtonsWrapperS>
      <HeaderLinksWrapper {...props}>
        <FilterButton />
        <TableSearchInput />
      </HeaderLinksWrapper>
    </>
  );
}

ProjectsDirectoryToolbar.propTypes = {
  isFilter: bool,
};
