import React from 'react';
import * as R from 'ramda';
import { arrayOf, shape } from 'prop-types';
import { EXPORT_XLS_CAPTION } from '@poly/constants';
import { LinkButton } from '@poly/admin-book';
import {
  createExcelExportCell,
  ExcelExportCellType,
  performExcelExport,
} from '@poly/client-utils';

import { staffReportPropTypes } from './prop-types.js';
import { staffReportMetricTitles } from './metricTitle.js';

const subTitleStyles = {
  font: { bold: true },
  alignment: { vertical: 'middle', horizontal: 'center' },
};

// getStaffReportXLSExportConfig :: [StaffReportEntry] -> ExcelExportConfig
// eslint-disable-next-line import/no-unused-modules
export const getStaffReportXLSExportConfig = (staffReport) => ({
  exportFileName: 'staff-activity-report.xlsx',
  columnWidths: [30, 15, 15, 15, 25, 25, 30, 25, 15, 15, 15, 15, 15],
  rows: [
    [
      {
        value: 'Staff Activity Report',
        cellType: ExcelExportCellType.title,
      },
    ],
    [
      {
        style: subTitleStyles,
        value: 'Current Assignments',
        _polyInternal: { mergeRight: 4 },
      },
      {},
      {},
      {},
      {
        value: 'Activity',
        style: subTitleStyles,
        _polyInternal: { mergeRight: 9 },
      },
    ],

    R.zipWith(
      createExcelExportCell,
      R.repeat(ExcelExportCellType.tableHeader, 13),
      ['Assigned CSR', ...R.map(R.nth(1))(staffReportMetricTitles)],
    ),

    ...R.map(
      R.compose(
        R.zipWith(
          createExcelExportCell,
          R.repeat({ style: { alignment: { horizontal: 'right' } } }, 13),
        ),
        R.juxt([
          R.path(['user', 'profile', 'fullName']),
          R.propOr(0, staffReportMetricTitles[0][0]),
          R.propOr(0, staffReportMetricTitles[1][0]),
          R.propOr(0, staffReportMetricTitles[2][0]),
          R.propOr(0, staffReportMetricTitles[3][0]),
          R.propOr(0, staffReportMetricTitles[4][0]),
          R.propOr(0, staffReportMetricTitles[5][0]),
          R.propOr(0, staffReportMetricTitles[6][0]),
          R.propOr(0, staffReportMetricTitles[7][0]),
          R.propOr(0, staffReportMetricTitles[8][0]),
          R.propOr(0, staffReportMetricTitles[9][0]),
          R.propOr(0, staffReportMetricTitles[10][0]),
          R.propOr(0, staffReportMetricTitles[11][0]),
        ]),
      ),
    )(staffReport),
  ],
});

export function StaffReportExportXLSBtn({ staffReport }) {
  const onClick = () => {
    const config = getStaffReportXLSExportConfig(staffReport);

    performExcelExport(config);
  };

  return (
    <LinkButton disabled={staffReport.length === 0} onClick={onClick}>
      {EXPORT_XLS_CAPTION}
    </LinkButton>
  );
}

StaffReportExportXLSBtn.propTypes = {
  staffReport: arrayOf(shape(staffReportPropTypes)).isRequired,
};
