import React from 'react';
import { Popover } from '@poly/admin-book';
import {
  SupplierDocumentPopoverContentWrapper,
  SupplierDocumentTitleDivider,
  SupplierDocumentWarningIconC,
  SupplierDocumentWarningMessage,
  SupplierDocumentWarningTitle,
} from '@poly/admin-ui/src/components/SupplierDocumentsWarning/components.js';

import { isSupplierWorkingHoursMatching } from './isSupplierWorkingHoursMatching.js';

export function SupplierWorkingHoursWarning(props) {
  if (isSupplierWorkingHoursMatching(props)) {
    return null;
  }

  return (
    <Popover
      position="right"
      Icon={SupplierDocumentWarningIconC}
      content={
        <SupplierDocumentPopoverContentWrapper>
          <SupplierDocumentWarningTitle>
            Working Hours Mismatch
          </SupplierDocumentWarningTitle>
          <SupplierDocumentTitleDivider />

          <SupplierDocumentWarningMessage>
            Working hours differ from the project schedule
          </SupplierDocumentWarningMessage>
        </SupplierDocumentPopoverContentWrapper>
      }
    />
  );
}
