import React from 'react';
import * as R from 'ramda';
import { func, string } from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { useNotificationState } from '@poly/admin-ui/src/hooks/useNotificationState.js';
import { TAG_NAME_LENGTH_LIMIT } from '@poly/constants/src/tag.js';
import { FormCreator } from '@poly/admin-book/src/Form/FormCreator.js';
import { Input } from '@poly/admin-book/src/Input/index.js';
import { ModalActions } from '@poly/admin-book/src/Modal/Modal.js';
import { SubmitBtn, CancelBtn } from '@poly/admin-ui/src/components/common.js';

import { commonModalLayout } from '../../../../modules/forms/common.js';

export const clientTagFormId = 'clientTagFormId';

const tagFormSections = [
  {
    id: 'main',
    layout: { column: 1 },
    order: 1,
    fields: [
      {
        field: {
          name: 'name',
          Component: Input,
          additionalProps: {
            maxLength: TAG_NAME_LENGTH_LIMIT,
            charactersLimit: TAG_NAME_LENGTH_LIMIT,
            showCharactersLeft: true,
          },
        },
        validators: [[R.identity, 'Name is required']],
      },
    ],
  },
];

const CREATE_TAG_MUTATION = gql`
  mutation CREATE_TAG_MUTATION($input: CreateTagInput!) {
    createTag(input: $input) {
      _id
    }
  }
`;

function ClientTagForm(props) {
  const formProps = {
    layout: commonModalLayout,
    id: clientTagFormId,
    sections: tagFormSections,
  };

  return <FormCreator {...formProps} {...props} />;
}

export function CreateClientTagForm({ clientId, handleCancelForm }) {
  const [createTag] = useMutation(CREATE_TAG_MUTATION);
  const { showSuccessNotification } = useNotificationState();
  const handleSubmit = async (formData) => {
    await createTag({ variables: { input: { ...formData, clientId } } });
    handleCancelForm();
    showSuccessNotification('Tag was successfully added');
  };

  const formProps = {
    initialValues: {},
    onSubmit: handleSubmit,
  };

  return <ClientTagForm {...formProps} />;
}

CreateClientTagForm.propTypes = {
  clientId: string.isRequired,
  handleCancelForm: func.isRequired,
};

const UPDATE_TAG_MUTATION = gql`
  mutation UPDATE_TAG_MUTATION($id: ID!, $input: UpdateTagInput!) {
    updateTag(id: $id, input: $input) {
      _id
    }
  }
`;

export function UpdateClientTagForm({ _id, name, handleCancelForm }) {
  const [updateTag] = useMutation(UPDATE_TAG_MUTATION);
  const { showSuccessNotification } = useNotificationState();
  const handleSubmit = async (formData) => {
    await updateTag({ variables: { id: _id, input: formData } });
    handleCancelForm();
    showSuccessNotification('Tag was successfully edited');
  };

  const formProps = {
    initialValues: { name },
    onSubmit: handleSubmit,
  };

  return (
    <>
      <ClientTagForm {...formProps} />
      <ModalActions>
        <SubmitBtn form={clientTagFormId}>Save</SubmitBtn>
        <CancelBtn onClick={handleCancelForm} />
      </ModalActions>
    </>
  );
}

UpdateClientTagForm.propTypes = {
  _id: string.isRequired,
  name: string.isRequired,
  handleCancelForm: func.isRequired,
};
