import React from 'react';
import * as R from 'ramda';
import { shape } from 'prop-types';
import { formatDate } from '@poly/utils';
import { performExcelExport } from '@poly/client-utils/src/excel-export/export.js';
import { EXPORT_XLS_CAPTION } from '@poly/constants/src/print.js';
import { ExcelExportCellType } from '@poly/utils/src/excel-export/constants.js';
import { createExcelExportCell } from '@poly/utils/src/excel-export/helpers.js';

import { WorkInProgressExportXLSBtnS } from '../WorkInProgressReport/WorkInProgressExportXLSBtn.js';
import { useDetailedClientSurveysReportLazyQuery } from './useDetailedClientSurveysReportQuery.js';

// getDetailedClientSurveysReportRowConfig :: [DetailedClientSurvey] -> [ExcelExportDataCell]
const getDetailedClientSurveysReportRowConfig = R.compose(
  R.zipWith(createExcelExportCell, R.repeat(ExcelExportCellType.default, 6)),
  R.juxt([
    R.compose(formatDate, R.prop('receivedAt')),
    R.pathOr('', ['client', 'name']),
    R.propOr('', 'projectId'),
    R.propOr('', 'question'),
    R.propOr('', 'answer'),
  ]),
);

export const getDetailedClientSurveysReportXlsExportConfig = (data) => ({
  exportFileName: 'detailed_client_surveys_report.xlsx',
  columnWidths: [20, 20, 10, 60, 20],
  rows: [
    [
      {
        cellType: ExcelExportCellType.title,
        value: 'Detailed Client Surveys Report',
      },
    ],
    R.zipWith(
      createExcelExportCell,
      R.repeat(ExcelExportCellType.tableHeader, 6),
      ['Date of Submission', 'Client', 'Project #', 'Question', 'Answer'],
    ),
    ...R.map(
      getDetailedClientSurveysReportRowConfig,
      R.pathOr([], ['detailedClientSurveysReport', 'hits'], data),
    ),
  ],
});

export function DetailedClientSurveysReportXLSBtn({ filter, sort, ...props }) {
  const queryHandler = useDetailedClientSurveysReportLazyQuery();

  const onExportClick = async () => {
    const { data } = await queryHandler({ filter, sort });

    const excelConfig = getDetailedClientSurveysReportXlsExportConfig(data);

    performExcelExport(excelConfig);
  };

  return (
    <WorkInProgressExportXLSBtnS {...props} onClick={onExportClick}>
      {EXPORT_XLS_CAPTION}
    </WorkInProgressExportXLSBtnS>
  );
}

DetailedClientSurveysReportXLSBtn.propTypes = {
  filter: shape({}),
  sort: shape({}),
};
