import React from 'react';
import styled from 'styled-components';
import { string, bool } from 'prop-types';
import { PageTabs, TableSearchInput, TablePagination } from '@poly/admin-ui';
import { ProblemCodeStatuses } from '@poly/constants';
import { forceTitleCase } from '@poly/utils';
import {
  TableWithPaginationContainer,
  PageHeaderContainer,
  MainHeader,
  TableCard,
  Loader,
  Status,
} from '@poly/admin-book';

import { problemCodeStatusColors } from './constants.js';
import { AddProblemCodeButton } from './AddProblemCodeButton.js';
import { NoDataToReport } from '../../components/NoDataToReport.js';
import { ProblemCodeLibraryTable } from './ProblemCobeLibraryTable.js';
import { useProblemCodesQuery } from './useProblemCodesQuery.js';

const { ACTIVE, INACTIVE } = ProblemCodeStatuses;

const contentStyles = 'margin: 0 80px 0 70px';

const EmptyWrapperS = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

function ProblemCodeTabTitle({ status }) {
  const { count } = useProblemCodesQuery(status);

  const textWithCount = `${forceTitleCase(status)} (${count})`;

  return (
    <Status text={textWithCount} color={problemCodeStatusColors[status]} />
  );
}

ProblemCodeTabTitle.propTypes = { status: string.isRequired };

function ProblemCodeTab(props) {
  return (
    <TableCard height="100%">
      <TableWithPaginationContainer paginationVisible>
        <ProblemCodeLibraryTable {...props} />
      </TableWithPaginationContainer>
      <TablePagination />
    </TableCard>
  );
}

function HasNoDataComponent({ loading }) {
  if (loading) return <Loader />;

  return (
    <EmptyWrapperS>
      <NoDataToReport />
    </EmptyWrapperS>
  );
}

HasNoDataComponent.propTypes = { loading: bool };

export function ProblemCodeLibraryPage() {
  const { hasNoData, loading } = useProblemCodesQuery();

  return (
    <>
      <PageHeaderContainer contentStyles={contentStyles}>
        <MainHeader>Problem Code Library</MainHeader>
        <AddProblemCodeButton />
      </PageHeaderContainer>
      {hasNoData ? (
        <HasNoDataComponent loading={loading} />
      ) : (
        <PageTabs
          toolBar={<TableSearchInput />}
          tabs={[
            [
              <ProblemCodeTabTitle key={ACTIVE} status={ACTIVE} />,
              ACTIVE,
              <ProblemCodeTab key={ACTIVE} status={ACTIVE} />,
            ],
            [
              <ProblemCodeTabTitle key={INACTIVE} status={INACTIVE} />,
              INACTIVE,
              <ProblemCodeTab key={INACTIVE} status={INACTIVE} />,
            ],
          ]}
          defaultValue={ACTIVE}
        />
      )}
    </>
  );
}
