import React from 'react';
import styled from 'styled-components';
import { number, shape } from 'prop-types';
import * as R from 'ramda';
import { assocBy } from '@poly/utils';

import {
  DashboardDelimiter,
  DashboardSection,
  DashboardSectionTitle,
  FlexContainer,
} from '../components.js';
import { recurringProjectsDashboardUIStatuses } from '../../../../modules/core/constants/projects.js';
import { getDashboardCounts } from '../../components/UserDashboard.js';
import { ReoccurringProjectsChart } from './ReoccurringProjectsChart.js';

const ReoccurringProjectsContainer = styled(DashboardSection)`
  display: flex;
  flex-direction: column;
  height: 164px;
  gap: 25px;
`;

const reoccurringProjectsChartsConfig = [
  {
    title: 'Scheduled',
    primaryColor: '#0086C9',
    secondaryColor: '#F0F9FF',
    data: [6, 50, 13, 27, 195, 35, 36, 66, 54, 142, 71, 77, 200],
    fieldName: 'scheduled',
    tab: recurringProjectsDashboardUIStatuses.SCHEDULED,
    Component: ReoccurringProjectsChart,
  },
  { fieldName: 'delimiter_1', Component: DashboardDelimiter },
  {
    title: 'Unscheduled',
    primaryColor: '#099250',
    secondaryColor: '#EDFCF2',
    data: [99, 71, 39, 95, 34, 55, 23, 61, 42, 9, 12, 44, 62],
    fieldName: 'unscheduled',
    tab: recurringProjectsDashboardUIStatuses.UNSCHEDULED,
    Component: ReoccurringProjectsChart,
  },
  { fieldName: 'delimiter_2', Component: DashboardDelimiter },
  {
    title: 'Completed',
    primaryColor: '#DC6803',
    secondaryColor: '#FFFAEB',
    data: [156, 66, 78, 115, 79, 73, 100, 34, 18, 55, 17, 133, 146],
    fieldName: 'completed',
    tab: recurringProjectsDashboardUIStatuses.COMPLETED,
    Component: ReoccurringProjectsChart,
  },
];

// prepareReoccurringProjectsChartsConfig :: OwnRecurringProjectsCounts -> [ReoccurringProjectsChartConfig]
const prepareReoccurringProjectsChartsConfig = (counts) =>
  R.map(
    assocBy('value', R.compose(R.propOr(0, R.__, counts), R.prop('fieldName'))),
    reoccurringProjectsChartsConfig,
  );

export function ReoccurringProjectsCharts({ dashboardData }) {
  const { ownRecurringProjectsCounts } = getDashboardCounts(dashboardData);

  const chartsConfig = prepareReoccurringProjectsChartsConfig(
    ownRecurringProjectsCounts,
  );

  return (
    <ReoccurringProjectsContainer>
      <DashboardSectionTitle
        title="Reoccurring Projects"
        iconName="tasksList"
      />
      <FlexContainer>
        {chartsConfig.map(({ fieldName, Component, ...config }) => (
          <Component key={fieldName} {...config} />
        ))}
      </FlexContainer>
    </ReoccurringProjectsContainer>
  );
}

ReoccurringProjectsCharts.propTypes = {
  dashboardData: shape({
    dashboard: shape({
      ownRecurringProjectsCounts: shape({
        completed: number,
        unscheduled: number,
        scheduled: number,
      }),
    }),
  }),
};
