import * as R from 'ramda';
import { addDays } from 'date-fns';
import { ClientFinancialTerms } from '@poly/constants';

import { ensureIsDate } from '../dates.js';

// addDaysCurried :: Date -> Number -> Date
const addDaysCurried = R.curryN(2, addDays);

const invoiceDueDaysMap = {
  [ClientFinancialTerms.due_on_receipt]: 0,
  [ClientFinancialTerms.net_10]: 10,
  [ClientFinancialTerms.net_25]: 25,
  [ClientFinancialTerms.net_30]: 30,
  [ClientFinancialTerms.net_45]: 45,
  [ClientFinancialTerms.net_60]: 60,
  [ClientFinancialTerms.net_90]: 90,
  [ClientFinancialTerms.net_120]: 120,
  [ClientFinancialTerms.net_180]: 180,
};

// getClientInvoiceDueDaysByTerms :: Client -> Number
export const getClientInvoiceDueDaysByTerms = R.compose(
  R.prop(R.__, invoiceDueDaysMap),
  R.pathOr(ClientFinancialTerms.due_on_receipt, ['financial', 'terms']),
);

// getClientInvoiceDueDate :: Date -> Client -> Date
export const getClientInvoiceDueDate = R.curry((invoiceDate, client) =>
  R.compose(
    addDaysCurried(ensureIsDate(invoiceDate)),
    getClientInvoiceDueDaysByTerms,
  )(client),
);
