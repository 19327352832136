import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { arrayOf, object, oneOfType, string } from 'prop-types';
import { ClientStatuses, EXPORT_XLS_CAPTION } from '@poly/constants';
import { useRouterQuery } from '@poly/client-routing/src/hooks.js';
import { performExcelExport } from '@poly/client-utils/src/excel-export/export.js';
import { CommonOuterButton } from '@poly/admin-ui/src/modules/buttons/TablePrintPDFBtn.js';

import { PageTabMap } from './constants.js';
import { getXlsClientsConfig } from './getXlsClientsConfig.js';
import { useClientsLazyQuery } from './useClientsLazyQuery.js';

const { ACTIVE, INACTIVE } = ClientStatuses;

const PageTabToStatusMap = {
  [PageTabMap.ACTIVE_CLIENTS]: ACTIVE,
  [PageTabMap.INACTIVE_CLIENTS]: INACTIVE,
};

const CommonOuterButtonS = styled(CommonOuterButton)`
  margin-right: 10px;
`;

// getQueryByTab :: String -> String
const getStatusByTab = R.compose(
  R.defaultTo(ACTIVE),
  R.prop(R.__, PageTabToStatusMap),
);

export function ClientsExportToXLSBtn({ sort }) {
  const totalCount = useSelector(R.path(['pagination', 'allItemsCount']));
  const { tab } = useRouterQuery(['tab']);
  const status = getStatusByTab(tab);

  const { runClientsQuery, loading } = useClientsLazyQuery({ sort, status });

  const onClick = async () => {
    const clients = await runClientsQuery();

    if (clients.length > 0) {
      const exportConfig = getXlsClientsConfig({
        clients,
        exportFileName: `${status}_clients_export`,
      });
      performExcelExport(exportConfig);
    }
  };

  const exportToXLSProps = {
    onClick,
    loading,
    disabled: totalCount === 0 || loading,
  };

  return (
    <CommonOuterButtonS {...exportToXLSProps}>
      {EXPORT_XLS_CAPTION}
    </CommonOuterButtonS>
  );
}

ClientsExportToXLSBtn.propTypes = {
  sort: arrayOf(oneOfType([string, object])),
};
