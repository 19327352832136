import React from 'react';
import styled from 'styled-components';
import {
  EXPORT_XLS_CAPTION,
  PRINT_PDF_CAPTION,
  ServiceTypeStatuses,
} from '@poly/constants';
import { openPrintWindowWithData } from '@poly/client-utils';
import { CommonPrintLayout } from '@poly/admin-ui';
import { LinkButton, ToolBarBtnDivider } from '@poly/admin-book';
import { ServiceTypesPrintTable } from '@poly/admin-ui/src/modules/tables/ServiceTypesTable/ServiceTypesTable.js';
import { performExcelExport } from '@poly/client-utils/src/excel-export/export.js';
import { useRouterQuery } from '@poly/client-routing';

import { useServiceTypesLazyQuery } from './useServiceTypesLazyQuery.js';
import {
  getServiceTypesExcelConfig,
  getServiceTypesExportFileName,
} from './getServiceTypesExcelConfig.js';

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export function ServiceTypesExportButtons() {
  const { tab } = useRouterQuery(['tab']);

  const status = tab || ServiceTypeStatuses.active;

  const { runServiceTypesQuery, loading } = useServiceTypesLazyQuery(status);

  const handlePrintPDF = async () => {
    const serviceTypes = await runServiceTypesQuery();

    await openPrintWindowWithData({
      fileName: getServiceTypesExportFileName(status, 'pdf'),
      Layout: CommonPrintLayout,
      Table: ServiceTypesPrintTable,
      metaData: {
        title: 'Service Types',
      },
      serviceTypes,
    });
  };

  const handleExportXLS = async () => {
    const serviceTypes = await runServiceTypesQuery();

    const config = getServiceTypesExcelConfig(serviceTypes, status);
    return performExcelExport(config);
  };

  return (
    <ButtonsWrapper>
      <LinkButton onClick={handlePrintPDF} disabled={loading}>
        {PRINT_PDF_CAPTION}
      </LinkButton>
      <ToolBarBtnDivider />
      <LinkButton onClick={handleExportXLS} disabled={loading}>
        {EXPORT_XLS_CAPTION}
      </LinkButton>
    </ButtonsWrapper>
  );
}
