import React from 'react';
import styled from 'styled-components';
import { func } from 'prop-types';
import { Button } from '@poly/admin-book';
import { useSelector } from 'react-redux';

import { SubmitButton } from '../../../modules/forms/supplierInvoiceForm/sections.js';
import { BulkEditingFilterFormId, BulkEditObjectType } from '../constants.js';

const FilterActionButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export function FilterActionButtons({ changeFieldValue }) {
  const submitting = useSelector(
    (state) => state.processes[BulkEditingFilterFormId],
  );

  const onReset = () => {
    changeFieldValue('rules', [
      {
        objectType: BulkEditObjectType.PROJECTS,
        rulesAndOrCondition: { and: true, or: false },
      },
      {},
    ]);
  };

  return (
    <FilterActionButtonsWrapper>
      <SubmitButton disabled={submitting}>Show results</SubmitButton>
      <Button
        size="small"
        type="button"
        styleType="basicSecondary"
        onClick={onReset}
      >
        Reset
      </Button>
    </FilterActionButtonsWrapper>
  );
}

FilterActionButtons.propTypes = {
  changeFieldValue: func,
};
