import React from 'react';
import * as R from 'ramda';
import styled, { css } from 'styled-components';
import { string, arrayOf, shape, bool } from 'prop-types';
import { useMapConfigToTableProps } from '@poly/admin-ui';
import {
  moneyColumnStyles,
  WindowedTable,
  getThemeColor,
  BodyRow,
  Popover,
  Icon,
} from '@poly/admin-book';

import { isWoCharge } from '../BatchInvoicingTM/BatchInvoicingPageBody.js';
import {
  isClientInvoiceAmountInvalid,
  isTotalsOutOfRange,
} from '../BatchInvoicingTM/batchInvoicingUtils.js';
import { getConsolidatedInvoiceProjectsTableConfig } from './consolidated-invoice-projects-table-config.js';

// getEmptyCellStyles :: Number -> String
const getEmptyCellStyles = (colNum) => css`
  th:nth-child(${colNum}),
  td:nth-child(${colNum}) {
    display: none;
  }
`;

const ClientInvoicingCollectionTableCompS = styled(WindowedTable)`
  thead {
    height: 45px;
  }
  tr {
    width: 100%;
    margin: 0;
  }

  td {
    vertical-align: middle;
  }

  th:nth-child(1),
  td:nth-child(1) {
    width: 90px;
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: 20px;
    padding-left: 0;
  }

  td:nth-child(3) {
    overflow: hidden;
    display: -webkit-inline-box !important;
    -webkit-box-align: center;
    white-space: normal;
    padding: 0 5px;
    margin: 6px 0;
    height: 32px;
  }

  th:nth-child(4),
  td:nth-child(4) {
    display: ${({ isConsolidatedByPO }) =>
      isConsolidatedByPO ? 'table-cell' : 'none'};
    width: 135px;
  }

  th:nth-child(5),
  td:nth-child(5) {
    width: 155px;
  }

  ${moneyColumnStyles(6, 100)};
  ${moneyColumnStyles(7)};

  ${({ showWoCharge }) =>
    showWoCharge ? moneyColumnStyles(8) : getEmptyCellStyles(8)};

  ${moneyColumnStyles(9)};

  th:nth-child(10),
  td:nth-child(10) {
    width: 40px;
    text-align: right;
  }
`;

const PopoverContentWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  text-align: start;
  background-color: #12347a;
  color: #fff;
  border-radius: 5px;
  max-width: 300px;
  font-size: 12px;
  line-height: 18px;
`;

// getAdminPurchaseOrderId :: Project -> String
const getAdminPurchaseOrderId = R.either(
  R.path(['adminPurchaseOrder', '_id']),
  R.path(['parent', 'adminPurchaseOrder', '_id']),
);

// hasClientReferenceNumber :: Boolean -> Project -> Boolean
const hasClientReferenceNumber = (isPORequired, isPOEnabled) =>
  R.ifElse(
    R.always(isPORequired),
    R.ifElse(
      R.always(isPOEnabled),
      getAdminPurchaseOrderId,
      R.either(
        R.either(
          R.prop('clientReferenceNumber'),
          R.path(['parent', 'clientReferenceNumber']),
        ),
        getAdminPurchaseOrderId,
      ),
    ),
    R.T,
  );

// getRowColorByProps :: Boolean ->  Row -> String
const getRowColorByProps = (isPORequired) =>
  R.cond([
    [
      R.compose(isTotalsOutOfRange, R.prop('row')),
      getThemeColor(['notificator', 'warning', 'text']),
    ],
    [
      R.compose(isClientInvoiceAmountInvalid, R.prop('row')),
      getThemeColor(['notificator', 'error', 'text']),
    ],
    [
      R.compose(
        R.complement(hasClientReferenceNumber(isPORequired)),
        R.prop('row'),
      ),
      getThemeColor(['notificator', 'error', 'text']),
    ],
    [R.T, getThemeColor(['primary'])],
  ]);

const getBodyRow = (isPORequired) => styled(BodyRow)`
  color: ${getRowColorByProps(isPORequired)};
`;

// prepareProjects :: Boolean -> [Project] -> [Project]
const prepareProjects = (isPOEnabled) => (projects) =>
  projects.map((project, index) => ({
    ...project,
    isPOEnabled,
    rowIndex: index,
  }));

function POMissingComponent() {
  return (
    <Popover
      position="left"
      bgColor="#12347a"
      isClickable={false}
      title={<Icon name="yellowWarning" size={16} />}
      content={<PopoverContentWrapperS>Missing PO#</PopoverContentWrapperS>}
    />
  );
}

export function ClientInvoicingCollectionTable({
  projects,
  isPOEnabled,
  isPORequired,
  isConsolidatedByPO,
  ...tableProps
}) {
  const { rows, headers, columns, sortQueries } = useMapConfigToTableProps(
    prepareProjects(isPOEnabled),
    getConsolidatedInvoiceProjectsTableConfig(
      isWoCharge(projects),
      isConsolidatedByPO,
    ),
    projects,
  );

  return (
    <ClientInvoicingCollectionTableCompS
      isSortable
      rows={rows}
      showScrollBar
      headers={headers}
      columns={columns}
      selectedRowsInEnd
      sortQueries={sortQueries}
      showWoCharge={isWoCharge(projects)}
      isConsolidatedByPO={isConsolidatedByPO}
      RowComponent={getBodyRow(isPORequired)}
      NotSelectableCheckboxComponent={POMissingComponent}
      isRowSelectable={hasClientReferenceNumber(isPORequired, isPOEnabled)}
      {...tableProps}
    />
  );
}

ClientInvoicingCollectionTable.propTypes = {
  isPOEnabled: bool,
  isPORequired: bool,
  isConsolidatedByPO: bool,
  projects: arrayOf(shape({ _id: string.isRequired })),
};
