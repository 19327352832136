import React, { useContext, useState } from 'react';
import * as R from 'ramda';
import {
  LinkButton,
  Status,
  TableToolBarWrapper,
  TableWithToolBarWrapper,
} from '@poly/admin-book';
import { string, func } from 'prop-types';
import { ServiceTypeStatuses } from '@poly/constants';
import styled from 'styled-components';
import { forceTitleCase } from '@poly/utils';

import { TableCardWithPaginationContainer } from '../../../components/index.js';
import { PageTabs } from '../../../components/Tabs.js';
import { serviceTypesStatusesColors } from '../../../constants/index.js';
import {
  ServiceTypesTable,
  ServiceTypesContext,
} from '../../tables/ServiceTypesTable/ServiceTypesTable.js';
import {
  usePrefetchServiceTypes,
  usePrefetchServiceTypesPage,
} from '../../../hooks/prefetches/serviceTypes.js';

const LinkButtonS = styled(LinkButton)`
  margin: 0 5px 5px 0;
  align-self: flex-end;
`;

function AddServiceTypeBtn() {
  const { onAddServiceType } = useContext(ServiceTypesContext);

  return <LinkButtonS onClick={onAddServiceType}>Add New</LinkButtonS>;
}

function ServiceTypesTab(props) {
  return (
    <TableCardWithPaginationContainer>
      <TableWithToolBarWrapper>
        <TableToolBarWrapper>
          <AddServiceTypeBtn />
        </TableToolBarWrapper>
        <ServiceTypesTable {...props} />
      </TableWithToolBarWrapper>
    </TableCardWithPaginationContainer>
  );
}

function ServiceTypesTabTitle({ status }) {
  const { supplierSource } = useContext(ServiceTypesContext);

  const { result } = usePrefetchServiceTypes({ status, supplierSource });

  return (
    <Status
      color={serviceTypesStatusesColors[status]}
      text={`${forceTitleCase(status)} (${
        result?.searchServiceTypes?.total || 0
      })`}
    />
  );
}

ServiceTypesTabTitle.displayName = 'ServiceTypesTabTitle';

ServiceTypesTabTitle.propTypes = {
  status: string.isRequired,
};

const tabs = [ServiceTypeStatuses.active, ServiceTypeStatuses.inactive].map(
  (status) => [
    <ServiceTypesTabTitle
      status={status}
      key={`${status}-service-types-tab-title`}
    />,
    status,
    <ServiceTypesTab status={status} key={`${status}-service-types-tab`} />,
  ],
);

// getContextValue :: Object -> Object
const getContextValue = R.pick([
  'onEditServiceType',
  'supplierSource',
  'onAddServiceType',
  'sort',
  'setSort',
]);

export function ServiceTypesTabs(props) {
  const { inactiveServiceTypes, activeServiceTypes } =
    usePrefetchServiceTypesPage(props.supplierSource);

  const [sort, setSort] = useState(null);

  return (
    <ServiceTypesContext.Provider
      value={getContextValue({ ...props, sort, setSort })}
    >
      <PageTabs
        {...inactiveServiceTypes}
        {...activeServiceTypes}
        {...props}
        tabs={tabs}
        defaultValue={ServiceTypeStatuses.active}
      />
    </ServiceTypesContext.Provider>
  );
}

ServiceTypesTabs.propTypes = {
  supplierSource: string.isRequired,
  onAddServiceType: func.isRequired,
  onEditServiceType: func.isRequired,
};
