import React from 'react';
import * as R from 'ramda';
import { bool, string } from 'prop-types';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useNotificationState } from '@poly/admin-ui/src/hooks/useNotificationState.js';
import { IconButton } from '@poly/admin-book/src/IconButton/index.js';
import { Loader } from '@poly/admin-book/src/Loader/index.js';
import { useModalContext } from '@poly/admin-ui/src/components/Modal/ModalProvider.js';
import { waitForTimeoutP } from '@poly/utils/src/general.js';
import { Popover } from '@poly/admin-book/src/Popover/index.js';

import {
  DisabledDeleteIcon,
  FlexColumnS,
  LoaderWrapper,
  PopoverContentTitle,
} from '../../../../pages/AssetManufactureModels/DeleteAssetModelInUse.js';
import { useSidebarLogicContext } from '../../../SidebarLogicContext.js';
import { useClientTagContext } from './ClientTagsProvider.js';

const DELETE_TAG_MUTATION = gql`
  mutation DELETE_TAG_MUTATION($id: ID!) {
    deleteTag(id: $id)
  }
`;

const confirmModalProps = {
  cancelBtnCaption: 'No',
  btnCaption: 'Yes, Delete',
  id: 'deleteClientTag',
  content: 'Are you sure you want to remove this tag?',
};

const PROPERTIES_BY_TAG_ID_QUERY = gql`
  query PROPERTIES_BY_TAG_ID_QUERY($searchInput: CollectionSearchParams!) {
    searchProperties(input: $searchInput) {
      hits {
        _id
        name
      }
      total
    }
  }
`;

// getProperties :: SearchPropertiesQueryResult -> {total:Number, properties: [Property] }
const getProperties = R.applySpec({
  total: R.pathOr(0, ['searchProperties', 'total']),
  properties: R.pathOr([], ['searchProperties', 'hits']),
});

function PopoverContent({ isActive, tagId }) {
  const { PropertyLink } = useSidebarLogicContext();

  const { data, loading } = useQuery(PROPERTIES_BY_TAG_ID_QUERY, {
    variables: {
      searchInput: {
        query: { bool: { must: { term: { tagIds: tagId } } } },

        size: 5,
      },
    },
    skip: !isActive,
    fetchPolicy: 'network-only',
  });
  const { properties, total } = getProperties(data);

  if (loading) {
    return (
      <LoaderWrapper>
        <Loader size={20} />
      </LoaderWrapper>
    );
  }

  return (
    <FlexColumnS>
      {total > 0 && (
        <PopoverContentTitle>
          Unable to delete while in use ({total}
          {total > 1 ? ' properties' : ' property'})
        </PopoverContentTitle>
      )}
      {properties.map(({ _id, name }) => (
        <PropertyLink _id={_id} key={_id} name={name} />
      ))}
    </FlexColumnS>
  );
}

PopoverContent.propTypes = {
  isActive: bool,
  tagId: string,
};

export function DeleteTagButton({ _id, isUseInProperties }) {
  const { openConfirmModal } = useModalContext();
  const { showSuccessNotification } = useNotificationState();
  const [deleteTag] = useMutation(DELETE_TAG_MUTATION);

  const { isTagProcess, setTagProcess } = useClientTagContext();

  const handleClick = () =>
    openConfirmModal({
      ...confirmModalProps,
      onConfirm: (closeConfirmModal) => async () => {
        try {
          setTagProcess(_id, true);
          await deleteTag({ variables: { id: _id } });
          showSuccessNotification('Tag was successfully removed');
        } finally {
          closeConfirmModal();
          await waitForTimeoutP(2000);
          setTagProcess(_id, false);
        }
      },
    });

  if (isUseInProperties || isTagProcess(_id)) {
    return (
      <Popover
        position="left"
        Icon={DisabledDeleteIcon}
        content={PopoverContent}
        contentProps={{ tagId: _id }}
      />
    );
  }

  return (
    <IconButton
      size={15}
      name="delete"
      hoverColor="red"
      onClick={handleClick}
    />
  );
}

DeleteTagButton.propTypes = {
  _id: string.isRequired,
  isUseInProperties: bool,
};
