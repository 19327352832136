import React from 'react';
import { css } from 'styled-components';
import { arrayOf, shape } from 'prop-types';
import { openPrintWindowWithData } from '@poly/client-utils';
import { PRINT_PDF_CAPTION } from '@poly/constants';
import { CommonPrintLayout } from '@poly/admin-ui';
import { LinkButton } from '@poly/admin-book';

import {
  staffReportPropTypes,
  staffReportQueryPropTypes,
} from './prop-types.js';
import { StaffReportTable } from './StaffReportTable.js';

const staffReportPdfStyles = css`
  @page {
    size: landscape;
  }
`;

export function StaffReportPrintBtn({ query, staffReport }) {
  const onClick = async () => {
    openPrintWindowWithData({
      query,
      staffReport,
      isPrint: true,
      Table: StaffReportTable,
      Layout: CommonPrintLayout,
      fileName: 'staff_activity_report',
      customStyles: staffReportPdfStyles,
      metaData: { title: 'Staff Activity Report' },
    });
  };

  return (
    <LinkButton disabled={staffReport.length === 0} onClick={onClick}>
      {PRINT_PDF_CAPTION}
    </LinkButton>
  );
}

StaffReportPrintBtn.propTypes = {
  query: shape(staffReportQueryPropTypes),
  staffReport: arrayOf(shape(staffReportPropTypes)).isRequired,
};
