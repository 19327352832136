import * as R from 'ramda';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { arrayOf, func, number, shape, string } from 'prop-types';
import { FieldLayout, LinkButton, getThemeColor } from '@poly/admin-book';
import { isNilOrEmpty } from '@poly/utils';

import { ProcedureSelect } from '../../../components/ProcedureSelect.js';
import { AllAssetsOption } from '../MultiAssetsSelect/useMultiAssetsSelectQuery.js';
import { MultiAssetsSelect } from '../MultiAssetsSelect/MultiAssetsSelect.js';

const ProcedureBulkAttachLineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const AddProcedureButtonS = styled(LinkButton)`
  position: absolute;
  top: 24px;
  right: 54px;

  ::after {
    display: block;
    content: '|';
    font-size: 12px;
    color: ${getThemeColor(['midDark'])};
    padding: 0 5px;
    position: absolute;
    top: 0;
    right: -12px;
    width: 1px;
  }
`;

const ProcedureLineS = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 15px;
`;

const DeleteProcedureButtonS = styled(LinkButton)`
  color: ${getThemeColor(['secondaryRegular'])};
  padding-top: 6px;
`;

const DeleteProcedureHolderS = styled.div`
  display: block;
  width: 37px;
`;

// prepareFormDataForAssetsSelect :: FormData -> FormData
const prepareFormDataForAssetsSelect = R.pick([
  'types',
  'propertyId',
  'attachedAssets',
  'subPropertiesIds',
  'executedProcedureAssets',
]);

function ProcedureAssetsSelect({ formData, ...props }) {
  const preparedFormData = useMemo(
    () => prepareFormDataForAssetsSelect(formData),
    [formData],
  );

  return <MultiAssetsSelect {...props} formData={preparedFormData} />;
}

ProcedureAssetsSelect.propTypes = {
  formData: shape({ propertyId: string.isRequired }).isRequired,
};

// getAlreadyAttachedAssets :: FormLines -> [ID]
const getAlreadyAttachedAssets = R.compose(
  R.map(R.prop('value')),
  R.flatten,
  R.map(R.prop('assetIds')),
  R.defaultTo([]),
);

// getAlreadyAttachedProcedures :: ID -> FormLines -> [ID]
const getAlreadyAttachedProcedures = (procedureId) =>
  R.compose(
    R.reject(R.isNil),
    R.reject(R.equals(procedureId)),
    R.map(R.prop('procedureId')),
    R.defaultTo([]),
  );

export function ProcedureBulkAttachLine({
  name,
  index,
  entity,
  formData,
  fields: { push, remove, value, update },
}) {
  const onAddProcedure = (e) => {
    e.preventDefault();
    push({ assetIds: [] });
  };

  const onRemoveProcedure = (e) => {
    e.preventDefault();
    remove(index);
  };

  const isSingleLine = useMemo(() => value.length === 1, [value]);

  const currentLineProcedureId = useMemo(
    () => R.path([index, 'procedureId'], value),
    [index, value],
  );

  const isCurrentLineProcedureExecuted = useMemo(
    () => R.path([index, 'isProcedureExecuted'], value),
    [index, value],
  );

  const currentLineAssetTypes = useMemo(
    () => R.pathOr([], [index, 'types'], value),
    [index, value],
  );

  const attachedAssets = useMemo(
    () => getAlreadyAttachedAssets(value),
    [value],
  );

  const attachedProcedures = useMemo(
    () => getAlreadyAttachedProcedures(currentLineProcedureId)(value),
    [value, currentLineProcedureId],
  );

  const isCurrentLineWithAssets = useMemo(
    () =>
      R.pathSatisfies(R.complement(isNilOrEmpty), [index, 'assetIds'], value),
    [index, value],
  );

  const setProcedureLine = (updatedLine) =>
    update(index, { ...value[index], ...updatedLine });

  const setAllAssets = (fieldName, allAssets) =>
    setProcedureLine({ [fieldName]: allAssets, assetIds: [AllAssetsOption] });

  return (
    <ProcedureBulkAttachLineWrapper>
      <AddProcedureButtonS onClick={onAddProcedure}>
        Add Procedure
      </AddProcedureButtonS>
      <ProcedureLineS>
        <FieldLayout
          required
          layout={{ width: '40%' }}
          field={{
            name: `${name}.procedureId`,
            Component: ProcedureSelect,
            additionalProps: {
              setProcedureLine,
              attachedProcedures,
              withWarningOption: isCurrentLineWithAssets,
            },
          }}
        />
        <FieldLayout
          required
          layout={{ width: '50%' }}
          disabled={isNilOrEmpty(currentLineProcedureId)}
          field={{
            name: `${name}.assetIds`,
            Component: ProcedureAssetsSelect,
            additionalProps: {
              entity,
              formData: {
                ...formData,
                attachedAssets,
                types: currentLineAssetTypes,
              },
              changeFieldValue: setAllAssets,
            },
          }}
        />
        {isSingleLine || isCurrentLineProcedureExecuted ? (
          <DeleteProcedureHolderS />
        ) : (
          <DeleteProcedureButtonS onClick={onRemoveProcedure}>
            Delete
          </DeleteProcedureButtonS>
        )}
      </ProcedureLineS>
    </ProcedureBulkAttachLineWrapper>
  );
}

ProcedureBulkAttachLine.propTypes = {
  name: string.isRequired,
  index: number.isRequired,
  entity: shape({ _id: string, name: string }),
  fields: shape({
    push: func.isRequired,
    update: func.isRequired,
    remove: func.isRequired,
    value: arrayOf(shape({ procedureId: string })).isRequired,
  }),
  formData: shape({ propertyId: string.isRequired }).isRequired,
};
