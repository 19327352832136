import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useOutSidebarContext } from '@poly/client-utils';
import { LinkButton, ToolBarBtnDivider } from '@poly/admin-book';
import { arrayOf, func, number, object, oneOfType, string } from 'prop-types';
import {
  MainHeader,
  PageHeaderContainer,
} from '@poly/admin-book/src/Header/index.js';

import { FlexRow } from '../../components/appBar/HistoryPopUp/historyComponents.js';
import { ProjectXlsExportButton } from './components/ProjectXlsExportButton/ProjectXlsExportButton.js';
import { BulkEditingFilterSidebar } from './BulkEditingFilterSidebar.js';
import { BulkEditingFilterSidebarId } from './constants.js';

const FlexRowS = styled(FlexRow)`
  align-items: center;
`;

const DocumentsCount = styled.div`
  line-height: 22px;
  font-size: 14px;
  font-weight: 500;
  color: #999;
`;

export function BulkEditingPageHeader({
  sort,
  input,
  height,
  setInput,
  documentsCount,
}) {
  const { openOutSidebar } = useOutSidebarContext();

  const openBulkEditingOutSidebar = () =>
    openOutSidebar({
      width: 610,
      alwaysFirst: true,
      content: <BulkEditingFilterSidebar setInput={setInput} />,
      id: BulkEditingFilterSidebarId,
    });

  useEffect(openBulkEditingOutSidebar, []);

  return (
    <PageHeaderContainer height={height} headerStyles="padding-bottom: 55px">
      <FlexRowS>
        <MainHeader>Bulk Edit</MainHeader>
        <DocumentsCount>
          ({documentsCount} result{documentsCount === 1 ? '' : 's'} found)
        </DocumentsCount>
      </FlexRowS>
      <FlexRowS>
        <ProjectXlsExportButton
          sort={sort}
          input={input}
          disabled={documentsCount === 0}
        />
        <ToolBarBtnDivider />
        <LinkButton onClick={openBulkEditingOutSidebar}>Filters</LinkButton>
      </FlexRowS>
    </PageHeaderContainer>
  );
}

BulkEditingPageHeader.propTypes = {
  height: string,
  setInput: func,
  documentsCount: number,
  // eslint-disable-next-line react/forbid-prop-types
  input: object,
  // eslint-disable-next-line react/forbid-prop-types
  sort: arrayOf(oneOfType([string, object])),
};
