import * as R from 'ramda';
import { arrayOf, bool, func, object, string } from 'prop-types';
import styled from 'styled-components';
import React, { useState } from 'react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useMapConfigToTableProps, useNotificationState } from '@poly/admin-ui';
import { Button, moneyColumnStyles, WindowedTable } from '@poly/admin-book';
import { formatBackLink, Link, useLocation } from '@poly/client-routing';
import { AACCheckStatus, AdminCheckTypes } from '@poly/constants';
import { assocBy, propEqLegacy, pathEqLegacy } from '@poly/utils';

import { FlexContainer } from '../../components/FlexContainer.js';
import { printChecksTableConfig } from './PrintChecksTableConfig.js';
import { SearchPageContentWrapper } from '../../components/PageWithSearchHeader.js';
import {
  BottomPanelContainer,
  BottomPanelBody,
} from '../../components/BottomPanel.js';
import { useShowLongRunningTaskResult } from '../../components/LongRunningTaskResult.js';
import { routesNames } from '../../routes/index.js';
import { useDownloadRemoteCheck } from './hooks/useDownloadRemoteCheck.js';

export const GET_SUPPLIER_CHECKS_QUERY = gql`
  query GET_SUPPLIER_CHECKS_QUERY($input: CollectionSearchParams) {
    searchAdminChecks(input: $input) {
      hits {
        _id
        type
        total
        checkNumber
        paymentDate
        isPrinted
        status
        bankAccountName
        supplier {
          _id
          status
          tax {
            id
            socialSecurityNumber
          }
          company {
            name
          }
        }
        payload {
          remoteCheckSuppliers {
            _id
            status
            company {
              name
            }
            tax {
              id
              socialSecurityNumber
            }
          }
        }
      }
      total
    }
  }
`;

export const GET_SUPPLIERS_SUBSCRIPTION = gql`
  subscription GET_SUPPLIERS_SUBSCRIPTION(
    $searchInput: CollectionSearchParams
  ) {
    supplierChecksChanged(input: $searchInput) {
      id
      type
    }
  }
`;

export const ButtonsContainer = styled(FlexContainer)`
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  height: 100%;

  > a {
    margin-right: 25px;
  }
`;

const PrintChecksTableS = styled(WindowedTable)`
  tbody {
    vertical-align: middle;
  }

  td {
    padding: 0 5px;
  }

  th:nth-child(8),
  td:nth-child(8) {
    width: 40px;
  }

  th:nth-child(1),
  td:nth-child(1) {
    width: 10%;
  }
  th:nth-child(2),
  td:nth-child(2) {
    width: 30%;
  }

  ${moneyColumnStyles(6)};
`;

export const AAC_CHECKS_MUTATION = gql`
  mutation AAC_CHECKS_MUTATION($input: GenerateAACChecksPDFInput!) {
    generateAACChecksPDF(input: $input) {
      aacChecksPDF
    }
  }
`;

const MARK_CHECK_AS_PRINTED_MUTATION = gql`
  mutation MARK_CHECK_AS_PRINTED_MUTATION($ids: [ID!]!) {
    markChecksAsPrinted(ids: $ids) {
      _id
    }
  }
`;

const GENERATE_REMOTE_ADMIN_CHECK_FILES_QUERY = gql`
  query GENERATE_REMOTE_ADMIN_CHECK_FILES_QUERY($ids: [ID!]!) {
    generateRemoteAdminCheckFiles(ids: $ids) {
      content
      fileName
      fileType
    }
  }
`;

// isCheckRowSelectable :: AACCheck -> Boolean
export const isCheckRowSelectable = R.complement(
  propEqLegacy('status', AACCheckStatus.VOIDED),
);

// getBase64Strings :: MutationResult -> [String]
// MutationResult = { data: { generateSupplierChecksPDF: GenerateSupplierChecksPDFResponse } }
export const getBase64Strings = R.path([
  'data',
  'generateAACChecksPDF',
  'aacChecksPDF',
]);

// toggleRow :: ([ID], SetFunction) -> ID -> _
// SetFunction = ([ID]) -> _
const toggleRow = (selectedCheckIds, setSelectedChecks) => (checkId) =>
  R.compose(
    setSelectedChecks,
    R.ifElse(
      R.includes(checkId),
      R.reject(R.equals(checkId)),
      R.append(checkId),
    ),
  )(selectedCheckIds);

// prepareAllChecksForSelect :: [ID] -> SupplierChecksData -> [ID]
const prepareAllChecksForSelect = (selectedCheckIds) =>
  R.compose(
    R.when(R.eqProps('length', selectedCheckIds), R.always([])),
    R.map(R.prop('_id')),
    R.filter(isCheckRowSelectable),
  );

const MAX_ALLOWED_CHECKS_AMOUNT = 2000;

function BackPaySuppliersLink() {
  const location = useLocation();
  const href = formatBackLink({
    fallbackLink: routesNames.FINANCIAL_DIRECTORY,
    withQuery: false,
    router: {
      pathname: location.pathname,
      previous: R.path(['state', 'previous'], location),
    },
  });
  return <Link {...{ href }}>Return to Pay Suppliers</Link>;
}

// getAdminChecksByQueryResult :: { searchAdminChecks: { hits: [AACCheck] } }
const getAdminChecksByQueryResult = R.pathOr([], ['searchAdminChecks', 'hits']);

// getSelectedChecks :: [ID] -> [AACCheck] -> [AACCheck]
const getSelectedChecks = (selectedCheckIds) =>
  R.compose(
    assocBy(
      'selectedRemoteCheckIds',
      R.compose(R.map(R.prop('_id')), R.prop('selectedRemoteChecks')),
    ),
    assocBy(
      'selectedManualCheckIds',
      R.compose(R.map(R.prop('_id')), R.prop('selectedManualChecks')),
    ),
    R.applySpec({
      selectedManualChecks: R.filter(
        propEqLegacy('type', AdminCheckTypes.manual),
      ),
      selectedRemoteChecks: R.filter(
        propEqLegacy('type', AdminCheckTypes.remote),
      ),
    }),
    R.filter(R.propSatisfies(R.includes(R.__, selectedCheckIds), '_id')),
    R.defaultTo([]),
  );

// getRemoteCheckFiles :: { data: { generateRemoteAdminCheckFiles: [GeneratedRemoteAdminCheckFile] } }
// -> [GeneratedRemoteAdminCheckFile]
const getRemoteCheckFiles = R.pathOr(
  [],
  ['data', 'generateRemoteAdminCheckFiles'],
);

export function PrintChecksTable({ data, tableProps, loading }) {
  const { downloadRemoteCheck } = useDownloadRemoteCheck();
  const location = useLocation();
  const showBackButton = pathEqLegacy(
    ['state', 'previous', 'pathname'],
    routesNames.PAY_SUPPLIERS,
    location,
  );

  const { showWarningNotification } = useNotificationState();

  const [generateChecks] = useMutation(AAC_CHECKS_MUTATION);
  const [markChecksAsPrinted] = useMutation(MARK_CHECK_AS_PRINTED_MUTATION);
  const [generateRemoteAdminCheckFiles] = useLazyQuery(
    GENERATE_REMOTE_ADMIN_CHECK_FILES_QUERY,
  );

  const showLongRunningTaskResult = useShowLongRunningTaskResult();

  const [selectedCheckIds, setSelectedCheckIds] = useState([]);

  const adminChecks = getAdminChecksByQueryResult(data);

  const { rows, headers, columns, sortQueries } = useMapConfigToTableProps(
    R.identity,
    printChecksTableConfig,
    adminChecks,
  );

  const toggleSelectAll = () =>
    setSelectedCheckIds(
      prepareAllChecksForSelect(selectedCheckIds)(adminChecks),
    );

  const onSubmit = async () => {
    if (selectedCheckIds.length > MAX_ALLOWED_CHECKS_AMOUNT) {
      showWarningNotification(
        `Not allowed to print more than ${MAX_ALLOWED_CHECKS_AMOUNT} checks at a time`,
      );
      return;
    }

    const {
      selectedManualChecks,
      selectedManualCheckIds,
      selectedRemoteChecks,
      selectedRemoteCheckIds,
    } = getSelectedChecks(selectedCheckIds)(adminChecks);

    if (!R.isEmpty(selectedRemoteChecks)) {
      const queryResult = await generateRemoteAdminCheckFiles({
        variables: { ids: selectedRemoteCheckIds },
      });

      const remoteCheckFiles = getRemoteCheckFiles(queryResult);

      remoteCheckFiles.map((remoteCheck) => downloadRemoteCheck(remoteCheck));

      await markChecksAsPrinted({ variables: { ids: selectedRemoteCheckIds } });
    }

    if (!R.isEmpty(selectedManualChecks)) {
      showLongRunningTaskResult({
        title: 'Print Checks',
        viewResultCaption: 'Open PDF',
        loadingMessage: 'Generating checks...',
        doneMessage: 'PDF is ready to open',
        taskAction: async () => {
          const mutationResult = await generateChecks({
            variables: {
              input: { checkIds: selectedManualCheckIds },
            },
          });
          return getBase64Strings(mutationResult);
        },
        onViewResult: (pdfUrls) => {
          pdfUrls.forEach((pdfUrl) => window.open(pdfUrl, '_blank'));
        },
      });
    }
  };

  return (
    <>
      <SearchPageContentWrapper>
        <PrintChecksTableS
          selectedRowsInEnd
          selectedRows={selectedCheckIds}
          isRowSelectable={isCheckRowSelectable}
          toggleSelectAll={toggleSelectAll}
          toggleRow={toggleRow(selectedCheckIds, setSelectedCheckIds)}
          headers={headers}
          rows={rows}
          columns={columns}
          sortQueries={sortQueries}
          isLoading={loading}
          showScrollBar
          {...tableProps}
        />
      </SearchPageContentWrapper>
      <BottomPanelContainer height="70px">
        <BottomPanelBody>
          <ButtonsContainer>
            {showBackButton && <BackPaySuppliersLink />}
            <Button
              size="small"
              onClick={onSubmit}
              disabled={R.isEmpty(selectedCheckIds)}
            >
              Print Checks
            </Button>
          </ButtonsContainer>
        </BottomPanelBody>
      </BottomPanelContainer>
    </>
  );
}

PrintChecksTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: object,
  // eslint-disable-next-line react/forbid-prop-types
  tableProps: object,
  loading: bool,
  selectedChecks: arrayOf(string),
  setSelectedChecks: func,
};
