import { ALL } from '@poly/admin-ui';
import { WorkOrderStatus } from '@poly/constants';

export const ProjectStatusOptions = [
  { label: 'All', value: ALL },
  { label: 'Active', value: WorkOrderStatus.ACTIVE },
  { label: 'Pending', value: WorkOrderStatus.PENDING },
  { label: 'Completed', value: WorkOrderStatus.COMPLETED },
  { label: 'On Hold Client', value: WorkOrderStatus.ON_HOLD_CLIENT },
  { label: 'On Hold Parts', value: WorkOrderStatus.ON_HOLD_PARTS },
  {
    label: 'On Hold Work Order',
    value: WorkOrderStatus.ON_HOLD_PROJECT,
  },
  { label: 'Blocked', value: WorkOrderStatus.BLOCKED },
];
