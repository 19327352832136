import * as R from 'ramda';
import styled from 'styled-components';
import React, { useMemo } from 'react';
import { startOfDay, endOfDay } from 'date-fns';
import { arrayOf, bool, func, string, shape } from 'prop-types';
import { UserGroupMultiSelect, UsersMultiSelect, ALL } from '@poly/admin-ui';
import { Select, DatePicker, HeaderLinksWrapper } from '@poly/admin-book';
import { ensureIsDate, propEqLegacy, isNilOrEmpty } from '@poly/utils';

import {
  staffReportPropTypes,
  staffReportQueryPropTypes,
} from './prop-types.js';
import {
  SearchHeaderButton,
  SearchHeaderColumn,
} from '../../components/SearchHeaderColumn.js';
import { StaffReportPrintBtn } from './StaffReportPrintBtn.js';
import { FlexCenterAlign } from '../../components/FlexContainer.js';
import { StaffReportExportXLSBtn } from './StaffReportExportXLSBtn.js';
import { FiltersContainer } from '../PaySuppliersPage/PaySuppliersComponents.js';
import { FlexSpaceBetween } from '../../modules/forms/assignSupplierForm/styles.js';
import { SearchPageHeaderContainer } from '../../components/PageWithSearchHeader.js';
import { useSearchFilters } from '../../hooks/useSearchFilters.js';
import {
  getStaffReportStartDateByPreset,
  getStaffReportEndDateByPreset,
  StaffReportDatePresets,
} from './dateRangeUtils.js';

const HeaderLinksWrapperS = styled(HeaderLinksWrapper)`
  margin-right: 25px;
`;

const datePresetOptions = [
  { value: StaffReportDatePresets.TODAY, label: 'Today' },
  { value: StaffReportDatePresets.THIS_WEEK, label: 'This Week' },
  { value: StaffReportDatePresets.THIS_MONTH, label: 'This Month' },
  { value: StaffReportDatePresets.YEAR_TO_DATE, label: 'Year To Date' },
];

// staffReportFilters :: StaffReportFilters
const staffReportFilters = {
  userGroupIds: 'userGroupIds',
  datePreset: 'datePreset',
  startDate: 'startDate',
  usersIds: 'usersIds',
  endDate: 'endDate',
};

const getStaffReportHeaderConfig = (defaultUserGroupIds) => [
  { name: staffReportFilters.userGroupIds, defaultValue: defaultUserGroupIds },
  { name: staffReportFilters.usersIds },
  {
    name: staffReportFilters.datePreset,
    defaultValue: StaffReportDatePresets.TODAY,
    subscribers: [
      [staffReportFilters.startDate, getStaffReportStartDateByPreset],
      [staffReportFilters.endDate, getStaffReportEndDateByPreset],
    ],
  },
  {
    name: staffReportFilters.startDate,
    defaultValue: getStaffReportStartDateByPreset(StaffReportDatePresets.TODAY),
  },
  {
    name: staffReportFilters.endDate,
    defaultValue: getStaffReportEndDateByPreset(StaffReportDatePresets.TODAY),
  },
];

// constructAndAdjustDate :: (Date -> Date, String) -> Object -> Object
const constructAndAdjustDate = (adjustDateFn, prop) =>
  R.over(
    R.lensProp(prop),
    R.when(R.is(String), R.compose(adjustDateFn, R.constructN(1, Date))),
  );

// generateStaffReportQuery :: StaffReportFilters -> StaffReportFilters
const generateStaffReportQuery = R.compose(
  R.omit([staffReportFilters.datePreset]),
  constructAndAdjustDate(
    R.compose(startOfDay, ensureIsDate),
    staffReportFilters.startDate,
  ),
  constructAndAdjustDate(
    R.compose(endOfDay, ensureIsDate),
    staffReportFilters.endDate,
  ),
  R.when(propEqLegacy('userGroupIds', ALL), R.dissoc('userGroupIds')),
  R.when(propEqLegacy('usersIds', ALL), R.dissoc('usersIds')),
  R.defaultTo({}),
);

// prepareUserGroupsIds :: [ID] -> [ID]
const prepareUserGroupsIds = R.compose(
  R.reject(R.either(isNilOrEmpty, R.equals(ALL))),
  R.unless(R.is(Array), R.of(Array)),
);

export function StaffReportHeader({
  query,
  loading,
  setQuery,
  staffReport,
  defaultUserGroupIds,
}) {
  const { searchFilters, handlers, onReset } = useSearchFilters(
    getStaffReportHeaderConfig(defaultUserGroupIds),
  );

  const onSearch = () => {
    setQuery(generateStaffReportQuery(searchFilters));
  };

  const onResetSearch = () => {
    onReset();
    setQuery(null);
  };

  const presetSelectProps = {
    width: '100%',
    required: true,
    name: 'datePreset',
    options: datePresetOptions,
    onChange: handlers.datePreset,
    value: searchFilters.datePreset,
  };

  const userGroupsMultiSelectProps = {
    width: '100%',
    maxHeight: '55px',
    name: 'userGroupIds',
    onChange: handlers.userGroupIds,
    value: searchFilters.userGroupIds,
  };

  const userGroupsIds = useMemo(
    () => prepareUserGroupsIds(searchFilters.userGroupIds),
    [searchFilters.userGroupIds],
  );

  return (
    <SearchPageHeaderContainer title="Staff Activity Report">
      <FiltersContainer>
        <FlexSpaceBetween>
          <SearchHeaderColumn
            title="Preset"
            titleWidth="100px"
            filterWidth="260px"
          >
            <Select {...presetSelectProps} />
          </SearchHeaderColumn>
          <SearchHeaderColumn
            title="Date From"
            titleWidth="100px"
            filterWidth="180px"
          >
            <DatePicker
              value={searchFilters.startDate}
              onChange={handlers.startDate}
              width="180px"
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn
            title="Date To"
            titleWidth="100px"
            filterWidth="180px"
          >
            <DatePicker
              value={searchFilters.endDate}
              onChange={handlers.endDate}
              width="180px"
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn titleWidth="0px" filterWidth="130px" />
        </FlexSpaceBetween>
      </FiltersContainer>
      <FiltersContainer>
        <FlexSpaceBetween>
          <SearchHeaderColumn
            title="User Groups"
            titleWidth="100px"
            filterWidth="260px"
          >
            <UserGroupMultiSelect {...userGroupsMultiSelectProps} />
          </SearchHeaderColumn>
          <SearchHeaderColumn
            title="Assigned CSR"
            titleWidth="100px"
            filterWidth="180px"
          >
            <UsersMultiSelect
              isMulti
              width="100%"
              name="usersIds"
              maxHeight="55px"
              onChange={handlers.usersIds}
              userGroupsIds={userGroupsIds}
              value={searchFilters.usersIds}
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn filterWidth="80px" />
          <SearchHeaderColumn titleWidth="0px" filterWidth="330px">
            <FlexCenterAlign>
              <HeaderLinksWrapperS>
                <StaffReportPrintBtn {...{ staffReport, query }} />
                <StaffReportExportXLSBtn staffReport={staffReport} />
              </HeaderLinksWrapperS>
              <SearchHeaderButton
                size="small"
                styleType="basicPrimary"
                onClick={onResetSearch}
              >
                Reset
              </SearchHeaderButton>
              <SearchHeaderButton
                size="small"
                onClick={onSearch}
                loader={loading}
              >
                Search
              </SearchHeaderButton>
            </FlexCenterAlign>
          </SearchHeaderColumn>
        </FlexSpaceBetween>
      </FiltersContainer>
    </SearchPageHeaderContainer>
  );
}

StaffReportHeader.propTypes = {
  loading: bool,
  setQuery: func.isRequired,
  defaultUserGroupIds: arrayOf(string),
  query: shape(staffReportQueryPropTypes),
  staffReport: arrayOf(shape(staffReportPropTypes)).isRequired,
};
