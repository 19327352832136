import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import { Icon } from '@poly/admin-book';
import { number, shape, string } from 'prop-types';
import { stringifyQuery } from '@poly/client-routing';
import { assocBy } from '@poly/utils';

import { DashboardDelimiter, DashboardSection } from '../components.js';
import { getDashboardCounts } from '../../components/UserDashboard.js';
import { openSupplierInvoicesItemsConfig } from '../../components/OpenSupplierInvoicesTotalComponent.js';
import { routesNames } from '../../../../routes/constants.js';
import { StatusBar, Title } from '../Charts/StatusBar.js';

const SupplierInvoicesContainer = styled(DashboardSection)`
  display: flex;
  min-height: 182px;
  gap: 15px;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: calc(50% - 10px);
`;

const Circle = styled.div`
  background-color: #436cbe;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const InvoicesTotal = styled.p`
  font-size: 32px;
  padding: 0;
  margin: 20px 0 0 0;
`;

const SubTitle = styled(Title)`
  font-size: 14px;
  color: #667085;
`;

// getTotalOwnSupplierInvoices :: SupplierInvoicesCounts -> Number
const getTotalOwnSupplierInvoices = R.compose(
  R.sum,
  R.juxt([
    R.propOr(0, 'received'),
    R.propOr(0, 'requested'),
    R.propOr(0, 'manualFollowUp'),
  ]),
);

// prepareOpenSupplierInvBarConfig :: (String, Number, Counts) -> BarConfig
const prepareOpenSupplierInvBarConfig = (managerId, total, counts) =>
  R.map(
    R.compose(
      assocBy(
        'value',
        R.compose(R.propOr(0, R.__, counts), R.prop('fieldName')),
      ),
      assocBy(
        'href',
        R.compose(
          (invoiceStatus) =>
            `${routesNames.OPEN_SUPPLIER_INVOICE}?${stringifyQuery({
              managerId,
              invoiceStatus,
            })}`,
          R.prop('invoiceStatus'),
        ),
      ),
      R.assoc('total', total),
    ),
  )(openSupplierInvoicesItemsConfig);

export function SupplierInvoicesChart({ dashboardData, userId }) {
  const { ownSupplierInvoicesCounts } = getDashboardCounts(dashboardData);

  const total = getTotalOwnSupplierInvoices(ownSupplierInvoicesCounts);

  const barConfig = prepareOpenSupplierInvBarConfig(
    userId,
    total,
    ownSupplierInvoicesCounts,
  );

  return (
    <SupplierInvoicesContainer>
      <ColumnContainer>
        <TitleContainer>
          <Circle>
            <Icon name="reading" />
          </Circle>
          <Title>Open Supplier Invoices</Title>
        </TitleContainer>
        <InvoicesTotal>{total}</InvoicesTotal>
        <SubTitle>My Tasks</SubTitle>
      </ColumnContainer>
      <DashboardDelimiter />
      <ColumnContainer>
        {barConfig.map((config) => (
          <StatusBar {...config} key={config.label} />
        ))}
      </ColumnContainer>
    </SupplierInvoicesContainer>
  );
}

SupplierInvoicesChart.propTypes = {
  dashboardData: shape({
    dashboard: shape({
      ownSupplierInvoicesCounts: shape({
        received: number,
        requested: number,
        manualFollowUp: number,
      }),
    }),
  }),
  userId: string,
};
