import React from 'react';
import styled from 'styled-components';
import { Icon } from '@poly/admin-book';

const NoDataToReportWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  line-height: 40px;
  color: #61646c;
`;

export function NoDataToReport() {
  return (
    <NoDataToReportWrapperS>
      <Icon name="noReport" size={120} />
      No data to report
    </NoDataToReportWrapperS>
  );
}
