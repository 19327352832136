import * as R from 'ramda';
import { useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { MAX_ITEMS, SERVICE_TYPES_BY_SEARCH } from '@poly/admin-ui';
import { ServiceTypesContext } from '@poly/admin-ui/src/modules/tables/ServiceTypesTable/ServiceTypesTable.js';
import { serviceTypesByStatusQuery } from '@poly/admin-ui/src/searchQueries/serviceTypes.js';

export const useServiceTypesLazyQuery = (status) => {
  const searchText = useSelector(R.prop('searchText'));
  const { sort, supplierSource } = useContext(ServiceTypesContext);
  const [loading, setLoading] = useState(false);

  const searchInput = useMemo(
    () => ({
      searchTerm: searchText,
      query: serviceTypesByStatusQuery(status, supplierSource),
      size: MAX_ITEMS,
      sort,
    }),
    [searchText, status, sort],
  );

  const [runServiceTypesQuery] = useLazyQuery(SERVICE_TYPES_BY_SEARCH);

  return {
    loading,
    runServiceTypesQuery: async () => {
      setLoading(true);
      const { data } = await runServiceTypesQuery({
        variables: { searchInput },
      });
      setLoading(false);
      return R.pathOr([], ['searchServiceTypes', 'hits'], data);
    },
  };
};
