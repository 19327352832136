import * as R from 'ramda';
import { NOTHING_UI_STRING } from '@poly/constants';
import { applyProp } from '@poly/utils';
import {
  formatDateOrNothingUI,
  commonSortQuery,
  keywordSortQuery,
} from '@poly/client-utils';

import { commonSortWithId } from '../../modules/tables/columns/projects.js';
import { ProjectLink } from '../../sidebars/ProjectSidebar/useOpenProjectSidebar.js';

// getConfirmSatisfactionTableConfig :: Boolean -> TableConfig
export const getConfirmSatisfactionTableConfig = (isPrintPDF) => [
  [
    'Project #',
    R.compose(ProjectLink, R.mergeRight({ isPrintPDF })),
    keywordSortQuery(['projectId']),
  ],
  [
    'Project description',
    R.propOr(NOTHING_UI_STRING, 'description'),
    keywordSortQuery(['description']),
  ],
  [
    'Requestor',
    R.pathOr(NOTHING_UI_STRING, ['contact', 'profile', 'fullName']),
    keywordSortQuery(['contact', 'profile', 'fullName']),
  ],
  [
    'Date completed',
    applyProp(formatDateOrNothingUI)('workCompletionDate'),
    commonSortWithId(commonSortQuery(['workCompletionDate'])),
  ],
];
