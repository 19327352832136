import React from 'react';
import { TableSearchInput } from '@poly/admin-ui';
import {
  PageHeaderContainer,
  HeaderLinksWrapper,
  MainHeader,
} from '@poly/admin-book';

import { ReassignUserTable } from './ReassignUserTable.js';

export function ReassignUserPage() {
  return (
    <>
      <PageHeaderContainer>
        <MainHeader>Reassign User</MainHeader>
        <HeaderLinksWrapper>
          <TableSearchInput />
        </HeaderLinksWrapper>
      </PageHeaderContainer>
      <ReassignUserTable />
    </>
  );
}
