import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import { defaultTheme } from '@poly/admin-book';
import { arrayOf, number, shape, string } from 'prop-types';
import { useNavigate } from '@poly/client-routing';

import { getProgressBarWidth } from './StatusBar.js';

const { primaryLight } = defaultTheme.colors;

const ChartWrapper = styled.div`
  height: 100%;
  display: flex;
  gap: 20px;
  width: 100%;
`;

const CustomBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 10px;
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'auto')};
`;

const CustomBarWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #fbfbfb;
  background-image: linear-gradient(
    -45deg,
    transparent 48%,
    #f5f5f5 48%,
    #f5f5f5 52%,
    transparent 52%
  );
  background-size: 20px 20px;
  border-radius: 5px;
  width: 100%;
  height: 100%;
`;

const CustomBarS = styled.div`
  border-radius: 5px;
  background-color: ${R.propOr(primaryLight, 'barColor')};
  height: ${R.propOr('0', 'height')};
  width: 100%;
  transition: height 1s ease;
`;

const BarLabel = styled.p`
  margin: 0;
  padding: 0;
  color: #767676;
  font-size: 10px;
  font-weight: 400;
`;

const BarValue = styled(BarLabel)`
  color: #313131;
  font-size: 14px;
`;

function CustomBar({ value, label, maxValue, barColor, href }) {
  const navigate = useNavigate();

  const [height, setHeight] = useState('0');

  useEffect(() => {
    setHeight(getProgressBarWidth(maxValue, value));
  }, [value, maxValue]);

  const handleNavigate = () => {
    if (href) {
      navigate(href);
    }
  };

  return (
    <CustomBarContainer pointer={href} onClick={handleNavigate}>
      <CustomBarWrapper>
        <CustomBarS barColor={barColor} height={height} />
      </CustomBarWrapper>
      <BarValue>{value}</BarValue>
      <BarLabel>{label}</BarLabel>
    </CustomBarContainer>
  );
}

CustomBar.propTypes = {
  value: number.isRequired,
  label: string.isRequired,
  maxValue: number,
  href: string.isRequired,
  barColor: string,
};

export function CustomBarChart({ maxValue, configs }) {
  return (
    <ChartWrapper>
      {configs.map((config) => (
        <CustomBar key={config?.label} maxValue={maxValue} {...config} />
      ))}
    </ChartWrapper>
  );
}

CustomBarChart.propTypes = {
  maxValue: number.isRequired,
  configs: arrayOf(
    shape({
      value: number.isRequired,
      label: string.isRequired,
      href: string,
    }),
  ),
};
