import * as R from 'ramda';
import { gql } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import { isNilOrEmpty } from '@poly/utils';
import { useReactiveQuery } from '@poly/client-utils';
import { MAX_ITEMS, entities } from '@poly/admin-ui';
import {
  ELASTIC_SCORE_FIELD,
  DESC_SORT_ORDER,
  AssetStatuses,
} from '@poly/constants';

import {
  PROJECT_ASSETS_SUB,
  RECURRING_PROJECT_ASSETS_SUB,
} from '../commonTabs/useSearchAssetsTabQuery.js';
import { ALL } from '../../../modules/core/constants/general.js';
import { ASSETS_BY_SEARCH_SUB } from '../../../components/AssetSelect.js';

const subscriptionQueryMap = {
  [entities.RECURRING_PROJECT]: RECURRING_PROJECT_ASSETS_SUB,
  [entities.PROJECT]: PROJECT_ASSETS_SUB,
};

export const AllAssetsOption = {
  value: ALL,
  label: 'All',
  isAssetOption: true,
};

const SEARCH_ASSETS_LIST = gql`
  query SEARCH_ASSETS_LIST($input: CollectionSearchParams!) {
    searchAssets(input: $input) {
      hits {
        _id
        type {
          _id
          name
        }
        displayName
        qrCodeId
        manufacturerDoc {
          _id
          name
        }
      }
      total
    }
  }
`;

// getIdsByOptions :: [SelectOption] -> [ID]
const getIdsByOptions = R.compose(R.map(R.prop('value')), R.defaultTo([]));

// getAssetsByQueryWithoutAttached :: ([Option], [ID]) -> SearchAssetsResult -> [Asset]
const getAssetsByQueryWithoutAttached = (assetTypesValue, attachedAssets) =>
  R.compose(
    R.unless(
      R.compose(R.isEmpty, R.always(assetTypesValue)),
      R.filter(
        R.pathSatisfies(R.includes(R.__, getIdsByOptions(assetTypesValue)), [
          'type',
          '_id',
        ]),
      ),
    ),
    R.reject(R.propSatisfies(R.includes(R.__, attachedAssets), '_id')),
    R.pathOr([], ['searchAssets', 'hits']),
  );

// prepareAssetOption :: Asset -> Option
const prepareAssetOption = R.applySpec({
  value: R.prop('_id'),
  label: R.prop('displayName'),
  isAssetOption: R.T,
  assetType: R.path(['type', 'name']),
});

// prepareAssetsOptions :: ([Option], [ID]) -> SearchAssetsResult -> [Option]
const prepareAssetsOptions = (assetTypesValue, attachedAssets) =>
  R.compose(
    R.unless(R.isEmpty, R.prepend(AllAssetsOption)),
    R.map(prepareAssetOption),
    getAssetsByQueryWithoutAttached(assetTypesValue, attachedAssets),
  );

// getAssetQueryByProperties :: FormData -> ElasticQuery
const getAssetQueryByProperties = R.compose(
  R.unless(R.isEmpty, R.compose(R.objOf('terms'), R.objOf('propertyId'))),
  R.reject(isNilOrEmpty),
  R.unnest,
  R.juxt([R.prop('propertyId'), R.propOr([], 'subPropertiesIds')]),
);

// getAssetQueryByType :: FormData -> ElasticQuery
const getAssetQueryByType = R.compose(
  R.ifElse(
    R.propEq(1, 'length'),
    R.always(null),
    R.compose(R.objOf('bool'), R.objOf('should')),
  ),
  R.reject(isNilOrEmpty),
  R.juxt([
    R.compose(
      R.unless(isNilOrEmpty, R.compose(R.objOf('terms'), R.objOf('type._id'))),
      R.prop('types'),
    ),
    R.always({ bool: { must_not: { exists: { field: 'type' } } } }),
  ]),
);

// prepareSearchAssetsQuery :: FormData -> ElasticQuery
const prepareSearchAssetsQuery = R.compose(
  R.objOf('bool'),
  R.objOf('must'),
  R.reject(isNilOrEmpty),
  R.juxt([
    R.always({ match: { status: AssetStatuses.ACTIVE } }),
    getAssetQueryByProperties,
    getAssetQueryByType,
  ]),
  R.defaultTo({}),
);

// prepareAssetTypesOptions :: [ID] -> SearchAssetsResult -> [Options]
const prepareAssetTypesOptions = (attachedAssets) =>
  R.compose(
    R.map(
      R.applySpec({
        value: R.prop('_id'),
        label: R.prop('name'),
        isAssetOption: R.F,
      }),
    ),
    R.uniqBy(R.prop('_id')),
    R.map(R.prop('type')),
    R.reject(R.propSatisfies(R.includes(R.__, attachedAssets), '_id')),
    R.pathOr([], ['searchAssets', 'hits']),
  );

export const useMultiAssetsSelectQuery = ({
  entity,
  formData,
  disabled,
  searchTerm,
  assetTypesValue,
}) => {
  const [currentAttachedAssets, setCurrentAttachedAssets] = useState([]);
  const { _id, name } = entity;
  const { propertyId, attachedAssets } = formData;

  useEffect(() => {
    setCurrentAttachedAssets(attachedAssets);
  }, []);

  const query = useMemo(() => prepareSearchAssetsQuery(formData), [formData]);

  const queryOptions = {
    variables: {
      input: {
        query,
        searchTerm,
        size: MAX_ITEMS,
        sort: [{ createdAt: DESC_SORT_ORDER }, ELASTIC_SCORE_FIELD],
      },
    },
    skip: !propertyId || !!disabled,
  };

  const projectSubQuery = subscriptionQueryMap[name] || PROJECT_ASSETS_SUB;

  const projectSubOptions = {
    variables: { input: { query: { match: { _id } } } },
    skip: !_id,
  };

  const { data, loading } = useReactiveQuery(
    SEARCH_ASSETS_LIST,
    [ASSETS_BY_SEARCH_SUB, projectSubQuery],
    {
      queryOptions,
      subscriptionOptions: [queryOptions, projectSubOptions],
    },
  );

  const options = useMemo(
    () => prepareAssetsOptions(assetTypesValue, currentAttachedAssets)(data),
    [data, assetTypesValue, currentAttachedAssets],
  );

  const assetTypeOptions = useMemo(
    () => prepareAssetTypesOptions(currentAttachedAssets)(data),
    [data, currentAttachedAssets],
  );

  return {
    loading,
    options,
    assetTypeOptions,
  };
};
