import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { shape, arrayOf, bool } from 'prop-types';
import { useMapConfigToTableProps } from '@poly/admin-ui';
import {
  getThemeColor,
  BodyRow,
  Loader,
  Table,
  Cell,
  Row,
} from '@poly/admin-book';

import { clientSurveysSummaryReportEntryPropTypes } from './prop-types.js';

const ClientSurveysSummaryReportTableS = styled(Table)`
  tr {
    padding-left: 35px;
  }

  td:nth-child(1),
  th:nth-child(1) {
    padding-left: 25px;
    width: 55%;
  }

  td:nth-child(2),
  th:nth-child(2) {
    width: 45%;
    padding-left: 113px;
  }
`;

const ClientCellS = styled(Cell)`
  padding-left: 0 !important;
  width: 100% !important;
  font-size: 14px;
`;

const SurveyNumberCellS = styled(Cell)`
  padding-left: 150px !important;
`;

const SurveyScoreCellS = styled(Cell)`
  padding-left: 0 !important;
`;

const CustomRowS = styled(Row)`
  border-bottom: 1px solid ${getThemeColor(['midLight'])};
`;

const TotalRowS = styled(CustomRowS)`
  &:not(:last-child) {
    border-bottom: none;
  }
`;

const BodyRowS = styled(BodyRow)`
  border: none;
`;

const clientSurveysSummaryReportTableConfig = [
  ['Question', R.prop('surveyQuestion')],
  ['Average Score', R.prop('surveyAverageScore')],
];

function ClientSurveysSummaryReportTableRow({ row, ...props }) {
  const {
    surveys,
    surveysNumber,
    surveysAverageScore,
    client: { _id, name },
  } = row;

  return (
    <>
      <CustomRowS>
        <ClientCellS>{name}</ClientCellS>
      </CustomRowS>
      {surveys.map((surveyRow, index) => {
        const surveyRowId = `${_id}_${index}`;
        return (
          <BodyRowS
            {...props}
            key={surveyRowId}
            row={{ ...surveyRow, _id: surveyRowId }}
          />
        );
      })}
      <TotalRowS>
        <SurveyNumberCellS>
          Number of Surveys: {surveysNumber}
        </SurveyNumberCellS>
        <SurveyScoreCellS>
          Average Total Score: {surveysAverageScore}
        </SurveyScoreCellS>
      </TotalRowS>
    </>
  );
}

ClientSurveysSummaryReportTableRow.propTypes = {
  row: shape(clientSurveysSummaryReportEntryPropTypes).isRequired,
};

export function ClientSurveysSummaryReportTable({ loading, reportEntries }) {
  const tableProps = useMapConfigToTableProps(
    R.identity,
    clientSurveysSummaryReportTableConfig,
    reportEntries,
  );

  if (loading) {
    return <Loader />;
  }

  if (R.isEmpty(reportEntries)) {
    return null;
  }

  return (
    <ClientSurveysSummaryReportTableS
      {...tableProps}
      RowComponent={ClientSurveysSummaryReportTableRow}
    />
  );
}

ClientSurveysSummaryReportTable.propTypes = {
  loading: bool,
  reportEntries: arrayOf(shape(clientSurveysSummaryReportEntryPropTypes))
    .isRequired,
};
