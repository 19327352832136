import React from 'react';
import * as R from 'ramda';
import { string, arrayOf, instanceOf } from 'prop-types';
import { LinkButton } from '@poly/admin-book';
import {
  propEqLegacy,
  getPropertiesDeepByHierarchyNodeR,
  prepareExcelWorkbookByConfig,
} from '@poly/utils';
import { getSpendReportsXlsConfig } from '@poly/client-utils';
import { EXPORT_XLS_CAPTION } from '@poly/constants';
import { downloadExcelWorkbook } from '@poly/client-utils/src/excel-export/download.js';

import { spendReportHierarchyBranchPropType } from './spendReportsPropTypes.js';
import { getMarkupSpendReportXlsConfigBase } from './getMarkupSpendReportXlsConfig.js';

// rejectZeroTotalReports :: [PropertyHierarchySpendReportResult] -> [PropertySpendReportResult]
export const rejectZeroTotalReports = R.compose(
  R.reject(propEqLegacy('total', 0)),
  R.flatten,
  R.map(getPropertiesDeepByHierarchyNodeR([])),
);

export function SpendReportsExportXlsBtn({
  reports,
  clientName,
  startDate,
  endDate,
}) {
  const onClick = () => {
    const noBullshitReports = rejectZeroTotalReports(reports);
    const config = getSpendReportsXlsConfig(
      noBullshitReports,
      clientName,
      startDate,
      endDate,
      true,
      true,
    );
    const markupReportConfig = getMarkupSpendReportXlsConfigBase({
      report: noBullshitReports,
    });

    const workbook = prepareExcelWorkbookByConfig([config, markupReportConfig]);

    return downloadExcelWorkbook('spend-report.xlsx', workbook);
  };

  return <LinkButton onClick={onClick}>{EXPORT_XLS_CAPTION}</LinkButton>;
}

SpendReportsExportXlsBtn.propTypes = {
  reports: arrayOf(spendReportHierarchyBranchPropType),
  clientName: string,
  startDate: instanceOf(Date),
  endDate: instanceOf(Date),
};
