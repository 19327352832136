import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { defaultTheme, getThemeColor, Icon } from '@poly/admin-book';
import { bool, number, oneOfType, shape, string } from 'prop-types';

import { getMasterProjectsExpiredItems } from '../totalItems.js';
import { routesNames } from '../../../routes/constants.js';
import { TotalItemContainer } from '../styles.js';
import {
  getOpenSupplierInvoicesItemCompProps,
  openSupplierInvoicesItemsConfig,
} from '../components/OpenSupplierInvoicesTotalComponent.js';

const {
  colors: { primaryMid },
} = defaultTheme;

const mastersProjectsDashboardConfig = getMasterProjectsExpiredItems(
  routesNames.MASTER_PROJECTS_DIRECTORY,
);

const tasksDashboardConfig = [
  {
    label: 'W.O. Verifications',
    href: routesNames.HOME,
    iconName: 'taskList',
  },
  {
    label: 'Open Supplier Invoices',
    href: routesNames.OPEN_SUPPLIER_INVOICE,
    iconName: 'itemsList',
    skipValue: true,
  },
];

const FlexContainer = styled.div`
  display: flex;
`;

const FlexColumn = styled(FlexContainer)`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const SidebarBlock = styled(FlexColumn)`
  gap: 25px;
`;

const TotalItemContainerS = styled(TotalItemContainer)`
  justify-content: space-between;
  margin: 0;
  padding: 0;
  border: none;
`;

const BlockTitle = styled.p`
  font-size: 16px;
  color: ${getThemeColor(['darkest'])};
  margin: 0;
  padding: 0;
`;

const ItemText = styled(BlockTitle)`
  font-size: 12px;
  line-height: 16px;
`;

const ItemLabelContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const ItemValue = styled(ItemText)`
  color: ${getThemeColor(['primaryMid'])};
`;

const ItemValueContainer = styled.div`
  padding: 3px 6px;
  font-size: 12px;
  border-radius: 6px;
  background-color: #e3e6ed;
`;

const BorderItem = styled.div`
  width: 11px;
  margin-right: 10px;
  border-bottom: 1px solid #e9eaec;
  height: 16px;
`;

const OSIContainer = styled(SidebarBlock)`
  margin-left: 5px;
  border-left: 1px solid #e9eaec;
  border-bottom-left-radius: 10px;
  a:last-child div > div {
    border-bottom-left-radius: 10px;
    margin-bottom: -8px;
  }
`;

function TotalItem({
  iconName,
  label,
  fieldName,
  href,
  border,
  counts,
  skipValue,
}) {
  const value = R.propOr(0, fieldName, counts);

  return (
    <TotalItemContainerS href={href}>
      <FlexContainer>
        {border && <BorderItem />}
        <ItemLabelContainer>
          {iconName && <Icon name={iconName} size={16} color={primaryMid} />}
          <ItemText>{label}</ItemText>
        </ItemLabelContainer>
      </FlexContainer>
      {!skipValue && (
        <ItemValueContainer>
          <ItemValue>{value}</ItemValue>
        </ItemValueContainer>
      )}
    </TotalItemContainerS>
  );
}

const globalMastersCountsProps = shape({
  expired: number,
  expiring30Days: number,
  expiring60Days: number,
  expiring90Days: number,
});

const supplierInvoicesCountsProps = shape({
  requested: number,
  received: number,
  rejected: number,
  manualFollowUp: number,
});

TotalItem.propTypes = {
  iconName: string,
  label: string,
  fieldName: string,
  href: string,
  counts: oneOfType([globalMastersCountsProps, supplierInvoicesCountsProps]),
  border: bool,
  skipValue: bool,
};

export function DashboardSidebar({ dashboardData }) {
  const { dashboard: { globalMastersCounts, supplierInvoicesCounts } = {} } =
    dashboardData;

  return (
    <FlexColumn>
      <SidebarBlock>
        <BlockTitle>Masters</BlockTitle>
        {mastersProjectsDashboardConfig.map((config) => (
          <TotalItem
            key={config.label}
            counts={globalMastersCounts}
            {...config}
          />
        ))}
      </SidebarBlock>
      <SidebarBlock>
        <BlockTitle>Tasks</BlockTitle>
        {tasksDashboardConfig.map((config) => (
          <TotalItem key={config.label} {...config} />
        ))}
        <OSIContainer>
          {openSupplierInvoicesItemsConfig.map(
            ({ invoiceStatus, ...config }) => (
              <TotalItem
                key={config.label}
                {...config}
                counts={supplierInvoicesCounts}
                {...getOpenSupplierInvoicesItemCompProps({ invoiceStatus })}
                border
              />
            ),
          )}
        </OSIContainer>
      </SidebarBlock>
    </FlexColumn>
  );
}

DashboardSidebar.propTypes = {
  dashboardData: shape({
    dashboard: shape({
      globalMastersCounts: globalMastersCountsProps,
      supplierInvoicesCounts: supplierInvoicesCountsProps,
    }),
  }),
};
