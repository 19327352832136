import React from 'react';
import * as R from 'ramda';
import { string } from 'prop-types';
import { gql } from '@apollo/client';
import styled from 'styled-components';
import { useMapConfigToTableProps } from '@poly/admin-ui/src/hooks/useMapConfigToTableProps.js';
import { useSidebarTableFormContext } from '@poly/admin-ui/src/sidebar/SidebarWrapperForTableForm.js';
import { useReactiveQuery } from '@poly/client-utils/src/hooks/useReactiveQuery.js';
import { IconButton } from '@poly/admin-book/src/IconButton/index.js';
import { Loader } from '@poly/admin-book/src/Loader/index.js';
import { Table } from '@poly/admin-book/src/Table/Table.js';
import { CommonTableFormWrapper } from '@poly/admin-ui/src/sidebar/tabs/tasksTab/useSidebarTasksTableHandlers.js';
import { keywordSortQuery } from '@poly/client-utils/src/sorting.js';
import { ASC_SORT_ORDER } from '@poly/constants/src/misc.js';
import { MAX_ITEMS } from '@poly/admin-ui/src/constants/general.js';

import { UpdateClientTagForm } from './ClientTagForm.js';
import { DisabledIconButton } from '../../../../pages/AssetManufactureModels/DeleteAssetModelInUse.js';

import { DeleteTagButton } from './DeleteTagButton.js';
import {
  ClientTagsProvider,
  useClientTagContext,
} from './ClientTagsProvider.js';

const SEARCH_CLIENT_TAGS_QUERY = gql`
  query SEARCH_CLIENT_TAGS_QUERY($searchInput: CollectionSearchParams) {
    searchTags(input: $searchInput) {
      hits {
        _id
        name
        isUseInProperties
      }
    }
  }
`;

const SEARCH_CLIENT_TAGS_SUBSCRIPTION = gql`
  subscription SEARCH_CLIENT_TAGS_SUBSCRIPTION(
    $searchInput: CollectionSearchParams
  ) {
    searchTagChanged(input: $searchInput) {
      id
      type
    }
  }
`;

const TagsTableS = styled(Table)`
  min-height: auto;
  th:nth-child(2),
  td:nth-child(2),
  th:nth-child(3),
  td:nth-child(3) {
    width: 40px;
  }
  margin-bottom: 25px;
`;

function EditTagButton({ _id, ...props }) {
  const { formSetter } = useSidebarTableFormContext();

  const { isTagProcess } = useClientTagContext();

  const onCancel = () => formSetter(null);

  const handleClick = () =>
    formSetter({
      elementId: _id,
      Content: (
        <CommonTableFormWrapper {...{ onCancel }}>
          <UpdateClientTagForm
            handleCancelForm={onCancel}
            _id={_id}
            {...props}
          />
        </CommonTableFormWrapper>
      ),
    });

  if (isTagProcess(_id)) {
    return <DisabledIconButton name="edit" />;
  }

  return <IconButton size={15} name="edit" onClick={handleClick} />;
}

EditTagButton.propTypes = {
  _id: string.isRequired,
};

const tagsTableConfig = [
  ['Name', R.prop('name')],
  ['', EditTagButton],
  ['', DeleteTagButton],
];

const defaultSort = keywordSortQuery(['name'])(ASC_SORT_ORDER);

export function ClientTagsTable({ clientId }) {
  const queryOptions = {
    variables: {
      searchInput: {
        query: { term: { clientId } },
        sort: defaultSort,
        size: MAX_ITEMS,
      },
    },
    fetchPolicy: 'network-only',
  };

  const { data, loading } = useReactiveQuery(
    SEARCH_CLIENT_TAGS_QUERY,
    SEARCH_CLIENT_TAGS_SUBSCRIPTION,
    { queryOptions, subscriptionOptions: queryOptions },
  );

  const clientTags = R.pathOr([], ['searchTags', 'hits'], data);

  const tableProps = useMapConfigToTableProps(
    R.identity,
    tagsTableConfig,
    clientTags,
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <ClientTagsProvider>
      <TagsTableS {...tableProps} />
    </ClientTagsProvider>
  );
}

ClientTagsTable.propTypes = {
  clientId: string.isRequired,
};
