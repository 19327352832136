import { Icon } from '@poly/admin-book';
import { format } from 'date-fns';
import { bool, func } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const FlexWrapper = styled.div`
  display: flex;
  gap: 15px;
`;

const DateContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 6px 8px;
  height: 36px;
  width: 174px;
  border: 1px solid #ececed;
  border-radius: 10px;
`;

const DateText = styled.div`
  font-size: 12px;
  color: #667085;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  height: 36px;
  width: 80px;
  border: 1px solid #f0f1f1;
  border-radius: 10px;
  align-items: center;
  justify-content: space-around;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${({ isActive }) => (isActive ? '#F5F8FF' : '#FFF')};
  height: 100%;
  width: 100%;
  padding: 0 3px;
`;

const LeftIconWrapper = styled(IconWrapper)`
  border-radius: 10px 0 0 10px;
`;

const RightIconWrapper = styled(IconWrapper)`
  border-radius: 0 10px 10px 0;
`;

const Delimiter = styled.div`
  height: 20px;
  width: 1px;
  background-color: #f0f1f1;
`;

export function DashboardSwitcher({
  isChart,
  withoutDate,
  handleSwitchDashboard,
}) {
  const getIconColor = (isActive) => (isActive ? '#174198' : '#94969C');

  const date = format(new Date(), 'MMMM d, yyyy');

  return (
    <FlexWrapper>
      {isChart && !withoutDate && (
        <DateContainer>
          <Icon name="calendar2" size={16} />
          <DateText>{date}</DateText>
        </DateContainer>
      )}
      <ButtonsWrapper>
        <LeftIconWrapper isActive={!isChart} onClick={handleSwitchDashboard}>
          <Icon name="list" size={20} color={getIconColor(!isChart)} />
        </LeftIconWrapper>
        <Delimiter />
        <RightIconWrapper isActive={isChart} onClick={handleSwitchDashboard}>
          <Icon name="chart" size={20} color={getIconColor(isChart)} />
        </RightIconWrapper>
      </ButtonsWrapper>
    </FlexWrapper>
  );
}

DashboardSwitcher.propTypes = {
  withoutDate: bool,
  isChart: bool.isRequired,
  handleSwitchDashboard: func.isRequired,
};
