import { useMemo, useState } from 'react';
import * as R from 'ramda';
import { useLazyQuery } from '@apollo/client';
import { useTableSearch } from '@poly/admin-ui/src/redux/search-text.js';
import { MAX_ITEMS } from '@poly/admin-ui/src/constants/general.js';

import { CLIENTS_BY_SEARCH } from '../../modules/core/hooks/clients/queries.js';

export const useClientsLazyQuery = ({ sort, status }) => {
  const [loading, setLoading] = useState(false);

  const { searchText } = useTableSearch();

  const searchInput = useMemo(
    () => ({
      sort,
      searchTerm: searchText,
      size: MAX_ITEMS,
      query: { match: { status } },
    }),
    [searchText, status, sort],
  );

  const [queryHandler] = useLazyQuery(CLIENTS_BY_SEARCH);

  const runClientsQuery = async () => {
    setLoading(true);
    const { data } = await queryHandler({
      variables: { searchInput },
    });
    setLoading(false);
    return R.pathOr([], ['searchClients', 'hits'], data);
  };

  return {
    runClientsQuery,
    loading,
  };
};
