import React from 'react';
import styled from 'styled-components';

import { DashboardSection, DashboardSectionTitle } from '../components.js';
import { SupplierInvoicesChart } from './SupplierInvoicesChart.js';
import { MyTasksChart } from './MyTasksChart.js';

const MyTasksContainer = styled(DashboardSection)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 40%;
`;

export function MyTasksDashboard(props) {
  return (
    <MyTasksContainer>
      <DashboardSectionTitle title="My Tasks" iconName="tasksList" />
      <MyTasksChart {...props} />
      <SupplierInvoicesChart {...props} />
    </MyTasksContainer>
  );
}
