import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
// eslint-disable-next-line import/extensions, node/file-extension-in-import
import Chart from 'chart.js/auto';
import { arrayOf, shape, string } from 'prop-types';

Chart.defaults.font.family = 'TTNormsMedium';
Chart.defaults.font.size = 12;

export const ChartComponent = forwardRef(
  ({ type, data, options, plugins }, ref) => {
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);

    useEffect(() => {
      const ctx = chartRef.current.getContext('2d');

      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }

      chartInstanceRef.current = new Chart(ctx, {
        type,
        data,
        options,
        plugins,
      });

      return () => {
        if (chartInstanceRef.current) {
          chartInstanceRef.current.destroy();
        }
      };
    }, [type, data, options, plugins]);

    useImperativeHandle(ref, () => ({
      getChartInstance: () => chartInstanceRef.current,
      getChartElement: () => chartRef.current,
    }));

    return <canvas ref={chartRef} />;
  },
);

ChartComponent.propTypes = {
  type: string.isRequired,
  data: shape({}).isRequired,
  options: shape({}),
  plugins: arrayOf(shape({})),
};

ChartComponent.defaultValues = {
  plugins: [],
};
