import * as R from 'ramda';
import { format } from 'date-fns';
import { formatDateProp } from '@poly/client-utils';
import {
  forceTitleCase,
  ExcelExportCellType,
  createExcelExportCell,
} from '@poly/utils';

const getCurrentDate = () => format(new Date(), 'MM-dd-yyyy');

// getBulkEditProjectRowConfig :: Project -> [ExcelExportDataCell]
const getBulkEditProjectRowConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 8),
  ]),
  R.juxt([
    R.propOr('', 'projectId'),
    R.propOr('', 'description'),
    R.pathOr('', ['property', 'name']),
    R.pathOr('', ['client', 'name']),
    R.pathOr('', ['manager', 'fullName']),
    R.compose(forceTitleCase, R.propOr('', 'status')),
    formatDateProp('startDate'),
    formatDateProp('endDate'),
  ]),
);

export const getProjectsExportExcelConfig = (projects) => ({
  exportFileName: `bulk-edit-projects-${getCurrentDate()}.xlsx`,
  columnWidths: [15, 40, 40, 20, 20, 15, 15, 15],
  rows: [
    R.zipWith(
      createExcelExportCell,
      [...R.repeat(ExcelExportCellType.tableHeader, 8)],
      [
        'WO ID',
        'Description',
        'Property',
        'Client',
        'Assigned CSR',
        'Status',
        'Start Date',
        'End Date',
      ],
    ),
    ...R.map(getBulkEditProjectRowConfig, projects),
  ],
});
