import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { shape, instanceOf, func, object } from 'prop-types';
import { ifNotEmpty } from '@poly/client-utils';
import { WebsiteFormInput } from '@poly/admin-ui';
import { DatePicker } from '@poly/admin-book';
import { validateURL } from '@poly/utils/src/validators.js';
import { getEndDateByProjectWithClient } from '@poly/utils';

import { isNotFeeProjectType } from '../utils/sections.js';
import { halfWidth } from '../../../../../modules/forms/common.js';
import { CheckboxWithLabel } from '../components/CheckboxWithLabel.js';
import { RecurringScheduler } from '../../../../../components/RecurringScheduler/RecurringScheduler.js';

const DatePickerS = styled(DatePicker)`
  > div:first-child {
    width: 100%;
    > div:first-child {
      width: 100%;
    }
  }
`;

function StartDatePicker(props) {
  return <DatePickerS {...props} width="100%" leftMove="10px" />;
}

// isNotNeverEnding :: ProjectFormObject -> Boolean
const isNotNeverEnding = R.compose(R.not, R.prop('never'), R.defaultTo({}));

function EndDatePicker({ formData: { startDate }, ...restProps }) {
  return <DatePickerS {...restProps} disabledDays={{ before: startDate }} />;
}

EndDatePicker.propTypes = {
  formData: shape({
    startDate: instanceOf(Date),
  }),
};

function NeverEndsCheckbox({ onChange, formData, changeFieldValue, ...props }) {
  const onNeverEndsChange = (newValue) => {
    if (!newValue) {
      const endDate = getEndDateByProjectWithClient(formData);

      changeFieldValue('endDate', endDate);
    }

    onChange(newValue);
  };

  return <CheckboxWithLabel {...props} onChange={onNeverEndsChange} />;
}

NeverEndsCheckbox.propTypes = {
  onChange: func,
  changeFieldValue: func,
  // eslint-disable-next-line react/forbid-prop-types
  formData: object,
};

export const startDateRecurringFormFieldConfig = {
  label: 'Start Date',
  layout: { row: 1, width: 'calc(33% - 15px)' },
  order: 1,
  field: {
    name: 'startDate',
    Component: StartDatePicker,
  },
};
export const endDateRecurringFormFieldConfig = {
  renderIf: isNotNeverEnding,
  leaveValues: true,
  label: 'End Date',
  layout: { row: 1, width: 'calc(33% - 15px)' },
  order: 2,
  field: {
    name: 'endDate',
    withFormData: true,
    Component: EndDatePicker,
  },
};
export const isNeverRecurringFormFieldConfig = {
  layout: { row: 1, width: 'calc(34% - 10px)', padding: '28px 0' },
  order: 3,
  field: {
    name: 'never',
    // Component: (props) => <CheckboxWithLabel {...props} label="Never Ends" />,
    withFormData: true,
    withChangeFieldValue: true,
    Component: NeverEndsCheckbox,
    additionalProps: { label: 'Never Ends' },
  },
};
export const scheduleRecurringFormFieldConfig = {
  label: 'Schedule',
  layout: { row: 2, width: '100%' },
  order: 3,
  field: {
    name: 'schedule',
    withFormData: true,
    Component: RecurringScheduler,
  },
};
export const contractRecurringFormFieldConfig = {
  label: 'Contract',
  layout: { row: 3, width: halfWidth },
  order: 4,
  field: {
    name: 'contractLink',
    Component: WebsiteFormInput,
  },
  renderIf: isNotFeeProjectType,
  validators: [
    [ifNotEmpty(validateURL), 'URL should be in form http(s)://example.com'],
  ],
};
