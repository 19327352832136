import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { string, arrayOf, shape, number } from 'prop-types';
import { Icon, Text } from '@poly/admin-book';
import {
  insertQueryParamsIntoURL,
  applySpecWithFields,
  forceTitleCase,
} from '@poly/utils';
import {
  MasterSupplierStatus,
  SupplierStatuses,
  SupplierTypes,
} from '@poly/constants';

import {
  StatusBar,
  formatChartNumber,
} from '../HomePage/ChartDashboard/Charts/StatusBar.js';
import { routesNames } from '../../routes/constants.js';

const SuppliersChartWrapperS = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border: 1px solid #f0f1f1;
  border-radius: 10px;
  height: 217px;
`;

const SuppliersChartRowS = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
  gap: ${R.propOr('10px', 'gap')};
`;

const SuppliersChartSectionS = styled(SuppliersChartWrapperS)`
  display: flex;
  width: 50%;
  flex-direction: row;
  justify-content: space-between;
  height: 142px;
`;

const SuppliersChartContextBoxS = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  gap: ${R.propOr('8px', 'gap')};
`;

const SuppliersChartDividerS = styled.div`
  width: 1px;
  background-color: #f0f1f1;
  height: 100%;
`;

function CommonSuppliersChartComponent({ title, iconName, config }) {
  return (
    <SuppliersChartWrapperS>
      <SuppliersChartRowS>
        <Icon name={iconName} size={26} />
        <Text size="14px">{title}</Text>
      </SuppliersChartRowS>
      <SuppliersChartRowS gap="16px">
        {config.map(({ label, total, statusBarsConfig }) => (
          <SuppliersChartSectionS key={label}>
            <SuppliersChartContextBoxS>
              <Text size="32px">{formatChartNumber(total)}</Text>
              <Text size="14px" color={['dark']}>
                {label}
              </Text>
            </SuppliersChartContextBoxS>
            <SuppliersChartDividerS />
            <SuppliersChartContextBoxS gap="24px">
              {statusBarsConfig.map((barItem) => (
                <StatusBar {...barItem} key={barItem.label} />
              ))}
            </SuppliersChartContextBoxS>
          </SuppliersChartSectionS>
        ))}
      </SuppliersChartRowS>
    </SuppliersChartWrapperS>
  );
}

CommonSuppliersChartComponent.propTypes = {
  title: string.isRequired,
  iconName: string.isRequired,
  config: arrayOf(
    shape({
      label: string.isRequired,
      total: number.isRequired,
      statusBarsConfig: arrayOf(
        shape({
          href: string,
          color: string,
          total: number.isRequired,
          label: string.isRequired,
          value: number.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
};

// getStatusBarsConfigForStatuses :: (String, [String]) -> [StatusCount] -> Object
const getStatusBarsConfigForStatuses = (route, [activeStatus, blockedStatus]) =>
  R.compose(
    R.objOf('statusBarsConfig'),
    R.sortBy(R.prop('label')),
    R.juxt([
      R.compose(
        R.ifElse(
          R.isNil,
          R.always({
            value: 0,
            label: forceTitleCase(SupplierStatuses.BLOCKED),
          }),
          R.compose(
            R.mergeLeft({
              color: '#FF6363',
              label: forceTitleCase(SupplierStatuses.BLOCKED),
              href: insertQueryParamsIntoURL({ tab: blockedStatus }, route),
            }),
            R.objOf('value'),
            R.propOr(0, 'count'),
          ),
        ),
        R.find(R.propEq(blockedStatus, 'status')),
      ),
      R.compose(
        R.ifElse(
          R.isNil,
          R.always({
            value: 0,
            label: forceTitleCase(SupplierStatuses.ACTIVE),
          }),
          R.compose(
            R.mergeLeft({
              label: forceTitleCase(SupplierStatuses.ACTIVE),
              href: insertQueryParamsIntoURL({ tab: activeStatus }, route),
            }),
            R.objOf('value'),
            R.propOr(0, 'count'),
          ),
        ),
        R.find(R.propEq(activeStatus, 'status')),
      ),
    ]),
    R.defaultTo([]),
  );

// getChartDataBySupplierType :: (String, String, [String]) -> [StatusCount] -> Object
const getChartDataBySupplierType = (label, route, statuses) =>
  R.compose(
    applySpecWithFields({
      statusBarsConfig: R.converge(R.map, [
        R.compose(R.mergeLeft, R.pick(['total'])),
        R.prop('statusBarsConfig'),
      ]),
    }),
    R.converge(R.mergeLeft, [
      R.compose(
        R.mergeLeft({ label }),
        R.objOf('total'),
        R.reduce(R.add, 0),
        R.map(R.propOr(0, 'count')),
      ),
      getStatusBarsConfigForStatuses(route, statuses),
    ]),
  );

const subcontractorSupplierLabel = `${SupplierTypes.SUBCONTRACTOR}s`;

// prepareSuppliersChartData :: AccountIndexQueryResult -> Object
const prepareSuppliersChartData = R.juxt([
  R.compose(
    getChartDataBySupplierType(
      subcontractorSupplierLabel,
      routesNames.SUPPLIERS_SUBCONTRACTORS,
      R.values(SupplierStatuses),
    ),
    R.pathOr(
      [],
      ['adminAccountIndex', 'aacSuppliersSubcontracts', 'countByStatuses'],
    ),
  ),
  R.compose(
    getChartDataBySupplierType(
      SupplierTypes.ADMINISTRATIVE,
      routesNames.SUPPLIERS_ADMINISTRATIVE_AAC,
      R.values(SupplierStatuses),
    ),
    R.pathOr(
      [],
      ['adminAccountIndex', 'aacSuppliersAdministrative', 'countByStatuses'],
    ),
  ),
]);

// prepareMasterSuppliersChartData :: AccountIndexQueryResult -> Object
const prepareMasterSuppliersChartData = R.juxt([
  R.compose(
    getChartDataBySupplierType(
      subcontractorSupplierLabel,
      routesNames.MASTER_SUPPLIERS_SUBCONTRACTORS,
      R.values(MasterSupplierStatus),
    ),
    R.pathOr(
      [],
      ['adminAccountIndex', 'masterSuppliersSubcontracts', 'countByStatuses'],
    ),
  ),
  R.compose(
    getChartDataBySupplierType(
      SupplierTypes.ADMINISTRATIVE,
      routesNames.MASTER_SUPPLIERS_ADMINISTRATIVE,
      R.values(MasterSupplierStatus),
    ),
    R.pathOr(
      [],
      ['adminAccountIndex', 'masterSuppliersAdministrative', 'countByStatuses'],
    ),
  ),
]);

export function SuppliersChart(props) {
  const suppliersChartProps = {
    title: 'Suppliers',
    iconName: 'suppliers',
    config: prepareSuppliersChartData(props),
  };

  return <CommonSuppliersChartComponent {...suppliersChartProps} />;
}

export function MasterSuppliersChart(props) {
  const masterSuppliersChartProps = {
    title: 'Master Suppliers',
    iconName: 'master-suppliers',
    config: prepareMasterSuppliersChartData(props),
  };

  return <CommonSuppliersChartComponent {...masterSuppliersChartProps} />;
}
