import React from 'react';
import styled from 'styled-components';
import { startOfYear } from 'date-fns';
import { func, bool, arrayOf, shape } from 'prop-types';
import { DateRangePicker, HeaderLinksWrapper } from '@poly/admin-book';
import { alwaysNewDate, isNilOrEmpty } from '@poly/utils';
import { ClientSelect } from '@poly/admin-ui';

import {
  FlexColumn,
  SpaceBetween,
  FlexCenterAlign,
} from '../../components/FlexContainer.js';
import {
  SearchHeaderButton,
  SearchHeaderColumn,
} from '../../components/SearchHeaderColumn.js';
import { clientSurveysSummaryReportEntryPropTypes } from './prop-types.js';
import { SearchPageHeaderContainer } from '../../components/PageWithSearchHeader.js';
import { ClientSurveysSummaryReportPrintBtn } from './ClientSurveysSummaryReportPrintBtn.js';
import { ClientPropertiesSelect } from '../../modules/selects/PropertySelect/PropertySelect.js';
import { ClientSurveysSummaryReportExportXLSBtn } from './ClientSurveysSummaryReportExportXLSBtn.js';
import { useSearchFilters } from '../../hooks/useSearchFilters.js';

const HeaderWrapperS = styled(FlexColumn)`
  width: 100%;
`;

const FirstRowWrapperS = styled(FlexCenterAlign)`
  width: 100%;

  > div {
    margin-right: 35px;
  }
`;

const SecondRowWrapperS = styled(SpaceBetween)`
  width: 100%;
`;

const HeaderLinksWrapperS = styled(HeaderLinksWrapper)`
  margin-right: 25px;
`;

const defaultFilterProps = {
  titleWidth: '95px',
  filterWidth: '300px',
};

const clientSurveysSummaryReportFiltersConfig = [
  { name: 'clientId' },
  { name: 'propertyId' },
  {
    name: 'date',
    nestedFields: ['startDate', 'endDate'],
    defaultValue: {
      endDate: alwaysNewDate().toDateString(),
      startDate: startOfYear(alwaysNewDate()).toDateString(),
    },
  },
];

export function ClientSurveysSummaryReportHeader({
  loading,
  setFilters,
  reportEntries,
}) {
  const { searchFilters, handlers, onReset, updateFiltersValues } =
    useSearchFilters(clientSurveysSummaryReportFiltersConfig);

  const onClientChange = (clientId) => {
    updateFiltersValues({
      propertyId: undefined,
      clientId: !isNilOrEmpty(clientId) ? clientId : undefined,
    });
  };

  const onSearch = () => {
    setFilters(searchFilters);
  };

  const onResetSearch = () => {
    onReset();
    setFilters({});
  };

  return (
    <SearchPageHeaderContainer title="Client Surveys Summary Report">
      <HeaderWrapperS>
        <FirstRowWrapperS>
          <SearchHeaderColumn title="Client" {...defaultFilterProps}>
            <ClientSelect
              width="100%"
              withoutSkip
              isClearable
              includeAllOption={false}
              onChange={onClientChange}
              value={searchFilters.clientId}
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn
            title="Date"
            {...defaultFilterProps}
            titleWidth="80px"
          >
            <DateRangePicker
              width="100%"
              onChange={handlers.date}
              value={searchFilters.date}
            />
          </SearchHeaderColumn>
        </FirstRowWrapperS>
        <SecondRowWrapperS>
          <SearchHeaderColumn title="Property" {...defaultFilterProps}>
            <ClientPropertiesSelect
              withoutSkip
              includeAllOption
              onChange={handlers.propertyId}
              value={searchFilters.propertyId}
              clientId={searchFilters.clientId}
              disabled={!searchFilters.clientId}
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn filterWidth="330px">
            <HeaderLinksWrapperS>
              <ClientSurveysSummaryReportPrintBtn
                reportEntries={reportEntries}
              />
              <ClientSurveysSummaryReportExportXLSBtn
                reportEntries={reportEntries}
              />
            </HeaderLinksWrapperS>
            <SearchHeaderButton
              size="small"
              onClick={onResetSearch}
              styleType="basicPrimary"
            >
              Reset
            </SearchHeaderButton>
            <SearchHeaderButton
              size="small"
              loader={loading}
              onClick={onSearch}
            >
              Search
            </SearchHeaderButton>
          </SearchHeaderColumn>
        </SecondRowWrapperS>
      </HeaderWrapperS>
    </SearchPageHeaderContainer>
  );
}

ClientSurveysSummaryReportHeader.propTypes = {
  loading: bool,
  setFilters: func.isRequired,
  reportEntries: arrayOf(shape(clientSurveysSummaryReportEntryPropTypes))
    .isRequired,
};
