import { gql } from '@apollo/client';
import { commonAddressFields } from '@poly/admin-ui';
import { supplierDocumentsFields, supplierAccountFields } from './fragments.js';

export const SUPPLIERS_BY_SEARCH_TABLE = gql`
  query SUPPLIERS_BY_SEARCH_TABLE($searchInput: CollectionSearchParams!) {
    searchSuppliers(input: $searchInput) {
      hits {
        _id
        status
        type
        rating {
          stars
          average
        }
        createdAt
        rates {
          hourly
        }
        company {
          name
          address {
            formatted_address
            geo {
              coordinates
            }
          }
          phones {
            type
            phone
          }
          services {
            _id
            name
          }
        }
      }
      total
    }
  }
`;

export const SUPPLIER_DETAILS = gql`
  query SUPPLIER_DETAILS($supplierId: ID!) {
    supplier(id: $supplierId) {
      _id
      company {
        name
        emails {
          email
          type
        }
      }
      masterSupplier {
        _id
      }
      status
      type
      isVeteran
      mwbe {
        isWBE
        minority
      }
      rating {
        stars
        average
      }
      cardNumber
      misc {
        source
      }
      tax {
        exempt
        w9File {
          url
        }
      }
      documents {
        ...supplierDocumentsFields
      }
    }
  }
  ${supplierDocumentsFields}
`;

export const SUPPLIER_ACCOUNT = gql`
  query SUPPLIER_ACCOUNT($supplierId: ID!) {
    supplier(id: $supplierId) {
      ...supplierAccountFields
    }
  }
  ${supplierAccountFields}
`;

export const SUPPLIER_ACCOUNT_WITH_INVOICES = gql`
  query SUPPLIER_ACCOUNT(
    $supplierId: ID!
    $searchInput: CollectionSearchParams!
  ) {
    supplier(id: $supplierId) {
      searchInvoices(input: $searchInput) {
        hits {
          _id
          balance
          status
        }
      }
      ...supplierAccountFields
    }
  }
  ${supplierAccountFields}
`;

export const SUPPLIERS_BY_SEARCH_FOR_OPTIONS = gql`
  query SUPPLIERS_BY_SEARCH_FOR_OPTIONS($searchInput: CollectionSearchParams!) {
    searchSuppliers(input: $searchInput) {
      hits {
        _id
        company {
          name
        }
      }
    }
  }
`;

export const SUPPLIER_OPTION_QUERY = gql`
  query SUPPLIER_OPTION_QUERY($id: ID!) {
    supplier(id: $id) {
      _id
      company {
        name
      }
    }
  }
`;

export const SUPPLIER_SERVICE_TYPES = gql`
  query SUPPLIER_SERVICES($id: ID!) {
    supplier(id: $id) {
      _id
      company {
        services {
          _id
          name
          status
        }
      }
    }
  }
`;

export const SUPPLIERS_BY_SEARCH_FOR_PROJECT_ASSIGN = gql`
  query SUPPLIERS_BY_SEARCH_FOR_PROJECT_ASSIGN(
    $searchInput: CollectionSearchParams!
  ) {
    searchSuppliers(input: $searchInput) {
      hits {
        _id
        status
        service_24_7
        rating {
          stars
          average
        }
        type
        isVeteran
        mwbe {
          isWBE
          minority
        }
        company {
          name
          phones {
            phone
            type
          }
          emails {
            email
            type
          }
          address {
            ...commonAddressFields
          }
        }
        rates {
          hourly
          overTime
          trip
          weekend
        }
        tax {
          exempt
          w9File {
            url
          }
        }
        officeHours {
          day
          isActive
          startTime
          endTime
        }
        documents {
          ...supplierDocumentsFields
        }
      }
    }
  }
  ${commonAddressFields}
  ${supplierDocumentsFields}
`;
