import { Input } from '@poly/admin-book';

export const defaultFieldValueComponentConfig = {
  Component: Input,
  getOptions: null,
  placeholder: 'Enter value',
  gqlSearchQuery: null,
  getGqlQueryVariables: null,
  gqlSearchChangedQuery: null,
};
