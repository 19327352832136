import * as R from 'ramda';
import React, { useContext } from 'react';
import { arrayOf, bool } from 'prop-types';
import styled, { css } from 'styled-components';
import { Loader, Table, WindowedTable } from '@poly/admin-book';
import { useMapConfigToTableProps } from '@poly/admin-ui';

import { confirmSatisfactionProjectPropTypes } from './prop-types.js';
import { EmptyListMessage } from '../../components/EmptyListMessage.js';
import { WhiteBodyWrapper } from '../PaySuppliersPage/PaySuppliersComponents.js';
import { PageWithSearchHeaderContext } from '../../components/PageWithSearchHeader.js';
import { getConfirmSatisfactionTableConfig } from './getConfirmSatisfactionTableConfig.js';

const WhiteBodyWrapperS = styled(WhiteBodyWrapper)`
  margin: 0 auto;
  max-width: 1300px;

  @media (max-width: 1350px) {
    margin: 0 25px;
    width: calc(100% - 50px);
  }
`;

const EmptyListMessageS = styled(EmptyListMessage)`
  margin-top: 40px;
`;

const confirmSatisfactionReportTableStyles = css`
  th:nth-child(1),
  td:nth-child(1) {
    width: 100px;
  }

  th:nth-child(3),
  td:nth-child(3) {
    width: 200px;
  }

  th:nth-child(4),
  td:nth-child(4) {
    width: 110px;
  }
`;

const ConfirmSatisfactionReportTableS = styled(WindowedTable)`
  ${confirmSatisfactionReportTableStyles}
`;

const PrintReportTableS = styled(Table)`
  ${confirmSatisfactionReportTableStyles}
`;

export function ConfirmSatisfactionReportTableContainer({
  loading,
  projects,
  isPrintPDF,
  ...restTableProps
}) {
  const { contentHeight } = useContext(PageWithSearchHeaderContext);

  const TableComponent = isPrintPDF
    ? PrintReportTableS
    : ConfirmSatisfactionReportTableS;

  const tableProps = useMapConfigToTableProps(
    R.identity,
    getConfirmSatisfactionTableConfig(isPrintPDF),
    projects,
  );

  if (loading) {
    return <Loader />;
  }

  if (R.isEmpty(projects)) {
    return <EmptyListMessageS />;
  }

  return (
    <WhiteBodyWrapperS height={contentHeight}>
      <TableComponent {...tableProps} {...restTableProps} />
    </WhiteBodyWrapperS>
  );
}

ConfirmSatisfactionReportTableContainer.propTypes = {
  loading: bool,
  isPrintPDF: bool,
  projects: arrayOf(confirmSatisfactionProjectPropTypes),
};
