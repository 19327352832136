import React from 'react';
import * as R from 'ramda';
import downloadFile from 'downloadjs';
import { gql, useLazyQuery } from '@apollo/client';
import { base64ToBlob } from '@poly/client-utils/src/print.js';
import { LinkButton } from '@poly/admin-book/src/LinkButton/index.js';

import { useAuthUserByTemporalAccess } from './DocumentsRequest/DocumentsRequestPage.js';

const generateWOCAssetsPDFByTemporalAccess = gql`
  query {
    generateWOCAssetsPDFByTemporalAccess {
      base64FileString
    }
  }
`;

// getBase64String :: { generateWOCAssetsPDFByTemporalAccess: { base64FileString: String } }
// -> String
const getBase64String = R.path([
  'generateWOCAssetsPDFByTemporalAccess',
  'base64FileString',
]);

export function WOCAssetsDownloadPage() {
  const { isAuthorized } = useAuthUserByTemporalAccess();
  const [generateWOC_PDF] = useLazyQuery(generateWOCAssetsPDFByTemporalAccess);

  const handleDownload = async () => {
    if (isAuthorized) {
      const { data } = await generateWOC_PDF({
        fetchPolicy: 'network-only',
        skip: !isAuthorized,
      });

      downloadFile(
        base64ToBlob(getBase64String(data), 'application/pdf'),
        'woc_assets.pdf',
      );
    }
  };

  return (
    <LinkButton onClick={handleDownload} disabled={!isAuthorized}>
      Download Assets List
    </LinkButton>
  );
}
