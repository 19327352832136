import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { insertQueryParamsIntoURL } from '@poly/utils';
import { useNavigate } from '@poly/client-routing';
import { number, shape } from 'prop-types';
import styled from 'styled-components';
import { defaultTheme, Dot } from '@poly/admin-book';

import { BarChart } from './Charts/BarChart.js';
import { routesNames } from '../../../routes/constants.js';
import { projectTabByStatus } from '../../../modules/tabs/TabsWithRouter.js';
import {
  projectStatusesUI,
  projectUIStatuses,
} from '../../../modules/core/constants/projects.js';
import {
  DashboardSection,
  DashboardSectionTitle,
  FlexContainer,
} from './components.js';

const { primaryLight, secondaryMid } = defaultTheme.colors;

const ProjectsChartWrapper = styled(DashboardSection)`
  height: 322px;
`;

const ChartWrapper = styled.div`
  height: 275px;
  width: 100%;
`;

const ProjectsChartHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 27px;
`;

const Label = styled.p`
  font-size: 12px;
  color: #667085;
  margin: 0;
  padding: 0;
`;

const PROJECTS_BAR_COLOR = primaryLight;
const MY_PROJECTS_BAR_COLOR = secondaryMid;

function ProjectChartInfo() {
  return (
    <FlexContainer>
      <FlexContainer gap="5px">
        <Dot color={PROJECTS_BAR_COLOR} />
        <Label>All Projects</Label>
      </FlexContainer>
      <FlexContainer gap="5px">
        <Dot color={MY_PROJECTS_BAR_COLOR} />
        <Label> My Projects</Label>
      </FlexContainer>
    </FlexContainer>
  );
}

const { PROJECTS_DIRECTORY, MY_PROJECTS, EMERGENCY_PROJECTS } = routesNames;

const projectChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  hover: {
    mode: 'nearest',
  },
  layout: {
    padding: {
      left: 0,
      right: 0,
      top: 30,
      bottom: 0,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
      ticks: {
        color: '#94969C',
      },
    },
    y: {
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
      ticks: {
        color: '#94969C',
        callback: (value) => {
          if (value >= 1000) {
            return `${value / 1000}k`;
          }
          return value;
        },
      },
    },
  },
};

const barValuePlugin = {
  id: 'bar-value-plugin',
  afterDatasetDraw(chart) {
    const { ctx } = chart;
    chart.data.datasets.forEach((dataset, datasetIndex) => {
      const meta = chart.getDatasetMeta(datasetIndex);
      meta.data.forEach((bar, index) => {
        const value = dataset.data[index];
        const { x, y } = bar;
        ctx.save();
        ctx.fillStyle = bar.options.backgroundColor;
        ctx.textAlign = 'center';
        ctx.fillText(value, x, y - 10);
        ctx.restore();

        // eslint-disable-next-line no-param-reassign
        bar.custom = { value, datasetIndex, index, x, y: y - 10 };
      });
    });
  },
};

const DUE_TODAY_BAR_LABEL = projectStatusesUI[projectUIStatuses.DUE_TODAY];
const DUE_TOMORROW_BAR_LABEL =
  projectStatusesUI[projectUIStatuses.DUE_TOMORROW];
const PAST_DUE_BAR_LABEL = projectStatusesUI[projectUIStatuses.PAST_DUE];
const EMERGENCY_BAR_LABEL = 'Emergency';
const ON_HOLD_BAR_LABEL = projectStatusesUI[projectUIStatuses.ON_HOLD];
const ACTIVE_BAR_LABEL = projectStatusesUI[projectUIStatuses.ACTIVE];

const getProjectUrlWithTab = (page, tab) =>
  insertQueryParamsIntoURL({ tab: projectTabByStatus(tab) }, page);

const PROJECTS_TABS_MAP = {
  [DUE_TODAY_BAR_LABEL]: projectUIStatuses.DUE_TODAY,
  [DUE_TOMORROW_BAR_LABEL]: projectUIStatuses.DUE_TOMORROW,
  [PAST_DUE_BAR_LABEL]: projectUIStatuses.PAST_DUE,
  [EMERGENCY_BAR_LABEL]: null,
  [ON_HOLD_BAR_LABEL]: projectUIStatuses.ON_HOLD,
  [ACTIVE_BAR_LABEL]: projectUIStatuses.ACTIVE,
};

// getProjectsChartData :: (String, DashboardQueryResult) -> [Number]
const getProjectsChartData = (propName, data) =>
  R.compose(
    R.juxt([
      R.propOr(0, 'dueToday'),
      R.propOr(0, 'dueTomorrow'),
      R.propOr(0, 'overdue'),
      R.propOr(0, 'emergency'),
      R.propOr(0, 'onHold'),
      R.propOr(0, 'active'),
    ]),
    R.path(['dashboard', propName]),
  )(data);

export function ProjectsChart({ dashboardData }) {
  const [animation, setAnimation] = useState(true);

  useEffect(() => {
    // prevent chart animation after refetch data
    const timer = setTimeout(() => setAnimation(false), 1000);

    return () => clearTimeout(timer);
  }, []);

  const navigate = useNavigate();
  const data = {
    labels: [
      DUE_TODAY_BAR_LABEL,
      DUE_TOMORROW_BAR_LABEL,
      PAST_DUE_BAR_LABEL,
      EMERGENCY_BAR_LABEL,
      ON_HOLD_BAR_LABEL,
      ACTIVE_BAR_LABEL,
    ],
    datasets: [
      {
        data: getProjectsChartData('globalProjectsCounts', dashboardData),
        backgroundColor: PROJECTS_BAR_COLOR,
      },
      {
        data: getProjectsChartData('ownProjectsCounts', dashboardData),
        backgroundColor: MY_PROJECTS_BAR_COLOR,
        myProjects: true,
      },
    ],
  };

  const handleBarClick = (label, dataset) => {
    if (label === EMERGENCY_BAR_LABEL) {
      navigate(EMERGENCY_PROJECTS);
      return;
    }

    const page = dataset?.myProjects ? MY_PROJECTS : PROJECTS_DIRECTORY;

    navigate(getProjectUrlWithTab(page, PROJECTS_TABS_MAP[label]));
  };

  return (
    <ProjectsChartWrapper>
      <ProjectsChartHeaderWrapper>
        <DashboardSectionTitle title="Projects" iconName="layer" />
        <ProjectChartInfo />
      </ProjectsChartHeaderWrapper>
      <ChartWrapper>
        <BarChart
          data={data}
          options={{ ...projectChartOptions, animation }}
          plugins={[barValuePlugin]}
          handleClick={handleBarClick}
        />
      </ChartWrapper>
    </ProjectsChartWrapper>
  );
}

const projectsCountPropsTypes = shape({
  dueToday: number,
  dueTomorrow: number,
  overdue: number,
  emergency: number,
  onHold: number,
  active: number,
});

ProjectsChart.propTypes = {
  dashboardData: shape({
    globalProjectsCounts: projectsCountPropsTypes,
    ownProjectsCounts: projectsCountPropsTypes,
  }),
};
