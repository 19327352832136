import React, { createContext, useContext, useMemo, useState } from 'react';
import * as R from 'ramda';
import { node } from 'prop-types';

const ClientTagContext = createContext({});

export function ClientTagsProvider({ children }) {
  const [tagsProcesses, setTagsProcesses] = useState({});

  const isTagProcess = (id) => R.prop(id, tagsProcesses);

  const setTagProcess = (id, loading) =>
    setTagsProcesses((prev) => ({ ...prev, [id]: loading }));

  const contextValue = useMemo(
    () => ({
      isTagProcess,
      setTagProcess,
    }),
    [tagsProcesses, setTagsProcesses],
  );

  return (
    <ClientTagContext.Provider value={contextValue}>
      {children}
    </ClientTagContext.Provider>
  );
}

ClientTagsProvider.propTypes = {
  children: node.isRequired,
};

export const useClientTagContext = () => {
  const { isTagProcess, setTagProcess } = useContext(ClientTagContext);

  return { isTagProcess, setTagProcess };
};
