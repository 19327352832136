import * as R from 'ramda';
import { gql } from '@apollo/client';
import { useMemo, useRef, useEffect } from 'react';
import { ASC_SORT_ORDER } from '@poly/constants';
import { usePagination } from '@poly/admin-ui';
import {
  useEntitiesByReactiveReduxSearch,
  commonSortQuery,
} from '@poly/client-utils';

const PROBLEM_CODES_SEARCH_QUERY = gql`
  query PROBLEM_CODES_SEARCH_QUERY($searchInput: CollectionSearchParams!) {
    searchProblemCodes(input: $searchInput) {
      hits {
        _id
        name
        status
        description
        updatedAt
        createdAt
        createdBy {
          _id
          fullName
        }
      }
      total
    }
  }
`;

const PROBLEM_CODES_SEARCH_CHANGED = gql`
  subscription PROBLEM_CODES_SEARCH_CHANGED(
    $searchInput: CollectionSearchParams!
  ) {
    searchProblemCodeChanged(input: $searchInput) {
      type
      id
    }
  }
`;

// getProblemCodesByResult :: ProblemCodesSearchResult -> [ProblemCode]
const getProblemCodesByResult = R.pathOr([], ['searchProblemCodes', 'hits']);

// getProblemCodesCountByResult :: ProblemCodesSearchResult -> Int
const getProblemCodesCountByResult = R.pathOr(0, [
  'searchProblemCodes',
  'total',
]);

export const useProblemCodesQuery = (status, sort) => {
  const firstRender = useRef(null);
  const { pagination } = usePagination();

  const query = useMemo(() => (status ? { term: { status } } : null), [status]);

  const { loading, result: queryResult } = useEntitiesByReactiveReduxSearch({
    sort: sort || commonSortQuery(['name'])(ASC_SORT_ORDER),
    gqlSearchChangedQuery: PROBLEM_CODES_SEARCH_CHANGED,
    gqlSearchQuery: PROBLEM_CODES_SEARCH_QUERY,
    pagination,
    query,
  });

  const count = useMemo(
    () => getProblemCodesCountByResult(queryResult),
    [queryResult],
  );

  const result = useMemo(
    () => getProblemCodesByResult(queryResult),
    [queryResult],
  );

  useEffect(() => {
    if (queryResult?.searchProblemCodes && !firstRender?.current) {
      firstRender.current = true;
    }
  }, [queryResult]);

  const hasNoData = count === 0 && !firstRender?.current;

  return { count, loading, hasNoData, result };
};
