import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { Checkbox, Icon } from '@poly/admin-book';
import { ALL, MultiSelectDropDown } from '@poly/admin-ui';
import {
  bool,
  func,
  shape,
  object,
  string,
  arrayOf,
  oneOfType,
} from 'prop-types';

const BOX_SHADOW =
  '0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)';

const AssetsSelectDropdownWrapper = styled.div`
  position: absolute;
  top: ${R.prop('top')};
  width: 100%;
  height: auto;
  border-radius: 4px;
  box-shadow: ${BOX_SHADOW};
`;

const SearchIconS = styled(Icon)`
  position: absolute;
  z-index: 1000;
  top: ${R.prop('top')};
  left: 10px;
`;

const MultiSelectDropDownS = styled(MultiSelectDropDown)`
  .multiselect__control {
    z-index: 999;
    min-height: 40px;
    border-radius: 4px 4px 0 0;
    border: none;
    border-bottom: 1px solid #ebebeb;
    padding-left: 20px;
  }
`;

// filterAssetsOptions :: [SelectOption] -> [SelectOption]
const filterAssetsOptions = R.compose(
  R.filter(R.prop('isAssetOption')),
  R.defaultTo([]),
);

// filterAssetTypesOptions :: [SelectOption] -> [SelectOption]
const filterAssetTypesOptions = R.compose(
  R.reject(R.prop('isAssetOption')),
  R.defaultTo([]),
);

const checkIsOptionSelected = ({ value }) =>
  R.compose(R.includes(value), R.map(R.prop('value')), R.defaultTo([]));

const WrapperCheckboxService = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  background-color: ${R.prop('bgColor')};

  input {
    margin: 0;
  }
`;

const defaultCheckboxOnChange = () => {
  // no implementation is required
};

const CheckboxS = styled(Checkbox)`
  display: flex;
  align-items: center;
  color: #1a1514;
  height: unset;

  > div {
    top: -7px !important;
  }
`;

const DotWrapperS = styled.div`
  position: relative;
  top: -2px;
  font-size: 24px;
  line-height: 5px;
  color: #5e6271;
`;

const AssetTypeWrapperS = styled.div`
  color: #5e6271;
`;

const AssetWrapperS = styled.div`
  color: #1a1514;
`;

function CustomOption({ isSelected, children, innerProps, innerRef, data }) {
  const { assetType, isAssetOption } = data || {};

  /* eslint-disable @cspell/spellchecker */
  return (
    <WrapperCheckboxService
      bgColor={isAssetOption ? '#FFF' : '#FAFAFA'}
      ref={innerRef}
      {...innerProps}
    >
      <CheckboxS
        value={isSelected}
        onChange={defaultCheckboxOnChange}
        name={children}
      />
      <AssetWrapperS>{children}</AssetWrapperS>
      {!!assetType && <DotWrapperS>&middot;</DotWrapperS>}
      {!!assetType && <AssetTypeWrapperS>{assetType}</AssetTypeWrapperS>}
    </WrapperCheckboxService>
  );
  /* eslint-enable @cspell/spellchecker */
}

CustomOption.propTypes = {
  isSelected: bool,
  children: string,
  // eslint-disable-next-line react/forbid-prop-types
  innerProps: object,
  // eslint-disable-next-line react/forbid-prop-types
  innerRef: oneOfType([object, func]),
  data: shape({
    assetType: string,
    isAssetOption: bool,
  }),
};

// isNotFilteredByAssetTypes :: { selectedAssetTypes: [Option] } -> Boolean
const isNotFilteredByAssetTypes = R.propSatisfies(
  R.isEmpty,
  'selectedAssetTypes',
);

// isALLOptionClicked :: { option: Option } -> Boolean
const isALLOptionClicked = R.pathEq(ALL, ['option', 'value']);

// isAssetOptionClicked :: { option: Option } -> Boolean
const isAssetOptionClicked = R.path(['option', 'isAssetOption']);

// filterOutAllOption :: { assetsValue: [Option] } -> [Option]
const filterOutAllOption = R.compose(
  R.reject(R.propEq(ALL, 'value')),
  R.propOr([], 'assetsValue'),
);

const getPreparedSelectAssetOptions = R.cond([
  [
    isALLOptionClicked,
    R.ifElse(
      isNotFilteredByAssetTypes,
      R.prop('assetsOptions'),
      R.compose(
        R.uniqBy(R.prop('value')),
        R.filter(R.prop('isAssetOption')),
        R.converge(R.concat, [
          R.propOr([], 'assetsValue'),
          R.propOr([], 'assetsOptions'),
        ]),
      ),
    ),
  ],
  [
    isAssetOptionClicked,
    R.compose(
      R.uniqBy(R.prop('value')),
      R.converge(R.append, [R.prop('option'), R.propOr([], 'assetsValue')]),
    ),
  ],
  [R.complement(isAssetOptionClicked), filterOutAllOption],
  [R.T, R.always([])],
]);

const getPreparedDeselectAssetOptions = R.cond([
  [
    isALLOptionClicked,
    R.ifElse(
      isNotFilteredByAssetTypes,
      R.always([]),
      R.compose(
        R.reject(R.propEq(ALL, 'value')),
        R.converge(R.difference, [
          R.propOr([], 'assetsValue'),
          R.propOr([], 'assetsOptions'),
        ]),
      ),
    ),
  ],
  [
    isAssetOptionClicked,
    R.converge(R.reject, [
      R.compose(R.propEq(R.__, 'value'), R.path(['option', 'value'])),
      R.propOr([], 'assetsValue'),
    ]),
  ],
  [R.complement(isAssetOptionClicked), filterOutAllOption],
  [R.T, R.always([])],
]);

const getPreparedAssetOptions = R.cond([
  [R.propEq('select-option', 'action'), getPreparedSelectAssetOptions],
  [R.propEq('deselect-option', 'action'), getPreparedDeselectAssetOptions],
  [R.T, R.always([])],
]);

export function MultiAssetsSelectOptionsDropdown({
  error,
  assetsValue,
  assetsLoading,
  assetsOptions,
  handleAssetSelect,
  assetTypesValue,
  assetTypesOptions,
  setAssetTypesValue,
}) {
  const handleChange = (selectedOptions, { action, option }) => {
    const selectedAssets = filterAssetsOptions(selectedOptions);
    const selectedAssetTypes = filterAssetTypesOptions(selectedOptions);

    setAssetTypesValue(selectedAssetTypes);

    const preparedAssetOptions = getPreparedAssetOptions({
      action,
      option,
      assetsValue,
      assetsOptions,
      selectedAssets,
      selectedAssetTypes,
    });

    handleAssetSelect(preparedAssetOptions);
  };

  const selectProps = {
    handleChange,
    styleType: 'withCheckBox',
    error: null,
    menuIsOpen: true,
    hideSelectedOptions: false,
    maxMenuHeight: 250,
    value: assetTypesValue,
    isOptionSelected: (option) =>
      checkIsOptionSelected(option)([...assetsValue, ...assetTypesValue]),
    loading: assetsLoading,
    placeholder: 'Enter to filter by asset types',
    options: [...assetTypesOptions, ...assetsOptions],
    components: { Option: CustomOption },
    styles: {
      menu: (provided) => ({
        ...provided,
        marginTop: 0,
        borderRadius: '0 0 4px 4px',
        boxShadow: BOX_SHADOW,
      }),
    },
  };

  const iconProps = {
    size: 13,
    top: error ? '0' : '24px',
  };

  return (
    <>
      <SearchIconS {...iconProps} name="search" />
      <AssetsSelectDropdownWrapper top={error ? '-14px' : '10px'}>
        <MultiSelectDropDownS {...selectProps} />
      </AssetsSelectDropdownWrapper>
    </>
  );
}

MultiAssetsSelectOptionsDropdown.propTypes = {
  error: string,
  assetsValue: arrayOf(shape({ label: string, value: string })),
  assetsLoading: bool,
  assetsOptions: arrayOf(shape({ label: string, value: string })),
  handleAssetSelect: func,

  assetTypesValue: arrayOf(shape({ label: string, value: string })),
  assetTypesOptions: arrayOf(shape({ label: string, value: string })),
  setAssetTypesValue: func,
  setAssetTypesSearchTerm: func,
};
