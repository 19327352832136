import * as R from 'ramda';
import { ALL } from '@poly/admin-ui';
import { isNilOrEmpty, ofArrayLegacy, propEqLegacy } from '@poly/utils';
import { SpendReportFilters, ProjectSpendTypes } from '@poly/constants';

// isDefaultProperty :: ID -> Boolean
export const isDefaultProperty = R.includes(R.__, [
  ALL,
  SpendReportFilters.all,
  SpendReportFilters.mainAccount,
]);

// prepareSpendReportFilters :: [ID] -> FormData -> FormData
export const prepareSpendReportFilters = (childPropertyIds) =>
  R.compose(
    R.over(
      R.lensProp('spendType'),
      R.compose(
        R.when(
          propEqLegacy(0, ProjectSpendTypes.RECURRING_SERVICE),
          R.append(ProjectSpendTypes.PREVENTIVE_REPAIR),
        ),
        R.ifElse(isNilOrEmpty, R.always(null), ofArrayLegacy),
      ),
    ),
    R.over(
      R.lensProp('propertyId'),
      R.ifElse(
        R.either(isDefaultProperty, R.isNil),
        R.always(null),
        R.compose(R.concat(childPropertyIds), ofArrayLegacy),
      ),
    ),
  );
