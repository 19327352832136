import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import {
  Dot,
  Icon,
  Loader,
  WindowedTable,
  getThemeColor,
} from '@poly/admin-book';
import { arrayOf, bool, shape, string } from 'prop-types';
import { useMapConfigToTableProps } from '@poly/admin-ui';
import {
  applyPathOrNothingUI,
  mongoSortQuery,
  useHasUserAccessToUpdateDocuments,
} from '@poly/client-utils';
import { formatDate } from '@poly/utils';
import {
  supplierDocumentNames,
  supplierDocumentRequestTypes,
} from '@poly/constants';

import {
  BottomPanelContainer,
  BottomPanelBody,
} from '../../components/BottomPanel.js';
import { EmptyListMessage } from '../../components/EmptyListMessage.js';
import { TableAndPanelWrapper } from '../VoidSupplierPayments/styles.js';
import { PayInvoicesTableCard } from '../PaySuppliersPage/PaySuppliersComponents.js';
import { useNavigateToRequestedDocumentReviewSidebar } from './sidebar/useNavigateToRequestedDocumentReviewSidebar.js';
import { ApproveButton, documentsDotColors } from './sidebar/components.js';
import { MasterSupplierLink, SupplierLink } from '../../components/Links.js';
import { useApproveRequestedSupplierDocuments } from './useApproveRequestedSupplierDocuments.js';
import { RejectRequestedDocumentButton } from './sidebar/RejectRequestedDocumentButton.js';

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 11px;
`;

const RequestedSupplierDocumentsTableS = styled(WindowedTable)`
  tbody {
    vertical-align: middle;
  }
  td {
    padding: 0 5px;
  }

  th:nth-child(1),
  td:nth-child(1) {
    width: 30%;
  }
  th:nth-child(5),
  td:nth-child(5) {
    width: 50px;
  }
`;

const DocumentTypeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const supplierDocumentNamesUI = {
  ...supplierDocumentNames,
  [supplierDocumentRequestTypes.W9]: 'Supplier Onboarding',
  [supplierDocumentRequestTypes.MBE]: 'Minority Business Enterprise',
  [supplierDocumentRequestTypes.VETERAN]: 'Veteran Owned Business',
  [supplierDocumentRequestTypes.WBE]: 'Woman-Owned Business Enterprise',
  [supplierDocumentRequestTypes.W9_MANUALLY]: 'W-9',
};

function DocumentTypeComp({ documentType }) {
  const documentName = supplierDocumentNamesUI[documentType];
  const color = documentsDotColors[documentType];

  return (
    <DocumentTypeWrapper>
      <Dot color={color} /> <p>{documentName}</p>
    </DocumentTypeWrapper>
  );
}

DocumentTypeComp.propTypes = {
  documentType: string.isRequired,
};

const PreviewDocumentWrapper = styled(DocumentTypeWrapper)`
  background-color: rgba(71, 119, 216, 0.08);
  color: ${getThemeColor(['navBarLighter2'])};
  padding: 2px 10px;
  width: 140px;
  border-radius: 5px;
  cursor: pointer;
`;

function DocumentPreviewBtn({ _id, documentType }) {
  const openRequestedDocumentSidebar =
    useNavigateToRequestedDocumentReviewSidebar();

  return (
    <PreviewDocumentWrapper
      onClick={() => openRequestedDocumentSidebar(_id, documentType)}
    >
      <Icon name="document" size="10" color="#5F7BB1" />
      <span>Preview document</span>
    </PreviewDocumentWrapper>
  );
}

DocumentPreviewBtn.propTypes = {
  _id: string.isRequired,
  documentType: string.isRequired,
};

function SupplierLinkComp({ supplier, masterSupplier }) {
  if (supplier) {
    return (
      <SupplierLink
        _id={supplier?._id}
        name={supplier?.company?.name}
        target="_blank"
      />
    );
  }

  if (masterSupplier) {
    return (
      <MasterSupplierLink
        _id={masterSupplier?._id}
        name={masterSupplier?.company?.name}
        target="_blank"
      />
    );
  }
}

SupplierLinkComp.propTypes = {
  supplier: shape({
    _id: string,
  }),
  masterSupplier: shape({
    _id: string,
  }),
};

export const supplierDocumentsForReviewTableConfig = [
  ['Supplier', SupplierLinkComp, mongoSortQuery('supplierName')],
  [
    'Expiration date',
    applyPathOrNothingUI(['expiration'], formatDate),
    mongoSortQuery('expiration'),
  ],
  ['Document type', DocumentTypeComp, mongoSortQuery('documentType')],
  ['Document file', DocumentPreviewBtn],
];

// getSelectedRowsRows :: [ID] -> ID -> [ID]
const getSelectedRowsRows = (docs, id) =>
  R.ifElse(R.includes(id), R.reject(R.equals(id)), R.append(id))(docs);

// filterSelectedRows :: ([ID], [RequestedDocument]) -> [ID]
const filterSelectedRows = (selectedIds, docs) =>
  R.filter(R.includes(R.__, R.pluck('_id', docs)))(selectedIds);

export function RequestedSupplierDocumentsReviewTable({
  documents,
  loading,
  ...props
}) {
  const [selectedDocuments, setSelectedDocuments] = useState([]);

  useEffect(() => {
    setSelectedDocuments(filterSelectedRows(selectedDocuments, documents));
  }, [documents]);

  const { handleApproveDocuments, isApproving } =
    useApproveRequestedSupplierDocuments(selectedDocuments);

  const { rows, headers, columns, sortQueries } = useMapConfigToTableProps(
    R.identity,
    supplierDocumentsForReviewTableConfig,
    documents,
  );

  const { hasUserAccessToUpdateDocuments, docAccessLoading } =
    useHasUserAccessToUpdateDocuments();

  const toggleSelectAll = () => {
    if (selectedDocuments.length !== documents.length) {
      setSelectedDocuments(R.pluck('_id', documents));
    } else {
      setSelectedDocuments([]);
    }
  };

  const toggleRow = (id) =>
    setSelectedDocuments(getSelectedRowsRows(selectedDocuments, id));

  const isButtonDisabled = loading || isApproving || !selectedDocuments.length;

  const isRejectBtnDisabled = selectedDocuments.length > 1 || isButtonDisabled;

  const isLoading = loading || docAccessLoading;

  const tableSelectProps = hasUserAccessToUpdateDocuments
    ? {
        toggleRow,
        toggleSelectAll,
        selectedRowsInEnd: true,
        selectedRows: selectedDocuments,
        isRowSelectable: R.T,
        ...props,
      }
    : props;

  return (
    <TableAndPanelWrapper>
      <PayInvoicesTableCard height="calc(100% - 90px)">
        {isLoading && <Loader />}
        {!isLoading && documents.length === 0 && <EmptyListMessage />}
        {!isLoading && documents.length > 0 && (
          <RequestedSupplierDocumentsTableS
            headers={headers}
            rows={rows}
            columns={columns}
            sortQueries={sortQueries}
            showScrollBar
            {...tableSelectProps}
          />
        )}
      </PayInvoicesTableCard>
      {hasUserAccessToUpdateDocuments && (
        <BottomPanelContainer height="90px">
          <BottomPanelBody>
            <FlexContainer>
              <RejectRequestedDocumentButton
                disabled={isRejectBtnDisabled}
                documentId={R.head(selectedDocuments)}
              />
              <ApproveButton
                disabled={isButtonDisabled}
                onClick={handleApproveDocuments}
              />
            </FlexContainer>
          </BottomPanelBody>
        </BottomPanelContainer>
      )}
    </TableAndPanelWrapper>
  );
}

RequestedSupplierDocumentsReviewTable.propTypes = {
  loading: bool.isRequired,
  documents: arrayOf(
    shape({
      _id: string.isRequired,
      documentType: string.isRequired,
    }),
  ),
};
