import React from 'react';
import { bool, func } from 'prop-types';
import styled from 'styled-components';
import { useCurrentUserByStoreOrQuery } from '@poly/client-utils/src/hooks/useCurrentUserByStoreOrQuery.js';

import {
  CompanyWorkflowContainer,
  MyWorkflowContainer,
  PageContainer,
} from '../styles.js';
import {
  getUserCredentials,
  UserDashboardInfo,
} from '../components/UserDashboard.js';
import { ProjectsChart } from './ProjectsChart.js';
import { DashboardSidebar } from './DashboardSidebar.js';
import { MyTasksDashboard } from './MyTasksDashboard/MyTasksDashboard.js';
import { ReoccurringProjectsCharts } from './ReoccurringProjectsCharts/ReoccurringProjectsCharts.js';
import { MyMastersDashboard } from './MyMastersDashboard.js';

const CompanyWorkflowContainerS = styled(CompanyWorkflowContainer)`
  min-height: 100%;
`;

const MyWorkflowContainerS = styled(MyWorkflowContainer)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FlexContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const FlexColumnContainer = styled(FlexContainer)`
  flex-direction: column;
  width: 60%;
`;

export function ChartDashboard(props) {
  const { user } = useCurrentUserByStoreOrQuery();
  const { userMessage, userId } = getUserCredentials(user);

  return (
    <PageContainer>
      <CompanyWorkflowContainerS>
        <DashboardSidebar {...props} />
      </CompanyWorkflowContainerS>
      <MyWorkflowContainerS>
        <UserDashboardInfo
          userMessage={userMessage}
          {...props}
          withChartDashboard
        />
        <ProjectsChart {...props} />
        <FlexContainer>
          <FlexColumnContainer>
            <MyMastersDashboard {...props} />
            <ReoccurringProjectsCharts {...props} />
          </FlexColumnContainer>
          <MyTasksDashboard {...props} userId={userId} />
        </FlexContainer>
      </MyWorkflowContainerS>
    </PageContainer>
  );
}

ChartDashboard.propTypes = {
  handleSwitchDashboard: func,
  isChart: bool,
};
