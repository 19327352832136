import * as R from 'ramda';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { DefaultBodyWrapper } from '@poly/admin-book';
import { UPDATE_PROJECT_PERMISSION } from '@poly/security';
import { TableCardWithPaginationContainer } from '@poly/admin-ui';
import {
  useSortableTable,
  useHasUserAccessWithPermission,
} from '@poly/client-utils';

import { BulkEditFooter } from './BulkEditFooter.js';
import { BulkEditingTable } from './BulkEditingTable.js';
import { BulkEditingPageHeader } from './BulkEditingPageHeader.js';
import { useBulkEditProjectsQuery } from './useBulkEditProjectsQuery.js';
import { bulkEditProjectTableConfig } from './table-config/bulkEditProjectTableConfig.js';

const HEADER_HEIGHT = '80px';
const FOOTER_HEIGHT = '60px';

// countFoundDocuments :: { searchProjects: { hits: [Project] }, searchRecurringProjects: { hits: [RecurringProject] } } -> Int
const countFoundDocuments = R.converge(R.add, [
  R.pathOr(0, ['searchRecurringProjects', 'total']),
  R.pathOr(0, ['searchProjects', 'total']),
]);

export function BulkEditingPage() {
  const [input, setInput] = useState({});

  const isUserWithUpdateProjectPermission = useHasUserAccessWithPermission(
    UPDATE_PROJECT_PERMISSION,
  );

  const { sort, ...tableSortProps } = useSortableTable({
    column: 1,
    tableConfig: bulkEditProjectTableConfig,
  });

  const pagination = useSelector(R.prop('pagination'));

  const { data } = useBulkEditProjectsQuery({
    sort,
    input,
    isUserWithUpdateProjectPermission,
  });

  const documentsCount = useMemo(() => countFoundDocuments(data), [data]);

  return (
    <>
      <BulkEditingPageHeader
        sort={sort}
        input={input}
        height={HEADER_HEIGHT}
        setInput={setInput}
        documentsCount={documentsCount}
      />
      {/* <PageHeaderContainer
        height={HEADER_HEIGHT}
        headerStyles="padding-bottom: 55px"
      >
        <FlexRowS>
          <MainHeader>Bulk Edit</MainHeader>
          <DocumentsCount>({documentsCount} results found)</DocumentsCount>
        </FlexRowS>
        <FlexRowS>
          <ProjectXlsExportButton
            sort={sort}
            input={input}
            disabled={documentsCount === 0}
          />
          <ToolBarBtnDivider />
          <LinkButton onClick={openBulkEditingOutSidebar}>Filters</LinkButton>
        </FlexRowS>
      </PageHeaderContainer> */}
      <DefaultBodyWrapper
        height={`calc(100% - ${HEADER_HEIGHT} - ${FOOTER_HEIGHT})`}
      >
        <TableCardWithPaginationContainer>
          <BulkEditingTable
            data={data}
            pagination={pagination}
            {...tableSortProps}
          />
        </TableCardWithPaginationContainer>
      </DefaultBodyWrapper>
      <BulkEditFooter height={FOOTER_HEIGHT} />
    </>
  );
}
