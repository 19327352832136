import * as R from 'ramda';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import React, { useMemo, useEffect, useState } from 'react';
import { arrayOf, func, oneOfType, string } from 'prop-types';
import { UserStatuses, UserEmployeeInfoStatus } from '@poly/constants';
import { isNilOrEmpty } from '@poly/utils';

import { ALL, MAX_ITEMS } from '../constants/general.js';
import { MultiSelectDropDown } from './MultiSelectDropDown.js';
import { USERS_BY_SEARCH } from '../hocs/users/queries.js';

const MultiSelectDropDownS = styled(MultiSelectDropDown)`
  .multiselect__control,
  .multiselect__control:hover,
  .multiselect__control:focus,
  .multiselect__control--is-focused {
    min-height: 32px;
  }

  .multiselect__control {
    padding: 0;
  }

  .multiselect__value-container {
    min-height: 30px;
    max-height: ${R.propOr('85px', 'maxHeight')};
    overflow-y: auto;

    > div:last-child {
      height: 22px;
    }
  }
`;

// prepareUsersSelectOptions :: SearchUsersResult -> [Option]
const prepareUsersSelectOptions = R.compose(
  R.unless(R.isEmpty, R.prepend({ value: ALL, label: 'All' })),
  R.map(R.applySpec({ value: R.prop('_id'), label: R.prop('fullName') })),
  R.pathOr([], ['searchUsers', 'hits']),
);

// formatValue :: [ID] -> [ID]
const formatValue = R.cond([
  [R.is(String), R.of(Array)],
  [isNilOrEmpty, R.always([])],
  [R.T, R.identity],
]);

// prepareSelectValue :: [ID] -> [Option] -> [Option]
const prepareSelectValue = (value, options) => {
  const selectedValues = formatValue(value);

  return options.filter((option) =>
    selectedValues.some((id) => id === option.value),
  );
};

// prepareOptionsBeforeChange :: [ID] -> [Option] -> [ID]
const prepareOptionsBeforeChange = (value) =>
  R.compose(
    R.when(
      R.includes(ALL),
      R.ifElse(
        R.compose(R.includes(ALL), R.defaultTo([]), R.always(value)),
        R.reject(R.equals(ALL)),
        R.filter(R.equals(ALL)),
      ),
    ),
    R.map(R.prop('value')),
  );

export function UsersMultiSelect({ value, onChange, userGroupsIds, ...props }) {
  const [userGroupsCached, setUserGroupsCached] = useState(userGroupsIds);

  const query = useMemo(
    () => ({
      bool: {
        must: [
          { match: { status: UserStatuses.ACTIVE } },
          {
            terms: {
              'employeeInfo.status': [
                UserEmployeeInfoStatus.ACTIVE,
                UserEmployeeInfoStatus.LEAVE,
              ],
            },
          },
          ...(!R.isEmpty(userGroupsIds)
            ? [
                {
                  nested: {
                    path: 'userGroups',
                    query: {
                      terms: { 'userGroups.userGroupId': userGroupsIds },
                    },
                  },
                },
              ]
            : []),
        ],
      },
    }),
    [userGroupsIds],
  );

  const { data } = useQuery(USERS_BY_SEARCH, {
    variables: {
      searchInput: {
        query,
        from: 0,
        size: MAX_ITEMS,
        sort: ['_score', { 'profile.fullName.keyword': { order: 'asc' } }],
      },
    },
  });

  const options = prepareUsersSelectOptions(data);

  const preparedValue = useMemo(
    () => prepareSelectValue(value, options),
    [value, options],
  );

  useEffect(() => {
    if (!R.equals(userGroupsCached, userGroupsIds)) {
      setUserGroupsCached(userGroupsIds);
      onChange([]);
    }
  }, [userGroupsIds]);

  const handleChange = (usersIds) => {
    const preparedUsers = prepareOptionsBeforeChange(value)(usersIds);
    onChange(preparedUsers);
  };

  const selectProps = {
    ...props,
    options,
    handleChange,
    value: preparedValue,
    placeholder: 'Start typing users',
  };

  return <MultiSelectDropDownS {...selectProps} />;
}

UsersMultiSelect.propTypes = {
  onChange: func,
  userGroupsIds: arrayOf(string),
  value: oneOfType([string, arrayOf(string)]),
};
