import React from 'react';
import { bool, number, shape, string } from 'prop-types';
import {
  CardsWrapper,
  Icon,
  SectionsWrapper,
  SideSection,
  Text,
} from '@poly/admin-book';
import { useHasUserAccessToSupplierPrivateFields } from '@poly/client-utils';
import { supplierTypes } from '@poly/constants';
import {
  SupplierDocumentsInfoSection,
  SupplierBankACHInfoSection,
  SupplierOfficeHoursSection,
  supplierAccountPropsTypes,
  SupplierRatesInfoSection,
  SupplierRemitInfoSection,
  RemarksSection,
} from '@poly/admin-ui';
import styled from 'styled-components';

import { FinancialSection } from './sections/FinancialSection.js';
import { GeneralInfoSection } from './sections/GeneralInfoSection.js';
import { useOpenSubcontractorAgreementSidebar } from '../../../sidebars/SubcontractorAgreementSidebar/useOpenSubcontractorAgreementSidebar.js';

const SupplierTaxIDWarningContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #ffeec4;
  height: 29px;
  width: 100%;
  border-radius: 5px 5px 0 0;
  padding-left: 20px;
  gap: 10px;
`;

const WarningText = styled(Text)`
  color: #d19c19;
  font-size: 12px;
`;

export function SupplierTaxIDWarning({ taxId, socialSecurityNumber }) {
  if (taxId || socialSecurityNumber) {
    return null;
  }

  return (
    <SupplierTaxIDWarningContainer>
      <Icon name="yellowWarning" size={18} />
      <WarningText>
        This supplier is currently missing a Tax ID Number. Please fill this in.
      </WarningText>
    </SupplierTaxIDWarningContainer>
  );
}

SupplierTaxIDWarning.propTypes = {
  taxId: string,
  socialSecurityNumber: string,
};

export function SupplierAccountTabComp({
  type,
  mwbe,
  remarks,
  bankACH,
  refetch,
  remitInfo,
  ratesInfo,
  generalInfo,
  financialInfo,
  documentsInfo,
}) {
  const { hasUserTaxPrivateFieldsAccess, hasUserBankPrivateFieldsAccess } =
    useHasUserAccessToSupplierPrivateFields();

  const openSubcontractorAgreement = useOpenSubcontractorAgreementSidebar();

  const openSubcontractorAgreementSidebar = () =>
    openSubcontractorAgreement({ ...documentsInfo, company: generalInfo });

  return (
    <CardsWrapper>
      <SectionsWrapper>
        <SideSection>
          <SupplierTaxIDWarning {...financialInfo} />
          <GeneralInfoSection {...{ type, mwbe, ...generalInfo }} />
          <SupplierOfficeHoursSection {...generalInfo} />
          {type === supplierTypes.SUBCONTRACTOR && (
            <SupplierRatesInfoSection {...ratesInfo} />
          )}
        </SideSection>
        <SideSection>
          <RemarksSection {...{ remarks }} />
          <SupplierRemitInfoSection {...remitInfo} />
          <FinancialSection
            {...financialInfo}
            supplierName={generalInfo.name}
            hasUserTaxPrivateFieldsAccess={hasUserTaxPrivateFieldsAccess}
          />
          <SupplierBankACHInfoSection
            {...bankACH}
            hasUserBankPrivateFieldsAccess={hasUserBankPrivateFieldsAccess}
          />
          {type === supplierTypes.SUBCONTRACTOR && (
            <SupplierDocumentsInfoSection
              {...{ ...mwbe, ...generalInfo, ...documentsInfo }}
              refetch={refetch}
              openSubcontractorAgreementSidebar={
                openSubcontractorAgreementSidebar
              }
            />
          )}
        </SideSection>
      </SectionsWrapper>
    </CardsWrapper>
  );
}

SupplierAccountTabComp.propTypes = {
  type: string.isRequired,
  generalInfo: shape(supplierAccountPropsTypes.generalInfoPropTypes).isRequired,
  ...supplierAccountPropsTypes.remarksPropTypes,
  financialInfo: shape(supplierAccountPropsTypes.financialPropTypes).isRequired,
  bankACH: shape(supplierAccountPropsTypes.bankACHPropTypes).isRequired,
  ratesInfo: shape({
    hourly: number,
    overTime: number,
    weekend: number,
    trip: number,
  }).isRequired,
  mwbe: shape({
    minority: string,
    isWBE: bool,
  }).isRequired,
};
