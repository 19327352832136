import React from 'react';
import { TableSearchInput, useModalContext } from '@poly/admin-ui';
import {
  PageHeaderContainer,
  HeaderLinksWrapper,
  LinkButton,
  MainHeader,
} from '@poly/admin-book';
import { useHasUserAccessWithPermission } from '@poly/client-utils';
import { CREATE_ACCOUNT_TYPE_PERMISSION } from '@poly/security';

import { addAccountTypeFormId } from './constants.js';
import { AccountTypeForm } from './forms/AccountTypeForm.js';

function AddAccountTypeButton() {
  const { openModalForm } = useModalContext();

  const ifHasPermission = useHasUserAccessWithPermission(
    CREATE_ACCOUNT_TYPE_PERMISSION,
  );

  const onClick = () =>
    openModalForm({
      id: addAccountTypeFormId,
      title: 'Create New Account Type',
      formId: addAccountTypeFormId,
      btnCaption: 'Create',
      content: <AccountTypeForm isNewAccountType />,
    });

  if (!ifHasPermission) {
    return null;
  }

  return (
    <LinkButton onClick={onClick} data-testid="add-new-account-type-button-id">
      Add New
    </LinkButton>
  );
}

export function AccountTypesPageHeader() {
  return (
    <PageHeaderContainer>
      <MainHeader>Account Types</MainHeader>
      <HeaderLinksWrapper>
        <TableSearchInput />
        <AddAccountTypeButton />
      </HeaderLinksWrapper>
    </PageHeaderContainer>
  );
}
