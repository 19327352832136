import * as R from 'ramda';
import { gql } from '@apollo/client';
import { entityToOptionByLabelPath } from '@poly/client-utils';
import { forceTitleCase, propEqLegacy } from '@poly/utils';
import {
  RecurringProjectTypes,
  ProjectTypeToNameMap,
  filesCollections,
  SupplierSources,
  UpdateTypes,
} from '@poly/constants';
import {
  CLIENTS_SEARCH_FOR_SELECT,
  defaultLoginAppOptions,
  CLIENTS_BY_SEARCH_SUB,
  USER_SEARCH_CHANGED,
  USERS_BY_SEARCH,
} from '@poly/admin-ui';
import {
  NOT_FLIPPED_CONTAINS_AIT_OPERATOR,
  PARENT_PROJECT_TYPE_AIT,
  FILE_COLLECTION_AIT,
  SUPPLIER_SOURCE_AIT,
  USER_GROUP_ID_AIT,
  TECHNICIAN_ID_AIT,
  REQUESTS_APP_NAME,
  PROJECT_TYPE_AIT,
  UPDATE_TYPE_AIT,
  SUPPLIER_ID_AIT,
  PROPERTY_ID_AIT,
  PROJECT_ID_AIT,
  CONTACT_ID_AIT,
  CREATED_BY_AIT,
  CLIENT_APP_AIT,
  CLIENT_ID_AIT,
  PO_AMOUNT_AIT,
} from '@poly/security';

import { getTechnicianUsersElasticQuery } from '@poly/client-utils/src/elasticQueries.js';
import { PROJECTS_BY_SEARCH_SELECT } from '../../../../components/ProjectsSelect.js';
import { PROJECTS_BY_SEARCH_SUB } from '../../../../modules/core/hooks/projects/subscriptions.js';
import { SUPPLIER_SEARCH_CHANGED } from '../../../../modules/core/hooks/suppliers/subscriptions.js';
import { PROPERTIES_BY_SEARCH_SUB } from '../../../../modules/core/hooks/properties/subscriptions.js';
import { SUPPLIERS_BY_SEARCH_FOR_OPTIONS } from '../../../../modules/core/hooks/suppliers/queries.js';
import {
  SEARCH_USER_GROUP_CHANGED_SUB,
  USER_GROUP_LIST_QUERY,
} from '../../queries/user-group-list-query.js';

export const USER_GROUP_ASSIGN_USER_SCOPE = 'userGroupAssignUserScope';

export const PROPERTIES_SEARCH_FOR_SELECT = gql`
  query PROPERTIES_SEARCH_FOR_SELECT($searchInput: CollectionSearchParams!) {
    searchProperties(input: $searchInput) {
      hits {
        _id
        name
        client {
          _id
        }
      }
    }
  }
`;

const customEntityLogicProps = {
  isCustomQuery: true,
  gqlSearchQuery: USERS_BY_SEARCH,
  optionsFromQueryResult: () => null,
  getDefaultValuesVariables: () => null,
  gqlSearchChangedQuery: USER_SEARCH_CHANGED,
};

const UpdateTypesStaticOptions = [
  { value: UpdateTypes.AUDIT, label: 'Audit' },
  { value: UpdateTypes.INTERNAL, label: 'Internal' },
  { value: UpdateTypes.CLIENT_UPDATE, label: 'Client Update' },
  { value: UpdateTypes.CLIENT_REPORT, label: 'Client Status Report' },
];

const ParentProjectTypesStaticOptions = [
  { value: RecurringProjectTypes.recurringProject, label: 'Recurring Project' },
  {
    value: RecurringProjectTypes.preventiveMaintenanceProject,
    label: 'Preventive Maintenance',
  },
];

// ProjectTypesStaticOptions :: Object -> [Option]
const ProjectTypesStaticOptions = R.compose(
  R.map(R.applySpec({ value: R.nth(0), label: R.nth(1) })),
  R.toPairs,
)(ProjectTypeToNameMap);

// SupplierSourcesStaticOptions :: Object -> [Option]
const SupplierSourcesStaticOptions = R.compose(
  R.map(R.applySpec({ value: R.identity, label: R.identity })),
  R.values,
)(SupplierSources);

// FileCollectionsStaticOptions :: Object -> [Option]
const FileCollectionsStaticOptions = R.compose(
  R.map(
    R.applySpec({
      value: R.identity,
      label: R.compose(forceTitleCase, R.join(' '), R.split(/(?=[A-Z])/)),
    }),
  ),
  R.sort(R.ascend(R.identity)),
  R.values,
)(filesCollections);

// getClientsOptions :: SearchClientsQueryResult -> [Option]
const getClientsOptions = R.compose(
  R.map(entityToOptionByLabelPath(['name'])),
  R.pathOr([], ['searchClients', 'hits']),
);

// getUsersOptions :: SearchUsersQueryResult -> [Option]
const getUsersOptions = R.compose(
  R.map(entityToOptionByLabelPath(['fullName'])),
  R.pathOr([], ['searchUsers', 'hits']),
);

// getUsersForAssignOptions :: SearchUsersQueryResult -> [Option]
const getUsersForAssignOptions = R.compose(
  R.map(
    R.applySpec({
      value: R.prop('_id'),
      label: R.compose(
        R.join(' | '),
        R.reject(R.isNil),
        R.juxt([R.prop('fullName'), R.path(['emails', '0', 'address'])]),
      ),
    }),
  ),
  R.pathOr([], ['searchUsers', 'hits']),
);

// getProjectsOptions :: SearchProjectsQueryResult -> [Option]
const getProjectsOptions = R.compose(
  R.map(entityToOptionByLabelPath(['projectId'])),
  R.pathOr([], ['searchProjects', 'hits']),
);

// getSuppliersOptions :: SearchSuppliersQueryResult -> [Option]
const getSuppliersOptions = R.compose(
  R.map(entityToOptionByLabelPath(['company', 'name'])),
  R.pathOr([], ['searchSuppliers', 'hits']),
);

// getPropertiesOptions :: SearchPropertiesQueryResult -> [Option]
const getPropertiesOptions = R.compose(
  R.map(
    R.converge(R.mergeLeft, [
      entityToOptionByLabelPath(['name']),
      R.compose(R.objOf('clientId'), R.path(['client', '_id'])),
    ]),
  ),
  R.pathOr([], ['searchProperties', 'hits']),
);

// getUserGroupsOptions :: SearchPropertiesQueryResult -> [Option]
const getUserGroupsOptions = R.compose(
  R.map(entityToOptionByLabelPath(['name'])),
  R.pathOr([], ['listUserGroups']),
);

// getDefaultIDsTermsVariables :: [ID] -> QueryVariables
const getDefaultIDsTermsVariables = R.compose(
  R.objOf('searchInput'),
  R.mergeAll,
  R.juxt([
    R.compose(R.objOf('size'), R.length),
    R.compose(R.objOf('query'), R.objOf('terms'), R.objOf('_id')),
  ]),
);

const getContactsQuery = () => ({
  bool: {
    must_not: {
      exists: { field: 'userGroups' },
    },
    must: {
      nested: {
        path: 'links',
        query: {
          bool: {
            should: [
              { exists: { field: 'links.clientId' } },
              { exists: { field: 'links.propertyId' } },
              { exists: { field: 'links.supplierId' } },
              { exists: { field: 'links.masterSupplierId' } },
              { exists: { field: 'links.adminPurchaseOrderId' } },
            ],
          },
        },
      },
    },
  },
});

const getAssignUsersQuery = () => ({
  bool: {
    must_not: { exists: { field: 'userGroups' } },
  },
});

// ClientAppOptions :: [Option] -> [Option]
export const ClientAppOptions = R.reject(
  propEqLegacy('value', REQUESTS_APP_NAME),
)(defaultLoginAppOptions);

// checkRelatedQueryEntitiesByProps :: (String, String) -> [String]
const checkRelatedQueryEntitiesByProps = (aitName, propName) =>
  R.compose(
    R.unless(
      R.isNil,
      R.compose(
        R.when(R.isEmpty, R.always(null)),
        R.uniq,
        R.map(R.prop(propName)),
        R.defaultTo([]),
        R.prop('entities'),
      ),
    ),
    R.find(propEqLegacy('scope', aitName)),
    R.defaultTo([]),
  );

// getRelatedQueryParamsByProps :: (String, String) -> [FormLine] -> ElasticQuery
const getRelatedQueryParamsByProps = (aitName, propName) =>
  R.ifElse(
    R.compose(R.isNil, checkRelatedQueryEntitiesByProps(aitName, propName)),
    R.always(null),
    R.compose(
      R.juxt([
        checkRelatedQueryEntitiesByProps(aitName, propName),
        propEqLegacy('operator', NOT_FLIPPED_CONTAINS_AIT_OPERATOR),
      ]),
    ),
  );

// getClientsQuery :: [FormLine] -> ElasticQuery
const getClientsQuery = R.compose(
  R.unless(R.isNil, ([entities, isMustNotOperator]) =>
    R.compose(
      R.objOf('bool'),
      R.objOf(isMustNotOperator ? 'must_not' : 'must'),
      R.objOf('terms'),
      R.objOf('_id'),
    )(entities),
  ),
  getRelatedQueryParamsByProps(PROPERTY_ID_AIT, 'clientId'),
);

// getPropertiesQuery :: [FormLine] -> ElasticQuery
const getPropertiesQuery = R.compose(
  R.unless(R.isNil, ([entities, isMustNotOperator]) =>
    R.compose(
      R.objOf('bool'),
      R.objOf(isMustNotOperator ? 'must_not' : 'must'),
      R.objOf('terms'),
      R.objOf('clientId'),
    )(entities),
  ),
  getRelatedQueryParamsByProps(CLIENT_ID_AIT, 'value'),
);

export const entitySelectPropsByScopeMap = {
  [CLIENT_ID_AIT]: {
    placeholder: 'Search clients',
    getSearchQuery: getClientsQuery,
    optionsFromQueryResult: getClientsOptions,
    gqlSearchQuery: CLIENTS_SEARCH_FOR_SELECT,
    gqlSearchChangedQuery: CLIENTS_BY_SEARCH_SUB,
    getDefaultValuesVariables: getDefaultIDsTermsVariables,
  },
  [CREATED_BY_AIT]: {
    placeholder: 'Search users',
    gqlSearchQuery: USERS_BY_SEARCH,
    optionsFromQueryResult: getUsersOptions,
    gqlSearchChangedQuery: USER_SEARCH_CHANGED,
    getDefaultValuesVariables: getDefaultIDsTermsVariables,
  },
  [CONTACT_ID_AIT]: {
    placeholder: 'Search contacts',
    gqlSearchQuery: USERS_BY_SEARCH,
    getSearchQuery: getContactsQuery,
    optionsFromQueryResult: getUsersOptions,
    gqlSearchChangedQuery: USER_SEARCH_CHANGED,
    getDefaultValuesVariables: getDefaultIDsTermsVariables,
  },
  [TECHNICIAN_ID_AIT]: {
    gqlSearchQuery: USERS_BY_SEARCH,
    placeholder: 'Search technicians',
    getSearchQuery: getTechnicianUsersElasticQuery,
    optionsFromQueryResult: getUsersOptions,
    gqlSearchChangedQuery: USER_SEARCH_CHANGED,
    getDefaultValuesVariables: getDefaultIDsTermsVariables,
  },
  [PROJECT_ID_AIT]: {
    placeholder: 'Search projects',
    gqlSearchQuery: PROJECTS_BY_SEARCH_SELECT,
    optionsFromQueryResult: getProjectsOptions,
    gqlSearchChangedQuery: PROJECTS_BY_SEARCH_SUB,
    getDefaultValuesVariables: getDefaultIDsTermsVariables,
  },
  [SUPPLIER_ID_AIT]: {
    placeholder: 'Search suppliers',
    optionsFromQueryResult: getSuppliersOptions,
    gqlSearchChangedQuery: SUPPLIER_SEARCH_CHANGED,
    gqlSearchQuery: SUPPLIERS_BY_SEARCH_FOR_OPTIONS,
    getDefaultValuesVariables: getDefaultIDsTermsVariables,
  },
  [PROPERTY_ID_AIT]: {
    placeholder: 'Search properties',
    getSearchQuery: getPropertiesQuery,
    optionsFromQueryResult: getPropertiesOptions,
    gqlSearchQuery: PROPERTIES_SEARCH_FOR_SELECT,
    gqlSearchChangedQuery: PROPERTIES_BY_SEARCH_SUB,
    getDefaultValuesVariables: getDefaultIDsTermsVariables,
  },
  [USER_GROUP_ID_AIT]: {
    ...customEntityLogicProps,
    placeholder: 'Search user group',
    gqlSearchQuery: USER_GROUP_LIST_QUERY,
    gqlSearchChangedQuery: SEARCH_USER_GROUP_CHANGED_SUB,
    optionsFromQueryResult: getUserGroupsOptions,
  },
  [CLIENT_APP_AIT]: {
    ...customEntityLogicProps,
    placeholder: 'Search apps',
    staticOptions: ClientAppOptions,
  },
  [UPDATE_TYPE_AIT]: {
    ...customEntityLogicProps,
    placeholder: 'Search update types',
    staticOptions: UpdateTypesStaticOptions,
  },
  [PROJECT_TYPE_AIT]: {
    ...customEntityLogicProps,
    placeholder: 'Search project types',
    staticOptions: ProjectTypesStaticOptions,
  },
  [PARENT_PROJECT_TYPE_AIT]: {
    ...customEntityLogicProps,
    placeholder: 'Search parent project types',
    staticOptions: ParentProjectTypesStaticOptions,
  },
  [SUPPLIER_SOURCE_AIT]: {
    ...customEntityLogicProps,
    placeholder: 'Search supplier sources',
    staticOptions: SupplierSourcesStaticOptions,
  },
  [FILE_COLLECTION_AIT]: {
    ...customEntityLogicProps,
    placeholder: 'Search file collections',
    staticOptions: FileCollectionsStaticOptions,
  },
  [PO_AMOUNT_AIT]: {
    ...customEntityLogicProps,
    placeholder: 'Search file collections',
    staticOptions: FileCollectionsStaticOptions,
  },

  // custom scopes
  [USER_GROUP_ASSIGN_USER_SCOPE]: {
    placeholder: 'Search users',
    gqlSearchQuery: USERS_BY_SEARCH,
    getSearchQuery: getAssignUsersQuery,
    optionsFromQueryResult: getUsersForAssignOptions,
    gqlSearchChangedQuery: USER_SEARCH_CHANGED,
    getDefaultValuesVariables: getDefaultIDsTermsVariables,
  },
};
