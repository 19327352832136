import { shape, number, string, instanceOf } from 'prop-types';

export const staffReportPropTypes = {
  postedUpdates: number.isRequired,
  activeProjects: number.isRequired,
  closedProjects: number.isRequired,
  createdProjects: number.isRequired,
  createdSuppliers: number.isRequired,
  user: shape({ _id: string.isRequired }),
  completedProjectTasks: number.isRequired,
  completedCapExProjects: number.isRequired,
  activeRecurringProjects: number.isRequired,
  completedReactiveProjects: number.isRequired,
  completedRecurringProjects: number.isRequired,
  completedPreventativeProjects: number.isRequired,
};

export const staffReportQueryPropTypes = {
  endDate: instanceOf(Date).isRequired,
  startDate: instanceOf(Date).isRequired,
};
