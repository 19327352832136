import * as R from 'ramda';
import React from 'react';
import { Popover } from '@poly/admin-book';
import { bool, object, oneOfType, shape, string, oneOf } from 'prop-types';
import { SupplierStatuses } from '@poly/constants';
import { getSupplierDocumentWarnings } from '@poly/utils';

import { isDisplaySupplierDocumentWarning } from './helpers.js';
import {
  SupplierDocumentWarningIconC,
  SupplierDocumentWarningTitle,
  SupplierDocumentTitleDivider,
  SupplierDocumentWarningMessage,
  SupplierDocumentPopoverContentWrapper,
} from './components.js';

// isActiveSupplier :: Supplier -> Boolean
const isActiveSupplier = R.compose(
  R.equals(SupplierStatuses.ACTIVE),
  R.toLower,
  R.prop('status'),
);

export function SupplierDocumentsWarning({
  supplier,
  loading,
  className,
  position = 'right',
}) {
  const supplierDocumentWarnings = getSupplierDocumentWarnings(supplier);

  return (
    !loading &&
    isDisplaySupplierDocumentWarning(supplier) &&
    isActiveSupplier(supplier) && (
      <Popover
        position={position}
        className={className}
        Icon={SupplierDocumentWarningIconC}
        content={
          <SupplierDocumentPopoverContentWrapper>
            <SupplierDocumentWarningTitle>
              Supplier Doc Alert
            </SupplierDocumentWarningTitle>
            <SupplierDocumentTitleDivider />
            {supplierDocumentWarnings.map((warning) => (
              <SupplierDocumentWarningMessage key={warning}>
                {warning}
              </SupplierDocumentWarningMessage>
            ))}
          </SupplierDocumentPopoverContentWrapper>
        }
      />
    )
  );
}

SupplierDocumentsWarning.propTypes = {
  loading: bool,
  supplier: shape({
    tax: shape({
      w9File: shape({
        url: string,
      }),
    }),
    documents: shape({
      wcFile: shape({
        url: string,
      }),
      wcExp: string,
      glFile: shape({
        url: string,
      }),
      glExp: string,
      scaFile: shape({
        url: string,
      }),
    }),
  }),
  className: oneOfType([string, object]),
  position: oneOf(['right', 'left']),
};
