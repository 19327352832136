import * as R from 'ramda';
import {
  ExcelExportCellType,
  XLS_CURRENCY_FORMAT,
  assocBy,
  calculateTotal,
  convertCentsToDollars,
  createExcelExportCell,
  formatTotal,
  mapIndexed,
} from '@poly/utils';

// preparePropertyInvoicesReport :: String -> PropertySpendReportResult -> PropertySpendReportResult
const preparePropertyInvoicesReport = (propName) => (input) =>
  R.compose(
    R.map(R.assoc('propertyName', R.prop('propertyName', input))),
    R.defaultTo([]),
    R.prop(propName),
  )(input);

// assocRuleInvoiceAmount :: [TmMarkupRule] -> (TmMarkupRule, Number) -> TmMarkupRule
const assocRuleInvoiceAmount = (rules) => (rule, index) =>
  R.compose(
    R.mergeRight(rule),
    R.objOf('invAmount'),
    R.join(' '),
    R.juxt([
      R.compose(
        formatTotal,
        R.ifElse(
          () => index === 0,
          R.always(0),
          R.compose(R.add(0.01), R.prop('upTo'), R.always(rules[index - 1])),
        ),
      ),
      R.always('-'),
      R.ifElse(R.isNil, R.always(' and up'), formatTotal),
    ]),
    R.prop('upTo'),
  )(rule);

// getMarkupRule :: (Number,  [TmMarkupRule]) -> TmMarkupRule
const getMarkupRule = (total, rules) =>
  R.compose(
    R.omit(['__typename']),
    R.find(R.compose(R.lte(total), R.propOr(Infinity, 'upTo'))),
    mapIndexed(assocRuleInvoiceAmount(rules)),
  )(rules);

// prepareMarkupSpendReport :: [PropertySpendReportResult] -> MarkupSpendReportData
const prepareMarkupSpendReport = R.compose(
  R.map(R.groupBy(R.prop('propertyName'))),
  R.groupBy(R.compose(R.toString, R.prop('tmMarkupRule'))),
  R.sortBy(R.path(['tmMarkupRule', 'percent'])),
  R.map(
    R.compose(
      R.pick([
        'clientInvoicesAmount',
        'markupAmount',
        'taxAmount',
        'tmMarkupRule',
        'projectNumber',
        'supplierInvoicesTotal',
        'propertyName',
      ]),
      assocBy(
        'tmMarkupRule',
        R.ifElse(
          R.prop('markupAmount'),
          R.converge(getMarkupRule, [
            R.compose(convertCentsToDollars, R.prop('supplierInvoicesTotal')),
            R.compose(R.defaultTo([]), R.prop('tmMarkupRules')),
          ]),
          R.always('No Markup'),
        ),
      ),
      assocBy(
        'supplierInvoicesTotal',
        R.compose(
          calculateTotal(R.propOr(0, 'total')),
          R.propOr([], 'invoices'),
        ),
      ),
    ),
  ),
  R.flatten,
  R.map(
    R.juxt([
      preparePropertyInvoicesReport('propertyInvoicesReport'),
      R.compose(
        R.map(preparePropertyInvoicesReport('propertyInvoicesReport')),
        R.defaultTo([]),
        R.prop('childProperties'),
      ),
    ]),
  ),
);

// getMarkupCategoryRows :: (String, String, String) -> [MarkupCategoryReport] -> [ExcelExportDataCell]
const getMarkupCategoryRows = (label, defaultValue, tmRuleProp) =>
  R.compose(
    R.zipWith(createExcelExportCell, [
      ...R.repeat(ExcelExportCellType.tableHeader, 2),
      ...R.repeat(ExcelExportCellType.default, 3),
    ]),
    R.unnest,
    R.juxt([
      R.always(label),
      R.compose(
        // R.unnest(R.is(String), R.toString),
        R.defaultTo(defaultValue),
        R.path([0, 0, 'tmMarkupRule', tmRuleProp]),
      ),
      R.always(R.repeat('', 3)),
    ]),
  );

// getMarkupSubTableHeader :: _ -> [ExcelExportDataCell]
const getMarkupSubTableHeader = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.tableHeader, 1),
    ...R.repeat(ExcelExportCellType.tableCurrencyHeader, 4),
  ]),
  R.always([
    'Row Labels',
    'Sum of Cost',
    'Sum of Sales Tax',
    'Sum of Markup',
    'Sum of Client Invoice Total',
  ]),
);

// getEmptyRow :: _ -> [ExcelExportDataCell]
const getEmptyRow = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 5),
  ]),
  R.always(R.repeat('', 5)),
);

// calculateTotalWithConvertToDollars :: String -> [MarkupPropertySpendReport] -> Number
const calculateTotalWithConvertToDollars = (propName) =>
  R.compose(convertCentsToDollars, calculateTotal(R.propOr(0, propName)));

// createExcelExportCellWithStyles :: Object -> String -> ExcelExportDataCell
const createExcelExportCellWithStyles = R.curry((style, value) => ({
  value,
  style,
}));

// getPropertyTotalsRow :: [MarkupPropertySpendReport] -> [ExcelExportDataCell]
const getPropertyTotalsRow = R.compose(
  R.zipWith(createExcelExportCellWithStyles, [
    ...R.repeat(
      {
        font: { bold: true },
      },
      1,
    ),
    ...R.repeat(
      {
        font: { bold: true },
        numFmt: XLS_CURRENCY_FORMAT,
        alignment: { horizontal: 'right' },
      },
      4,
    ),
  ]),
  R.juxt([
    R.path([0, 'propertyName']),
    calculateTotalWithConvertToDollars('supplierInvoicesTotal'),
    calculateTotalWithConvertToDollars('taxAmount'),
    calculateTotalWithConvertToDollars('markupAmount'),
    calculateTotalWithConvertToDollars('clientInvoicesAmount'),
  ]),
);

// propWithConvertToDollars :: String ->  [MarkupPropertySpendReport] -> Number
const propWithConvertToDollars = (propName) =>
  R.compose(convertCentsToDollars, R.propOr(0, propName));

// getMarkupSpendReportRows :: [MarkupPropertySpendReport] -> [ExcelExportDataCell]
const getMarkupSpendReportRows = R.map(
  R.compose(
    R.zipWith(createExcelExportCell, [
      ...R.repeat(ExcelExportCellType.default, 1),
      ...R.repeat(ExcelExportCellType.defaultCurrency, 4),
    ]),
    R.juxt([
      R.prop('projectNumber'),
      propWithConvertToDollars('supplierInvoicesTotal'),
      propWithConvertToDollars('taxAmount'),
      propWithConvertToDollars('markupAmount'),
      propWithConvertToDollars('clientInvoicesAmount'),
    ]),
  ),
);

// getTotalsByPropName :: [MarkupCategoryReport] -> Number
const getTotalsByPropName = (propName) =>
  R.compose(
    convertCentsToDollars,
    R.sum,
    R.map(calculateTotal(R.propOr(0, propName))),
  );

// getMarkupCategoryTotalRow :: [MarkupCategoryReport] -> [ExcelExportDataCell]
const getMarkupCategoryTotalRow = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.tableStringFooter, 1),
    ...R.repeat(ExcelExportCellType.tableCurrencyFooter, 4),
  ]),
  R.juxt([
    R.always('Grand Total'),
    getTotalsByPropName('supplierInvoicesTotal'),
    getTotalsByPropName('taxAmount'),
    getTotalsByPropName('markupAmount'),
    getTotalsByPropName('clientInvoicesAmount'),
  ]),
);

// getMarkupCategoryXlsConfig :: MarkupSpendReportData -> [[ExcelExportDataCell]]
const getMarkupCategoryXlsConfig = R.compose(
  R.splitEvery(5),
  R.flatten,
  R.map(
    R.compose(
      R.juxt([
        getMarkupCategoryRows('Category', 'No Markup', 'invAmount'),
        getMarkupCategoryRows('Markup', '0', 'percent'),
        getEmptyRow,
        getMarkupSubTableHeader,
        R.map(R.juxt([getPropertyTotalsRow, getMarkupSpendReportRows])),
        getMarkupCategoryTotalRow,
        getEmptyRow,
      ]),
      R.values,
    ),
  ),
  R.values,
  prepareMarkupSpendReport,
);

export const getMarkupSpendReportXlsConfigBase = ({ clientName, report }) => ({
  name: clientName || 'markup',
  columnWidths: [50, 20, 20, 20, 20, 25],
  rows: getMarkupCategoryXlsConfig(report),
});

// getMarkupSpendReportXlsConfig :: [String , [PropertySpendReportResult]] -> [SpendReportXlsConfig]
export const getMarkupSpendReportXlsConfig = R.map(
  R.compose(
    getMarkupSpendReportXlsConfigBase,
    R.applySpec({
      clientName: R.compose(
        R.replace('/', ''),
        R.join(' - '),
        R.juxt([R.prop('cardNumber'), R.prop('name')]),
        R.path([0, 'client']),
        R.last,
      ),
      report: R.last,
    }),
  ),
);
