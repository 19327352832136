import React, { useMemo } from 'react';
import * as R from 'ramda';
import {
  useHighlightMatchesBySearch,
  useReactiveQuery,
  useSortableTable,
} from '@poly/client-utils';
import { gql } from '@apollo/client';
import { useMapConfigToTableProps, useTableSearch } from '@poly/admin-ui';
import { assocBy } from '@poly/utils';

import { accountTypesTableConfig } from './accountTypesTableConfig.js';
import { AccountTypesTableS } from './components.js';
import { ReportPageTableWrapper } from '../../components/ReportPageTableWrapper.js';
import {
  accountTypeCategoryOptions,
  accountTypeNormalBalanceOptions,
} from './forms/AccountTypeFormSections.js';

const GET_ACCOUNT_TYPES_QUERY = gql`
  query GET_ACCOUNT_TYPES($input: AccountTypesInput) {
    accountTypes(input: $input) {
      _id
      name
      status
      category
      normal_balance
      system_type
    }
  }
`;

const ACCOUNT_TYPES_CHANGED_SUBSCRIPTION = gql`
  subscription ACCOUNT_TYPES_CHANGED_SUBSCRIPTION(
    $input: CollectionSearchParams
  ) {
    searchAccountTypeChanged(input: $input) {
      id
      type
    }
  }
`;

// getAccountTypesFieldUI :: (String, [{label: String, value: String}]) -> AccountType -> String
const getAccountTypesFieldUI = (fieldName, config) =>
  R.compose(
    R.propOr('', 'label'),
    R.find(R.__, config),
    R.propEq(R.__, 'value'),
    R.prop(fieldName),
  );

// prepareAccountTypesTableData :: AccountTypesQueryResult -> [AccountType]
const prepareAccountTypesTableData = R.compose(
  R.map(
    R.compose(
      assocBy(
        'categoryUI',
        getAccountTypesFieldUI('category', accountTypeCategoryOptions),
      ),
      assocBy(
        'normalBalanceUI',
        getAccountTypesFieldUI(
          'normal_balance',
          accountTypeNormalBalanceOptions,
        ),
      ),
      assocBy('accountType', R.identity),
    ),
  ),
  R.propOr([], 'accountTypes'),
);

export function AccountTypesTable() {
  const { searchText } = useTableSearch();

  const { sort, ...tableSortProps } = useSortableTable({
    column: 1,
    tableConfig: accountTypesTableConfig,
  });

  const queryOptions = useMemo(
    () => ({
      variables: { input: { searchText, sort } },
      fetchPolicy: 'network-only',
    }),
    [sort, searchText],
  );

  const { data, loading } = useReactiveQuery(
    GET_ACCOUNT_TYPES_QUERY,
    ACCOUNT_TYPES_CHANGED_SUBSCRIPTION,
    { queryOptions, subscriptionOptions: queryOptions },
  );

  const { rows, headers, columns, sortQueries } = useMapConfigToTableProps(
    prepareAccountTypesTableData,
    accountTypesTableConfig,
    data,
  );

  const { highlightedRows } = useHighlightMatchesBySearch(
    R.identity,
    [['name'], ['categoryUI'], ['normalBalanceUI']],
    rows,
  );

  return (
    <ReportPageTableWrapper loading={loading}>
      <AccountTypesTableS
        {...tableSortProps}
        isSortable
        headers={headers}
        rows={highlightedRows}
        columns={columns}
        sortQueries={sortQueries}
        isLoading={loading}
      />
    </ReportPageTableWrapper>
  );
}
