import * as R from 'ramda';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { DefaultBodyWrapper } from '@poly/admin-book';
import { TableCardWithPaginationContainer } from '@poly/admin-ui';
import { READ_DETAILED_CLIENT_SURVEYS_REPORT_PERMISSION } from '@poly/security';
import {
  useSortableTable,
  useHasUserAccessWithPermission,
} from '@poly/client-utils';

import { PageWithSearchHeader } from '../../components/PageWithSearchHeader.js';
import { DetailedClientSurveysReportTable } from './DetailedClientSurveysReportTable.js';
import { useDetailedClientSurveysReportQuery } from './useDetailedClientSurveysReportQuery.js';
import { DetailedClientSurveysReportPageHeader } from './DetailedClientSurveysReportPageHeader.js';
import { detailedClientSurveysReportTableConfig } from './table-config.js';

export function DetailedClientSurveysReportPage() {
  const [queryFilters, setQueryFilters] = useState({});

  const isUserWithPermission = useHasUserAccessWithPermission(
    READ_DETAILED_CLIENT_SURVEYS_REPORT_PERMISSION,
  );

  const { sort, ...tableSortProps } = useSortableTable({
    column: 1,
    tableConfig: detailedClientSurveysReportTableConfig,
  });

  const pagination = useSelector(R.prop('pagination'));

  const { data, loading } = useDetailedClientSurveysReportQuery({
    sort,
    queryFilters,
    isUserWithPermission,
  });

  return (
    <PageWithSearchHeader headerHeight="160px">
      <DetailedClientSurveysReportPageHeader
        data={data}
        sort={sort}
        loading={loading}
        setQueryFilters={setQueryFilters}
      />

      <DefaultBodyWrapper height="calc(100% - 160px)">
        <TableCardWithPaginationContainer>
          <DetailedClientSurveysReportTable
            data={data}
            pagination={pagination}
            {...tableSortProps}
          />
        </TableCardWithPaginationContainer>
      </DefaultBodyWrapper>
    </PageWithSearchHeader>
  );
}
