import React, { useState } from 'react';
import { format } from 'date-fns';
import { Loader } from '@poly/admin-book';
import { useHasUserAccessWithPermission } from '@poly/client-utils';
import { READ_OPEN_SUPPLIER_INVOICES_PERMISSION } from '@poly/security';

import {
  getTasksTotalItems,
  projectsTotalItems,
  masterProjectsTotalItems,
} from './totalItems.js';
import { UserDashboard } from './components/UserDashboard.js';
import { TotalInfoSections } from './components/TotalInfoSections.js';
import { useDashboardQueryWorker } from './useDashboardQueryWorker.js';
import {
  CompanyWorkflowContainer,
  MyWorkflowContainer,
  SectionTitleText,
  SubHeaderText,
  PageContainer,
} from './styles.js';
import { ChartDashboard } from './ChartDashboard/ChartDashboard.js';

export function HomePage() {
  const { data = {}, loading } = useDashboardQueryWorker();

  const [isChart, setIsChart] = useState(false);

  const handleSwitchDashboard = () => setIsChart(!isChart);

  const hasPermissionToReadOSIPage = useHasUserAccessWithPermission(
    READ_OPEN_SUPPLIER_INVOICES_PERMISSION,
  );

  if (loading) {
    return <Loader />;
  }

  const {
    dashboard: {
      globalMastersCounts,
      globalProjectsCounts,
      supplierInvoicesCounts,
    } = {},
  } = data;

  const date = format(new Date(), 'MMMM d, yyyy');

  const companyWorkflowSections = [
    {
      label: 'Global Projects',
      items: projectsTotalItems,
      counts: globalProjectsCounts,
    },
    {
      label: 'Masters',
      counts: globalMastersCounts,
      items: masterProjectsTotalItems,
    },
    {
      label: 'Tasks',
      items: getTasksTotalItems(hasPermissionToReadOSIPage),
      counts: supplierInvoicesCounts,
    },
  ];

  if (isChart) {
    return (
      <ChartDashboard
        dashboardData={data}
        isChart={isChart}
        handleSwitchDashboard={handleSwitchDashboard}
      />
    );
  }

  return (
    <PageContainer>
      <CompanyWorkflowContainer>
        <SectionTitleText>Workflow</SectionTitleText>
        <SubHeaderText margin="20px">{date}</SubHeaderText>
        <TotalInfoSections config={companyWorkflowSections} margin="30px" />
      </CompanyWorkflowContainer>
      <MyWorkflowContainer>
        <UserDashboard
          dashboardData={data}
          withChartDashboard
          handleSwitchDashboard={handleSwitchDashboard}
          isChart={isChart}
        />
      </MyWorkflowContainer>
    </PageContainer>
  );
}
