import { propertyEntityColumnWithSort } from '../../../modules/tables/columns/property.js';
import {
  projectIdColumn,
  ProjectStatusDot,
  projectClientColumn,
  projectStatusColumn,
  projectManagerColumn,
  projectEndDateColumn,
  projectStartDateColumn,
  projectDescriptionColumn,
} from '../../../modules/tables/columns/projects.js';

export const bulkEditProjectTableConfig = [
  ['', ProjectStatusDot],
  projectIdColumn,
  projectDescriptionColumn,
  propertyEntityColumnWithSort,
  projectClientColumn,
  projectManagerColumn,
  projectStatusColumn,
  projectStartDateColumn,
  projectEndDateColumn,
];
