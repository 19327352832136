import React, { useEffect } from 'react';
import * as R from 'ramda';
import { Select } from '@poly/admin-book';
import { InvoicesStatuses } from '@poly/constants';
import { bool, func, shape, string } from 'prop-types';
import {
  ServiceTypesSelect,
  SupplierSelect,
  ClientSelect,
} from '@poly/admin-ui';

import { ALL } from '../../modules/core/constants/general.js';
import { useSearchFilters } from '../../hooks/useSearchFilters.js';
import { SearchPageHeaderContainer } from '../../components/PageWithSearchHeader.js';
import { FiltersContainer } from '../PaySuppliersPage/PaySuppliersComponents.js';
import { FlexSpaceBetween } from '../../modules/forms/assignSupplierForm/styles.js';
import { FlexCenterAlign } from '../../components/FlexContainer.js';
import { ManagerSelect } from '../../components/AACManagerSelect.js';
import { PropertySelect } from '../../modules/pagesHeaders/ProjectSearchPageHeader/filter/components.js';
import { ProjectTypeSelect } from '../../components/ProjectTypeSelect.js';
import {
  SearchHeaderColumn,
  SearchHeaderButton,
} from '../../components/SearchHeaderColumn.js';
import { ProjectSelect } from '../../components/ProjectsSelect.js';
import { ALL_ALLOWED_INVOICE_STATUSES } from './helpers.js';

const supplierInvoiceStatusOptions = [
  { value: ALL, label: 'All' },
  { value: InvoicesStatuses.RECEIVED, label: 'Received' },
  { value: InvoicesStatuses.REQUESTED, label: 'Requested' },
  { value: InvoicesStatuses.MANUAL_FOLLOW_UP, label: 'Manual Follow Up' },
];

const openSupplierInvoicesPageHeaderConfig = [
  { name: 'projectId', defaultValue: '' },
  { name: 'serviceTypeId', defaultValue: '' },
  { name: 'managerId', defaultValue: '' },
  {
    name: 'clientId',
    defaultValue: '',
    subscribers: [['propertyId', R.always('')]],
  },
  { name: 'projectType', defaultValue: ALL },
  { name: 'propertyId', defaultValue: '' },
  { name: 'invoiceStatus', defaultValue: ALL },
  { name: 'supplierId' },
];

// prepareStatusValue :: String -> String
const prepareStatusValue = R.unless(
  R.includes(R.__, [...ALL_ALLOWED_INVOICE_STATUSES, ALL]),
  R.always(ALL),
);

export function OSIPageHeader({
  filters,
  setFilters,
  loading,
  refetching,
  refetchTableData,
  hasPermissionToReadOSIPage,
}) {
  const { searchFilters, onReset, handlers } = useSearchFilters(
    openSupplierInvoicesPageHeaderConfig,
  );

  const onResetSearch = () => {
    onReset();
    setFilters({});
  };

  const onSearch = async () => {
    if (R.equals(filters, searchFilters)) {
      await refetchTableData();
    } else {
      setFilters(searchFilters);
    }
  };

  useEffect(() => {
    setFilters(searchFilters);
  }, []);

  const invoiceStatusSelectProps = {
    options: supplierInvoiceStatusOptions,
    value: prepareStatusValue(searchFilters.invoiceStatus),
    onChange: handlers.invoiceStatus,
    width: '100%',
    name: 'invoiceStatus',
    required: true,
  };

  return (
    <SearchPageHeaderContainer title="Open Supplier Invoices">
      <FiltersContainer>
        <FlexSpaceBetween>
          <SearchHeaderColumn title="Client" labelForId="clientId">
            <ClientSelect
              name="clientId"
              width="100%"
              onChange={handlers.clientId}
              value={searchFilters.clientId}
              includeAllOption={false}
              withoutSkip
              changeOnBlur
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn title="Service Type" labelForId="serviceTypeId">
            <ServiceTypesSelect
              name="serviceTypeId"
              width="100%"
              onChange={handlers.serviceTypeId}
              value={searchFilters.serviceTypeId}
              changeOnBlur
              isClearable
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn title="Assigned CSR" labelForId="managerId">
            <ManagerSelect
              name="managerId"
              width="100%"
              onChange={handlers.managerId}
              value={searchFilters.managerId}
              changeOnBlur
            />
          </SearchHeaderColumn>
        </FlexSpaceBetween>
        <FlexSpaceBetween>
          <SearchHeaderColumn title="Property" labelForId="propertyId">
            <PropertySelect
              name="propertyId"
              width="100%"
              relatedFilters={{ clientId: searchFilters.clientId }}
              onChange={handlers.propertyId}
              value={searchFilters.propertyId}
              disabled={!searchFilters.clientId}
              changeOnBlur
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn title="Project Number" labelForId="projectId">
            <ProjectSelect
              name="projectId"
              value={searchFilters.projectId}
              onChange={handlers.projectId}
              placeholder="Start Typing Project Number"
              withoutReportOnly={false}
              width="100%"
              changeOnBlur
              isClearable
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn title="Project Type" labelForId="projectType">
            <ProjectTypeSelect
              name="projectType"
              width="100%"
              onChange={handlers.projectType}
              value={searchFilters.projectType}
              changeOnBlur
              required
            />
          </SearchHeaderColumn>
        </FlexSpaceBetween>
        <FlexSpaceBetween>
          <SearchHeaderColumn title="Status" labelForId="invoiceStatus">
            <Select {...invoiceStatusSelectProps} />
          </SearchHeaderColumn>
          <SearchHeaderColumn title="Supplier" labelForId="supplierId">
            <SupplierSelect
              name="supplierId"
              value={searchFilters.supplierId}
              onChange={handlers.supplierId}
              query={null}
              isClearable
            />
          </SearchHeaderColumn>
          <SearchHeaderColumn padding="0 0 0 135px">
            <FlexCenterAlign>
              <SearchHeaderButton
                size="small"
                styleType="basicPrimary"
                onClick={onResetSearch}
              >
                Reset
              </SearchHeaderButton>
              <SearchHeaderButton
                size="small"
                onClick={onSearch}
                loader={loading || refetching}
                disabled={!hasPermissionToReadOSIPage}
              >
                Search
              </SearchHeaderButton>
            </FlexCenterAlign>
          </SearchHeaderColumn>
        </FlexSpaceBetween>
      </FiltersContainer>
    </SearchPageHeaderContainer>
  );
}

OSIPageHeader.propTypes = {
  setFilters: func,
  refetchTableData: func,
  loading: bool,
  refetching: bool,
  hasPermissionToReadOSIPage: bool,
  filters: shape({
    clientId: string,
    serviceTypeId: string,
    managerId: string,
    propertyId: string,
    projectId: string,
    projectType: string,
  }),
};
