import React from 'react';
import { useDispatch } from 'react-redux';
import { useHasUserAccessWithPermission } from '@poly/client-utils';
import { CREATE_PROBLEM_CODE_PERMISSION } from '@poly/security';
import { TextButton } from '@poly/admin-book';
import { openModal } from '@poly/admin-ui';

import { modalNames } from '../../modules/modals/constants.js';

export function AddProblemCodeButton() {
  const dispatch = useDispatch();

  const hasPermission = useHasUserAccessWithPermission(
    CREATE_PROBLEM_CODE_PERMISSION,
  );

  const onClick = () => {
    dispatch(openModal({ name: modalNames.ADD_PROBLEM_CODE, payload: {} }));
  };

  return hasPermission ? (
    <TextButton onClick={onClick}>Add New</TextButton>
  ) : null;
}

AddProblemCodeButton.displayName = 'AddProblemCodeButton';
