import { ProblemCodeStatuses } from '@poly/constants';
import { defaultTheme } from '@poly/admin-book';

const {
  colors: {
    statuses: { active, inactive },
  },
} = defaultTheme;

export const problemCodeStatusColors = {
  [ProblemCodeStatuses.ACTIVE]: active,
  [ProblemCodeStatuses.INACTIVE]: inactive,
};

export const addProblemCodeFormId = 'addProblemCodeFormId';
export const editProblemCodeFormId = 'editProblemCodeFormId';
