import * as R from 'ramda';
import { gql } from '@apollo/client';
import { useReactiveQuery } from '@poly/client-utils';
import { InvoicesStatuses } from '@poly/constants';
import { convertCentsToDollars } from '@poly/utils/src/converters.js';
import { formatTotal } from '@poly/utils/src/formatters.js';

export const PROJECT_INVOICES_BY_SEARCH = gql`
  query PROJECT_INVOICES_BY_SEARCH($searchInput: CollectionSearchParams!) {
    searchInvoices(input: $searchInput) {
      hits {
        _id
        total
      }
    }
  }
`;

export const PROJECT_INVOICES_SEARCH_SUB = gql`
  subscription PROJECT_INVOICES_SEARCH_SUB($input: CollectionSearchParams!) {
    searchInvoiceChanged(input: $input) {
      id
      type
    }
  }
`;

// calculateInvoicesTotal :: SearchInvoicesResult -> Number
const calculateInvoicesTotal = (data, invTotal) =>
  R.compose(
    R.add(R.defaultTo(0, invTotal)),
    R.sum,
    R.map(R.compose(R.defaultTo(0), R.prop('total'))),
    R.pathOr([], ['searchInvoices', 'hits']),
  )(data);

export const useProjectSupplierNTEWarning = ({
  projectId,
  supplierId,
  supplierInvoiceId,
  supplierNTE,
  value,
  skipInvoicesQuery,
  supplierInvoicesTotal,
}) => {
  const queryOptions = {
    variables: {
      searchInput: {
        query: {
          bool: {
            must: [
              { term: { documentId: projectId } },
              { term: { supplierId } },
              {
                terms: {
                  status: [
                    InvoicesStatuses.PENDING,
                    InvoicesStatuses.PARTIALLY_PAID,
                    InvoicesStatuses.PAID,
                  ],
                },
              },
            ],
            ...(supplierInvoiceId
              ? { must_not: { term: { _id: supplierInvoiceId } } }
              : {}),
          },
        },
      },
    },
    skip: skipInvoicesQuery || !supplierNTE || !projectId || !supplierId,
    fetchPolicy: 'network-only',
  };

  const { data } = useReactiveQuery(
    PROJECT_INVOICES_BY_SEARCH,
    PROJECT_INVOICES_SEARCH_SUB,
    { queryOptions, subscriptionOptions: queryOptions },
  );

  const totalInvoices = skipInvoicesQuery
    ? R.add(supplierInvoicesTotal, value)
    : calculateInvoicesTotal(data, value);

  return supplierNTE && supplierNTE < totalInvoices
    ? `Total exceeds Supplier NTE ${formatTotal(
        convertCentsToDollars(supplierNTE),
      )}`
    : null;
};
