import * as R from 'ramda';
import React from 'react';
import { Select } from '@poly/admin-book';
import { arrayOf, func, number, string } from 'prop-types';

import { FieldSelectOptionsByObjectTypeMap } from '../constants.js';

// getOptionsByObjectType :: [String] -> String -> [Option]
const getOptionsByObjectType = (alreadySelectedFields) =>
  R.compose(
    R.reject(R.propSatisfies(R.includes(R.__, alreadySelectedFields), 'value')),
    R.propOr([], R.__, FieldSelectOptionsByObjectTypeMap),
  );

// getPreparedAlreadySelectedFields :: String -> [String] -> [String]
const getPreparedAlreadySelectedFields = (currentValue) =>
  R.reject(R.equals(currentValue));

export function FieldSelect({
  index,
  value,
  onChange,
  objectType,
  changeFieldValue,
  alreadySelectedFields,
  ...props
}) {
  const preparedAlreadySelectedFields = getPreparedAlreadySelectedFields(value)(
    alreadySelectedFields,
  );

  const selectProps = {
    value,
    options: getOptionsByObjectType(preparedAlreadySelectedFields)(objectType),
    placeholder: 'Select field',
    required: true,
    onChange: (newValue) => {
      if (changeFieldValue) {
        changeFieldValue(`rules.${index}.fieldOperation`, null);
        changeFieldValue(`rules.${index}.fieldValue`, null);
      }

      onChange(newValue);
    },
    ...props,
  };

  return <Select {...selectProps} />;
}

FieldSelect.propTypes = {
  value: string,
  index: number,
  onChange: func,
  objectType: string,
  changeFieldValue: func,
  alreadySelectedFields: arrayOf(string),
};
