import React from 'react';
import * as R from 'ramda';
import { useMutation } from '@apollo/client';
import { string, shape, func, arrayOf } from 'prop-types';
import { FormCreator } from '@poly/admin-book';
import {
  commonSidebarFormSectionLayout,
  useOnSubmitSetStopSubmitting,
  commonSidebarFormFieldLayout,
  commonSidebarFormLayout,
  useNotificationState,
  entities,
} from '@poly/admin-ui';

import { ALL } from '../../../modules/core/constants/general.js';
import { MultiAssetsSelect } from '../../components/MultiAssetsSelect/MultiAssetsSelect.js';
import { assetsUpdateMutationByEntityMap } from '../../components/commonTabs/useUnlinkAssetMutation.js';

// checkIfAllOptionSelected :: [Option] -> Boolean
const checkIfAllOptionSelected = R.compose(
  R.includes(ALL),
  R.map(R.prop('value')),
  R.defaultTo([]),
);

const getAttachAssetSections = (entity) => [
  {
    order: 1,
    layout: { margin: '24px 0 0 0' },
    fields: [
      {
        order: 1,
        label: 'Assets',
        layout: { row: 1, with: '100%' },
        field: {
          name: 'assetIds',
          withFormData: true,
          withChangeFieldValue: true,
          additionalProps: { entity },
          Component: MultiAssetsSelect,
        },
        validators: [
          [R.complement(R.isEmpty), 'At least one asset should be added'],
        ],
      },
    ],
  },
];

// prepareAttachedAssetsForMutation :: (ID, String, [ID]) -> FormData -> MutationVariables
const prepareAttachedAssetsForMutation = (id, name, assetIds) =>
  R.compose(
    R.mergeLeft({ id }),
    R.when(() => name === entities.PROJECT, R.objOf('update')),
    R.objOf('assetIds'),
    R.concat(assetIds),
    R.ifElse(
      R.compose(checkIfAllOptionSelected, R.prop('assetIds')),
      R.prop('allAssetsIds'),
      R.compose(R.map(R.prop('value')), R.prop('assetIds')),
    ),
  );

export function AttachAssetForm({ assetIds, formId, entity, onCancel }) {
  const { showSuccessNotification } = useNotificationState();

  const { _id, name, propertyId, subPropertiesIds } = entity;
  const MUTATION = assetsUpdateMutationByEntityMap[name];

  const [attachAssetsMutation] = useMutation(MUTATION);

  const onSubmitHandler = async (formData) => {
    const variables = prepareAttachedAssetsForMutation(
      _id,
      name,
      assetIds,
    )(formData);

    await attachAssetsMutation({ variables });

    showSuccessNotification('Assets were successfully attached');
    onCancel();
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(formId, onSubmitHandler);

  const initialValues = {
    propertyId,
    assetIds: [],
    allAssetsIds: [],
    subPropertiesIds,
    attachedAssets: assetIds,
  };

  return (
    <FormCreator
      id={formId}
      onSubmit={onSubmit}
      initialValues={initialValues}
      sections={getAttachAssetSections(entity)}
      layout={commonSidebarFormLayout}
      fieldLayout={{ ...commonSidebarFormFieldLayout, width: '100%' }}
      sectionLayout={{
        ...commonSidebarFormSectionLayout,
        padding: '0 0 24px 0',
      }}
    />
  );
}

AttachAssetForm.propTypes = {
  formId: string.isRequired,
  onCancel: func.isRequired,
  assetIds: arrayOf(string).isRequired,
  entity: shape({
    payloadName: string,
    _id: string.isRequired,
    name: string.isRequired,
    propertyId: string,
  }).isRequired,
};
