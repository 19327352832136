import * as R from 'ramda';
import { string } from 'prop-types';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Loader, Table, dateColumnStyles, LinkButton } from '@poly/admin-book';
import {
  useInfiniteScrollQueryWithSubscription,
  useHighlightMatchesBySearch,
  useOutSidebarContext,
} from '@poly/client-utils';
import {
  useMapConfigToTableProps,
  USER_SEARCH_CHANGED,
  BaseSidebarLayout,
  useTableSorting,
  adminUserQuery,
  SidebarWidth,
} from '@poly/admin-ui';

import {
  pipColumnStyles,
  phoneColumnStyles,
} from '../../modules/tables/tablesStyles.js';
import { ReassignUserForm } from './ReassignUserForm.js';
import { SidebarFormLayout } from '../../sidebars/components/SidebarFormLayout.js';
import { staffUsersTableConfig } from '../../modules/tables/usersTable/UsersTable.js';
import { STAFF_USERS_BY_SEARCH_TABLE } from '../../modules/core/hooks/users/queries.js';

const reassignUserFormId = 'reassignUserFormId';

const ReassignUserTableWrapperS = styled.div`
  height: calc(100% - 85px);
  padding: 0 50px;
`;

const ReassignUsersTableS = styled(Table)`
  ${pipColumnStyles(1)};

  th:nth-child(2),
  td:nth-child(2),
  th:nth-child(3),
  td:nth-child(3) {
    width: 170px;
  }

  ${phoneColumnStyles(4)}

  th:nth-child(5),
  td:nth-child(5) {
    width: 60px;
  }

  th:nth-child(6),
  td:nth-child(6) {
    width: 300px;
  }

  ${dateColumnStyles(7)}

  td:nth-child(8) {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  th:nth-child(9),
  td:nth-child(9) {
    width: 70px;
  }
`;

const ReassignUserSidebarLayoutS = styled(BaseSidebarLayout)`
  background-color: #fafafa;
`;

const ReassignUserSidebarFromS = styled(SidebarFormLayout)`
  justify-content: flex-start;

  > div {
    background-color: #fafafa;
  }
`;

function ReassignUserSidebarFrom({ formId, ...props }) {
  return (
    <ReassignUserSidebarFromS
      formId={formId}
      title="Reassign User"
      submitButtonCaption="Save"
    >
      <ReassignUserForm {...props} formId={formId} />
    </ReassignUserSidebarFromS>
  );
}

ReassignUserSidebarFrom.propTypes = { formId: string.isRequired };

function ReassignUserButton({ _id }) {
  const { openOutSidebar, closeOutSidebar } = useOutSidebarContext();

  const onCancel = () => closeOutSidebar(reassignUserFormId);

  const onReassignUser = () =>
    openOutSidebar({
      alwaysFirst: true,
      width: SidebarWidth,
      id: reassignUserFormId,
      Layout: ReassignUserSidebarLayoutS,
      content: (
        <ReassignUserSidebarFrom
          userId={_id}
          onCancel={onCancel}
          formId={reassignUserFormId}
        />
      ),
    });

  return <LinkButton onClick={onReassignUser}>Reassign</LinkButton>;
}

ReassignUserButton.propTypes = { _id: string.isRequired };

const reassignUserTableConfig = [
  ...staffUsersTableConfig,
  [' ', ReassignUserButton],
];

// getReassignUsersList :: SearchUsersQueryResult :: -> [User]
const getReassignUsersList = R.pathOr([], ['searchUsers', 'hits']);

export function ReassignUserTable() {
  const searchTerm = useSelector((state) => state.searchText);

  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig: reassignUserTableConfig,
    column: 2,
  });

  const searchInput = useMemo(
    () => ({
      sort,
      searchTerm,
      query: { bool: { filter: adminUserQuery } },
    }),
    [searchTerm, sort],
  );

  const { data, loading, tableProps } = useInfiniteScrollQueryWithSubscription(
    STAFF_USERS_BY_SEARCH_TABLE,
    searchInput,
    {
      pageSize: 50,
      inputName: 'searchInput',
      endpointName: 'searchUsers',
    },
    USER_SEARCH_CHANGED,
    { searchInput },
  );

  const { rows, ...restTableProps } = useMapConfigToTableProps(
    getReassignUsersList,
    reassignUserTableConfig,
    data,
  );

  const { highlightedRows } = useHighlightMatchesBySearch(
    R.identity,
    [['fullName'], ['address', 'formatted_address'], ['status'], ['email']],
    rows,
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <ReassignUserTableWrapperS>
      <ReassignUsersTableS
        {...tableSortingProps}
        {...tableProps}
        {...restTableProps}
        rows={highlightedRows}
      />
    </ReassignUserTableWrapperS>
  );
}
