import * as R from 'ramda';
import { assocBy } from '@poly/utils';

// prepareDetailedClientSurveysReportToDisplay :: { searchProjects: { hits: [Project] } } -> [PreparedReportRow]
// PreparedReportRow = {
//    _id: String
//    submissionDate: DateTime
//    client: Client
//    projectId: String
//    question: String
//    answer: String
// }
export const prepareDetailedClientSurveysReportToDisplay = R.compose(
  R.map(
    assocBy(
      '_id',
      R.converge(R.concat, [R.prop('projectId'), R.prop('question')]),
    ),
  ),
  R.pathOr([], ['detailedClientSurveysReport', 'hits']),
);
