import React from 'react';
import { PageHeaderContainer, MainHeader } from '@poly/admin-book';
import {
  READ_DETAILED_CLIENT_SURVEYS_REPORT_PERMISSION,
  READ_CLIENT_SURVEYS_SUMMARY_REPORT_PERMISSION,
  READ_REQUESTED_SUPPLIER_DOCUMENT_PERMISSION,
  READ_SPEND_REPORT_PERMISSION,
  READ_QUOTE_DISCREPANCIES_PERMISSION,
} from '@poly/security';

import { routesNames } from '../routes/index.js';
import { AdminSettingsGrid } from '../components/AdminSettingsGrid.js';

const reportPageItems = [
  {
    title: 'Contracts',
    column: 1,
    links: [
      {
        name: 'Contract Expirations',
      },
      {
        name: 'Master Recurring Projects',
      },
    ],
  },
  {
    title: 'Suppliers',
    column: 1,
    links: [
      { name: 'New Supplier Applications' },
      {
        name: 'Supplier Document Exceptions',
        link: routesNames.SUPPLIER_DOCUMENT_EXCEPTIONS,
      },
      {
        name: 'Supplier Documents',
        link: routesNames.NEW_SUPPLIER_DOCUMENTS_REVIEW,
        permissions: [READ_REQUESTED_SUPPLIER_DOCUMENT_PERMISSION],
      },
    ],
  },
  {
    title: 'Financial',
    column: 1,
    links: [
      {
        name: 'AR Aging Report',
        link: routesNames.AR_AGING_REPORT,
      },
      {
        name: 'Confirm Satisfaction Report',
        link: routesNames.CONFIRM_SATISFACTION_REPORT,
      },
    ],
  },
  {
    title: 'Projects',
    column: 2,
    links: [
      {
        name: 'Recent Projects Report',
        link: routesNames.RECENT_PROJECTS_REPORT,
      },
      {
        name: 'Work In Progress QC Report',
        link: routesNames.WORK_IN_PROGRESS_QC_REPORT,
      },

      {
        name: 'Open Work Orders Report',
        link: routesNames.OPEN_WORK_ORDERS_REPORT,
      },
    ],
  },
  {
    title: 'Surveys',
    column: 2,
    links: [
      {
        name: 'Client Surveys Summary Report',
        link: routesNames.CLIENT_SURVEYS_SUMMARY_REPORT,
        permissions: [READ_CLIENT_SURVEYS_SUMMARY_REPORT_PERMISSION],
      },
      {
        name: 'Detailed Client Surveys Report',
        link: routesNames.DETAILED_CLIENT_SURVEYS_REPORT,
        permissions: [READ_DETAILED_CLIENT_SURVEYS_REPORT_PERMISSION],
      },
    ],
  },
  {
    title: 'Spend',
    column: 3,
    links: [
      {
        name: 'Spend Report',
        link: routesNames.SPEND_REPORTS,
        permissions: [READ_SPEND_REPORT_PERMISSION],
      },
      {
        name: 'Quote Discrepancies',
        link: routesNames.QUOTE_DISCREPANCIES,
        permissions: [READ_QUOTE_DISCREPANCIES_PERMISSION],
      },
      {
        name: 'Suppliers Quote Discrepancies',
        link: routesNames.SUPPLIERS_QUOTE_DISCREPANCIES,
        permissions: [READ_QUOTE_DISCREPANCIES_PERMISSION],
      },
    ],
  },
];

export function ReportsPage() {
  return (
    <>
      <PageHeaderContainer>
        <MainHeader>Report Directory</MainHeader>
      </PageHeaderContainer>
      <AdminSettingsGrid items={reportPageItems} />
    </>
  );
}
