export const BulkEditingFilterSidebarId = 'BulkEditingFilterSidebarId';
export const BulkEditingFilterFormId = 'BulkEditingFilterFormId';

export const BulkEditObjectType = {
  PROJECTS: 'projectsBulkEditObjectType',
};

export const FieldOperation = {
  EQ: 'eq',
  IN: 'in',
  NIN: 'nin',
  LT: 'lt',
  GT: 'gt',
};

export const BulkEditProjectFieldValues = {
  STATUS: 'status',
  ACCOUNTING_STATUS: 'accountingStatus',
  PROJECT_TYPE: 'projectType',
  COST_TYPE: 'type',
  CLIENT_NAME: 'clientId',
  AA_MANAGER: 'managerId',
  PROPERTY_NAME: 'propertyId',
  SERVICE_TYPE: 'serviceTypeId',
  KEYWORD: 'keyword',
  STATE: 'property.address.address_parts.administrative_area_level_1',
  CITY: 'property.address.address_parts.locality',
  START: 'startDate',
  END: 'endDate',
};

export const FieldSelectOptionsByObjectTypeMap = {
  [BulkEditObjectType.PROJECTS]: [
    {
      label: 'Status',
      value: BulkEditProjectFieldValues.STATUS,
    },
    {
      label: 'Accounting',
      value: BulkEditProjectFieldValues.ACCOUNTING_STATUS,
    },
    {
      label: 'Project Type',
      value: BulkEditProjectFieldValues.PROJECT_TYPE,
    },
    {
      label: 'Cost Type',
      value: BulkEditProjectFieldValues.COST_TYPE,
    },
    {
      label: 'Client Name',
      value: BulkEditProjectFieldValues.CLIENT_NAME,
    },
    {
      label: 'AA Manager',
      value: BulkEditProjectFieldValues.AA_MANAGER,
    },
    {
      label: 'Property Name',
      value: BulkEditProjectFieldValues.PROPERTY_NAME,
    },
    {
      label: 'Service Type',
      value: BulkEditProjectFieldValues.SERVICE_TYPE,
    },
    {
      label: 'Keyword',
      value: BulkEditProjectFieldValues.KEYWORD,
    },
    {
      label: 'State',
      value: BulkEditProjectFieldValues.STATE,
    },
    {
      label: 'City',
      value: BulkEditProjectFieldValues.CITY,
    },
    {
      label: 'Start',
      value: BulkEditProjectFieldValues.START,
    },
    {
      label: 'End',
      value: BulkEditProjectFieldValues.END,
    },
  ],
};

export const ProjectTypeSelectOptionValues = {
  PROJECT: 'projectProjectTypeSelectOptionValue',
  CHILD_RECURRING: 'childRecurringProjectTypeSelectOptionValue',
  MASTER_RECURRING: 'masterRecurringProjectTypeSelectOptionValue',
  CHILD_PM: 'childPMProjectTypeSelectOptionValue',
  PM: 'pmProjectTypeSelectOptionValue',
};
