import React from 'react';
import * as R from 'ramda';
import { string } from 'prop-types';
import styled from 'styled-components';
import { formatDateTime, isNilOrEmpty } from '@poly/utils';
import { Loader, Table, Dot } from '@poly/admin-book';
import { commonSortQuery } from '@poly/client-utils';
import { NOTHING_UI_STRING } from '@poly/constants';
import {
  useSetInitialPaginationOnMount,
  useMapConfigToTableProps,
  useSetItemsCount,
  useTableSorting,
} from '@poly/admin-ui';

import { problemCodeStatusColors } from './constants.js';
import { EditProblemCodeButton } from './EditProblemCodeButton.js';
import { pipColumnStyles } from '../../modules/tables/tablesStyles.js';
import { useProblemCodesQuery } from './useProblemCodesQuery.js';

const ProblemCodeTableS = styled(Table)`
  ${pipColumnStyles(1)};

  th:nth-child(2),
  td:nth-child(2),
  th:nth-child(5),
  td:nth-child(5) {
    width: 200px;
  }

  th:nth-child(4),
  td:nth-child(4) {
    width: 300px;
  }

  th:nth-child(6),
  td:nth-child(6) {
    width: 50px;
    text-align: center;
  }
`;

function ProblemCodeStatus({ status }) {
  return <Dot color={problemCodeStatusColors[status]} />;
}

ProblemCodeStatus.propTypes = { status: string.isRequired };

// getLastModifiedDate :: ProblemCode -> String
const getLastModifiedDate = R.compose(
  R.ifElse(isNilOrEmpty, R.always(NOTHING_UI_STRING), formatDateTime),
  R.prop('updatedAt'),
);

// getCreatedProblemCodeInfo :: ProblemCode -> String
const getCreatedProblemCodeInfo = R.compose(
  R.join(', '),
  R.juxt([
    R.path(['createdBy', 'fullName']),
    R.compose(formatDateTime, R.prop('createdAt')),
  ]),
);

const problemCodeTableConfig = [
  ['', ProblemCodeStatus],
  ['Name', R.prop('name'), commonSortQuery(['name'])],
  ['Description', R.propOr(NOTHING_UI_STRING, 'description')],
  ['Created', getCreatedProblemCodeInfo],
  ['Last Modified', getLastModifiedDate],
  [' ', EditProblemCodeButton],
];

export function ProblemCodeLibraryTable({ status }) {
  useSetInitialPaginationOnMount();

  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig: problemCodeTableConfig,
    column: 2,
  });

  const { result, count, loading } = useProblemCodesQuery(status, sort);

  useSetItemsCount(R.identity, count);

  const tableProps = useMapConfigToTableProps(
    R.identity,
    problemCodeTableConfig,
    result,
  );

  if (loading) return <Loader />;

  return <ProblemCodeTableS {...tableSortingProps} {...tableProps} />;
}

ProblemCodeLibraryTable.propTypes = { status: string.isRequired };
