import React from 'react';
import * as R from 'ramda';
import { string, func, bool } from 'prop-types';
import { Select } from '@poly/admin-book';
import {
  entityToOptionByLabelPath,
  getAppendDefaultEntity,
  useMapPropsForDropdown,
  useQueryWithSearch,
} from '@poly/client-utils';

import { ALL } from '../../../../constants/general.js';
import { USERS_BY_SEARCH } from '../../../../hocs/users/queries.js';
import { USER_SEARCH_CHANGED } from '../../../../hocs/users/subscriptions.js';
import { useDefaultUser } from '../../../../hooks/users/index.js';

// mapUsersToDropdownOptions :: [Users] -> [Option]
const mapUsersToDropdownOptions = ({ mapEntityFunc, ...props }) =>
  R.pipe(R.pathOr([], ['searchUsers', 'hits']), R.map(mapEntityFunc))(props);

// mapOptions :: Object -> [Option]
const mapOptions = R.ifElse(
  R.prop('staticOptions'),
  R.converge(R.concat, [R.prop('staticOptions'), mapUsersToDropdownOptions]),
  mapUsersToDropdownOptions,
);

export function UserSelect({
  isClearable = true,
  includeAllOption = false,
  name = 'UserSelectDropdown',
  placeholder = 'Start typing users',
  mapEntityFunc = entityToOptionByLabelPath(['fullName']),
  ...restProps
}) {
  const { value, onChange, error: errorFromProps } = restProps;

  const { data } = useDefaultUser(value);

  const { result, error, onSearchChange } = useQueryWithSearch({
    gqlSearchQuery: USERS_BY_SEARCH,
    gqlSearchChangedQuery: USER_SEARCH_CHANGED,
    error: errorFromProps,
    propsOfComponent: {
      name,
      isClearable,
      placeholder,
      mapEntityFunc,
      ...restProps,
    },
    ...restProps,
  });

  const updatedEntityPath = getAppendDefaultEntity({
    entityPath: ['user'],
    allEntitiesPath: ['searchUsers'],
    props: {
      user: data?.user,
      searchUsers: result?.searchUsers,
    },
  });

  const { options, onChange: onSelectChange } = useMapPropsForDropdown({
    mapper: mapOptions,
    onChange,
    restProps: {
      ...result,
      ...updatedEntityPath,
    },
  });

  const preparedOptions = includeAllOption
    ? [{ value: ALL, label: 'All' }, ...options]
    : options;

  return (
    <Select
      {...data?.user}
      {...result}
      error={error}
      options={preparedOptions}
      onChange={onSelectChange}
      onInputChange={onSearchChange}
      {...restProps}
    />
  );
}

UserSelect.displayName = 'UserSelect';

UserSelect.propTypes = {
  name: string,
  value: string,
  isClearable: bool,
  placeholder: string,
  mapEntityFunc: func,
  includeAllOption: bool,
  onChange: func.isRequired,
};
