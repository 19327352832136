import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import { number, shape } from 'prop-types';
import { assocBy } from '@poly/utils';

import { myTasksConfig } from '../../totalItems.js';
import { getDashboardCounts } from '../../components/UserDashboard.js';

import { CustomBarChart } from '../Charts/CustomBarChart.js';

const MyTasksChartWrapper = styled.div`
  height: 156px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
`;

// getMyTasksMaxValue :: OwnTaskCounts -> Number
const getMyTasksMaxValue = R.compose(
  (arr) => Math.max(...arr),
  R.juxt([
    R.propOr(0, 'dueToday'),
    R.propOr(0, 'dueTomorrow'),
    R.propOr(0, 'overdue'),
    R.propOr(0, 'readyToClose'),
  ]),
);

// prepareTasksChartConfig :: OwnTaskCounts -> BarChartConfig
const prepareTasksChartConfig = (counts) =>
  R.map(
    assocBy('value', R.compose(R.propOr(0, R.__, counts), R.prop('fieldName'))),
    myTasksConfig,
  );

export function MyTasksChart({ dashboardData }) {
  const { ownTaskCounts } = getDashboardCounts(dashboardData);

  const maxValue = getMyTasksMaxValue(ownTaskCounts);

  const myTaskChartConfig = prepareTasksChartConfig(ownTaskCounts);

  return (
    <MyTasksChartWrapper>
      <CustomBarChart maxValue={maxValue} configs={myTaskChartConfig} />
    </MyTasksChartWrapper>
  );
}

MyTasksChart.propTypes = {
  dashboardData: shape({
    dashboard: shape({
      ownTaskCounts: shape({
        dueToday: number,
        dueTomorrow: number,
        overdue: number,
        readyToClose: number,
      }),
    }),
  }),
};
