import * as R from 'ramda';
import { createExcelExportCell } from '@poly/utils/src/excel-export/helpers.js';
import { ExcelExportCellType } from '@poly/utils/src/excel-export/constants.js';

export const getServiceTypesExportFileName = (tab, ext) =>
  `service_types_${tab}_export.${ext}`;

// getServiceTypesRowExcelConfig :: ServiceType -> [ExcelExportDataCell]
const getServiceTypesRowExcelConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 2),
  ]),
  R.juxt([R.propOr('', 'name'), R.propOr('', 'description')]),
);

// getServiceTypesExcelConfig :: ([ServiceType], String) -> ExcelConfig
export const getServiceTypesExcelConfig = (serviceTypes, tab) => ({
  exportFileName: getServiceTypesExportFileName(tab, 'xlsx'),
  columnWidths: [25, 45, 25, 15],
  rows: [
    R.zipWith(
      createExcelExportCell,
      [...R.repeat(ExcelExportCellType.tableHeader, 2)],
      ['Service Type', 'Description'],
    ),
    ...R.map(getServiceTypesRowExcelConfig, serviceTypes),
  ],
});
