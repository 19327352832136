import React, { useState } from 'react';
import styled from 'styled-components';
import { EXPORT_XLS_CAPTION } from '@poly/constants';
import { performExcelExport } from '@poly/client-utils';
import { LinkButtonWithLoader } from '@poly/admin-book';
import { arrayOf, bool, object, oneOfType, string } from 'prop-types';

import { getProjectsExportExcelConfig } from './getProjectsExportExcelConfig.js';
import { useBulkEditProjectsLazyQuery } from '../../useBulkEditProjectsQuery.js';

const LinkButtonWithLoaderS = styled(LinkButtonWithLoader)`
  width: 20px;
  min-width: unset;
  position: relative;
`;

export function ProjectXlsExportButton({ disabled, input, sort }) {
  const [loading, setLoading] = useState(false);
  const getProjects = useBulkEditProjectsLazyQuery();

  const onClick = async () => {
    setLoading(true);
    const projects = await getProjects(input, sort);

    const excelConfig = getProjectsExportExcelConfig(projects);

    performExcelExport(excelConfig);
    setLoading(false);
  };

  return (
    <LinkButtonWithLoaderS
      disabled={disabled}
      loader={loading}
      onClick={onClick}
    >
      {EXPORT_XLS_CAPTION}
    </LinkButtonWithLoaderS>
  );
}

ProjectXlsExportButton.propTypes = {
  disabled: bool,
  // eslint-disable-next-line react/forbid-prop-types
  input: object,
  // eslint-disable-next-line react/forbid-prop-types
  sort: arrayOf(oneOfType([string, object])),
};
