import { gql } from '@apollo/client';
import { commonAddressFields } from '@poly/admin-ui';
import { supplierDocumentsFields } from '../suppliers/fragments.js';

export const clientPropertyFields = gql`
  fragment clientPropertyFields on Property {
    _id
    name
    isMaster
    status
    division
    address {
      formatted_address
      address_parts {
        street_number
        postal_code
        administrative_area_level_1
        route
        locality
      }
    }
    masterProperty {
      _id
      name
    }
    tags {
      _id
      name
    }
  }
`;

export const propertySupplierFields = gql`
  fragment propertySupplierFields on PropertySupplier {
    relationTypes
    supplier {
      _id
      status
      isVeteran
      mwbe {
        isWBE
        minority
      }
      company {
        name
        phones {
          phone
          type
        }
        services {
          _id
          name
        }
        address {
          address_parts {
            locality
            administrative_area_level_1
          }
        }
      }
      type
      tax {
        exempt
        w9File {
          url
        }
      }
      documents {
        ...supplierDocumentsFields
      }
    }
  }
  ${supplierDocumentsFields}
`;

export const propertyFieldsForFilters = gql`
  fragment propertyFieldsForFilters on Property {
    _id
    name
    costCenter
    isMaster
    address {
      formatted_address
      address_parts {
        street_number
        route
        locality
        administrative_area_level_2
        administrative_area_level_1
        country
        postal_code
        postal_code_suffix
        neighborhood
      }
    }
  }
`;

export const propertyDetailsFields = gql`
  fragment propertyDetailsFields on Property {
    _id
    name
    isMaster
    status
    cardNumber
    requesterSurveyEnabled
    masterProperty {
      _id
      name
      costCenter
      tmMarkupRules {
        upTo
        percent
      }
      woCharge
    }
    address {
      ...commonAddressFields
    }
    aacContractMGR {
      _id
      fullName
    }
    branchManager {
      _id
      profile {
        fullName
        personalEmail
        personalPhone
        cellPhoneNumber
      }
      fullName
      email
      emails {
        address
      }
    }
    addressTwo
    remarks
    defaultWOInstructions
    invoiceTo
    fax
    phone
    occupancy
    ownership
    clientOuterId
    clientExternalId
    clientUnitName
    tier
    typeByClient
    square
    submitProjectWebFormUrl
    client {
      _id
      name
      apps
      status
      woCharge
      bidRules {
        type
        label
        amount
        isRuleActive
      }
      configs {
        adminApp {
          allowProjectCreation
        }
        propertyFields {
          division
          storeNumber
          districtFacilityManager
          buildingInfo {
            occupancy
            ownership
            lockBoxCode
            parcelTaxId
            buildingSize
            buildingType
            powerRequirements
            landlordResponsibility
          }
          alarmInfo
          financialCoding
        }
        defaultProjectType
        enableHousekeepingProjectType
        enableDistricts
        requesterSurvey {
          enabled
        }
      }
      clientManagers {
        _id
        fullName
      }
      districts {
        _id
        name
      }
      enablePropertyLocationHierarchy
      enableCostCenter
      financial {
        costCenter
      }
      tmMarkupRules {
        upTo
        percent
      }
    }
    storeNumber
    division
    districtFacilityManager {
      _id
      fullName
      emails {
        address
      }
      profile {
        cellPhoneNumber
        personalPhone
      }
    }
    buildingInfo {
      buildingSize
      buildingOwnership
      occupancy
      landlordResponsibility
      powerRequirements
      lockBoxCode
      parcelTaxId
      buildingType
    }
    alarmInfo {
      alarmCode
      alarmSystem
      accountNumber
      alarmCompanyPhone
      password
      gateCode
    }
    financialCoding
    clientDistrict {
      _id
      name
    }
    costCenter
    tmMarkupRules {
      upTo
      percent
    }
    woCharge
    esfmPropertyId
    bidRules {
      type
      label
      amount
      assetsNumber
      isRuleActive
    }
    tags {
      _id
      name
    }
  }

  ${commonAddressFields}
`;
