import * as R from 'ramda';
import { ClientStatuses } from '@poly/constants';
import { ExcelExportCellType } from '@poly/utils/src/excel-export/constants.js';
import { createExcelExportCell } from '@poly/utils/src/excel-export/helpers.js';

const ClientStatusesUi = {
  [ClientStatuses.ACTIVE]: 'Active',
  [ClientStatuses.INACTIVE]: 'Inactive',
};

// getClientRowConfig :: Boolean -> Client -> [ExcelExportDataCell]
const getClientRowConfig = (withStatus) =>
  R.compose(
    R.zipWith(createExcelExportCell, [
      ...R.repeat(ExcelExportCellType.default, withStatus ? 8 : 7),
    ]),
    R.juxt([
      ...(withStatus
        ? [
            R.ifElse(
              R.propIs(String, 'status'),
              R.compose(R.prop(R.__, ClientStatusesUi), R.prop('status')),
              R.always(''),
            ),
          ]
        : []),
      R.propOr('', 'name'),
      R.pathOr('', ['address', 'formatted_address']),
      R.pathOr('', ['address', 'address_parts', 'street_number']),
      R.pathOr('', ['address', 'address_parts', 'route']),
      R.pathOr('', ['address', 'address_parts', 'locality']),
      R.pathOr('', ['address', 'address_parts', 'administrative_area_level_1']),
      R.pathOr('', ['address', 'address_parts', 'postal_code']),
    ]),
  );

export const getXlsClientsConfig = ({
  clients,
  exportFileName,
  withStatus = true,
}) => ({
  exportFileName: `${exportFileName}.xlsx`,
  columnWidths: [10, 25, 45, 25, 25, 25, 10, ...(withStatus ? [10] : [])],
  rows: [
    R.zipWith(
      createExcelExportCell,
      [...R.repeat(ExcelExportCellType.tableHeader, 8)],
      [
        ...(withStatus ? ['Status'] : []),
        'Name',
        'Address (full)',
        'Street Number',
        'Street Name',
        'City',
        'State',
        'Zip',
      ],
    ),
    ...R.map(getClientRowConfig(withStatus), clients),
  ],
});
