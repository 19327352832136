import React from 'react';
import { useDispatch } from 'react-redux';
import {
  ModalWindow as ModalWindowImp,
  confirmCancelFormPayload,
  openConfirmModal,
  GalleryModal,
  ConfirmModal,
} from '@poly/admin-ui';

import { modalNames } from './constants.js';
import { LoadingModal } from './LoadingModal.js';
import { ConfirmPrintModal } from './ConfirmPrintModal.js';
import { confirmButtons } from './confirmButtons/index.js';
import { AddFilesModal } from '../forms/addFilesForm/index.js';
import { AddTaskForm } from '../forms/taskForm/AddTaskForm.js';
import { AddUpdateModal } from '../forms/addUpdateForm/index.js';
import { EditTaskForm } from '../forms/taskForm/EditTaskForm.js';
import { confirmButtonNames } from './confirmButtons/constants.js';
import { CompleteTaskForm } from '../forms/taskForm/CompleteTaskForm.js';
import { PostTaskUpdateForm } from '../forms/taskForm/PostTaskUpdateForm.js';
import { AddServiceTypeForm } from '../forms/serviceTypes/AddServiceTypeForm.js';
import { UpdateServiceTypeForm } from '../forms/serviceTypes/EditServiceTypeForm.js';
import { PropertySupplierForm } from '../forms/propertySupplierForm/PropertySupplierForm.js';
import { CreateMentionGroupForm } from '../forms/mentionGroupForm/CreateMentionGroupForm.js';
import { UpdateMentionGroupForm } from '../forms/mentionGroupForm/UpdateMentionGroupForm.js';
import {
  EditProblemCodeModalForm,
  AddProblemCodeModalForm,
} from '../../pages/ProblemCodeLibrary/ProblemCodeForm.js';

const modals = {
  [modalNames.LOADING]: LoadingModal,
  [modalNames.ADD_UPDATE]: AddUpdateModal,
  [modalNames.CONFIRM_PRINT]: ConfirmPrintModal,
  [modalNames.ADD_FILES]: AddFilesModal,
  [modalNames.ADD_TASK]: AddTaskForm,
  [modalNames.EDIT_TASK]: EditTaskForm,
  [modalNames.COMPLETE_TASK]: CompleteTaskForm,
  [modalNames.POST_TASK_UPDATE]: PostTaskUpdateForm,
  [modalNames.ADD_MENTION_GROUP]: CreateMentionGroupForm,
  [modalNames.EDIT_MENTION_GROUP]: UpdateMentionGroupForm,
  [modalNames.ADD_PROPERTY_SUPPLIER]: PropertySupplierForm,
  [modalNames.CONFIRM_MODAL]: ConfirmModal,
  [modalNames.GALLERY]: GalleryModal,
  [modalNames.ADD_SERVICE_TYPE]: AddServiceTypeForm,
  [modalNames.EDIT_SERVICE_TYPE]: UpdateServiceTypeForm,
  [modalNames.ADD_PROBLEM_CODE]: AddProblemCodeModalForm,
  [modalNames.EDIT_PROBLEM_CODE]: EditProblemCodeModalForm,
  empty: () => null,
};

export function ModalWindow(props) {
  const dispatch = useDispatch();

  const onConfirmClose = () =>
    dispatch(
      openConfirmModal({
        payload: {
          ...confirmCancelFormPayload,
          confirmButtonName: confirmButtonNames.CONFIRM_CLOSE_MODAL,
        },
      }),
    );

  return (
    <ModalWindowImp
      {...props}
      modals={modals}
      onConfirmClose={onConfirmClose}
    />
  );
}

export function ConfirmModalWindow() {
  return <ConfirmModal confirmButtons={confirmButtons} />;
}
