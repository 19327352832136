import React from 'react';
import { arrayOf, shape } from 'prop-types';
import { openPrintWindowWithData } from '@poly/client-utils';
import { PRINT_PDF_CAPTION } from '@poly/constants';
import { CommonPrintLayout } from '@poly/admin-ui';
import { LinkButton } from '@poly/admin-book';

import { clientSurveysSummaryReportEntryPropTypes } from './prop-types.js';
import { ClientSurveysSummaryReportTable } from './ClientSurveysSummaryReportTable.js';

export function ClientSurveysSummaryReportPrintBtn({ reportEntries }) {
  const onClick = async () => {
    openPrintWindowWithData({
      reportEntries,
      Layout: CommonPrintLayout,
      Table: ClientSurveysSummaryReportTable,
      fileName: 'client_surveys_summary_report',
      metaData: { title: 'Client Surveys Summary Report' },
    });
  };

  return (
    <LinkButton disabled={reportEntries.length === 0} onClick={onClick}>
      {PRINT_PDF_CAPTION}
    </LinkButton>
  );
}

ClientSurveysSummaryReportPrintBtn.propTypes = {
  reportEntries: arrayOf(shape(clientSurveysSummaryReportEntryPropTypes))
    .isRequired,
};
