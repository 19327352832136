import * as R from 'ramda';
import React from 'react';
import { Select } from '@poly/admin-book';
import { func, number, string } from 'prop-types';

import { FieldOperation, BulkEditProjectFieldValues } from '../constants.js';

// getOperationSelectOptions :: String -> [Option]
const getOperationSelectOptions = R.cond([
  [
    R.includes(R.__, [
      BulkEditProjectFieldValues.START,
      BulkEditProjectFieldValues.END,
    ]),
    R.always([
      { label: 'LT', value: FieldOperation.LT },
      { label: 'GT', value: FieldOperation.GT },
    ]),
  ],
  [
    R.includes(R.__, [
      BulkEditProjectFieldValues.KEYWORD,
      BulkEditProjectFieldValues.PROJECT_TYPE,
    ]),
    R.always([{ label: 'EQ', value: FieldOperation.EQ }]),
  ],
  [
    R.T,
    R.always([
      { label: 'EQ', value: FieldOperation.EQ },
      { label: 'IN', value: FieldOperation.IN },
      { label: 'NIN', value: FieldOperation.NIN },
    ]),
  ],
]);

export function FieldOperationSelect({
  index,
  onChange,
  ruleField,
  changeFieldValue,
  ...props
}) {
  const selectProps = {
    placeholder: 'EQ',
    required: true,
    options: getOperationSelectOptions(ruleField),
    onChange: (value) => {
      if (changeFieldValue) {
        changeFieldValue(`rules.${index}.fieldValue`, null);
      }

      onChange(value);
    },
    ...props,
  };

  return <Select {...selectProps} />;
}

FieldOperationSelect.propTypes = {
  index: number,
  onChange: func,
  ruleField: string,
  changeFieldValue: func,
};
