import { ProjectTypeSelectOptionValues } from '../../../constants.js';

export const ProjectTypeSelectOptions = [
  { label: 'Project', value: ProjectTypeSelectOptionValues.PROJECT },
  {
    label: 'Child Recurring',
    value: ProjectTypeSelectOptionValues.CHILD_RECURRING,
  },
  {
    label: 'Master Recurring',
    value: ProjectTypeSelectOptionValues.MASTER_RECURRING,
  },
  { label: 'Child PM', value: ProjectTypeSelectOptionValues.CHILD_PM },
  { label: 'PM', value: ProjectTypeSelectOptionValues.PM },
];
