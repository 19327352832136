import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import { number, shape, string } from 'prop-types';
import { Icon } from '@poly/admin-book';
import { useNavigate } from '@poly/client-routing';
import { insertQueryParamsIntoURL } from '@poly/utils';

import {
  DashboardDelimiter,
  DashboardSection,
  DashboardSectionTitle,
} from './components.js';
import { getDashboardCounts } from '../components/UserDashboard.js';
import {
  expiringMasterProjectsDays,
  projectUIStatuses,
} from '../../../modules/core/constants/projects.js';
import { routesNames } from '../../../routes/constants.js';

const MyMastersDashboardContainer = styled(DashboardSection)`
  height: 252px;
  width: 100%;
`;

const DashboardSectionS = styled(DashboardSection)`
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
  height: 188px;
`;

const MyMastersItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: calc(50% - 20px);
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const FlexBetweenContainer = styled(FlexContainer)`
  justify-content: space-between;
  cursor: pointer;
`;

const MyMastersLabel = styled.p`
  margin: 0;
  padding: 0;
  color: #1a1c1e;
  font-size: 14px;
`;

const myMastersLeftSideDashboardConfig = [
  {
    label: 'My Masters',
    fieldName: 'active',
    iconName: 'masters',
    tab: projectUIStatuses.ACTIVE,
  },
  {
    label: 'Expiring 30 Days',
    fieldName: 'expiring30Days',
    iconName: 'timer30',
    tab: expiringMasterProjectsDays.EXPIRING30,
  },
  {
    label: 'Expiring 60 Days',
    fieldName: 'expiring60Days',
    iconName: 'timer60',
    tab: expiringMasterProjectsDays.EXPIRING60,
  },
  {
    label: 'Expiring 90 Days',
    fieldName: 'expiring90Days',
    iconName: 'timer90',
    tab: expiringMasterProjectsDays.EXPIRING90,
  },
];

const myMastersRightSideDashboardConfig = [
  { label: 'Awarded', iconName: 'timer' },
  {
    label: 'Expired',
    fieldName: 'expired',
    iconName: 'timerCancel',
    tab: projectUIStatuses.PAST_DUE,
  },
  { label: 'Onboarding', iconName: 'branch' },
  { label: 'Bidding', iconName: 'books' },
];

// getMyMasterDashboardValue :: (String, OwnMastersCounts) -> Number
const getMyMasterDashboardValue = (fieldName, counts) =>
  R.ifElse(R.always(fieldName), R.propOr(0, fieldName), R.always(0))(counts);

function MyMastersItem({ label, fieldName, iconName, tab, ownMastersCounts }) {
  const navigate = useNavigate();

  const value = getMyMasterDashboardValue(fieldName, ownMastersCounts);

  const handleNavigate = () => {
    if (tab) {
      const url = insertQueryParamsIntoURL(
        { tab },
        routesNames.MY_MASTER_PROJECTS_DIRECTORY,
      );

      navigate(url);
    }
  };

  return (
    <FlexBetweenContainer onClick={handleNavigate}>
      <FlexContainer>
        <Icon size={22} name={iconName} color="#61646C" />
        <MyMastersLabel>{label}</MyMastersLabel>
      </FlexContainer>
      <MyMastersLabel>{value}</MyMastersLabel>
    </FlexBetweenContainer>
  );
}

MyMastersItem.propTypes = {
  label: string.isRequired,
  fieldName: string,
  iconName: string.isRequired,
  tab: string,
  ownMastersCounts: shape({
    active: number,
    expired: number,
    expiring30Days: number,
    expiring60Days: number,
    expiring90Days: number,
  }),
};

export function MyMastersDashboard({ dashboardData }) {
  const { ownMastersCounts } = getDashboardCounts(dashboardData);

  return (
    <MyMastersDashboardContainer>
      <DashboardSectionTitle title="My Masters" />
      <DashboardSectionS>
        <MyMastersItemsContainer>
          {myMastersLeftSideDashboardConfig.map((config) => (
            <MyMastersItem
              key={config.label}
              {...config}
              ownMastersCounts={ownMastersCounts}
            />
          ))}
        </MyMastersItemsContainer>
        <DashboardDelimiter />
        <MyMastersItemsContainer>
          {myMastersRightSideDashboardConfig.map((config) => (
            <MyMastersItem
              key={config.label}
              {...config}
              ownMastersCounts={ownMastersCounts}
            />
          ))}
        </MyMastersItemsContainer>
      </DashboardSectionS>
    </MyMastersDashboardContainer>
  );
}

MyMastersDashboard.propTypes = {
  dashboardData: shape({
    dashboard: shape({
      ownMastersCounts: shape({
        active: number,
        expired: number,
        expiring30Days: number,
        expiring60Days: number,
        expiring90Days: number,
      }),
    }),
  }),
  userId: string,
};
