import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import React, { useState, useMemo } from 'react';
import { PREVIEW_CLIENT_SPEND_REPORT_BY_HIERARCHY } from '@poly/client-utils';
import { DefaultBodyWrapper, TableCard, Loader } from '@poly/admin-book';

import { SpendReportsFooterC } from './spendReportsTable/SpendReportsFooter.js';
import { SpendReportsPageHeaderC } from './SpendDetailsPageHeader/SpendDetailsPageHeaderC.js';
import { SpendReportHierarchyTree } from './spendReportsTable/SpendReportHierarchyTree.js';
import { prepareSpendReportQueryInput } from './helpers.js';
import { prepareSpendReports } from './SpendDetailsPageHeader/prepareSpendReports.js';

const TableCardS = styled(TableCard)`
  height: calc(100% - 60px);
  align-items: flex-start;
`;

const ContentWrapperS = styled(DefaultBodyWrapper)`
  align-items: center;
  height: calc(100% - 140px);
  flex-direction: column;
`;

export function SpendReportsPage() {
  const [queryInput, setQueryInput] = useState(null);

  const skip = !queryInput?.endDate || !queryInput?.startDate;

  const { data, loading } = useQuery(PREVIEW_CLIENT_SPEND_REPORT_BY_HIERARCHY, {
    variables: { input: prepareSpendReportQueryInput(queryInput) },
    fetchPolicy: 'network-only',
    skip,
  });

  const additionalRecordProps = useMemo(
    () => ({
      ...(!!queryInput && queryInput?.spendCostConfig
        ? { spendCostConfig: queryInput?.spendCostConfig }
        : {}),
    }),
    [queryInput],
  );

  const filterHierarchyId = queryInput ? queryInput.hierarchyId : null;

  const reports = useMemo(
    () => prepareSpendReports(filterHierarchyId, additionalRecordProps)(data),
    [data, queryInput],
  );

  return (
    <>
      <SpendReportsPageHeaderC
        reports={reports}
        queryInput={queryInput}
        setQueryInput={setQueryInput}
      />
      <ContentWrapperS>
        <TableCardS>
          {loading || !reports ? (
            <Loader />
          ) : (
            <SpendReportHierarchyTree {...queryInput} reports={reports} />
          )}
        </TableCardS>
        <SpendReportsFooterC reports={reports} />
      </ContentWrapperS>
    </>
  );
}
