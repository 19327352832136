import React, { useContext, createContext, useEffect } from 'react';
import {
  keywordSortQuery,
  useHighlightMatchesBySearch,
} from '@poly/client-utils';
import * as R from 'ramda';
import { arrayOf, element, shape, string } from 'prop-types';
import { Dot, LinkButton, Loader } from '@poly/admin-book';
import { useServiceTypesBySearchTable } from '../../../hooks/serviceTypes/index.js';
import { useSetItemsCount } from '../../../hooks/useSetItemsCount.js';
import { useTableSorting } from '../../../hooks/useTableSorting.js';
import { useMapConfigToTableProps } from '../../../hooks/useMapConfigToTableProps.js';
import { useSetInitialPaginationOnMount } from '../../../hooks/useSetInitialPaginationOnMount.js';
import { serviceTypesStatusesColors } from '../../../constants/index.js';
import { serviceTypesByStatusQuery } from '../../../searchQueries/serviceTypes.js';
import { TableS } from './ServiceTypesTableStyles.js';

export const ServiceTypesContext = createContext({});

function ServiceTypesStatus({ status }) {
  return <Dot color={serviceTypesStatusesColors[status]} />;
}

ServiceTypesStatus.propTypes = {
  status: string,
};

function EditServiceTypeBtn(props) {
  const { onEditServiceType, ...restContextProps } =
    useContext(ServiceTypesContext);

  return (
    <LinkButton
      onClick={() => onEditServiceType({ ...restContextProps, ...props })}
    >
      Edit
    </LinkButton>
  );
}

const serviceTypesTableConfigBase = [
  ['', ServiceTypesStatus],
  ['Service Type', R.prop('name'), keywordSortQuery(['name'])],
  ['Description', R.prop('description')],
];

const serviceTypesTableConfig = [
  ...serviceTypesTableConfigBase,
  ['', EditServiceTypeBtn],
];

// getServiceTypesTotalCount :: SearchServiceTypesQueryResult -> Number
const getServiceTypesTotalCount = R.pathOr(0, ['searchServiceTypes', 'total']);

// getServiceTypes :: SearchServiceTypesQueryResult -> [ServiceType]
const getServiceTypes = R.path(['searchServiceTypes', 'hits']);

// eslint-disable-next-line import/no-unused-modules
export function ServiceTypesPrintTable({ serviceTypes }) {
  const tableProps = useMapConfigToTableProps(
    R.identity,
    serviceTypesTableConfigBase,
    serviceTypes,
  );

  return <TableS {...tableProps} />;
}

ServiceTypesPrintTable.propTypes = {
  serviceTypes: arrayOf(
    shape({
      _id: string.isRequired,
      name: string.isRequired,
      description: string,
      status: string.isRequired,
    }),
  ),
};

export function ServiceTypesTable({ status, ...restProps }) {
  const { setSort } = useContext(ServiceTypesContext);

  useSetInitialPaginationOnMount();

  const { supplierSource, ...restContextProps } =
    useContext(ServiceTypesContext);

  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig: serviceTypesTableConfig,
    column: 2,
  });

  useEffect(() => {
    if (setSort) {
      setSort(sort);
    }
  }, [sort]);

  const { loading, result } = useServiceTypesBySearchTable({
    query: serviceTypesByStatusQuery(status, supplierSource),
    sort,
  });

  useSetItemsCount(getServiceTypesTotalCount, result);

  const { rows, ...tableProps } = useMapConfigToTableProps(
    getServiceTypes,
    serviceTypesTableConfig,
    result,
  );

  const { highlightedRows } = useHighlightMatchesBySearch(
    R.identity,
    [['name'], ['description']],
    rows,
  );

  if (loading) return <Loader />;

  return (
    <TableS
      {...restProps}
      {...tableSortingProps}
      {...restContextProps}
      {...tableProps}
      rows={highlightedRows}
    />
  );
}

ServiceTypesTable.displayName = 'ServiceTypesTable';

ServiceTypesTable.propTypes = {
  toolBar: element,
  status: string,
};
