import { defaultTheme } from '@poly/admin-book';
import { RecurringProjectStatusesText, WorkOrderStatus } from '@poly/constants';

const {
  colors: {
    primaryMid,
    statuses: { active, pending, error, funded },
    projectStatuses: { inactive, blocked },
  },
} = defaultTheme;

export const UnknownProjectStatus = 'UNKNOWN';

export const projectStatusesColors = {
  [WorkOrderStatus.ACTIVE]: active,
  [WorkOrderStatus.PENDING]: primaryMid,
  [WorkOrderStatus.IN_PROCESS]: active,
  [WorkOrderStatus.ON_HOLD_CLIENT]: pending,
  [WorkOrderStatus.ON_HOLD_PROJECT]: pending,
  [WorkOrderStatus.ON_HOLD_PARTS]: pending,
  [WorkOrderStatus.BLOCKED]: blocked,
  [WorkOrderStatus.COMPLETED]: inactive,
};

export const masterProjectStatusesColors = {
  [RecurringProjectStatusesText.ACTIVE]: active,
  [RecurringProjectStatusesText.CLOSED]: inactive,
  [RecurringProjectStatusesText.EXPIRED]: error,
  [UnknownProjectStatus]: funded,
};
