import * as R from 'ramda';
import { DEFAULT_PROPERTY_HIERARCHY_OPTION } from '@poly/constants';
import {
  prepareHierarchyNotDuplicatedProperties,
  assocPropsForPropertyReportRecursive,
  isNilOrEmpty,
  pathEqLegacy,
} from '@poly/utils';

// prepareSpendReports :: (ID, Object) -> { previewClientSpendReportByHierarchy: [PropertyHierarchySpendReportResult] }
// -> [PropertyHierarchySpendReportResult]
export const prepareSpendReports = (filterHierarchyId, additionalRecordProps) =>
  R.compose(
    R.map(assocPropsForPropertyReportRecursive(additionalRecordProps)),
    R.map(R.omit(['filterHierarchyId'])),
    R.ifElse(
      R.either(
        R.pathSatisfies(isNilOrEmpty, ['0', 'filterHierarchyId']),
        pathEqLegacy(
          ['0', 'filterHierarchyId'],
          DEFAULT_PROPERTY_HIERARCHY_OPTION,
        ),
      ),
      R.compose(
        R.map(R.over(R.lensProp('properties'), R.uniqBy(R.prop('propertyId')))),
        R.filter(R.propSatisfies(R.isNil, '_id')),
      ),
      prepareHierarchyNotDuplicatedProperties(filterHierarchyId),
    ),
    R.map(R.assoc('filterHierarchyId', filterHierarchyId)),
    R.pathOr([], ['previewClientSpendReportByHierarchy']),
  );
