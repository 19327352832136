import React from 'react';
import { useDispatch } from 'react-redux';
import { useHasUserAccessWithPermission } from '@poly/client-utils';
import { UPDATE_PROBLEM_CODE_PERMISSION } from '@poly/security';
import { TextButton } from '@poly/admin-book';
import { openModal } from '@poly/admin-ui';

import { modalNames } from '../../modules/modals/constants.js';

export function EditProblemCodeButton(props) {
  const dispatch = useDispatch();

  const hasPermission = useHasUserAccessWithPermission(
    UPDATE_PROBLEM_CODE_PERMISSION,
  );

  const onClick = () => {
    dispatch(openModal({ name: modalNames.EDIT_PROBLEM_CODE, payload: props }));
  };

  return hasPermission ? <TextButton onClick={onClick}>Edit</TextButton> : null;
}

EditProblemCodeButton.displayName = 'EditProblemCodeButton';
