import { AccountingStatus } from '@poly/constants';

export const ProjectAccountingStatusOptions = [
  {
    label: 'Ready To Invoice',
    value: AccountingStatus.READY_TO_INVOICE,
  },
  { label: 'Pending', value: AccountingStatus.PENDING },
  { label: 'Invoiced', value: AccountingStatus.INVOICED },
  { label: 'Paid', value: AccountingStatus.PAID },
  { label: 'Non Billable', value: AccountingStatus.NON_BILLABLE },
  { label: 'Report Only', value: AccountingStatus.REPORT_ONLY },
];
