import * as R from 'ramda';
import { isNilOrEmpty } from '@poly/utils';

// checkIfSingleUserGroupWithoutVariables :: String -> User -> Boolean
export const checkIfSingleUserGroupWithoutVariables =
  (userGroupSidebarId) => (user) =>
    R.compose(
      R.isEmpty,
      R.filter(
        R.both(
          R.pathEq(userGroupSidebarId, ['userGroup', '_id']),
          R.propSatisfies(R.complement(isNilOrEmpty), 'variables'),
        ),
      ),
      R.defaultTo([]),
      R.prop('userGroups'),
    )(user);
