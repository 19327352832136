import React from 'react';
import { useDispatch } from 'react-redux';

import { SupplierSources } from '@poly/constants';
import { PageHeaderContainer, MainHeader } from '@poly/admin-book';
import {
  openModal,
  ServiceTypesTabs as ServiceTypesTabsImp,
  TableSearchInput,
} from '@poly/admin-ui';

import { FlexColumn } from '../../components/FlexContainer.js';
import { modalNames } from '../../modules/modals/constants.js';
import { ServiceTypesExportButtons } from './ServiceTypesExportButtons.js';

function ServiceTypesTabs() {
  const dispatch = useDispatch();

  const onAddServiceType = () =>
    dispatch(
      openModal({
        name: modalNames.ADD_SERVICE_TYPE,
      }),
    );

  const onEditServiceType = ({ _id }) =>
    dispatch(
      openModal({
        name: modalNames.EDIT_SERVICE_TYPE,
        payload: { serviceTypeId: _id },
      }),
    );

  return (
    <ServiceTypesTabsImp
      openModal={openModal}
      supplierSource={SupplierSources.AAC}
      onAddServiceType={onAddServiceType}
      onEditServiceType={onEditServiceType}
      toolBar={<ServiceTypesExportButtons />}
    />
  );
}

export function ServiceTypesManagementPage() {
  return (
    <>
      <PageHeaderContainer headerStyles="height: 100px; padding: 20px 0">
        <MainHeader>Service Types</MainHeader>
        <FlexColumn>
          <TableSearchInput />
        </FlexColumn>
      </PageHeaderContainer>
      <ServiceTypesTabs />
    </>
  );
}
