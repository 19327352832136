import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { shape } from 'prop-types';

import { Table } from '@poly/admin-book/src/Table/Table.js';
import { useMapConfigToTableProps } from '@poly/admin-ui/src/hooks/useMapConfigToTableProps.js';

import { detailedClientSurveysReportTableConfig } from './table-config.js';
import { prepareDetailedClientSurveysReportToDisplay } from './helpers.js';

const OpenWorkOrdersReportTableS = styled(Table)`
  th:nth-child(1),
  td:nth-child(1) {
    width: 150px;
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: 200px;
  }

  th:nth-child(3),
  td:nth-child(3) {
    width: 100px;
  }

  th:nth-child(5),
  td:nth-child(5) {
    width: 150px;
  }
`;

export function DetailedClientSurveysReportTable({
  data,
  pagination,
  ...restTableProps
}) {
  const tableProps = useMapConfigToTableProps(
    R.identity,
    detailedClientSurveysReportTableConfig,
    prepareDetailedClientSurveysReportToDisplay(data),
  );

  return (
    <OpenWorkOrdersReportTableS
      pagination={pagination}
      {...restTableProps}
      {...tableProps}
    />
  );
}

DetailedClientSurveysReportTable.propTypes = {
  data: shape({}),
  pagination: shape({}),
};
