import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { FieldArray } from 'react-final-form-arrays';
import { pathEqLegacy } from '@poly/utils';
import { func, node, object, oneOfType, shape, string } from 'prop-types';

import { commonFields, layoutType } from '../../formTypes.js';
import { FormFieldLabel } from '../../FormFieldLabel.js';
import { getFormData } from '../../formUtils.js';

function RowComponent(props) {
  const rowProps = R.omit(['label', 'component', 'isWrapped'], props);

  return <div {...rowProps} />;
}

export const Row = styled(RowComponent)`
  display: ${R.propOr('flex', 'display')};
  flex-direction: ${(props) =>
    pathEqLegacy(['label', 'position'], 'left', props) ? 'row' : 'column'};
  width: ${R.prop('width')};
  margin: ${R.prop('margin')};
  padding: ${R.prop('padding')};
  flex: ${R.prop('flex')};
  overflow: ${R.prop('overflow')};
  ${(props) => R.prop('alignRight', props) && 'margin-left: auto;'}
  ${({ position }) => !!position && `position: ${position};`}
`;

export const ComponentColumn = styled.div`
  width: 100%;
  padding: ${R.prop('padding')};
`;

export function FieldArrayComp({
  label,
  fieldLayout,
  labelLayout,
  componentLayout,
  field: {
    name,
    Component,
    validate,
    withFormData,
    isEqual,
    withChangeFieldValue,
    additionalProps = {},
  },
  values,
  changeFieldValue,
}) {
  return (
    <Row {...fieldLayout}>
      {label && <FormFieldLabel {...labelLayout}>{label}</FormFieldLabel>}
      <ComponentColumn {...componentLayout}>
        <FieldArray name={name} validate={validate} isEqual={isEqual}>
          {({ fields }) =>
            fields.map((fieldName, index) => (
              <Component
                {...{
                  name: fieldName,
                  arrFieldName: name,
                  index,
                  fields,
                  ...getFormData(withFormData, values),
                  ...(withChangeFieldValue ? { changeFieldValue } : {}),
                  key: index,
                  ...additionalProps,
                }}
              />
            ))
          }
        </FieldArray>
      </ComponentColumn>
    </Row>
  );
}

FieldArrayComp.propTypes = {
  field: shape({
    name: string.isRequired,
    Component: oneOfType([func, node, object]),
  }).isRequired,
  fieldLayout: layoutType.isRequired,
  labelLayout: layoutType.isRequired,
  componentLayout: layoutType.isRequired,
  ...commonFields,
};
