import React from 'react';
import * as R from 'ramda';
import { func, object, bool } from 'prop-types';
import { usePristineSubscribe } from '@poly/client-routing';
import { FormCreator, Loader } from '@poly/admin-book';
import {
  commonSidebarFormSectionLayout,
  useOnSubmitSetStopSubmitting,
  commonSidebarFormFieldLayout,
  commonSidebarFormLayout,
  useSaveContacts,
} from '@poly/admin-ui';
import arrayMutators from 'final-form-arrays';

import { editPropertyFormId } from '../../constants.js';
import { SidebarFormLayout } from '../../../components/SidebarFormLayout.js';
import { sideBarPropertyFormSections } from '../form/sideBarPropertyFormSections.js';
import { useOnSubmitEditPropertyForm } from './useOnSubmitEditPropertyForm.js';
import { propertyToEditForm } from './formDataToMutation.js';
import { useHierarchyOptions } from '../../../../pages/PropertyHierarchyManagement/useHierarchyOptions.js';
import { usePropertyHierarchies } from '../../usePropertyHierarchies.js';
import { findPropertyNode } from '../../PropertySidebarHierarchy.js';

// getInitialHierarchies :: ([PropertyHierarchy], ID) -> [{hierarchy: ID, hierarchyNode: ID}]
const getInitialHierarchies = (hierarchies, propertyId) =>
  R.map(
    R.applySpec({
      hierarchy: R.prop('_id'),
      hierarchyNode: R.compose(
        R.prop('id'),
        findPropertyNode(R.__, propertyId),
      ),
    }),
  )(hierarchies);

function EditPropertyForm({ property, isCard }) {
  const saveContacts = useSaveContacts();
  const pristineSubscribeProps = usePristineSubscribe({
    property,
    id: editPropertyFormId,
  });
  const { options: clientHierarchies, loading } = useHierarchyOptions(
    property.client._id,
    true,
  );

  const { hierarchies, loading: hierarchiesLoading } = usePropertyHierarchies(
    property.client._id,
    property._id,
  );
  const onSubmitHandler = useOnSubmitEditPropertyForm({
    saveContacts,
    property,
    isCard,
  });
  const { onSubmit } = useOnSubmitSetStopSubmitting(
    editPropertyFormId,
    onSubmitHandler,
  );

  if (loading || hierarchiesLoading) {
    return <Loader />;
  }

  const initialValues = propertyToEditForm(property);

  const handleSubmit = (formData) => onSubmit({ formData, initialValues });

  return (
    <FormCreator
      id={editPropertyFormId}
      layout={commonSidebarFormLayout}
      fieldLayout={commonSidebarFormFieldLayout}
      sectionLayout={commonSidebarFormSectionLayout}
      initialValues={{
        ...initialValues,
        clientHierarchies,
        hierarchies: hierarchies.length
          ? getInitialHierarchies(hierarchies, property._id)
          : [{}],
      }}
      sections={sideBarPropertyFormSections({
        isEdit: true,
        isCard,
        client: property?.client,
      })}
      onSubmit={handleSubmit}
      {...pristineSubscribeProps}
      mutators={arrayMutators}
    />
  );
}

EditPropertyForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  property: object,
  isCard: bool,
};

export function EditPropertySidebarForm({ onClose, ...props }) {
  return (
    <SidebarFormLayout
      onClose={onClose}
      title="Edit Property"
      submitButtonCaption="Save"
      formId={editPropertyFormId}
    >
      <EditPropertyForm {...props} />
    </SidebarFormLayout>
  );
}

EditPropertySidebarForm.propTypes = {
  onClose: func.isRequired,
};
