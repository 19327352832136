import React from 'react';
import { shape } from 'prop-types';
import { PRINT_PDF_CAPTION } from '@poly/constants';
import { CommonPrintLayout } from '@poly/admin-ui/src/components/CommonPrintLayout.js';
import { openPrintWindowWithData } from '@poly/client-utils/src/print.js';

import { ExportPDFBtn } from '../WorkInProgressReport/WorkInProgressExportPDFBtn.js';
import { DetailedClientSurveysReportTable } from './DetailedClientSurveysReportTable.js';
import { useDetailedClientSurveysReportLazyQuery } from './useDetailedClientSurveysReportQuery.js';

export function DetailedClientSurveysReportPDFBtn({ filter, sort, ...props }) {
  const queryHandler = useDetailedClientSurveysReportLazyQuery();

  const onPrintPDFClick = async () => {
    const { data } = await queryHandler({ filter, sort });

    await openPrintWindowWithData({
      Layout: CommonPrintLayout,
      Table: DetailedClientSurveysReportTable,
      fileName: 'open_work_orders_report',
      metaData: { title: 'Detailed Client Surveys Report' },
      data,
    });
  };

  return (
    <ExportPDFBtn {...props} onClick={onPrintPDFClick}>
      {PRINT_PDF_CAPTION}
    </ExportPDFBtn>
  );
}

DetailedClientSurveysReportPDFBtn.propTypes = {
  filter: shape({}),
  sort: shape({}),
};
