import { WorkOrderPriority, ProjectType } from '@poly/constants';
import { UnknownProjectStatus } from '@poly/admin-ui';
import { defaultTheme } from '@poly/admin-book';

const {
  colors: {
    dark,
    amber,
    darkest,
    primaryDark,
    secondaryDark,
    primaryRegular,
    tertiaryRegular,
    secondaryRegular,
    primaryMid,
    statuses: { active, error, pending, funded },
    projectStatuses: { blocked, inactive },
  },
} = defaultTheme;

export const projectUIStatuses = {
  ACTIVE: 'ACTIVE',
  DUE_TODAY: 'DUE_TODAY',
  DUE_TOMORROW: 'DUE_TOMORROW',
  PAST_DUE: 'PAST_DUE',
  ON_HOLD: 'ON_HOLD',
  BLOCKED: 'BLOCKED',
  COMPLETED: 'COMPLETED',
  CLOSED: 'CLOSED',
  ALL: 'ALL',
  PENDING: 'PENDING',
  RECALLED: 'RECALLED',
};

export const expiringMasterProjectsDays = {
  EXPIRING30: 'EXPIRING30',
  EXPIRING60: 'EXPIRING60',
  EXPIRING90: 'EXPIRING90',
};

export const projectStatusesUI = {
  [projectUIStatuses.ACTIVE]: 'Active',
  [projectUIStatuses.DUE_TODAY]: 'Due Today',
  [projectUIStatuses.CLOSED]: 'Closed',
  [projectUIStatuses.DUE_TOMORROW]: 'Due Tomorrow',
  [projectUIStatuses.PAST_DUE]: 'Past Due',
  [projectUIStatuses.ON_HOLD]: 'On Hold',
  [projectUIStatuses.BLOCKED]: 'Blocked',
  [projectUIStatuses.COMPLETED]: 'Completed',
  [projectUIStatuses.ALL]: 'All',
  [projectUIStatuses.PENDING]: 'Pending',
  [UnknownProjectStatus]: UnknownProjectStatus,
};

export const expiredProjectsUI = {
  [expiringMasterProjectsDays.EXPIRING30]: 'Expiring 30',
  [expiringMasterProjectsDays.EXPIRING60]: 'Expiring 60',
  [expiringMasterProjectsDays.EXPIRING90]: 'Expiring 90',
};

export const projectStatusesColors = {
  [projectUIStatuses.DUE_TODAY]: active,
  [projectUIStatuses.DUE_TOMORROW]: active,
  [projectUIStatuses.PAST_DUE]: error,
  [projectUIStatuses.ON_HOLD]: pending,
  [projectUIStatuses.ACTIVE]: active,
  [projectUIStatuses.BLOCKED]: blocked,
  [projectUIStatuses.COMPLETED]: inactive,
  [projectUIStatuses.PENDING]: primaryMid,
  [projectUIStatuses.CLOSED]: inactive,
  [UnknownProjectStatus]: funded,
};

export const projectTypesColors = {
  [ProjectType.WORK_ORDER]: primaryRegular,
  [ProjectType.BID]: tertiaryRegular,
  [ProjectType.FEE]: amber,
  [ProjectType.HOUSEKEEPING]: primaryDark,
  [ProjectType.LISTING]: darkest,
  [ProjectType.PASS_THROUGH]: secondaryRegular,
  [ProjectType.PREVENTIVE_MAINTENANCE]: secondaryDark,
  [ProjectType.REPORT_ONLY]: dark,
};

export const projectPrioritiesColors = {
  [WorkOrderPriority.NORMAL]: blocked,
  [WorkOrderPriority.URGENT]: pending,
  [WorkOrderPriority.EMERGENCY]: error,
  [WorkOrderPriority.CUSTOM]: funded,
};

export const ProjectOccurrence = {
  ONE_TIME_OCCURRENCE: 'oneTimeOccurrence',
  RECURRING_OCCURRENCE: 'recurringOccurrence',
  PREVENTIVE_MAINTENANCE_OCCURRENCE: 'preventiveMaintenanceOccurrence',
};

export const recurringProjectsDashboardUIStatuses = {
  SCHEDULED: 'scheduled',
  UNSCHEDULED: 'unscheduled',
  COMPLETED: 'completed',
};
