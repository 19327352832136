import * as R from 'ramda';
import { MAX_ITEMS } from '@poly/admin-ui';
import { gql, useLazyQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { useRouterQuery } from '@poly/client-routing';
import { extractSearchQueryOptionsFromProps } from '@poly/client-utils';
import { propEqLegacy } from '@poly/utils';

import { projectsTabsConfig } from '../../modules/tabs/common.js';
import { RECURRING_PROJECTS_BY_SEARCH } from '../../modules/core/hooks/projects/queries.js';
import { ProjectFilterTypes } from '../../modules/pagesHeaders/ProjectSearchPageHeader/filter/constants.js';
import {
  projectStatusesColors,
  projectStatusesUI,
} from '../../modules/core/constants/projects.js';

const PROJECTS_TOOLBAR_QUERY = gql`
  query PROJECTS_TOOLBAR_QUERY($searchInput: CollectionSearchParams!) {
    searchProjects(input: $searchInput) {
      hits {
        _id
        type
        blockedAt
        projectId
        status
        createdAt
        description
        startDate
        endDate
        expiresAt
        priority
        workCompletionDate
        property {
          _id
          name
        }
        client {
          _id
          name
          nickName
        }
        manager {
          _id
          fullName
        }
      }
      total
    }
  }
`;

// getPrintTab :: String -> String
const getPrintTab = (tab) =>
  R.compose(R.prop(0), R.find(propEqLegacy(1, tab)))(projectsTabsConfig);

// checkRecurringSearch :: Filter -> Boolean
export const checkRecurringSearch = R.compose(
  R.any(R.equals(true)),
  R.props([
    ProjectFilterTypes.MASTER_RECURRING,
    ProjectFilterTypes.PREVENTIVE_MAINTENANCE,
  ]),
);

// checkIfNoPrioritySort :: { sort: [Sorting] } -> Boolean
const checkIfNoPrioritySort = R.compose(
  R.isNil,
  R.find(R.has('priority')),
  R.defaultTo([]),
  R.prop('sort'),
);

// prepareQueryOptionsWithAllItems :: QueryOptions -> QueryOptions
const prepareQueryOptionsWithAllItems = R.mergeDeepLeft({
  variables: { searchInput: { from: 0, size: MAX_ITEMS } },
});

export const useProjectsToolbarLogic = ({
  setLoading,
  customFileName,
  customTitle,
}) => {
  const { tab } = useRouterQuery(['tab']);
  const filter = useSelector((state) => state.searchFilters.projectsFilter);
  const params = useSelector((state) => state.searchEntityParams);
  const [projectsBySearchQuery] = useLazyQuery(PROJECTS_TOOLBAR_QUERY);
  const [recurringProjectsBySearchQuery] = useLazyQuery(
    RECURRING_PROJECTS_BY_SEARCH,
  );

  const preQueryOptions = params
    ? extractSearchQueryOptionsFromProps(params)
    : {
        skip: true,
        variables: { searchInput: {} },
      };

  const queryOptions = prepareQueryOptionsWithAllItems(preQueryOptions);

  const isNoPrioritySort = checkIfNoPrioritySort(params);
  const isRecurringSearch = !!filter && checkRecurringSearch(filter);

  let getProjectsQuery = projectsBySearchQuery;
  let pathBySearch = 'searchProjects';

  if (isRecurringSearch && isNoPrioritySort) {
    pathBySearch = 'searchRecurringProjects';
    getProjectsQuery = recurringProjectsBySearchQuery;
  }

  const getProjectsHandler = async () => {
    setLoading(true);

    const { data } = await getProjectsQuery(queryOptions);

    const projects = R.pathOr([], [pathBySearch, 'hits'], data);

    const tabForPrint = tab ? getPrintTab(tab) : projectsTabsConfig[0][0];

    const fileName = filter ? 'search_projects' : 'projects_directory';

    const metaData = filter
      ? {
          title: 'Search Projects',
        }
      : {
          title: customTitle || 'Projects Directory',
          status: {
            text: projectStatusesUI[tabForPrint],
            color: projectStatusesColors[tabForPrint],
          },
        };

    setLoading(false);

    return {
      projects,
      fileName: customFileName || fileName,
      metaData,
      isRecurringSearch,
    };
  };

  return getProjectsHandler;
};
