import React from 'react';
import * as R from 'ramda';
import { string, shape, arrayOf } from 'prop-types';
import { SidebarRow } from '@poly/admin-ui';
import { Text } from '@poly/admin-book';
import styled from 'styled-components';
import { isNilOrEmpty } from '@poly/utils';

import {
  SectionLabel,
  SectionWrapper,
} from '../components/commonSidebarComponents.js';

const NoTagsText = styled.div`
  color: rgba(142, 145, 149, 0.6);
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
`;

// getPropertyTagsString :: Property -> String
const getPropertyTagsString = R.compose(
  R.join(', '),
  R.pluck('name'),
  R.propOr([], 'tags'),
);

// isNoPropertyTags :: Property -> Boolean
const isNoPropertyTags = R.compose(isNilOrEmpty, R.prop('tags'));

export function PropertySidebarTags({ property }) {
  const propertyTagsStr = getPropertyTagsString(property);

  return (
    <SectionWrapper>
      <SidebarRow>
        <SectionLabel>Tags</SectionLabel>
      </SidebarRow>
      <SidebarRow>
        {isNoPropertyTags(property) ? (
          <NoTagsText>No tags yet</NoTagsText>
        ) : (
          <Text size="12px">{propertyTagsStr}</Text>
        )}
      </SidebarRow>
    </SectionWrapper>
  );
}

PropertySidebarTags.propTypes = {
  property: shape({
    tags: arrayOf(shape({ _id: string.isRequired, name: string.isRequired })),
  }),
};
