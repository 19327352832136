import styled from 'styled-components';
import React, { useState } from 'react';
import { isBeta } from '@poly/client-utils';
import {
  PageHeaderContainer,
  DefaultBodyWrapper,
  ListBodyWrapper,
  getThemeColor,
  MainHeader,
  Loader,
} from '@poly/admin-book';

import { ClientsChart } from './ClientsChart.js';
import { SuppliersChart, MasterSuppliersChart } from './SuppliersCharts.js';
import { DashboardSwitcher } from '../HomePage/ChartDashboard/DashboardSwitcher.js';
import { ClientsStatistics } from '../../modules/entitiesStatistics/ClientsStatisctics.js';
import { MasterSuppliersStatistics } from '../../modules/entitiesStatistics/MasterSuppliersStatistics.js';
import { AACSuppliersStatistics } from '../../modules/entitiesStatistics/AACSuppliersStatistics/AACSuppliersStatistics.js';
import { useAccountIndexQuery } from './useAccountIndexQuery.js';

const EntitiesWrapperS = styled(ListBodyWrapper)`
  overflow: auto;
  justify-content: space-evenly;
`;

const EntitiesSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
`;

const ChartsWrapperS = styled(DefaultBodyWrapper)`
  position: relative;
  background-color: transparent;

  &:before {
    display: block;
    content: '';
    width: 100%;
    position: absolute;
    top: 0;
    border-top: 4px solid ${getThemeColor(['white'])};
    z-index: 1;
  }
`;

const ChartsRowS = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1440px;
  min-width: 1220px;
  width: calc(100% - 150px);
  margin: 0 70px 0 80px;
  padding: 10px 0;
  gap: 16px;
`;

const SuppliersChartsColumnS = styled.div`
  display: flex;
  width: 68%;
  flex-direction: column;
  gap: 16px;
`;

export function AccountIndexPage() {
  const [isChart, setIsChart] = useState(false);
  const { data, loading } = useAccountIndexQuery();

  const handleSwitchDashboard = () => setIsChart(!isChart);

  if (loading) return <Loader />;

  return (
    <>
      <PageHeaderContainer>
        <MainHeader>Account Index</MainHeader>
        {isBeta && (
          <DashboardSwitcher
            withoutDate
            {...{ isChart, handleSwitchDashboard }}
          />
        )}
      </PageHeaderContainer>
      {isChart ? (
        <ChartsWrapperS>
          <ChartsRowS>
            <ClientsChart {...data} />
            <SuppliersChartsColumnS>
              <SuppliersChart {...data} />
              <MasterSuppliersChart {...data} />
            </SuppliersChartsColumnS>
          </ChartsRowS>
        </ChartsWrapperS>
      ) : (
        <EntitiesWrapperS>
          <EntitiesSection>
            <ClientsStatistics {...data} />
          </EntitiesSection>
          <EntitiesSection>
            <AACSuppliersStatistics {...data} />
          </EntitiesSection>
          <EntitiesSection>
            <MasterSuppliersStatistics {...data} />
          </EntitiesSection>
        </EntitiesWrapperS>
      )}
    </>
  );
}
