import * as R from 'ramda';
import { isNilOrEmpty } from '@poly/utils';
import { AACCheckStatus } from '@poly/constants';

// queryOrEmptyIfFilterNotExists :: (String, Object -> Object) -> Object
const queryOrEmptyIfFilterNotExists = (filterProp, getQueryFunc) =>
  R.ifElse(
    R.propSatisfies(isNilOrEmpty, filterProp),
    R.always({}),
    getQueryFunc,
  );

// prepareSupplierChecksFilters :: Object -> Object -> Object
export const prepareSupplierChecksFilters = (sort) =>
  R.compose(
    R.assocPath(['query', 'bool', 'must'], R.__, { sort }),
    R.reject(R.isEmpty),
    R.juxt([
      R.always({ term: { status: AACCheckStatus.ACTIVE } }),
      queryOrEmptyIfFilterNotExists('checkNumber', ({ checkNumber }) => ({
        term: { checkNumber },
      })),
      queryOrEmptyIfFilterNotExists('checkType', ({ checkType }) => ({
        term: { type: checkType },
      })),
      queryOrEmptyIfFilterNotExists('isPrinted', ({ isPrinted }) => ({
        term: { isPrinted },
      })),
      queryOrEmptyIfFilterNotExists('bankAccountId', ({ bankAccountId }) => ({
        term: { bankAccountId },
      })),
      R.ifElse(
        R.both(
          R.propSatisfies(isNilOrEmpty, 'paymentDateTo'),
          R.propSatisfies(isNilOrEmpty, 'paymentDateFrom'),
        ),
        R.always({}),
        R.compose(
          R.assocPath(['range', 'paymentDate'], R.__, {}),
          R.reject(isNilOrEmpty),
          R.applySpec({
            gte: R.prop('paymentDateFrom'),
            lte: R.prop('paymentDateTo'),
            format: R.always('EEE MMM dd yyyy'),
          }),
        ),
      ),
    ]),
  );
