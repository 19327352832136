import React from 'react';
import * as R from 'ramda';
import { arrayOf, shape } from 'prop-types';
import { EXPORT_XLS_CAPTION } from '@poly/constants';
import { LinkButton } from '@poly/admin-book';
import {
  createExcelExportCell,
  ExcelExportCellType,
  performExcelExport,
} from '@poly/client-utils';

import { clientSurveysSummaryReportEntryPropTypes } from './prop-types.js';

// getClientSurveysSummaryReportRows :: [ClientSurveysSummaryReportEntry] -> [ExcelExportDataCell]
const getClientSurveysSummaryReportRows = R.compose(
  R.unnest,
  R.map(({ client: { name }, surveys, surveysNumber, surveysAverageScore }) => [
    [
      {
        value: name,
        cellType: ExcelExportCellType.subTitleRow,
      },
    ],
    ...R.map(
      R.compose(
        R.zipWith(createExcelExportCell, [
          ExcelExportCellType.default,
          { style: { alignment: { horizontal: 'right' } } },
        ]),
        R.juxt([R.prop('surveyQuestion'), R.prop('surveyAverageScore')]),
      ),
    )(surveys),
    [
      {
        value: `Number of Surveys: ${surveysNumber}`,
        cellType: ExcelExportCellType.tableStringFooter,
        style: { alignment: { horizontal: 'center' } },
      },
      {
        value: `Average Total Score: ${surveysAverageScore}`,
        cellType: ExcelExportCellType.tableStringFooter,
        style: { alignment: { horizontal: 'center' } },
      },
    ],
  ]),
);

// getClientSurveysSummaryReportXLSExportConfig :: [ClientSurveysSummaryReportEntry] -> ExcelExportConfig
// eslint-disable-next-line import/no-unused-modules
export const getClientSurveysSummaryReportXLSExportConfig = (
  reportEntries,
) => ({
  exportFileName: 'client-surveys-summary-report.xlsx',
  columnWidths: [100, 50],
  rows: [
    [
      {
        value: 'Client Surveys Summary Report',
        cellType: ExcelExportCellType.title,
      },
    ],
    R.zipWith(
      createExcelExportCell,
      [
        ExcelExportCellType.tableHeader,
        ExcelExportCellType.tableCurrencyHeader,
      ],
      ['Question', 'Average Score'],
    ),

    ...getClientSurveysSummaryReportRows(reportEntries),
  ],
});

export function ClientSurveysSummaryReportExportXLSBtn({ reportEntries }) {
  const onClick = () => {
    const config = getClientSurveysSummaryReportXLSExportConfig(reportEntries);

    performExcelExport(config);
  };

  return (
    <LinkButton disabled={reportEntries.length === 0} onClick={onClick}>
      {EXPORT_XLS_CAPTION}
    </LinkButton>
  );
}

ClientSurveysSummaryReportExportXLSBtn.propTypes = {
  reportEntries: arrayOf(shape(clientSurveysSummaryReportEntryPropTypes))
    .isRequired,
};
