import * as R from 'ramda';

import { prepareProjectQueryInput } from './helpers/prepareProjectQueryInput.js';
import { BulkEditObjectType, BulkEditProjectFieldValues } from './constants.js';

// isQueryForProjects :: [{ objectType: String }] -> Boolean
const isQueryForProjects = R.pathEq(BulkEditObjectType.PROJECTS, [
  '0',
  'objectType',
]);

// getSearchTermByRules :: [RuleLine] -> String
const getSearchTermByRules = R.compose(
  R.prop('fieldValue'),
  R.find(R.propEq(BulkEditProjectFieldValues.KEYWORD, 'field')),
);

export const usePrepareQueryInput = () => {
  const prepareQueryInput = ({ rules }) => {
    const searchTerm = getSearchTermByRules(rules);

    if (isQueryForProjects(rules)) {
      const query = prepareProjectQueryInput(rules);

      return {
        query,
        ...(!searchTerm ? {} : { searchTerm }),
      };
    }

    return null;
  };

  return prepareQueryInput;
};
