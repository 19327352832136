import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { string } from 'prop-types';
import { Icon, Text } from '@poly/admin-book';

export const DashboardSection = styled.div`
  border: 1px solid #f0f1f1;
  border-radius: 10px;
  padding: 15px;
  width: ${R.propOr('100%', 'width')};
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${R.propOr('10px', 'gap')};
  padding: 0;
  margin: 0;
`;

export const DashboardDelimiter = styled.div`
  width: 1px;
  background-color: #f0f1f1;
  height: 100%;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border: 1px solid #ececed;
  border-radius: 6px;
`;

export function DashboardSectionTitle({ title, iconName }) {
  return (
    <FlexContainer>
      {iconName && (
        <IconWrapper>
          <Icon name={iconName} size={16} />
        </IconWrapper>
      )}
      <Text size="14px">{title}</Text>
    </FlexContainer>
  );
}

DashboardSectionTitle.propTypes = {
  title: string.isRequired,
  iconName: string,
};
