import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { arrayOf, element, oneOfType } from 'prop-types';
import { parseQuery, useLocation, useNavigate } from '@poly/client-routing';
import { AuthRoutesRedirect } from '@poly/client-utils';
import { ModalProvider } from '@poly/admin-ui';

import { LayoutContainer } from './styles.js';
import { authRoutesList, routesNames } from '../routes/constants.js';
import { useUserAuthBroadcastChannel } from '../hooks/useBroadcastChannel.js';

const PublicLayoutContainer = styled(LayoutContainer)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: min-content;
`;

// shouldSkipTokenValidation :: String -> Boolean
const shouldSkipTokenValidation = R.complement(
  R.includes(R.__, [routesNames.LOGIN, routesNames.SUPPLIERS_MAP]),
);

export function PublicLayout({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { onLoggedInListener } = useUserAuthBroadcastChannel();
  const skipTokenValidation = shouldSkipTokenValidation(location.pathname);

  const { previousUrl } = parseQuery(location.search);

  onLoggedInListener(() => navigate(previousUrl || routesNames.HOME));

  return (
    <ModalProvider>
      <PublicLayoutContainer>{children}</PublicLayoutContainer>
      <AuthRoutesRedirect
        authRoutesList={authRoutesList}
        homeRoute={routesNames.HOME}
        skipTokenValidation={skipTokenValidation}
      />
    </ModalProvider>
  );
}

PublicLayout.propTypes = {
  children: oneOfType([element, arrayOf(element)]).isRequired,
};
