export const modalNames = {
  LOADING: 'LOADING',
  ADD_UPDATE: 'ADD_UPDATE',
  CONFIRM_PRINT: 'CONFIRM_PRINT',
  ADD_FILES: 'ADD_FILES',
  ADD_TASK: 'ADD_TASK',
  EDIT_TASK: 'EDIT_TASK',
  COMPLETE_TASK: 'COMPLETE_TASK',
  POST_TASK_UPDATE: 'POST_TASK_UPDATE',
  ADD_MENTION_GROUP: 'ADD_MENTION_GROUP',
  EDIT_MENTION_GROUP: 'EDIT_MENTION_GROUP',
  ADD_PEOPLE: 'ADD_PEOPLE',
  ADD_PROPERTY_SUPPLIER: 'ADD_PROPERTY_SUPPLIER',
  CONFIRM_MODAL: 'CONFIRM_MODAL',
  GALLERY: 'GALLERY',
  ADD_SERVICE_TYPE: 'ADD_SERVICE_TYPE',
  EDIT_SERVICE_TYPE: 'EDIT_SERVICE_TYPE',
  ADD_PROBLEM_CODE: 'ADD_PROBLEM_CODE',
  EDIT_PROBLEM_CODE: 'EDIT_PROBLEM_CODE',
};
