import { string, number, shape, arrayOf } from 'prop-types';

export const clientSurveysSummaryReportEntryPropTypes = {
  client: shape({ _id: string.isRequired, name: string.isRequired }).isRequired,
  surveysNumber: number.isRequired,
  surveysAverageScore: number.isRequired,
  surveys: arrayOf(
    shape({
      surveyQuestion: string.isRequired,
      surveyAverageScore: number.isRequired,
    }),
  ).isRequired,
};
