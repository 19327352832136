import React, { useState } from 'react';
import { ClientStatuses } from '@poly/constants';
import { DefaultBodyWrapper } from '@poly/admin-book/src/Tabs/components.js';
import {
  MainHeader,
  PageHeaderContainer,
} from '@poly/admin-book/src/Header/index.js';
import {
  TableCard,
  TableWithPaginationContainer,
} from '@poly/admin-book/src/Card.js';
import { TableSearchInput } from '@poly/admin-ui/src/components/TableSearchInput.js';
import { TablePagination } from '@poly/admin-ui/src/components/Pagination.js';

import { ClientsTable } from '../../modules/tables/clientsTable/ClientsTable.js';
import {
  FlexContainer,
  ProspectsExportButtons,
} from './ProspectsExportButtons.js';

export function ProspectsPage() {
  const [sort, setSort] = useState(null);

  return (
    <>
      <PageHeaderContainer>
        <MainHeader>Prospects</MainHeader>
        <FlexContainer gap="15px">
          <ProspectsExportButtons sort={sort} />
          <TableSearchInput />
        </FlexContainer>
      </PageHeaderContainer>
      <DefaultBodyWrapper>
        <TableCard>
          <TableWithPaginationContainer paginationVisible>
            <ClientsTable status={ClientStatuses.PROSPECT} setSort={setSort} />
          </TableWithPaginationContainer>
          <TablePagination />
        </TableCard>
      </DefaultBodyWrapper>
    </>
  );
}
