import * as R from 'ramda';
import { ProjectSpendTypesUI, NOTHING_UI_STRING } from '@poly/constants';
import {
  mergeObjectWithArrayOfObjects,
  getProjectSpendCostByConfig,
  createExcelExportCell,
  convertCentsToDollars,
  ExcelExportCellType,
  calculateTotal,
  isNilOrEmpty,
  formatDate,
  assocBy,
} from '@poly/utils';

import { formatFromToDateTitle } from '../dates.js';

// createExcelExportCellWithStyles :: Object -> String -> ExcelExportDataCell
const createExcelExportCellWithStyles = R.curry((style, value) => ({
  value,
  style,
}));

// adjustDataCell :: Function -> Invoice -> ExcelExportDataCell
const adjustDataCell = (getValue) => R.compose(R.objOf('value'), getValue);

// adjustDataCellWithStyles :: Function -> Invoice -> ExcelExportDataCell
const adjustDataCellWithStyles = (getValue) =>
  R.converge(R.mergeLeft, [
    R.compose(R.objOf('value'), getValue),
    R.pick(['_polyInternal']),
  ]);

// createExcelCellForSpendReport :: String -> Object -> ExcelExportDataCell
const createExcelCellForSpendReport = R.curry((cellType, props) => ({
  ...props,
  cellType,
}));

// getProjectSpendTypeOrNothingUI :: { spendType: ProjectSpendType } -> String
export const getProjectSpendTypeOrNothingUI = R.compose(
  R.ifElse(
    isNilOrEmpty,
    R.always(NOTHING_UI_STRING),
    R.prop(R.__, ProjectSpendTypesUI),
  ),
  R.prop('spendType'),
);

// getRowByInvoiceSpendResult :: Int -> [InvoiceSpendReportResult] -> [ExcelExportDataCell]
const getRowByInvoiceSpendResult = (maxNodesCount) =>
  R.compose(
    R.map(
      R.compose(
        R.zipWith(createExcelCellForSpendReport, [
          ...R.repeat(ExcelExportCellType.default, 8),
          ...R.repeat(ExcelExportCellType.defaultCurrency, 6),
          ...R.repeat(ExcelExportCellType.default, maxNodesCount),
        ]),
        R.compose(
          R.flatten,
          R.juxt([
            adjustDataCellWithStyles(R.prop('projectNumber')),
            adjustDataCell(R.prop('invoiceNumber')),
            adjustDataCell(R.compose(formatDate, R.prop('date'))),
            adjustDataCell(R.prop('supplier')),
            adjustDataCell(R.prop('serviceType')),
            adjustDataCell(R.prop('propertyName')),
            adjustDataCell(R.prop('description')),
            adjustDataCell(getProjectSpendTypeOrNothingUI),
            adjustDataCell(getProjectSpendCostByConfig),
            adjustDataCell(
              R.compose(convertCentsToDollars, R.prop('supplierTax')),
            ),
            adjustDataCell(R.compose(convertCentsToDollars, R.prop('total'))),
            adjustDataCellWithStyles(
              R.compose(convertCentsToDollars, R.prop('taxAmount')),
            ),
            adjustDataCellWithStyles(
              R.compose(convertCentsToDollars, R.prop('markupAmount')),
            ),
            adjustDataCellWithStyles(
              R.compose(convertCentsToDollars, R.prop('clientInvoicesAmount')),
            ),
            R.compose(R.map(adjustDataCell(R.identity)), R.prop('nodes')),
          ]),
        ),
      ),
    ),
    R.flatten,
    R.map(
      R.compose(
        R.when(
          R.compose(R.lt(1), R.length),
          R.compose(
            R.converge(R.over(R.lensIndex(0)), [
              R.compose(
                R.mergeRight,
                R.objOf('_polyInternal'),
                R.objOf('mergeDown'),
                R.length,
              ),
              R.identity,
            ]),
            (rows) =>
              rows.map((row, index) =>
                R.when(
                  () => index > 0,
                  R.assoc('skipClientInvoice', true),
                )(row),
              ),
          ),
        ),
        ({ invoices, ...rest }) =>
          R.map(
            R.compose(
              R.mergeRight(rest),
              R.dissoc('taxAmount'),
              assocBy('supplierTax', R.prop('taxAmount')),
            ),
          )(invoices),
      ),
    ),
  );

// getSpendReportGrandTotalRow :: (String, Int) -> Number -> [ExcelExportDataCell]
const getSpendReportGrandTotalRow = (label, maxNodesCount) =>
  R.compose(
    R.zipWith(createExcelExportCell, [
      ...R.repeat(ExcelExportCellType.tableStringFooter, 8),
      ...R.repeat(ExcelExportCellType.tableCurrencyFooter, 6),
      ...R.repeat(ExcelExportCellType.tableStringFooter, maxNodesCount),
    ]),
    R.flatten,
    R.append(R.repeat('', maxNodesCount)),
    R.prepend([label, ...R.repeat('', 9)]),
  );

// mergePropertyFieldsIntoInvoicesReports :: PropertySpendReportResult -> [InvoiceSpendReportResult]
const mergePropertyFieldsIntoInvoicesReports = R.converge(
  mergeObjectWithArrayOfObjects,
  [R.pick(['propertyName', 'nodes']), R.prop('propertyInvoicesReport')],
);

// getRowsByPropertySpendResult :: Int -> PropertySpendReportResult -> [[ExcelExportDataCell]]
const getRowsByPropertySpendResult = (maxNodesCount) =>
  R.compose(
    getRowByInvoiceSpendResult(maxNodesCount),
    R.flatten,
    R.juxt([
      mergePropertyFieldsIntoInvoicesReports,
      R.compose(
        R.map(mergePropertyFieldsIntoInvoicesReports),
        R.propOr([], 'childProperties'),
      ),
    ]),
    R.over(R.lensProp('propertyInvoicesReport'), R.defaultTo([])),
  );

// omitNonTransparentData :: [Any] -> [Any]
const omitNonTransparentData = R.compose(R.values, R.omit([1, 3, 9, 10]));

// omitMarkupAndTaxData :: [Any] -> [Any]
const omitMarkupAndTaxData = R.compose(R.values, R.omit([11, 12]));

// omitNotTransparentDataIfNeed :: Boolean -> Boolean -> [Any] -> [Any]
const omitNotTransparentDataIfNeed = R.curry((isTransparent, isAdmin, cells) =>
  R.compose(
    R.unless(R.always(isTransparent), omitNonTransparentData),
    R.unless(R.always(isAdmin), omitMarkupAndTaxData),
  )(cells),
);

// getRowsByReports :: Boolean -> Boolean -> [PropertySpendReportResult] -> Int -> [[ExcelExportCellType]]
const getRowsByReports = R.curry(
  (isTransparent, isAdmin, report, maxNodesCount) =>
    R.compose(
      R.unless(R.always(isTransparent), R.map(omitNonTransparentData)),
      R.unless(R.always(isAdmin), R.map(omitMarkupAndTaxData)),
      R.unnest,
      R.map(getRowsByPropertySpendResult(maxNodesCount)),
    )(report),
);
// getGrandTotalRow :: (Int, String) -> [PropertySpendReportResult] -> [ExcelExportDataCell]
const getGrandTotalRow = (maxNodesCount, label = 'Grand Total') =>
  R.compose(
    getSpendReportGrandTotalRow(label, maxNodesCount),
    R.juxt([
      R.compose(
        convertCentsToDollars,
        calculateTotal(R.propOr(0, 'invoicesTotal')),
      ),
      R.compose(
        convertCentsToDollars,
        calculateTotal(R.propOr(0, 'taxesTotal')),
      ),
      R.compose(
        convertCentsToDollars,
        calculateTotal(R.propOr(0, 'markupsTotal')),
      ),
      R.compose(
        convertCentsToDollars,
        calculateTotal(R.propOr(0, 'clientInvoicesTotal')),
      ),
    ]),
  );

// getMaxNodesCount :: [Property] -> Int
// Property = {
//    ...PropertySpendReportResult
//    nodes: [String]
// }
const getMaxNodesCount = R.compose(
  R.reduce(R.maxBy(R.identity), 0),
  R.map(R.path(['nodes', 'length'])),
);

const TOTAL_COLUMNS = 13;

// getClientNameRow ::  Number -> [[String], [Any]] [ExcelExportDataCell]
const getClientNameRow = (maxNodesCount) =>
  R.compose(
    R.of(Array),
    R.zipWith(createExcelExportCellWithStyles, [
      ...R.repeat(
        { font: { bold: true } },
        R.add(TOTAL_COLUMNS, maxNodesCount),
      ),
    ]),
    R.flatten,
    R.append(R.repeat('', R.add(TOTAL_COLUMNS, maxNodesCount))),
    R.of(Array),
    R.head,
  );

// getClientRowsByReports :: {maxNodesCount: Number, isTransparent: Number, isAdmin: Number}
// -> [PropertySpendReportResult] -> [[ExcelExportCellType]]
const getClientRowsByReports = ({ maxNodesCount, isTransparent, isAdmin }) =>
  R.compose(
    R.unnest,
    R.map(
      R.compose(
        R.unnest,
        R.juxt([
          getClientNameRow(maxNodesCount),
          R.compose(
            (report) =>
              getRowsByReports(isTransparent, isAdmin, report, maxNodesCount),
            R.last,
          ),
          ([name, report]) => [
            getGrandTotalRow(maxNodesCount, `${name} - Total`)(report),
          ],
        ]),
      ),
    ),
  );

export const getSpendReportsXlsConfig = (
  properties,
  clientName,
  startDate,
  endDate,
  isTransparent = true,
  isAdmin = false,
  clientsReport = null,
) => {
  const maxNodesCount = getMaxNodesCount(properties);

  return {
    exportFileName: 'spend-report.xlsx',
    columnWidths: omitNotTransparentDataIfNeed(isTransparent, isAdmin, [
      20,
      20,
      15,
      40,
      20,
      40,
      50,
      20,
      15,
      15,
      15,
      15,
      15,
      15,
      ...R.repeat(20, maxNodesCount),
    ]),
    rows: [
      [{ value: 'Spend Report', cellType: ExcelExportCellType.title }],
      [{ value: clientName, cellType: ExcelExportCellType.subTitle }],
      [
        {
          value: formatFromToDateTitle(startDate, endDate),
          cellType: ExcelExportCellType.subTitle,
        },
      ],

      R.zipWith(
        createExcelExportCell,
        omitNotTransparentDataIfNeed(isTransparent, isAdmin, [
          ...R.repeat(ExcelExportCellType.tableHeader, 8),
          ...R.repeat(ExcelExportCellType.tableCurrencyHeader, 6),
          ...R.repeat(ExcelExportCellType.tableHeader, maxNodesCount),
        ]),
        omitNotTransparentDataIfNeed(isTransparent, isAdmin, [
          'Project #',
          'Invoice #',
          'Invoice Date',
          'Supplier',
          'Service Type',
          'Property Name',
          'Description',
          'Spend Type',
          'Spend Cost',
          'Supplier Tax',
          'Cost',
          'Sales Tax',
          'Markup',
          'Client Invoice',
          ...R.repeat('', maxNodesCount).map(
            (_, index) => `Hierarchy Node ${index + 1}`,
          ),
        ]),
      ),

      ...(clientsReport
        ? getClientRowsByReports({ maxNodesCount, isAdmin, isTransparent })(
            clientsReport,
          )
        : getRowsByReports(
            isTransparent,
            isAdmin,
            properties,

            maxNodesCount,
          )),

      omitNotTransparentDataIfNeed(
        isTransparent,
        isAdmin,
        getGrandTotalRow(maxNodesCount)(properties),
      ),
    ],
  };
};
