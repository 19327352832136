import React, { useState } from 'react';
import { shape, string } from 'prop-types';
import styled from 'styled-components';
import { UPDATE_CLIENT_PERMISSION } from '@poly/security/src/permissions.js';
import { useHasUserAccessWithPermission } from '@poly/client-utils/src/hooks/useHasUserAccessWithPermission.js';
import { CommonSidebarFormWrapper } from '@poly/admin-ui/src/sidebar/CommonSidebarFormWrapper.js';
import { SidebarWrapperForTableForm } from '@poly/admin-ui/src/sidebar/SidebarWrapperForTableForm.js';

import { EditClientTabButton, TabSectionWrapper } from '../commonComponents.js';
import { ClientTagsTable } from './ClientTagsTable.js';
import { CreateClientTagForm, clientTagFormId } from './ClientTagForm.js';

const SidebarRowS = styled.div`
  display: flex;
  justify-content: end;
  margin-right: 15px;
  margin-bottom: ${({ showForm }) => (showForm ? '5px' : '-10px')};
  z-index: 99999;
`;

export function ClientTagsSubTab({ client }) {
  const [showForm, setShowForm] = useState(false);
  const hasAccess = useHasUserAccessWithPermission(UPDATE_CLIENT_PERMISSION);

  const handleCancelForm = () => setShowForm(false);

  return (
    <TabSectionWrapper>
      <SidebarRowS showForm={showForm}>
        {hasAccess && (
          <EditClientTabButton onClick={() => setShowForm(true)}>
            Add Tag
          </EditClientTabButton>
        )}
      </SidebarRowS>
      {showForm && (
        <CommonSidebarFormWrapper
          onCancel={handleCancelForm}
          title="Tag"
          formId={clientTagFormId}
        >
          <CreateClientTagForm
            clientId={client?._id}
            handleCancelForm={handleCancelForm}
          />
        </CommonSidebarFormWrapper>
      )}
      <SidebarWrapperForTableForm>
        <ClientTagsTable clientId={client?._id} />
      </SidebarWrapperForTableForm>
    </TabSectionWrapper>
  );
}

ClientTagsSubTab.propTypes = {
  client: shape({ _id: string }),
};
