import { ALL } from '@poly/admin-ui';
import { ProjectType, ProjectTypeToNameMap } from '@poly/constants';

export const ProjectCostTypeSelectOptions = [
  { label: 'All', value: ALL },
  {
    label: ProjectTypeToNameMap[ProjectType.WORK_ORDER],
    value: ProjectType.WORK_ORDER,
  },
  {
    label: ProjectTypeToNameMap[ProjectType.REPORT_ONLY],
    value: ProjectType.REPORT_ONLY,
  },
  {
    label: ProjectTypeToNameMap[ProjectType.PASS_THROUGH],
    value: ProjectType.PASS_THROUGH,
  },
  {
    label: ProjectTypeToNameMap[ProjectType.FEE],
    value: ProjectType.FEE,
  },
  {
    label: ProjectTypeToNameMap[ProjectType.BID],
    value: ProjectType.BID,
  },
  {
    label: ProjectTypeToNameMap[ProjectType.HOUSEKEEPING],
    value: ProjectType.HOUSEKEEPING,
  },
  {
    label: ProjectTypeToNameMap[ProjectType.LISTING],
    value: ProjectType.LISTING,
  },
];
