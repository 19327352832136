import { StaffReportMetricType } from '@poly/constants';

export const staffReportMetricTitles = [
  [StaffReportMetricType.ACTIVE_RECURRING_PROJECTS, 'Master Projects'],
  [StaffReportMetricType.ACTIVE_CHILDREN_PROJECTS, 'Children Projects'],
  [StaffReportMetricType.ACTIVE_PROJECTS, 'Regular Projects'],
  [
    StaffReportMetricType.COMPLETED_REACTIVE_PROJECTS,
    'Completed Reactive Projects',
  ],
  [
    StaffReportMetricType.COMPLETED_RECURRING_PROJECTS,
    'Completed Recurring Projects',
  ],
  [
    StaffReportMetricType.COMPLETED_PREVENTATIVE_PROJECTS,
    'Completed Preventative Projects',
  ],
  [
    StaffReportMetricType.COMPLETED_CAP_EX_PROJECTS,
    'Completed Cap.Ex Projects',
  ],
  [StaffReportMetricType.CLOSED_PROJECTS, 'Closed Projects'],
  [StaffReportMetricType.CREATED_PROJECTS, 'Created Projects'],
  [StaffReportMetricType.COMPLETED_PROJECT_TASKS, 'Completed Tasks'],
  [StaffReportMetricType.POSTED_UPDATES, 'Posted Updates'],
  [StaffReportMetricType.CREATED_SUPPLIERS, 'Created Suppliers'],
];
